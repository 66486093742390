import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import config from '../../config';

import css from './LegalInformationPage.css';

const LegalInformationPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'LegalInformationPage.legalInformationTitle' }),
      selected: true,
      linkProps: {
        name: 'LegalInformationPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.privacyTabTitle' }),
      selected: false,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'TermsOfServicePage.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LegalInformationPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const numberOfCustomers = '61 711';
  const yearOfUpdate = 2024;
  const updateMonth = 2;

  const dateOfLastUpdate = intl.formatDate(new Date(yearOfUpdate,updateMonth-1),  { month: 'long', year: 'numeric' });

  const sites = [
    {name: 'rent.decathlon.pl', link: 'https://rent.decathlon.pl/'},
    {name: 'rent.decathlon.hu', link: 'https://rent.decathlon.hu/'},
    {name: 'rent.decathlon.cz', link: 'https://rent.decathlon.cz/'},
    {name: 'rent.decathlon.lt', link: 'https://rent.decathlon.lt/'},
    {name: 'rent.decathlon.lv', link: 'https://rent.decathlon.lv/'},
    {name: 'rent.decathlon.ee', link: 'https://rent.decathlon.ee/'},
  ]

  const sitesList = sites.map(({name, link}, index) => <span><a href={link}>{name}</a>{index === sites.length-2 ? <>{' '}<FormattedMessage id="LegalInformationPage.and" />{' '}</> : index === sites.length-1 ? '' : ', '}</span>);

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LegalInformationPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id="LegalInformationPage.heading" />
            </h1>
            <p className={css.text}>
              <FormattedMessage id="LegalInformationPage.mainTextFirst" />
              {sitesList}
              <FormattedMessage id="LegalInformationPage.mainTextSecond" />
            </p>
            <h2> <FormattedMessage id="LegalInformationPage.subheading" /></h2>
            <p className={css.text}>
              <FormattedMessage id="LegalInformationPage.subTextFirst"/>
              {sitesList}
              <FormattedMessage id="LegalInformationPage.subTextSecond" values={{ numberOfCustomers, dateOfLastUpdate }} />
            </p>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

LegalInformationPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const LegalInformationPage = compose(
  connect(mapStateToProps),
  injectIntl
)(LegalInformationPageComponent);

export default LegalInformationPage;
