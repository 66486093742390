import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, BusinessInfo } from '../../components';

import css from './BookingConditionsForm.css';

const identity = v => v;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  render() {
    const isDecathlonUser = !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.decathlon_user);
    const isExternalProvider = !isDecathlonUser && !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.is_provider);
    const displayCompanySection = isDecathlonUser || isExternalProvider;

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadInProgress,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  {intl.formatMessage( { id: 'BookingConditionsForm.rentalDeposit' })}
                </h3>
                  <BusinessInfo
                    infoText={intl.formatMessage( { id: 'BookingConditionsForm.infoDepositConditions' })}
                  />
                  <FieldTextInput
                    className={css.businessName}
                    type="textarea"
                    id="depositConditions"
                    name="depositConditions"
                    label={intl.formatMessage( { id: 'BookingConditionsForm.labelDepositConditions' })}
                    placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderDepositConditions' })}
                  />
              </div>
              {
                isExternalProvider &&
                <div className={css.sectionContainer}>
                  <h3 className={css.sectionTitle}>
                  {intl.formatMessage( { id: 'BookingConditionsForm.rentTerms' })}
                  </h3>
                    <BusinessInfo
                      infoText={intl.formatMessage( { id: 'BookingConditionsForm.infoRentTerms' })}
                   />
                    <FieldTextInput
                      className={css.businessName}
                      type="textarea"
                      id="rentTerms"
                      name="rentTerms"
                      label={intl.formatMessage( { id: 'BookingConditionsForm.labelRentTerms' })}
                      placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderRentTerms' })}
                    />
                </div>
              }
              {
                isExternalProvider &&
                <div className={css.sectionContainer}>
                    <h3 className={css.sectionTitle}>
                    {intl.formatMessage( { id: 'BookingConditionsForm.minBookingDays' })}
                    </h3>
                      <BusinessInfo
                        infoText={intl.formatMessage( { id: 'BookingConditionsForm.infoMinBookingDays' })}
                      />
                      <FieldTextInput
                        className={css.businessName}
                        type="number"
                        id="minBookingDays"
                        name="minBookingDays"
                        label={intl.formatMessage( { id: 'BookingConditionsForm.labelMinBookingDays' })}
                        placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderMinBookingDays' })}
                        min={1}
                        max={30}
                      />
                  </div>
              }
              {
                isExternalProvider &&
                <div className={css.sectionContainer}>
                  <h3 className={css.sectionTitle}>
                    {intl.formatMessage( { id: 'BookingConditionsForm.discounts' })}
                  </h3>
                    <BusinessInfo
                      infoText= {intl.formatMessage( { id: 'BookingConditionsForm.infoDiscounts' })}
                    />
                    <>
                      <FieldTextInput
                        className={css.businessName}
                        type="number"
                        id="fourDaysDiscount"
                        name="fourDaysDiscount"
                        label={intl.formatMessage( { id: 'BookingConditionsForm.labelFourDaysDiscount' })}
                        placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderFourDaysDiscount' })}
                        min={0}
                        max={90}
                      />
                      <FieldTextInput
                        className={css.businessName}
                        type="number"
                        id="weekDiscount"
                        name="weekDiscount"
                        label={intl.formatMessage( { id: 'BookingConditionsForm.labelWeekDiscount' })}
                        placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderWeekDiscount' })}
                        min={0}
                        max={90}
                      />
                      <FieldTextInput
                        className={css.businessName}
                        type="number"
                        id="monthDiscount"
                        name="monthDiscount"
                        label={intl.formatMessage( { id: 'BookingConditionsForm.labelMonthDiscount' })}
                        placeholder={intl.formatMessage( { id: 'BookingConditionsForm.placeholderMonthDiscount' })}
                        min={0}
                        max={90}
                      />
                    </>
                </div>
              }
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                {intl.formatMessage( { id: 'BookingConditionsForm.openingHours' })}
                </h3>
                  <BusinessInfo
                    infoText=  {intl.formatMessage( { id: 'BookingConditionsForm.infoOpeningHours' })}
                  />
                  <FieldTextInput
                    className={css.businessName}
                    type="textarea"
                    id="openingHours"
                    name="openingHours"
                    label=  {intl.formatMessage( { id: 'BookingConditionsForm.labelOpeningHours' })}
                    placeholder=  {intl.formatMessage( { id: 'BookingConditionsForm.placeholderOpeningHours' })}
                  />
                </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
