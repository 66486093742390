export const addToCartEvent = (params) => {
  const { atcType, currency, value, item } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'add_to_cart',
    atcType: atcType,
    ecommerce: {
      currency: currency,
      value: value,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_brand: '',
          item_category: item.category,
          item_variant: '',
          store_name: item.provider,
          location_id: '',
          price: item.price,
          quantity: item.quantity
        }
      ]
    }
  });
}

export const beginCheckoutEvent = (params) => {
  const { currency, value, items } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: currency,
      value: value,
      items: items.map(item => (
        {
          item_id: item.id,
          item_name: item.name,
          item_brand: '',
          item_category: item.category,
          item_variant: '',
          store_name: item.provider,
          location_id: '',
          price: item.price,
          quantity: item.quantity
        }
      )
      )
    }
  });
}

export const finishedCheckoutEvent = (params) => {
  const { currency, paymentType, value, items, discount, discountValue } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'finished_checkout',
    ecommerce: {
      currency: currency,
      discount,
      discountValue,
      value: value,
      payment_type: paymentType,
      items: items.map(item => (
        {
          item_id: item.id,
          item_name: item.name,
          item_brand: '',
          item_category: item.category,
          item_variant: '',
          store_name: item.provider,
          location_id: '',
          price: item.price,
          quantity: item.quantity
        }
      )
      )
    }
  });
}

export const searchResultsEvent = (params) => {
  const { searchTerm = '', searchCategory = '', searchSubcategory = '', searchCity = '' } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'search_term',
    search_term: searchTerm,
    search_category: searchCategory,
    search_subcategory: searchSubcategory,
    search_city: searchCity,
    search_type: !searchCategory && !searchSubcategory ? 'manual' : 'autosuggestion'
  });
}

export const openCalendarEvent = (params) => {
  const { currency, item, value } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

  window.dataLayer.push({
    event: 'open_calendar',
    ecommerce: {
      currency: currency,
      value: value,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_brand: '',
          item_category: item.category,
          item_variant: '',
          store_name: item.provider,
          location_id: '',
          price: item.price,
          quantity: item.quantity,
        },
      ],
    },
  });
}

export const selectDatesEvent = (params) => {
  const { currency, item, value } = params;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'select_dates',
    ecommerce: {
      currency: currency,
      value,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_brand: '',
          item_category: item.category,
          item_variant: '',
          store_name: item.provider,
          location_id: '',
          price: item.price,
          quantity: item.quantity
        }
      ]
    }
  });
}
