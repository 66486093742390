import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './SectionTop.css';
import { FormattedMessage } from 'react-intl';

const SectionTop = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="SectionTop.title"/>
        </h1>
        <p></p>
      </div>
      <div className={css.elements}>
        <NamedLink
          className={css.primaryElement}
          name="SearchPage"
          to={{ search: '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=rower gorski elektryczny e-st 500 27​,​5 rockrider' }}
        >
          <div className={css.img}>
            <div className={css.imgWrapper}><img src={"https://sharetribe.imgix.net/5f1acc63-5ecf-40a6-8e59-5a2affb8bc6b%2F5f21814a-813c-4fc0-b6c5-3d97925d7acf?auto=format&fit=clip&h=750&ixlib=java-1.1.1&w=750&s=fe215352664f6800e22458997d7ddecd"}/></div>
            <div className={css.desc}>
            <h2>
              <FormattedMessage id="SectionTop.productOne"/>
            </h2>
            <p>
              <FormattedMessage id="SectionTop.textOne"/>
            </p>
          </div>
          </div>
          
        </NamedLink>
        <NamedLink
          className={css.primaryElement}
          name="SearchPage"
          to={{ search: '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=rower szosowy Triban RC520 z hamulcami tarczowymi' }}
        >
          <div className={css.img}>
            <div className={css.imgWrapper}><img src={"https://sharetribe.imgix.net/5f1acc63-5ecf-40a6-8e59-5a2affb8bc6b%2F5f61be04-20e6-47a0-89ac-41f0eae56c08?auto=format&fit=clip&h=750&ixlib=java-1.1.1&w=750&s=afde96dda80273da2ac2617ea4d4b40a"}/></div>
            <div className={css.desc}>
            <h2>
              <FormattedMessage id="SectionTop.productTwo"/>
            </h2>
            <p>
              <FormattedMessage id="SectionTop.textOne"/>
            </p>
          </div>
          </div>
          
        </NamedLink>
        <NamedLink
          className={css.primaryElement}
          name="SearchPage"
          to={{ search: '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=rower górski mtb st' }}
        >
          <div className={css.img}>
            <div className={css.imgWrapper}><img src={"https://sharetribe.imgix.net/5f1acc63-5ecf-40a6-8e59-5a2affb8bc6b%2F5f202707-a460-4e57-a75a-91e62ed0a534?auto=format&fit=clip&h=750&ixlib=java-1.1.1&w=750&s=7eb8459a53d21f1bf595beac507b7a50"}/></div>
            <div className={css.desc}>
            <h2>
              <FormattedMessage id="SectionTop.productThree"/>
            </h2>
            <p>
              <FormattedMessage id="SectionTop.textTwo"/>
            </p>
          </div>
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

SectionTop.defaultProps = { rootClassName: null, className: null };

SectionTop.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTop;
