import React from 'react';
import { number, string, func, arrayOf, object } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../../util/reactIntl';
import { IconClose, IconMap, IconSpinner } from '../../../../components';
import { injectIntl } from '../../../../util/reactIntl';

import css from './LocationDefaultList.css';

// Touch devices need to be able to distinguish touches for scrolling and touches to tap
const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const NUMBER_OF_DEFAULT_CITIES = 6;

const LocationDefaultList = ({
  className,
  clearSelectEnd,
  highlightedIndex,
  latestSearches,
  defaultCountryCities,
  intl,
  onSelectStart,
  onSelectMove,
  onSelectEnd,
  userLocationSelectEnd,
  userLocationIsLoading,
  rootClassName,
}) => {
  const classes = classNames(rootClassName || css.suggestionRoot, className);
  const filterDefaultCities = defaultCountryCities.filter(
    (item, index) => index < NUMBER_OF_DEFAULT_CITIES
  );

  const displayListItem = (suggestion, isHighlighted, index) => (
    <li
      className={isHighlighted ? css.highlighted : null}
      key={index}
      onTouchStart={e => {
        e.preventDefault();
        onSelectStart(getTouchCoordinates(e.nativeEvent));
      }}
      onMouseDown={e => {
        e.preventDefault();
        onSelectStart();
      }}
      onTouchMove={e => {
        e.preventDefault();
        onSelectMove(getTouchCoordinates(e.nativeEvent));
      }}
      onTouchEnd={e => {
        e.preventDefault();
        onSelectEnd(suggestion);
      }}
      onMouseUp={e => {
        e.preventDefault();
        onSelectEnd(suggestion);
      }}
    >
      <span>{suggestion.location}</span>
    </li>
  );

  const userLocationButton = isHighlighted =>
    userLocationIsLoading ? (
      <div className={css.loadingContainer}>
        <IconSpinner />
      </div>
    ) : (
      <button
        className={classNames(css.btn, isHighlighted ? css.highlighted : null)}
        onTouchStart={e => {
          e.preventDefault();
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={e => {
          e.preventDefault();
          onSelectStart();
        }}
        onTouchMove={e => {
          e.preventDefault();
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={e => {
          e.preventDefault();
          userLocationSelectEnd();
        }}
        onMouseUp={e => {
          e.preventDefault();
          userLocationSelectEnd();
        }}
      >
        <IconMap />
        <span>{intl.formatMessage({ id: 'AutocompleteLocation.yourLocation' })}</span>
      </button>
    );

  const clearButton = (
    <button
      className={css.clearBtn}
      onTouchStart={e => {
        e.preventDefault();
        onSelectStart(getTouchCoordinates(e.nativeEvent));
      }}
      onMouseDown={e => {
        e.preventDefault();
        onSelectStart();
      }}
      onTouchMove={e => {
        e.preventDefault();
        onSelectMove(getTouchCoordinates(e.nativeEvent));
      }}
      onTouchEnd={e => {
        e.preventDefault();
        clearSelectEnd();
      }}
      onMouseUp={e => {
        e.preventDefault();
        clearSelectEnd();
      }}
    >
      <FormattedMessage id={'MainFilters.clear'} />
      <IconClose className={css.icon} />
    </button>
  );

  return (
    <div className={classes}>
      {userLocationButton(highlightedIndex === 0)}
      {!!latestSearches.length && (
        <div className={css.container}>
          <div className={css.subheadingContainer}>
            <p className={css.subheading}>
              <FormattedMessage id={'MainFilters.latestSearch'} />
            </p>
            {clearButton}
          </div>
          <ul className={css.suggestionList}>
            {latestSearches.map((suggestion, index) => {
              const isHighlighted = index === highlightedIndex - 1;
              return displayListItem(suggestion, isHighlighted, index);
            })}
          </ul>
        </div>
      )}
      <div className={css.container}>
        <p className={css.subheading}>
          <FormattedMessage id={'MainFilters.suggestions'} />
        </p>
        <ul className={css.suggestionList}>
          {filterDefaultCities.map((suggestion, index) => {
            const isHighlighted = index === highlightedIndex - latestSearches.length - 1;
            return displayListItem(suggestion, isHighlighted, index);
          })}
        </ul>
      </div>
    </div>
  );
};

LocationDefaultList.defaultProps = {
  rootClassName: null,
  className: null,
  highlightedIndex: null,
};

LocationDefaultList.propTypes = {
  rootClassName: string,
  className: string,
  highlightedIndex: number,
  latestSearches: arrayOf(object),
  clearSelectEnd: func.isRequired,
  defaultCountryCities: arrayOf(object).isRequired,
  onSelectStart: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectEnd: func.isRequired,
};

export default injectIntl(LocationDefaultList);
