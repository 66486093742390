import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconMap = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 17.4167L14.125 13.2917C14.9408 12.4759 15.4963 11.4365 15.7213 10.3049C15.9463 9.17337 15.8308 8.00051 15.3892 6.93465C14.9477 5.86878 14.2 4.95777 13.2408 4.31682C12.2815 3.67587 11.1537 3.33377 10 3.33377C8.8463 3.33377 7.71851 3.67587 6.75924 4.31682C5.79997 4.95777 5.05229 5.86878 4.61076 6.93465C4.16923 8.00051 4.05368 9.17337 4.27871 10.3049C4.50374 11.4365 5.05926 12.4759 5.875 13.2917L10 17.4167ZM10 19.7734L4.69667 14.47C3.64779 13.4211 2.93349 12.0847 2.64411 10.6299C2.35473 9.17504 2.50326 7.66704 3.07092 6.2966C3.63858 4.92616 4.59987 3.75483 5.83324 2.93072C7.0666 2.10661 8.51665 1.66675 10 1.66675C11.4834 1.66675 12.9334 2.10661 14.1668 2.93072C15.4001 3.75483 16.3614 4.92616 16.9291 6.2966C17.4968 7.66704 17.6453 9.17504 17.3559 10.6299C17.0665 12.0847 16.3522 13.4211 15.3033 14.47L10 19.7734ZM10 10.8334C10.442 10.8334 10.866 10.6578 11.1785 10.3452C11.4911 10.0326 11.6667 9.60871 11.6667 9.16668C11.6667 8.72466 11.4911 8.30073 11.1785 7.98817C10.866 7.67561 10.442 7.50002 10 7.50002C9.55798 7.50002 9.13405 7.67561 8.82149 7.98817C8.50893 8.30073 8.33334 8.72466 8.33334 9.16668C8.33334 9.60871 8.50893 10.0326 8.82149 10.3452C9.13405 10.6578 9.55798 10.8334 10 10.8334ZM10 12.5C9.11595 12.5 8.2681 12.1488 7.64298 11.5237C7.01786 10.8986 6.66667 10.0507 6.66667 9.16668C6.66667 8.28263 7.01786 7.43478 7.64298 6.80966C8.2681 6.18454 9.11595 5.83335 10 5.83335C10.8841 5.83335 11.7319 6.18454 12.357 6.80966C12.9821 7.43478 13.3333 8.28263 13.3333 9.16668C13.3333 10.0507 12.9821 10.8986 12.357 11.5237C11.7319 12.1488 10.8841 12.5 10 12.5Z"
        fill="#3643BA"
      />
    </svg>
  );
};

IconMap.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMap.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMap;
