import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ClearButton.css';

const ClearButton = ({ buttonClassName, ...props }) => {
  const buttonClasses = classNames(buttonClassName, css.btnClear);

  return (
    <button type="button" className={buttonClasses} {...props}>
      <FormattedMessage id="FilterModal.clear" />
    </button>
  );
};

ClearButton.defaultProps = {
  buttonClassName: null,
};

ClearButton.propTypes = {
  buttonClassName: string,
};

export default ClearButton;
