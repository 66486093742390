import omit from 'lodash/omit';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditProductPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditProductPage/CLEAR_UPDATED_TAB';

export const CREATE_PRODUCT_DRAFT_REQUEST = 'app/EditProductPage/CREATE_PRODUCT_DRAFT_REQUEST';
export const CREATE_PRODUCT_DRAFT_SUCCESS = 'app/EditProductPage/CREATE_PRODUCT_DRAFT_SUCCESS';
export const CREATE_PRODUCT_DRAFT_ERROR = 'app/EditProductPage/CREATE_PRODUCT_DRAFT_ERROR';

export const PUBLISH_PRODUCT_REQUEST = 'app/EditProductPage/PUBLISH_PRODUCT_REQUEST';
export const PUBLISH_PRODUCT_SUCCESS = 'app/EditProductPage/PUBLISH_PRODUCT_SUCCESS';
export const PUBLISH_PRODUCT_ERROR = 'app/EditProductPage/PUBLISH_PRODUCT_ERROR';

export const UPDATE_PRODUCT_REQUEST = 'app/EditProductPage/UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'app/EditProductPage/UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'app/EditProductPage/UPDATE_PRODUCT_ERROR';

export const SHOW_PRODUCTS_REQUEST = 'app/EditProductPage/SHOW_PRODUCTS_REQUEST';
export const SHOW_PRODUCTS_SUCCESS = 'app/EditProductPage/SHOW_PRODUCTS_SUCCESS';
export const SHOW_PRODUCTS_ERROR = 'app/EditProductPage/SHOW_PRODUCTS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditProductPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditProductPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditProductPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditProductPage/UPDATE_IMAGE_ORDER';

export const REMOVE_PRODUCT_IMAGE = 'app/EditProductPage/REMOVE_PRODUCT_IMAGE';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createProductError: null,
  updateProductError: null,
  showProductError: null,
  uploadImageError: null,
  createProductInProgress: false,
  submittedProductId: null,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  listingDraft: null,
  updatedTab: null,
  updateProductInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateProductError: null };

    case CREATE_PRODUCT_DRAFT_REQUEST:
      return {
        ...state,
        createProductInProgress: true,
        createProductError: null,
        submittedProductId: null,
        listingDraft: null,
      };

    case CREATE_PRODUCT_DRAFT_SUCCESS:
      return {
        ...state,
        createProductInProgress: false,
        submittedProductId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_PRODUCT_DRAFT_ERROR:
      return {
        ...state,
        createProductInProgress: false,
        createProductError: payload,
      };

    case UPDATE_PRODUCT_REQUEST:
      return { ...state, updateProductInProgress: true, updateProductError: null };
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, updateProductInProgress: false };
    case UPDATE_PRODUCT_ERROR:
      return { ...state, updateProductInProgress: false, updateProductError: payload };

    case SHOW_PRODUCTS_REQUEST:
      return { ...state, showProductError: null };
    case SHOW_PRODUCTS_SUCCESS:
      return { ...initialState, availabilityCalendar: { ...state.availabilityCalendar } };

    case SHOW_PRODUCTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showProductError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER: {
      return { ...state, imageOrder: payload.imageOrder };
    }

    case REMOVE_PRODUCT_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => {
  return {
    type: UPDATE_IMAGE_ORDER,
    payload: { imageOrder },
  }
};

export const removeProductImage = imageId => ({
  type: REMOVE_PRODUCT_IMAGE,
  payload: { imageId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createProduct = requestAction(CREATE_PRODUCT_DRAFT_REQUEST);
export const createProductSuccess = successAction(CREATE_PRODUCT_DRAFT_SUCCESS);
export const createProductError = errorAction(CREATE_PRODUCT_DRAFT_ERROR);

// SDK method: ownListings.update
export const updateProduct = requestAction(UPDATE_PRODUCT_REQUEST);
export const updateProductSuccess = successAction(UPDATE_PRODUCT_SUCCESS);
export const updateProductError = errorAction(UPDATE_PRODUCT_ERROR);

// SDK method: ownListings.show
export const showProduct = requestAction(SHOW_PRODUCTS_REQUEST);
export const showProductSuccess = successAction(SHOW_PRODUCTS_SUCCESS);
export const showProductError = errorAction(SHOW_PRODUCTS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// ================ Thunk ================ //

export function requestShowProduct(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showProduct(actionPayload));
    return sdk.newSdk.catalogProducts
      .show(actionPayload)
      .then(response => {
        // EditListingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.EditListingPage (user will be redirected away)
        dispatch(showProductSuccess(response));
        return response;
      })
      .catch(e => dispatch(showProductError(storableError(e))));
  };
}

export function requestCreateProduct(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createProduct(data));

    const queryParams = {
      expand: true,
      include: ['images'],
    };

    return sdk.newSdk.catalogProducts
      .create(data, queryParams)
      .then(async response => {
        const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createProductSuccess(response));
        const payload = {
          id,
          include: ['images'],
        };
        await dispatch(requestShowProduct(payload))
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createProductError(storableError(e)));
      });
  };
}

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return sdk.newSdk.images
      .upload({ file: actionPayload.file })
      .then(resp => {
        dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } }))
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateProduct(data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateProduct(data));
    const { id, countryId, ...restData } = data;
    let updateResponse;

    return sdk.newSdk.catalogProducts
      .update({ id: id, ...restData })
      .then(response => {
        updateResponse = response;
        const payload = {
          id: id,
          include: ['images'],
        };
        return dispatch(requestShowProduct(payload));
      })
      .then(() => {
        dispatch(updateProductSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        return dispatch(updateProductError(storableError(e)));
      });
  };
}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  const { id, type, tab } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(response => {
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = {
    id,
    include: ['images'],
  };

  return Promise.all([
    dispatch(requestShowProduct(payload)),
    dispatch(fetchCurrentUser()),
  ])
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};
