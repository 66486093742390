import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  loadData,
  deletePermissions,
  createPermissions,
  updatePermissions,
  clearUpdatePermissions,
} from './TeamPage.duck';
import {
  IconSpinner,
  IconAdd,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconWarning,
  Select,
} from '../../components';
import PermissionItem from './PermissionItem/PermissionItem';
import PermissionCreationCard from './PermissionCreationCard/PermissionCreationCard';
import { TopbarContainer } from '../../containers';

import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './TeamPage.css';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const TeamPageComponent = props => {
  const {
    users,
    fetchInProgress,
    scrollingDisabled,
    fetchPermissionsError,
    intl,
    location: { search },
    history,
    userProviders,
    deletePermissions,
    deleteInProgress,
    deletePermissionsError,
    deletePermissionsSuccess,
    createPermissions,
    createInProgress,
    createPermissionsSuccess,
    createPermissionsError,
    onManageDisableScrolling,
    params,
    updateInProgress,
    updatePermissions,
    updatePermissionsSuccess,
    updatePermissionsError,
    clearUpdatePermissionsState,
  } = props;
  const { providerId } = params;
  const [isOpenNewUser, setIsOpenNewUser] = useState(false);
  const [permissionIdEdit, setPermissionIdEdit] = useState(null);
  const providerName = userProviders.find(provider => provider.id.uuid === providerId)?.attributes
    .name;
  const title = intl.formatMessage({ id: 'TeamPage.title' });

  const errorMessage = fetchPermissionsError ? (
    providerId ? (
      <p className={css.error}>
        <IconWarning variant="error" /> <span>{fetchPermissionsError.message}</span>
      </p>
    ) : (
      <p><FormattedMessage id="TeamPage.noUserFound" /></p>
    )
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !users.length && !fetchPermissionsError ? (
      <p>
        <FormattedMessage id="TeamPage.noUserFound" />
      </p>
    ) : null;

  const handleProviderChange = event => {
    history.push(`/team/${event.target.value}?${stringify({ ...parse(search), page: 1 })}`);
  };

  const providersWithOwnerRole = userProviders.filter(
    provider => provider.attributes.myRole === 'owner'
  );
  const sortedProviders = providersWithOwnerRole?.sort((a, b) =>
    b.attributes?.name.toLowerCase() < a.attributes?.name.toLowerCase() ? 1 : -1
  );

  const handleCreateUser = event => {
    event.preventDefault();
    setIsOpenNewUser(prevState => !prevState);
  };

  const addPermissions = (
    <div>
      {isOpenNewUser ? (
        <PermissionCreationCard
          intl={intl}
          providerId={providerId}
          providerName={providerName}
          createInProgress={createInProgress}
          createPermissionsSuccess={createPermissionsSuccess}
          createPermissions={createPermissions}
          createPermissionsError={createPermissionsError}
          setIsOpenNewUser={setIsOpenNewUser}
          key={providerId}
        />
      ) : (
        providerId && (
          <button onClick={handleCreateUser} className={css.addButton}>
            <IconAdd />
            {intl.formatMessage({ id: 'TeamPage.addPermissions' })}
          </button>
        )
      )}
    </div>
  );

  useEffect(() => {
    updatePermissionsSuccess && setPermissionIdEdit(null);
  }, [updatePermissionsSuccess]);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="TeamPage" />
          <UserNav selectedPageName="TeamPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="TeamPage" />
        <LayoutWrapperMain className={css.colorBackground}>
          <div>
            <Select
              label= {intl.formatMessage({ id: 'General.provider' })}
              value={providerId}
              onChange={handleProviderChange}
            >
              <option hidden value="">{intl.formatMessage({ id: 'Console.select' })}</option>
              {sortedProviders.map(provider => (
                <option value={provider.id.uuid}>{provider.attributes.name}</option>
              ))}
            </Select>
            {addPermissions}
            {errorMessage}
            {fetchInProgress ? (
              <div className={css.listItemsLoading}>
                <IconSpinner />
              </div>
            ) : (
              <ul>
                {users.map(user => {
                  const {
                    id: { uuid },
                  } = user;
                  return (
                    <PermissionItem
                      intl={intl}
                      user={user}
                      deletePermissions={deletePermissions}
                      deleteInProgress={deleteInProgress}
                      deletePermissionsSuccess={deletePermissionsSuccess}
                      deletePermissionsError={deletePermissionsError}
                      onManageDisableScrolling={onManageDisableScrolling}
                      updateInProgress={updateInProgress}
                      updatePermissions={updatePermissions}
                      updatePermissionsSuccess={updatePermissionsSuccess}
                      updatePermissionsError={updatePermissionsError}
                      permissionIdEdit={permissionIdEdit}
                      setPermissionIdEdit={setPermissionIdEdit}
                      clearUpdatePermissionsState={clearUpdatePermissionsState}
                    />
                  );
                })}
              </ul>
            )}
            {noResultsMessage}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <ScrollToTop />
    </Page>
  );
};

TeamPageComponent.defaultProps = {
  users: [],
  fetchInProgress: false,
  fetchPermissionsError: null,
  scrollingDisabled: false,
};

TeamPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchPermissionsError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchPermissionsError,
    permissions,
    deleteInProgress,
    deletePermissionsError,
    deletePermissionsSuccess,
    createInProgress,
    createPermissionsError,
    createPermissionsSuccess,
    updateInProgress,
    updatePermissionsError,
    updatePermissionsSuccess,
  } = state.TeamPage;
  const { currentUser, userProviders } = state.user;
  return {
    fetchInProgress,
    fetchPermissionsError,
    scrollingDisabled: isScrollingDisabled(state),
    users: getMarketplaceEntities(state, permissions),
    currentUser,
    userProviders,
    deleteInProgress,
    deletePermissionsError,
    deletePermissionsSuccess,
    createInProgress,
    createPermissionsError,
    createPermissionsSuccess,
    updateInProgress,
    updatePermissionsError,
    updatePermissionsSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  deletePermissions: data => dispatch(deletePermissions(data)),
  createPermissions: data => dispatch(createPermissions(data)),
  updatePermissions: data => dispatch(updatePermissions(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  clearUpdatePermissionsState: () => dispatch(clearUpdatePermissions()),
});

const TeamPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(TeamPageComponent);

TeamPage.loadData = loadData;

export default TeamPage;
