import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

import bikesImage from './images/bikes_hp.jpg';
import watersportsImage from './images/watersports_hp.jpg';
import tourismImage from './images/tourism_hp.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, description, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  const descriptionText = <span className={css.locationDesc}>{description}</span>
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        {/* <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        /> */}
        { nameText }
        { descriptionText }
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641',
          }}
          className={css.heroButton}
        >
          Przeglądaj wszystkie produkty
        </NamedLink> */}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Rowery i akcesoria',
          'Wybieraj z pośród wielu najnowszych modeli rowerów dostępnych w sklepach Decathlon.',
          bikesImage,
          '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&pub_category=bikes'
        )}
        {locationLink(
          'Sporty wodne',
          'Sprawdź się w sportach wodnych testując takie produkty jak: SUP, kajak, surf i wiele innych…',
          watersportsImage,
          '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&pub_category=watersports'
        )}
        {locationLink(
          'Turystyka',
          'Planujesz wypad w góry? Zaopatrz się w namiot, plecak, nosidełko i ciesz się przygodą!',
          tourismImage,
          '?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&pub_category=tourism'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
