import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import Options from './Options';
import IconArrowHead from '../IconArrowHead/IconArrowHead';

import css from './AutocompleteSelect.css';

const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const KEY_CODE_BACKSPACE = 8;

const AutocompleteSelect = (props) => {
  const {
    inputValue,
    onChange,
    placeholder,
    inputClassName,
    inputFocusClassName,
    optionsWrapperClassName,
    inputContainerClassName,
    labelClassName,
    errorClassName,
    options,
    label,
    autocompleteClassName,
    error,
    isValidation,
    markRequired,
    disabled,
    onFocus,
    ...rest
  } = props;
  const labelValue = options.find(o => o.value === inputValue.value)?.label || '';
  const [phrase, setPhrase] = useState(labelValue);
  const [selectedValue, setSelectedValue] = useState(inputValue);
  const [suggestions, setSuggestions] = useState(options);
  const [suggestionsOpened, setSuggestionsOpened] = useState(false);
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState(-1);
  const refSuggestions = useRef(null);
  const ref = useRef(null);
  const selectedRef = useRef(null);
  const optionsWrapperClasses = classNames(
    optionsWrapperClassName,
    css.suggestionsWrapper,
    { [css.validationWrapper]: isValidation }
  );
  const autocompleteClasses = classNames(autocompleteClassName,css.wrapper);
  const inputClasses = classNames(inputClassName,css.input, {
    [inputFocusClassName]: suggestionsOpened,
  });
  const containerClasses = classNames(
    css.container,
    { [css.validation]: isValidation },
    inputContainerClassName
  );
  const labelClasses = classNames(css.labelText, labelClassName);
  const errorClasses = classNames(css.error, errorClassName);

  const handleChange = e => {
    if (!e.target.value) {
      onChange({value: '', label: ''});
      setSelectedValue({value: '', label: ''});
    }
    setPhrase(e.target.value)
    setSuggestions(options.filter(option => {
      const labelLowerCase = option.label.toLowerCase();
      const valueLowerCase = e.target.value.toLowerCase();
      return labelLowerCase.includes(valueLowerCase);
    }));
  };

  const handleClick = (chosenValue,label) => {
    onChange({value: chosenValue, label});
    setSelectedValue({value: chosenValue, label});
    setPhrase(label);
    setSuggestions(options.filter(option => {
      const labelLowerCase = option.label.toLowerCase();
      const valueLowerCase = label.toLowerCase();
      return labelLowerCase.includes(valueLowerCase);
    }));
    setSuggestionsOpened(false);
    ref.current.blur();
  };

  const suggestionsOnChange = (index) => {
    onChange({value: suggestions[index].value, label: suggestions[index].label});
    setSelectedValue({value: suggestions[index].value, label: suggestions[index].label});
    setPhrase(suggestions[index].label);
    setSuggestions(options.filter(option => {
      const labelLowerCase = option.label.toLowerCase();
      const valueLowerCase = suggestions[index].label.toLowerCase();
      return labelLowerCase.includes(valueLowerCase);
    }));
  };

  const finalize = () => {
    setSuggestionsOpened(false);
    ref.current.blur();
    setHighlightedSuggestionIndex(-1);
  };

  const setScroll = () => {
    const selected = selectedRef?.current?.querySelector("#highlighted");
    if (selected) {
      return selected?.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }

  const handleKeyDown = event => {
    const suggestionsLength = suggestions.length;
    if (event.keyCode === KEY_CODE_ARROW_DOWN) {
      event.preventDefault();
      const stepValue = 1;
      const index =
        highlightedSuggestionIndex === -1
          ? 0
          : (highlightedSuggestionIndex + stepValue) % suggestionsLength;

      setHighlightedSuggestionIndex(index);
    } else if (event.keyCode === KEY_CODE_ARROW_UP) {
        event.preventDefault();
        const stepValue = -1;
        const index =
          highlightedSuggestionIndex <= 0
            ? suggestionsLength - 1
            : (highlightedSuggestionIndex + stepValue) % suggestionsLength;

        setHighlightedSuggestionIndex(index);
    } else if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault();
      event.stopPropagation();

      if (highlightedSuggestionIndex !== -1) {
          highlightedSuggestionIndex >= 0 &&
            suggestionsOnChange(highlightedSuggestionIndex);
      }
      finalize();
    } else if (event.keyCode === KEY_CODE_BACKSPACE) {
      setSuggestionsOpened(true);
    } else if (event.keyCode === KEY_CODE_ESC || event.keyCode === KEY_CODE_TAB) {
      finalize();
    }
  };

  const handleFocus = () => {
    setSuggestionsOpened(true);
    setPhrase('')
    onFocus && onFocus();
  }

  useEffect(() => {
    const handleOutsideClick = event => {
      const isSuggestionsClosed =
        ref?.current &&
        !ref?.current.contains(event.target) &&
        !refSuggestions.current?.contains(event.target);

      if (isSuggestionsClosed) {
        !selectedValue.value && setPhrase('');
        setSuggestionsOpened(false);
        setHighlightedSuggestionIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [inputValue]);

  useEffect(() => {
    setSuggestions(options);
  }, [suggestionsOpened, options.length]);

  useEffect(() => {
    const inputLabel = options.find(o => o.value === inputValue.value)?.label;
    if (inputLabel) {
      setPhrase(inputLabel);
      setSuggestions(options.filter(option => {
        const labelLowerCase = option.label.toLowerCase();
        const valueLowerCase = inputLabel.toLowerCase();
        return labelLowerCase.includes(valueLowerCase);
      }));
    }
  }, [inputValue]);

  return (
    <div className={autocompleteClasses}>
      <div className={containerClasses}>
        <label className={css.label}>
          {label && (
            <span className={labelClasses}>
            {label}
              {markRequired && '*'}
          </span>
          )}
          <input
            value={phrase}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            className={inputClasses}
            onKeyDown={handleKeyDown}
            ref={ref}
            disabled={disabled}
            autocomplete="off"
            {...rest}
          />
          <IconArrowHead direction={suggestionsOpened ? 'up' : 'down'} className={css.arrow} />
        </label>
        {error && isValidation && <span className={errorClasses}>{error}</span>}
      </div>
      {suggestionsOpened && !disabled && (
        <div className={optionsWrapperClasses} ref={refSuggestions}>
            <Options
              value={phrase}
              handleClick={handleClick}
              highlightedSuggestionIndex={highlightedSuggestionIndex}
              options={suggestions}
              ref={selectedRef}
              setScroll={setScroll}
            />
        </div>
      )}
    </div>
  );
};

AutocompleteSelect.defaultProps = {
  optionsWrapperClasses: null,
  autocompleteClassName: null,
  inputFocusClassName: null,
  inputContainerClassName: null,
  errorClassName: null,
  labelClassName: null,
  options: [],
  label: null,
  error: null,
  markRequired: false,
  isValidation: true,
  inputValue: { value: '', label: '' },
  disabled: false,
};

AutocompleteSelect.propTypes = {
  optionsWrapperClasses: string,
  autocompleteClassName: string,
  inputFocusClassName: string,
  errorClassName: string,
  labelClassName: string,
  inputContainerClassName: string,
  options: arrayOf(shape({ value: string, label: string })),
  inputValue: shape({ value: string, label: string }),
  onChange: func,
  label: string,
  error: string,
  markRequired: bool,
  isValidation: bool,
  disabled: bool,
};

export default AutocompleteSelect;
