import React from 'react';
import { FormattedMessage } from './util/reactIntl';

export const countryId = process.env.REACT_APP_COUNTRY_ID;
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

  //   config: {
const homeDeliveryMaybe = countryId === 'pl' ? [
  {
    id: 'homeDelivery',
    label: 'Dostawa do domu',
    type: 'SelectBoolFilter',
    group: 'primary',
    queryParamNames: ['pub_homeDelivery'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'bikes', label: 'Rowery' },
        { key: 'camping', label: 'Kemping' },
        { key: 'tourism', label: 'Turystyka' },
        { key: 'watersports', label: 'Sporty wodne' },
        { key: 'transport', label: 'Transport sprzętu' },
        // { key: 'nature', label: 'Natura' },
        { key: 'recreation', label: 'Rekreacja' },
        // { key: 'racquet_sports', label: 'Sporty rakietowe' },
        // { key: 'fishing', label: 'Wędkarstwo' },
        { key: 'scooter', label: 'Hulajnogi' },
        // { key: 'electronics', label: 'Elektronika' },
        { key: 'fitness', label: 'Fitness' },
        { key: 'wintersports', label: 'Sporty zimowe' },
      ],
    }}] : [];


export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Termin',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  // {
  //   id: 'dates',
  //   label: <FormattedMessage id="FiltersConfig.availability" />,
  //   type: 'BookingDateRangeLengthFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates', 'minDuration', 'availability'],
  //   config: {},
  // },
  // {
  //   id: 'price',
  //   label: <FormattedMessage id="FiltersConfig.price" />,
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 200,
  //     step: 5,
  //   },
  // },
  // {
  //   id: 'keyword',
  //   label: <FormattedMessage id="FiltersConfig.product" />,
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  {
    id: 'category',
    label: <FormattedMessage id="FiltersConfig.category" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'bikes', label: <FormattedMessage id="FiltersConfig.bikes" />, translation: "FiltersConfig.bikes" },
        { key: 'camping', label: <FormattedMessage id="FiltersConfig.camping" />, translation: "FiltersConfig.camping" },
        { key: 'tourism', label: <FormattedMessage id="FiltersConfig.tourism" />, translation: "FiltersConfig.tourism" },
        { key: 'watersports', label: <FormattedMessage id="FiltersConfig.watersports" />, translation: "FiltersConfig.watersports" },
        { key: 'transport', label: <FormattedMessage id="FiltersConfig.transport" />, translation: "FiltersConfig.transport" },
        // { key: 'nature', label: <FormattedMessage id="FiltersConfig.nature" />, translation: "FiltersConfig.nature" },
        { key: 'recreation', label: <FormattedMessage id="FiltersConfig.recreation" />, translation: "FiltersConfig.recreation"},
        // { key: 'racquet_sports', label: <FormattedMessage id="FiltersConfig.racketsports" />, translation: "FiltersConfig.racketsports"},
        // { key: 'fishing', label: 'Wędkarstwo' },
        { key: 'scooter', label: <FormattedMessage id="FiltersConfig.scooters" />, translation: "FiltersConfig.scooters" },
        // { key: 'electronics', label: <FormattedMessage id="FiltersConfig.electronics" />, translation: "FiltersConfig.electronics" },
        { key: 'fitness', label: <FormattedMessage id="FiltersConfig.fitness" />, translation: "FiltersConfig.fitness" },
        { key: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersports" />, translation: "FiltersConfig.wintersports" },
      ],
    },
  },
  ...homeDeliveryMaybe,
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       {
  //         key: 'towels',
  //         label: 'Towels',
  //       },
  //       {
  //         key: 'bathroom',
  //         label: 'Bathroom',
  //       },
  //       {
  //         key: 'swimming_pool',
  //         label: 'Swimming pool',
  //       },
  //       {
  //         key: 'own_drinks',
  //         label: 'Own drinks allowed',
  //       },
  //       {
  //         key: 'jacuzzi',
  //         label: 'Jacuzzi',
  //       },
  //       {
  //         key: 'audiovisual_entertainment',
  //         label: 'Audiovisual entertainment',
  //       },
  //       {
  //         key: 'barbeque',
  //         label: 'Barbeque',
  //       },
  //       {
  //         key: 'own_food_allowed',
  //         label: 'Own food allowed',
  //       },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label:  <FormattedMessage id="FiltersConfig.newest" /> },
    { key: '-createdAt', label:  <FormattedMessage id="FiltersConfig.oldest" /> },
    { key: '-price', label:  <FormattedMessage id="FiltersConfig.expensive" /> },
    { key: 'price', label: <FormattedMessage id="FiltersConfig.cheapest" /> },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: <FormattedMessage id="FiltersConfig.relevance" />, longLabel: <FormattedMessage id="FiltersConfig.relevanceLong" /> },
  ],
};
