import React, { Component, useState } from 'react';
import css from './ToggleSwitch.css';

class ToggleSwitch extends Component {
  render() {
    const { checked, onChange } = this.props;
    return (
      <div className={css.toggleWrapper}>
        <span className={css.title}>{this.props.name}</span>
        <div className={css.toggleSwitch}>
          <input
            type="checkbox"
            className={css.toggleSwitchCheckbox}
            name={this.props.name}
            id={this.props.name}
            checked={checked}
            onChange={e => onChange({checked: e.target.checked})}
          />
          <label className={css.toggleSwitchLabel} htmlFor={this.props.name}>
            <span className={css.toggleSwitchInner} />
            <span className={css.toggleSwitchSwitch} />
          </label>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;