import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import moment from 'moment/moment';
import classNames from 'classnames';

import {
  Button,
  CustomDatePicker,
  IconDelete,
  IconSpinner,
  IconWarning,
} from '../index';
import CreateOpeningException from './CreateOpeningException/CreateOpeningException';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';

import css from './OpeningException.css';

const OpeningException = ({
  containerClassName,
  titleClassName,
  dateContainerClassName,
  buttonShowExceptionsClassName,
  dayContainerClassName,
  calendarClassName,
  createContainerClassName,
  intl,
  providerId,
  getOpeningException,
  openingExceptions,
  fetchOpeningExceptionsInProgress,
  fetchOpeningExceptionsError,
  openingPattern,
  createOpeningExceptionsInProgress,
  createOpeningExceptionsError,
  createOpeningExceptionsSuccess,
  deleteOpeningExceptionsSuccess,
  removeOpeningException,
  addOpeningException
}) => {
  const containerClasses = classNames(css.container, containerClassName);
  const titleClasses = classNames(css.title, titleClassName);
  const dateContainerClasses = classNames(css.dateContainer, dateContainerClassName);
  const btnShowExceptionsClasses = classNames(css.btnShowExceptions, buttonShowExceptionsClassName);
  const dayContainerClasses = classNames(css.dayContainer, dayContainerClassName);
  const defaultStart = new Date();
  const defaultEnd = moment(new Date()).add(1, 'years');
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(new Date(defaultEnd));
  const isOpeningExceptions = !!openingExceptions.length;
  const sortedOpeningExceptions = openingExceptions && openingExceptions.sort((a,b) => (
    new Date(a.attributes.date) - new Date(b.attributes.date))
  )

  const errorMessage = fetchOpeningExceptionsError ? (
      <p className={css.error}>
        <IconWarning variant="error" /> <span>{fetchOpeningExceptionsError.message}</span>
      </p>
  ) : null;

  const handleOpenExceptionClick = (event) => {
    event.preventDefault();
    getOpeningException({
      providerId,
      start: moment(start).format('YYYY-MM-DD'),
      end:  moment(end).format('YYYY-MM-DD')
    });
  }

  const handleDeleteExceptionClick = (event, id) => {
    event.preventDefault();
    removeOpeningException(id)
  }

  useEffect(() => {
    providerId && getOpeningException({
      providerId,
      start: moment(start).format('YYYY-MM-DD'),
      end:  moment(end).format('YYYY-MM-DD')
    });
  }, [providerId]);

  useEffect(() => {
    providerId && (deleteOpeningExceptionsSuccess || createOpeningExceptionsSuccess) && getOpeningException({
      providerId,
      start: moment(start).format('YYYY-MM-DD'),
      end:  moment(end).format('YYYY-MM-DD')
    });
  }, [deleteOpeningExceptionsSuccess, createOpeningExceptionsSuccess]);

  return (
    <div className={containerClasses}>
      <p className={titleClasses} >
        {intl.formatMessage({ id: 'OpeningException.openingExceptionTitle' })}
      </p>
      <div className={dateContainerClasses}>
        <CustomDatePicker
          showIcon
          selected={start}
          onChange={setStart}
          label={intl.formatMessage({ id: 'OpeningException.dateStart' })}
          inputFormat={'dd, D MMM YYYY'}
        />
        <CustomDatePicker
          showIcon
          selected={end}
          onChange={setEnd}
          label={intl.formatMessage({ id: 'OpeningException.dateEnd' })}
          inputFormat={'dd, D MMM YYYY'}
        />
        <Button
          className={btnShowExceptionsClasses}
          inProgress={fetchOpeningExceptionsInProgress}
          onClick={handleOpenExceptionClick}
          disabled={ (defaultEnd === end && defaultStart === start) || !end || !start }
        >
          {intl.formatMessage({ id: 'OpeningException.showOpeningException' })}
        </Button>
      </div>
      {errorMessage}
      {fetchOpeningExceptionsInProgress ? (
          <div className={css.listItemsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <>
            {isOpeningExceptions ?
              <li className={dayContainerClasses}>
                {
                  sortedOpeningExceptions.map(item => (
                    <li key={item.id.uuid} className={css.day}>
                      {moment(new Date(item.attributes.date)).format('dd, D MMM YYYY')}
                      <span className={classNames(css.info, { [css.opened]: item.attributes.opened, [css.closed]: !item.attributes.opened })}>
                        {intl.formatMessage({ id: item.attributes.opened ? 'OpeningException.opened' : 'OpeningException.closed' })}
                      </span>
                      <button className={css.deleteButton} onClick={event => handleDeleteExceptionClick(event,item.id.uuid)}>
                        <IconDelete />
                      </button>
                    </li>
                  ))
                }
              </li>
              : <p className={css.text}>
                  {intl.formatMessage({ id: 'OpeningException.noExceptions' })}
                </p>
            }
          </>
        )}
        <CreateOpeningException
          intl={intl}
          providerId={providerId}
          openingPattern={openingPattern}
          openingExceptions={openingExceptions}
          addOpeningException={addOpeningException}
          createOpeningExceptionsInProgress={createOpeningExceptionsInProgress}
          createOpeningExceptionsError={createOpeningExceptionsError}
          containerClassName={createContainerClassName}
          calendarClassName={calendarClassName}
        />
    </div>
  )
}

OpeningException.defaultProps = {
  containerClassName: null,
  titleClassName: null,
  dateContainerClassName: null,
  buttonShowExceptionsClassName: null,
  dayContainerClassName: null,
  calendarClassName: null,
  createContainerClassName: null,
  openingExceptions: [],
  fetchOpeningExceptionsInProgress: false,
  fetchOpeningExceptionsSuccess: false,
  fetchOpeningExceptionsError: null,
  createOpeningExceptionsInProgress: false,
  createOpeningExceptionsError: null,
  deleteOpeningExceptionsSuccess: false,
  createOpeningExceptionsSuccess: false,
}

OpeningException.propTypes = {
  containerClassName: string,
  titleClassName: string,
  dateContainerClassName: string,
  buttonShowExceptionsClassName: string,
  dayContainerClassName: string,
  calendarClassName: string,
  createContainerClassName: string,
  openingExceptions: arrayOf(propTypes.openingException),
  intl: intlShape.isRequired,
  providerId: string.isRequired,
  getOpeningException: func.isRequired,
  fetchOpeningExceptionsInProgress: bool,
  fetchOpeningExceptionsError: propTypes.error,
  fetchOpeningExceptionsSuccess: bool,
  openingPattern: string.isRequired,
  createOpeningExceptionsInProgress: bool,
  createOpeningExceptionsError: propTypes.error,
  createOpeningExceptionsSuccess: bool,
  deleteOpeningExceptionsSuccess: bool,
  removeOpeningException: func.isRequired,
  addOpeningException: func.isRequired,
}

export default OpeningException;
