import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import css from './SelectBoolFilter.css';
import { FormattedMessage } from 'react-intl';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  handleClick = () => {
    const { queryParamNames, onSelect, initialValues } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const isOn = !!initialValues[queryParamName];
    onSelect({ [queryParamName]: !isOn || undefined });
  }

  render() {
    const {
      queryParamNames,
      initialValues,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);

    const isOn = !!initialValues[queryParamName];

    return (
      <div className={css.deliveryRoot}>
        <label className={css.filterLabel}>
          < FormattedMessage id="SelectSingleFilterPlain.homeDeliveryEquipment" />
        </label>
        <button className={classNames(css.menuLabel, css.homeDelivery, { [css.homeDeliveryOn]: isOn })} onClick={this.handleClick}>
          < FormattedMessage id="SelectSingleFilterPlain.homeDelivery" />
        </button>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useBullets: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useBullets: bool,
};

export default SelectSingleFilterPlain;