import React, { useState, useEffect, memo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment/moment';

import {
  Button,
  InlineTextButton,
  IconWarning,
  Select,
  Textarea,
} from '../../../components';
import DiscountSection from './DiscountSection/DiscountSection';
import CodesSection from './CodesSection/CodesSection';
import ExpDate from '../ExpDate/ExpDate';
import RentalDates from '../RentalDates/RentalDates';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './CouponCreationForm.css';

const VALUE_ALL = 'all';

const FIELDS = {
  EXPIRATION_DATE: 'expirationDate',
  TYPE: 'type',
  PARTNER_ID: 'partnerId',
  AMOUNT: 'amount',
  DESCRIPTION: 'description',
  CODES: 'codes',
  PREFIX: 'prefix',
  DISCOUNT_AMOUNT: 'discountAmount',
  DISCOUNT_PERCENTAGE: 'discountPercentage',
  DISCOUNT_VALUE: 'discountValue',
  START_DATE: 'start',
  END_DATE: 'end',
  COUPON_SETTLEMENT_ID: 'id',
  COUNTRY_ID: 'countryId'
};

const CouponCreationForm = ({
  intl,
  createInProgress,
  createCouponsSuccess,
  createCoupons,
  createCouponsError,
  setIsOpenCreateCoupon,
  partners,
  createCouponsClearState,
  couponType,
  methodsOfSettlingCoupons,
  allCountriesFilter,
  defaultAdminCountryId,
  countryCurrency,
}) => {
  const defaultSelectedCountry = countryCurrency.find(item => item.countryId === defaultAdminCountryId);
  const [isConfirm, setIsConfirm] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultSelectedCountry);
  const [isGeneratedAutomatically, setIsGeneratedAutomatically] = useState(true);
  const countriesFilter = allCountriesFilter.filter(item => item.countryId !== VALUE_ALL);
  const isPartnerSelect = allCountriesFilter.find(item => item.countryId === 'pl');
  const couponsTypeWithoutCard = couponType.filter(item => item.value !== 'gift-card');

  const initValues = {
    codes: [],
    amount: 0,
    prefix: '',
    expirationDate: null,
    discountValue: '',
    discountAmount: true,
    discountPercentage: false,
    partnerId: VALUE_ALL,
    type: couponType[0].value,
    description: '',
    start: null,
    end: null,
    id: VALUE_ALL,
    countryId: defaultAdminCountryId,
  };

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    setValue,
    clearErrors,
    setError,
    watch
  } = useForm({
    defaultValues: initValues,
  });

  const onSubmit = data => {
    setIsConfirm(true);
    const obj = {};
    const {
      codes,
      amount,
      prefix,
      expirationDate,
      discountValue,
      discountAmount,
      partnerId,
      type,
      description,
      start,
      end,
      id,
      countryId
    } = data;
    const { isPaymentSource, expense } = methodsOfSettlingCoupons.find(item => item.id === parseInt(id));
    obj[discountAmount ? FIELDS.DISCOUNT_AMOUNT : FIELDS.DISCOUNT_PERCENTAGE] = discountAmount
      ? parseInt(discountValue * 100)
      : parseInt(discountValue);
    if (isGeneratedAutomatically) {
      obj[FIELDS.AMOUNT] = parseInt(amount);
      if (prefix) obj[FIELDS.PREFIX] = prefix.toUpperCase();
    } else {
      obj[FIELDS.CODES] = codes;
    }

    const startParam = start ? {start: moment(start).format('YYYY-MM-DD')} : undefined;
    const endParam = end ? {end: moment(end).format('YYYY-MM-DD')} : undefined;

    createCoupons({
      ...obj,
      ...startParam,
      ...endParam,
      exp: expirationDate && moment(expirationDate).format('YYYY-MM-DD'),
      countryId,
      partnerId: countryId === 'pl' ? partnerId : null,
      type,
      expense,
      isPaymentSource,
      description,
      include: ['vouchers']
    });

    reset({ defaultValues: initValues });
  };
  const typeField = watch(FIELDS.TYPE);
  const countryIdField = watch(FIELDS.COUNTRY_ID);

  const errorText =
    createCouponsError?.message === 'Voucher code is already used'
      ? 'Console.duplicateError'
      : 'Console.creatingError';
  const errorMessage =
    createCouponsError && isConfirm ? (
      <p className={css.error}>
        <IconWarning variant="error" /> <FormattedMessage id={errorText} />
      </p>
    ) : null;

  const handleCancel = event => {
    event.preventDefault();
    reset({ defaultValues: initValues });
    setIsOpenCreateCoupon(false);
  };

  useEffect(() => {
    if (typeField === 'gift-card') {
      setValue(FIELDS.DISCOUNT_AMOUNT, true);
      setValue(FIELDS.DISCOUNT_PERCENTAGE, false);
    }
  }, [typeField]);

  useEffect(() => {
    const newCountry = countryCurrency.find(item => item.countryId === countryIdField);
    setSelectedCountry(newCountry);
  }, [countryIdField]);

  useEffect(() => {
    unregister(isGeneratedAutomatically ? FIELDS.CODES : [FIELDS.AMOUNT, FIELDS.PREFIX], {
      keepDefaultValue: true,
    });
  }, [isGeneratedAutomatically]);

  useEffect(() => {
    if (!createInProgress && createCouponsSuccess && isConfirm) {
      setIsOpenCreateCoupon(false);
    }
  }, [createInProgress, createCouponsSuccess]);

  useEffect(() => {
    createCouponsClearState();
  }, []);

  return (
    <div className={css.formContainer}>
      <h3 className={css.title}>{intl.formatMessage({ id: 'Console.addCoupon' })}</h3>
      <form onSubmit={handleSubmit(onSubmit)} className={css.mainForm}>
          <div>
            <h3 className={css.subtitle}
                key={'subtitle'}>{intl.formatMessage({ id: 'Console.stepZero' })}
            </h3>
            <Controller
              control={control}
              name={FIELDS.COUNTRY_ID}
              key={FIELDS.COUNTRY_ID}
              rules={{
                validate: value => value !== VALUE_ALL,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  label={intl.formatMessage({ id: "Console.country" })}
                  error={errors[FIELDS.COUNTRY_ID] && intl.formatMessage({ id: 'Console.countryError' })}
                  markRequired={true}
                >
                  <option hidden value="">
                    {intl.formatMessage({ id: 'Console.select' })}
                  </option>
                  {countriesFilter.map(({ countryId, country }) => (
                    <option value={countryId}>{intl.formatMessage({ id: country })}</option>
                  ))}
                </Select>
              )}
            />
            {isPartnerSelect &&
              <Controller
                control={control}
                name={FIELDS.PARTNER_ID}
                key={FIELDS.PARTNER_ID}
                rules={{
                  validate: value => countryIdField === 'pl' ? value !== VALUE_ALL : true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id={FIELDS.PARTNER_ID}
                    onChange={onChange}
                    value={value}
                    error={errors[FIELDS.PARTNER_ID] && intl.formatMessage({ id: 'Console.choosePartnerError' })}
                    label={intl.formatMessage({ id: 'Console.partner' })}
                    markRequired={true}
                    disabled={countryIdField !== 'pl'}
                  >
                    <option
                      value={VALUE_ALL}>{intl.formatMessage({ id: 'Console.choose' })}</option>
                    {partners.map(item => (
                      <option value={item?.id.uuid}>{item?.attributes.name}</option>
                    ))}
                  </Select>
                )}
              />
            }
            <Controller
              control={control}
              name={FIELDS.TYPE}
              key={FIELDS.TYPE}
              rules={{
                validate: value => value !== VALUE_ALL,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id={FIELDS.TYPE}
                  onChange={onChange}
                  value={value}
                  error={errors[FIELDS.TYPE] && intl.formatMessage({ id: 'Console.chooseTypeError' })}
                  label={intl.formatMessage({ id: 'Console.couponType' })}
                  markRequired={true}
                >
                  {couponsTypeWithoutCard.map(({ name, value }) => (
                    <option value={value}>{intl.formatMessage({ id: name })}</option>
                  ))}
                </Select>
              )}
            />
            <Controller
              control={control}
              name={FIELDS.COUPON_SETTLEMENT_ID}
              key={FIELDS.COUPON_SETTLEMENT_ID}
              rules={{
                validate: value => value !== VALUE_ALL,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id={FIELDS.COUPON_SETTLEMENT_ID}
                  onChange={onChange}
                  value={value}
                  error={errors[FIELDS.COUPON_SETTLEMENT_ID] && intl.formatMessage({ id: 'Console.settlementMethodError' })}
                  label={intl.formatMessage({ id: 'Console.settlementMethod' })}
                  markRequired={true}
                >
                  <option value={VALUE_ALL}>{intl.formatMessage({ id: 'Console.choose' })}</option>
                  {methodsOfSettlingCoupons.map(item => (
                    <option value={item.id}>{intl.formatMessage({ id: item.message })}</option>
                  ))}
                </Select>
              )}
            />
          </div>
          <CodesSection
            intl={intl}
            isGeneratedAutomatically={isGeneratedAutomatically}
            setIsGeneratedAutomatically={setIsGeneratedAutomatically}
            control={control}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            setError={setError}
            fields={FIELDS}
          />
          <DiscountSection
            intl={intl}
            control={control}
            setValue={setValue}
            errors={errors}
            fields={FIELDS}
            typeField={typeField}
            currency={selectedCountry?.currency}
          />
          <ExpDate
            intl={intl}
            titleId={'Console.stepThree'}
            setValue={setValue}
            control={control}
            registerName={FIELDS.EXPIRATION_DATE}
            errors={errors}
            clearErrors={clearErrors}
          />
          <RentalDates
            intl={intl}
            titleId={'Console.stepRentalTime'}
            control={control}
            startName={FIELDS.START_DATE}
            endName={FIELDS.END_DATE}
          />
          <div>
            <h3 className={css.subtitle}
                key={'subtitle'}>{intl.formatMessage({ id: 'Console.stepFive' })}
            </h3>
            <Controller
              control={control}
              name={FIELDS.DESCRIPTION}
              key={FIELDS.DESCRIPTION}
              rules={{ minLength: 3 }}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={intl.formatMessage({ id: 'Console.description' })}
                  value={value}
                  onChange={onChange}
                  id={FIELDS.DESCRIPTION}
                  placeholder={intl.formatMessage({ id: 'Console.descriptionPlaceholder' })}
                />
              )}
            />
          </div>
          {errorMessage}
          <div className={css.actionContainer}>
            <InlineTextButton type="button" className={css.cancelButton} onClick={handleCancel}>
              <FormattedMessage id="Console.cancel" />
            </InlineTextButton>
            <Button type="submit" className={css.submitButton} inProgress={createInProgress}>
              {intl.formatMessage({ id: 'Console.create' })}
            </Button>
          </div>
      </form>
    </div>
);
};

export default memo(CouponCreationForm);
