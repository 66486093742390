import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { BusinessSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { updateProfile } from './BusinessSettingsPage.duck';

import css from './BusinessSettingsPage.css';

const { LatLng } = sdkTypes;

export class BusinessSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      intl,
    } = this.props;

    const handleSubmit = values => {
      const { businessLocation, businessName } = values;

      const businessLocationUpdate = businessLocation && businessLocation.selectedPlace ? {
        search: businessLocation.selectedPlace.address, selectedPlace: { 
          address: businessLocation.selectedPlace.address,
          origin: businessLocation.selectedPlace.origin
        }
      } : null;

      const profile = {
        publicData: {
          businessLocation: businessLocationUpdate,
          businessName,
        },
      };

      // Update profileImage only if file system has been accessed
      const updatedValues = profile;

      onUpdateProfile(updatedValues);
    };

    const user = ensureCurrentUser(currentUser);
    const { publicData: { businessLocation = null, businessName } = {} } = user.attributes.profile;

    const userBusinessLocation = businessLocation ?
      {
        search: businessLocation.selectedPlace.address,
        selectedPlace: { address: businessLocation.selectedPlace.address, origin: new LatLng(businessLocation.selectedPlace.origin.lat, businessLocation.selectedPlace.origin.lng) }
      } : null;

    const profileSettingsForm = user.id ? (
      <BusinessSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ 
          businessLocation: userBusinessLocation,
          businessName,
        }}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const title = intl.formatMessage({id: "BusinessSettingsPage.companyDetails"});

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="BusinessSettingsPage" />
            <UserNav selectedPageName="BusinessSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperBusinessSideNav currentTab="BusinessSettingsPage" />
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  {intl.formatMessage({id: "BusinessSettingsPage.companyDetails"})}
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    {intl.formatMessage({id: "BusinessSettingsPage.seeProfile"})}
                  </NamedLink>
                ) : null}
              </div>
              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

BusinessSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

BusinessSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const BusinessSettingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(BusinessSettingsPageComponent);

export default BusinessSettingsPage;
