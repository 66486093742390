import React from 'react';
import classNames from 'classnames';
import { string, number } from 'prop-types';

import Table from '../Table/Table';

import css from './TableSkeleton.css';

const TableSkeleton = ({
  rowNumber,
  columnNumber,
  cellSkeletonClassName,
  classNameCell,
  classNameHeader,
  classNameRow,
  classNameTable
}) => {
  const cellSkeletonClasses = classNames(cellSkeletonClassName, css.skeletonCell);
  const headerClasses = classNames(classNameHeader, css.tableHeader);
  const rowClasses = classNames(classNameRow, css.row);
  const cellClasses = classNames(classNameCell, css.cell);
  const tableClasses = classNames(classNameTable, css.table);
  const keyFn = item =>  item.id;
  const skeletonCell = {
    label: () => {},
    render: () => <div className={cellSkeletonClasses}>{' '}</div>,
  };
  const skeletonRows = new Array(rowNumber).fill({id: ''}).map((item, index) => ({ id: index }));
  const tableSkeletonConfig = new Array(columnNumber).fill(skeletonCell);

  return (
    <Table
      data={skeletonRows}
      config={tableSkeletonConfig}
      keyFn={keyFn}
      classNameCell={cellClasses}
      classNameTable={tableClasses}
      classNameHeader={headerClasses}
      classNameRow={rowClasses}
    />
  );
};

TableSkeleton.defaultProps = {
  cellSkeletonClassName: null,
  classNameHeader: null,
  classNameRow: null,
  classNameRow: null,
  classNameTable: null,
};

TableSkeleton.propTypes = {
  rowNumber: number.isRequired,
  columnNumber: number.isRequired,
  cellSkeletonClassName: string,
  classNameHeader: string,
  classNameRow: string,
  classNameRow: string,
  classNameTable: string,
};

export default TableSkeleton;
