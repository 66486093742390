import React from 'react';
import { number, string, func, arrayOf, object, shape } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { FormattedMessage } from '../../../../util/reactIntl';
import businessCategoryConfig from '../../../../business-category-config';
import { sortBy } from './Suggestions.helpers';

import css from './../AutocompleteListing.css';

const Suggestions = ({
  value,
  intl,
  categories,
  handleClick,
  highlightedSuggestionIndex,
  predictionsClasses,
  sortFlatSuggestions,
}) => {
  const suggestions = sortBy(sortFlatSuggestions, value, intl, categories, businessCategoryConfig);

  return (
    <div className={css.container}>
      <p className={css.subheading}>
        <FormattedMessage id={'MainFilters.suggestions'} />
      </p>
      <ul className={predictionsClasses}>
        {suggestions?.map((suggestion, index) => {
          const {
            attributes: {
              keyword: { text, exact },
              parentId,
            },
            id: { uuid },
          } = suggestion;
          const category = !parentId ? uuid : parentId;
          const subcategory = parentId ? uuid : '';
          const categoryLabel = !parentId && categories.find(option => option.key === uuid);
          const subcategoryLabel =
            parentId && businessCategoryConfig.find(({ key }) => key === uuid);
          const labelValue = parentId ? subcategoryLabel : categoryLabel;
          const keywordValue = exact ? intl.formatMessage({ id: labelValue.translation }) : text;
          const keywordLowerCase = value.keywords.toLowerCase();
          const firstPart = text.substring(0, text.indexOf(keywordLowerCase));
          const secondPart = text.substring(text.indexOf(keywordLowerCase) + value.keywords.length);

          return (
            <li
              key={index}
              onClick={() => handleClick(keywordValue, category, subcategory, exact)}
              className={classNames(css.listItem, {
                [css.highlightedSuggestion]: index === highlightedSuggestionIndex,
              })}
            >
              {exact ? (
                <>
                  <span className={css.text}>
                    {intl.formatMessage({ id: 'General.category' })}{' '}
                  </span>
                  <span className={css.textBold}>{labelValue.label}</span>
                </>
              ) : (
                <>
                  <span className={css.text}>{firstPart}</span>
                  <span className={css.searchText}>{keywordLowerCase}</span>
                  <span className={css.text}>{secondPart}</span>
                  <p className={css.subtext}>{labelValue.label}</p>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Suggestions.defaultProps = {
  predictionsClasses: null,
};

Suggestions.propTypes = {
  predictionsClasses: string,
  handleClick: func.isRequired,
  sortFlatSuggestions: arrayOf(object),
  categories: arrayOf(propTypes.categoriesSuggestions).isRequired,
  highlightedSuggestionIndex: number.isRequired,
  value: shape({ keyword: string, category: string, subcategory: string }),
  intl: intlShape.isRequired,
};

export default Suggestions;
