import React from 'react';
import moment from 'moment';
import { arrayOf, bool, object, shape, string, oneOf }  from 'prop-types';

import { stringify } from '../../../util/urlHelpers';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { Table, NamedLink, PaginationLinks, TableSkeleton } from '../../../components';

import css from './Payouts.css';

const formattedDate = (date, intl) =>
  intl.formatDate(moment(date), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const Payouts = ({
  intl,
  payouts,
  pagination,
  queryPayoutsInProgress,
  queryPayoutsError,
  queryPayoutsSuccess,
  title,
  id,
  params,
}) => {
  const tableConfig = [
    {
      label: intl.formatMessage({ id: 'PayoutPage.date' }),
      render: payout => formattedDate(payout?.attributes?.createdAt, intl),
    },
    {
      label: intl.formatMessage({ id: 'PayoutPage.amount' }),
      render: payout => formatMoney(intl, payout?.attributes?.amount),
    },
    {
      label: intl.formatMessage({ id: 'PayoutPage.transactions' }),
      render: payout => {
        const date = formattedDate(payout?.attributes?.createdA, intl);
        return payout?.id.uuid ? (
          <NamedLink
            className={css.link}
            name="PayoutTransactionsPage"
            params={{ id, payoutId: payout?.id.uuid }}
            to={{ search: stringify({ date }) }}
          >
            <FormattedMessage id="PayoutPage.link" />
          </NamedLink>
        ) : null;
      },
    },
  ];

  const keyFn = payouts => payouts?.id.uuid;

  const errorMessage = queryPayoutsError ? (
    <p className={css.error}>{queryPayoutsError?.message}</p>
  ) : null;

  const noResultsMessage =
    !queryPayoutsInProgress && !payouts?.length && !queryPayoutsError ? (
      <p className={css.noResults}>
        <FormattedMessage id="PayoutPage.noPayoutFound" />
      </p>
    ) : null;

  const paginationLinks =
    !queryPayoutsInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="PayoutPage"
        pagePathParams={params}
        pageSearchParams={{ page: 1 }}
        pagination={pagination}
      />
    ) : null;

  return (
    <div>
      <p className={css.title}>{title}</p>
      {queryPayoutsInProgress ? (
        <div className={css.tableWrapper}>
          <TableSkeleton columnNumber={tableConfig.length} rowNumber={6} classNameCell={css.cell} />
        </div>
      ) : (
        <div>
          {payouts?.length ? (
            <div className={css.tableWrapper}>
              <Table data={payouts} config={tableConfig} keyFn={keyFn} classNameCell={css.cell} />
              {paginationLinks}
            </div>
          ) : null}
        </div>
      )}
      {errorMessage}
      {noResultsMessage}
    </div>
  );
};

Payouts.propTypes = {
  pagination: propTypes.pagination,
  queryPayoutsInProgress: bool.isRequired,
  queryPayoutsError: oneOf(propTypes.error, bool),
  queryPayoutsSuccess: bool,
  payouts: arrayOf(object).isRequired,
  title: string,
  id: string.isRequired,
  params:  shape({ id: string, payoutId: string }).isRequired,
  intl: intlShape.isRequired,
};

export default Payouts;
