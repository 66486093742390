import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const QUERY_PAYOUT_TRANSACTIONS_REQUEST = 'app/PayoutTransactionsPage/QUERY_PAYOUT_TRANSACTIONS_REQUEST';
export const QUERY_PAYOUT_TRANSACTIONS_SUCCESS = 'app/PayoutTransactionsPage/QUERY_PAYOUT_TRANSACTIONS_SUCCESS';
export const QUERY_PAYOUT_TRANSACTIONS_ERROR = 'app/PayoutTransactionsPage/QUERY_PAYOUT_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  transactions: [],
  queryPayoutTransactionsInProgress: false,
  queryPayoutTransactionsError: null,
  queryPayoutTransactionsSuccess: false,
};

const payoutTransactionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case QUERY_PAYOUT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryPayoutTransactionsInProgress: true,
        queryPayoutTransactionsError: null,
        queryPayoutTransactionsSuccess: false,
      };

    case QUERY_PAYOUT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        queryPayoutTransactionsSuccess: true,
        queryPayoutTransactionsInProgress: false,
        queryPayoutTransactionsError: false,
        transactions: payload.data,
        pagination: payload.meta,
      };

    case QUERY_PAYOUT_TRANSACTIONS_ERROR:
      return {
        ...state,
        queryPayoutTransactionsInProgress: true,
        queryPayoutTransactionsError: payload,
        queryPayoutTransactionsSuccess: false,
      };

    default:
      return state;
  }
};

export default payoutTransactionsPageReducer;

// ================ Action creators ================ //

export const queryPayoutTransactionsRequest = () => ({
  type: QUERY_PAYOUT_TRANSACTIONS_REQUEST,
});

export const queryPayoutTransactionsSuccess = response => ({
  type: QUERY_PAYOUT_TRANSACTIONS_SUCCESS,
  payload: response.data,
});

export const queryPayoutTransactionsError = () => ({
  type: QUERY_PAYOUT_TRANSACTIONS_ERROR,
});

// ================ Thunks ================ //

const TRANSACTIONS_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1 } = parse(search);
  const { payoutId } = params;
  const paginationParams = {
    page,
    per_page: TRANSACTIONS_PAGE_SIZE,
  };

  dispatch(queryPayoutTransactionsRequest());

  return sdk.newSdk.transactions
    .query({
      payoutId,
      ...paginationParams
    })
    .then(response => {
      dispatch(queryPayoutTransactionsSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(queryPayoutTransactionsError(error));
      throw error;
    });
};
