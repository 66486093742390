import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, shape, string, func, arrayOf } from 'prop-types';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  createOpeningException,
  deleteOpeningException,
  fetchOpeningException,
  updateProvider,
} from './ProviderPage.duck';
import {
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconWarning,
  Select,
  OpeningException
} from '../../components';
import { TopbarContainer } from '../../containers';
import ProviderEditForm from './ProviderEditForm/ProviderEditForm';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './ProviderPage.css';

const ProviderPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    location: { search },
    history,
    userProviders,
    params,
    fetchUserProvidersInProgress,
    fetchUserProvidersError,
    updateProviderData,
    updateProviderInProgress,
    getOpeningException,
    openingExceptions,
    fetchOpeningExceptionsInProgress,
    fetchOpeningExceptionsError,
    createOpeningExceptionsInProgress,
    createOpeningExceptionsError,
    createOpeningExceptionsSuccess,
    deleteOpeningExceptionsSuccess,
    removeOpeningException,
    addOpeningException
  } = props;
  const { providerId } = params;
  const provider = userProviders.find(provider => provider.id.uuid === providerId);
  const title = intl.formatMessage({ id: 'ProviderPage.title' });
  const isDecathlon = provider && provider.attributes.isDecathlon;
  const isAdmin = currentUser && currentUser.attributes.isAdmin;
  const isAdminForCountries = currentUser && currentUser.attributes.adminForCountries.length;
  const isDisabled = isDecathlon && !isAdmin && !isAdminForCountries;

  const errorMessage = fetchUserProvidersError ? (
    providerId ? (
      <p className={css.error}>
        <IconWarning variant="error" /> <span>{fetchUserProvidersError.message}</span>
      </p>
    ) : (
      <p><FormattedMessage id='ProviderPage.noProviderFound' /></p>
    )
  ) : null;

  const noResultsMessage =
    !fetchUserProvidersInProgress && !userProviders.length && !fetchUserProvidersError ? (
      <p>
        <FormattedMessage id="TeamPage.noUserFound" />
      </p>
    ) : null;

  const handleProviderChange = event => {
    history.push(`/provider/${event.target.value}?${stringify({ ...parse(search), page: 1 })}`);
  };

  const providersWithOwnerRole = userProviders.filter(
    provider => provider.attributes.myRole === 'owner'
  );

  const sortedProviders = providersWithOwnerRole?.sort((a, b) =>
    b.attributes?.name.toLowerCase() < a.attributes?.name.toLowerCase() ? 1 : -1
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProviderPage" />
          <UserNav selectedPageName="ProviderPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="ProviderPage" />
        <LayoutWrapperMain className={css.colorBackground}>
          <div>
            <Select
              label= {intl.formatMessage({ id: 'General.provider' })}
              value={providerId}
              onChange={handleProviderChange}
            >
              <option hidden value="">{intl.formatMessage({ id: 'Console.select' })}</option>
              {sortedProviders.map(provider => (
                <option value={provider.id.uuid}>{provider.attributes.name}</option>
              ))}
            </Select>
            {errorMessage}
            {fetchUserProvidersInProgress ? (
              <div className={css.listItemsLoading}>
                <IconSpinner />
              </div>
            ) : (
              provider?.attributes &&
              <div>
                <ProviderEditForm
                  name={provider.attributes.name}
                  description={provider.attributes.description}
                  openingHours={provider.attributes.openingHours}
                  openingPattern={provider.attributes.openingPattern}
                  intl={intl}
                  providerId={providerId}
                  updateProvider={updateProviderData}
                  updateProviderInProgress={updateProviderInProgress}
                  isDisabled={isDisabled}
                />
                <OpeningException
                  intl={intl}
                  getOpeningException={getOpeningException}
                  providerId={providerId}
                  openingExceptions={openingExceptions}
                  openingPattern={provider.attributes.openingPattern}
                  fetchOpeningExceptionsInProgress={fetchOpeningExceptionsInProgress}
                  fetchOpeningExceptionsError={fetchOpeningExceptionsError}
                  createOpeningExceptionsInProgress={createOpeningExceptionsInProgress}
                  createOpeningExceptionsError={createOpeningExceptionsError}
                  createOpeningExceptionsSuccess={createOpeningExceptionsSuccess}
                  deleteOpeningExceptionsSuccess={deleteOpeningExceptionsSuccess}
                  removeOpeningException={removeOpeningException}
                  addOpeningException={addOpeningException}
                />
              </div>
            )}
            {noResultsMessage}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ProviderPageComponent.defaultProps = {
  scrollingDisabled: false,
};

ProviderPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  currentUser: propTypes.currentUser,
  userProviders: arrayOf(propTypes.providerDetails).isRequired,
  fetchUserProvidersInProgress: bool,
  fetchUserProvidersError:  propTypes.error,
  updateProviderInProgress: bool,
  openingExceptions: arrayOf(propTypes.openingException)
};

const mapStateToProps = state => {
  const { currentUser, userProviders, fetchUserProvidersInProgress, fetchUserProvidersError } = state.user;
  const {
    updateProviderInProgress,
    openingExceptions,
    fetchOpeningExceptionsInProgress,
    fetchOpeningExceptionsError,
    createOpeningExceptionsInProgress,
    createOpeningExceptionsError,
    createOpeningExceptionsSuccess,
    deleteOpeningExceptionsSuccess,
  } = state.ProviderPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    userProviders,
    fetchUserProvidersInProgress,
    fetchUserProvidersError,
    updateProviderInProgress,
    openingExceptions,
    fetchOpeningExceptionsInProgress,
    fetchOpeningExceptionsError,
    createOpeningExceptionsInProgress,
    createOpeningExceptionsError,
    createOpeningExceptionsSuccess,
    deleteOpeningExceptionsSuccess
  };
};

const mapDispatchToProps = dispatch => ({
  updateProviderData: data => dispatch(updateProvider(data)),
  getOpeningException: date => dispatch(fetchOpeningException(date)),
  removeOpeningException: date => dispatch(deleteOpeningException(date)),
  addOpeningException: date => dispatch(createOpeningException(date)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ProviderPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ProviderPageComponent);

export default ProviderPage;
