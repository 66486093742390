import React, { useState } from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, nonEmptyArray } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, AddImages, ValidationError } from '../../components';
import css from './EditItemForm.css';

const TITLE_MAX_LENGTH = 60;
const ACCEPT_IMAGES = 'image/*';

const EditItemFormComponent = props => {
  const [imageUploadRequested, setImageUploadRequested] = useState(false);

  const onImageUploadHandler = (file) => {
    if (file) {
      setImageUploadRequested(true);
      props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  }

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          fetchErrors,
          submitInProgress,
          catalogProducts,
          form,
          images,
          onRemoveImage,
          onUpdateImageOrder,
        } = formRenderProps;

        const titleMessage = intl.formatMessage({ id: 'EditItemForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditItemForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditItemForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditItemForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditItemForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditItemForm.descriptionPlaceholder',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditItemForm.descriptionRequired',
        });

        const articleCodeLabel = intl.formatMessage({
          id: 'EditItemForm.articleCodeLabel',
        });

        const articleCodePlaceholder = intl.formatMessage({
          id: 'EditItemForm.articleCodePlaceholder',
        });

        const externalLinkLabel = intl.formatMessage({
          id: 'EditItemForm.externalLinkLabel',
        });

        const externalLinkPlaceholder = intl.formatMessage({
          id: 'EditItemForm.externalLinkPlaceholder',
        });

        const catalogProductsLabel = intl.formatMessage({
          id: 'EditItemForm.catalogProductsLabel',
        });

        const catalogProductsPlaceholder = intl.formatMessage({
          id: 'EditItemForm.catalogProductsPlaceholder',
        });

        const imageRequiredMessage = intl.formatMessage({
          id: "EditListingPhotosForm.imgRequired"
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditItemForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditItemForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditItemForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <AddImages
              className={css.imagesField}
              images={images}
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              onRemoveImage={onRemoveImage}
              onUpdateImageOrder={onUpdateImageOrder}
              form={form}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={intl.formatMessage({ id: 'EditListingPhotosForm.chooseImg' })}
                type="file"
                disabled={imageUploadRequested}
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file);
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    <div className={css.addImageWrapper}>
                      <div className={css.aspectRatioWrapper}>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label htmlFor={name} className={css.addImage}>
                          {label}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
                // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
              />
            </AddImages>

            <FieldSelect
              id="catalogProductId"
              name="catalogProductId"
              className={css.category}
              label={catalogProductsLabel}
              validate={required(catalogProductsLabel)}
              disabled={props.isUpdate}
            >
              <option disabled value="">
                {catalogProductsPlaceholder}
              </option>
              {catalogProducts?.map(product => (
                <option key={product.id.uuid} value={product.id.uuid}>
                  {product.attributes.title}
                </option>
              ))}
            </FieldSelect>

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              autoFocus
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
            />

            <FieldTextInput
              id="articleCode"
              name="articleCode"
              className={css.description}
              type="text"
              label={articleCodeLabel}
              placeholder={articleCodePlaceholder}
            />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
};

EditItemFormComponent.defaultProps = { className: null, fetchErrors: null };

export default compose(injectIntl)(EditItemFormComponent);
