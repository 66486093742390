import React, { useEffect, useState } from 'react';
import {  bool } from 'prop-types';
import classNames from 'classnames';

import { NamedLink, ResponsiveImage } from '../../../components';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import businessCategoryConfig from '../../../business-category-config';
import CatalogItem from '../CatalogItem/CatalogItem';
import { propTypes } from '../../../util/types';

import css from './ProductItem.css';

const ProductItem = ({
  catalogProduct,
  intl,
  isExpandedItems,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedItems);

  const { attributes: {
    businessCategory,
    countryId,
    createdAt,
    description,
    minBookingDays,
    modelCode,
    parts,
    price: { amount, currency },
    title },
    catalogItems
  } = catalogProduct;
  const catalogProductId = catalogProduct.id.uuid;
  const businessCategoryLabel = businessCategoryConfig.find(item => item.key === businessCategory).translation;

  const image =
    catalogProduct.images && catalogProduct.images.length > 0 ? catalogProduct.images[0] : null;

  const handleExpandClick = event => {
    event.preventDefault();
    setIsExpanded(prevState => !prevState);
  };

  useEffect(() => {
    setIsExpanded(isExpandedItems);
  }, [isExpandedItems]);

  return (
    <li className={css.listItem} key={catalogProductId}>
      <div className={classNames(css.itemContainer, { [css.expanded]: isExpanded })}>
        <div className={css.imgContainer}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={image}
              variants={['scaled-medium']}
            />
          </div>
        </div>
        <div className={css.detailsContainer}>
          <div className={css.details}>
            <p className={css.title}>{title}</p>
            <p className={css.price}>
              <span className={css.priceValue}>{amount / 100} {currency}</span>
              <span className={css.perUnit}>{intl.formatMessage({ id: 'Console.perDay' })}</span>
            </p>
            <div className={css.wrapper}>
              <p className={css.text}>
                {intl.formatMessage({ id: 'Console.businessCategory' })}: {intl.formatMessage({ id: businessCategoryLabel })}
              </p>
              <p className={css.text}>
                {intl.formatMessage({ id: 'Console.modelCode' })}: {modelCode}
              </p>
            </div>
            <div className={css.wrapper}>
              <p className={css.text}>
                {intl.formatMessage({ id: 'Console.minBookingDays' })}: {minBookingDays}
              </p>
              <p
                className={css.text}>{intl.formatMessage({ id: 'Console.itemsNumber' })}: {catalogItems.length}
              </p>
            </div>
            <p className={css.text}>{intl.formatMessage({ id: 'Console.parts' })}: {parts}</p>
            <p
              className={css.text}>{intl.formatMessage({ id: 'Console.description' })}: {description}
            </p>
          </div>
          <div className={css.actionContainer}>
            <div className={css.actionWrapper}>
              <NamedLink className={css.link} name="EditProductPage" params={{ id: catalogProductId, type: 'edit' }}>
                <FormattedMessage id="Console.editCatalogProduct" />
              </NamedLink>
              <span> • </span>
              <NamedLink className={css.link} name="EditItemPage" params={{ id: '00000-0000-0000-0000', type: 'new' }} to={{ search: `productId=${catalogProductId}`}}>
                <FormattedMessage id="Console.creatCatalogItem" />
              </NamedLink>
            </div>
            {!!catalogItems.length &&
              <button
                className={classNames(css.link, css.showBtn)}
                onClick={handleExpandClick}
              >
                {intl.formatMessage({
                  id: isExpanded ? 'Console.collapseCatalogItems' : 'Console.expandCatalogItems',
                })}
              </button>}
          </div>
        </div>
      </div>
      {isExpanded &&  !!catalogItems.length &&
        <>
          <h3>{intl.formatMessage({ id: "Console.catalogItemsHeading" })}</h3>
          {catalogItems.map(item => (
            <CatalogItem key={item.id.uuid} catalogItem={item} intl={intl} productTitle={title} catalogProductId={catalogProductId}/>
          ))}
        </>}
    </li>
  );
};

ProductItem.defaultProps = {
  catalogProduct: null,
};

ProductItem.propTypes = {
  catalogProduct: propTypes.catalogProduct,
  intl: intlShape.isRequired,
  isExpandedItems: bool.isRequired,
};

export default ProductItem;
