import React from 'react';
import { number, string, func, arrayOf, object } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../../util/types';
import { FormattedMessage } from '../../../../util/reactIntl';
import businessCategoryConfig from '../../../../business-category-config';

import css from './../AutocompleteListing.css';

const DefaultSearches = ({
  defaultSearches,
  categories,
  handleClick,
  lengthOfLastSearches,
  highlightedSuggestionIndex,
  predictionsClasses,
  intl,
}) => {
  const sortedSuggestions = defaultSearches.sort((a, b) => Number(b.exact) - Number(a.exact));

  return (
    <div className={css.container}>
      <p className={css.subheading}>
        <FormattedMessage id={'MainFilters.suggestions'} />
      </p>
      <ul className={predictionsClasses}>
        {sortedSuggestions.map(({ keyword, category, subcategory, exact }, index) => {
          const categoryLabel = categories.find(option => option.key === category);
          const subcategoryLabel = businessCategoryConfig.find(({ key }) => key === subcategory);
          const label = subcategoryLabel || categoryLabel;
          const keywordValue = exact ? intl.formatMessage({ id: label.translation }) : keyword;

          return (
            <li
              key={index}
              onClick={() => handleClick(keyword, category, subcategory, exact)}
              className={classNames(css.listItem, {
                [css.highlightedSuggestion]:
                  index + lengthOfLastSearches === highlightedSuggestionIndex,
              })}
            >
              {exact ? (
                <>
                  <span className={css.text}>
                    {intl.formatMessage({ id: 'General.category' })}{' '}
                  </span>
                  <span className={css.textBold}>{keywordValue}</span>
                </>
              ) : (
                <>
                  <span className={css.text}>{keywordValue}</span>
                  <p className={css.subtext}>{intl.formatMessage({ id: label.translation })}</p>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

DefaultSearches.defaultProps = {
  rootClassName: null,
  className: null,
};

DefaultSearches.propTypes = {
  defaultSearches: arrayOf(object),
  handleClick: func.isRequired,
  categories: arrayOf(propTypes.categoriesSuggestions).isRequired,
  lengthOfLastSearches: number.isRequired,
  highlightedSuggestionIndex: number.isRequired,
  predictionsClasses: string,
};

export default DefaultSearches;
