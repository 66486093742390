import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { BookingConditionsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { updateProfile } from './BookingConditionsPage.duck';

import css from './BookingConditionsPage.css';

const { LatLng } = sdkTypes;

export class BookingConditionsPageComponent extends Component {
  render() {
    const {
      currentUser,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      intl,
    } = this.props;

    const handleSubmit = values => {
      const { minBookingDays, fourDaysDiscount, weekDiscount, monthDiscount, rentTerms, depositConditions, openingHours } = values;

      const profile = {
        publicData: {
          discountsSchema: {
            type: 'period',
            params: {
              fourDays: fourDaysDiscount / 100,
              week: weekDiscount / 100,
              month: monthDiscount / 100
            }
          },
          minBookingDays,
          rentTerms,
          depositConditions,
          openingHours
        },
      };

      onUpdateProfile(profile);
    };

    const user = ensureCurrentUser(currentUser);
    const { publicData: { minBookingDays, discountsSchema, depositConditions, rentTerms, openingHours } = {} } = user.attributes.profile;

    const fourDaysDiscount = discountsSchema && discountsSchema.params ? parseInt(discountsSchema.params.fourDays * 100) : 0;
    const weekDiscount = discountsSchema && discountsSchema.params ? parseInt(discountsSchema.params.week * 100) : 0;
    const monthDiscount = discountsSchema && discountsSchema.params ? parseInt(discountsSchema.params.month * 100) : 0;

    const bookingConditionsForm = user.id ? (
      <BookingConditionsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ 
          minBookingDays,
          fourDaysDiscount,
          weekDiscount,
          monthDiscount,
          rentTerms,
          openingHours,
          depositConditions
        }}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const title = intl.formatMessage({
      id: "BookingConditionsPage.rentalConditions",
    });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="BookingConditionsPage" />
            <UserNav selectedPageName="BookingConditionsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperBusinessSideNav currentTab="BookingConditionsPage" />
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  {intl.formatMessage({ id: "BookingConditionsPage.rentalConditions" })}
                </h1>
              </div>
              {bookingConditionsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

BookingConditionsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

BookingConditionsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const BookingConditionsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(BookingConditionsPageComponent);

export default BookingConditionsPage;
