import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTick.css';

const IconTick = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7052 6.29289L20.3516 6.64645L20.7052 6.29289C20.3146 5.90237 19.6815 5.90237 19.2909 6.29289L19.5648 6.56677L19.2909 6.29289L9.49805 16.0858L4.70515 11.2929C4.31463 10.9024 3.68146 10.9024 3.29094 11.2929C2.90042 11.6834 2.90042 12.3166 3.29094 12.7071L9.14449 18.5607L9.49805 18.9142L9.8516 18.5607L20.7052 7.70711C21.0957 7.31658 21.0957 6.68342 20.7052 6.29289Z"
        fill="#23A942"
        stroke="#23A942"
        strokeLinecap="round"
      />
    </svg>
  );
};

IconTick.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconTick.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconTick;
