/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensurePaymentMethodCard } from '../../util/data';

import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  FieldSelect,
  IconWarning,
  CheckoutCustomFields
} from '../../components';

import css from './StripePaymentForm.css';

const INVOICE_FIELDS = [
  {
    "label": "StripePaymentForm.country",
    "type": "text",
    "placeholder": "StripePaymentForm.country",
    "name": "invoice.country",
    "required": true
  },
  {
    "label": "StripePaymentForm.city",
    "type": "text",
    "placeholder": "StripePaymentForm.city",
    "name": "invoice.city",
    "required": true
  },
  {
    "label": "StripePaymentForm.zip",
    "type": "text",
    "placeholder": "StripePaymentForm.zip",
    "name": "invoice.zip",
    "required": true
  },
  {
    "label": "StripePaymentForm.address",
    "type": "text",
    "placeholder": "StripePaymentForm.address",
    "name": "invoice.address",
    "required": true
  },
];

const COMPANY_FIELDS = [
  {
    "label": "StripePaymentForm.companyName",
    "type": "text",
    "placeholder": "StripePaymentForm.companyName",
    "name": "invoice.companyName",
    "required": true
  },
  {
    "label": "StripePaymentForm.taxId",
    "type": "text",
    "placeholder": "StripePaymentForm.taxId",
    "name": "invoice.taxId",
    "required": true
  },
  ...INVOICE_FIELDS
];

const INDIVIDUAL_FIELDS = [
  {
    "label": "StripePaymentForm.customerName",
    "type": "text",
    "placeholder": "StripePaymentForm.customerName",
    "name": "invoice.name",
    "required": true
  },
  ...INVOICE_FIELDS
]

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
      ? 'onetimeCardPayment'
      : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  paymentMethodType: null
};

class StripePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
    this.p24Container = null;
    this.deliveryOption = null;
    this.elements = null;
  }

  componentDidMount() {
    this.setState({ deliveryOption: this.props.isStoreSelect ? 'store' : null });
  }

  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      defaultPaymentMethod,
    } = this.props;

    const { paymentMethod } = this.state;

    if (inProgress) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      card: this.card,
      elements: this.elements,
      formId,
      formValues: { ...values, delivery: this.state.deliveryOption },
      paymentMethod: this.state.paymentMethodType === 'p24' ? 'p24' : getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      )
    };

    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      authorDisplayName,
      intl,
      initiateOrderError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      currentProvider,
      values,
      warnings,
      prolongFor,
      prolongationTo,
      isPartnership
    } = formRenderProps;

    this.finalFormAPI = form;

    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const classes = classNames(rootClassName || css.root, className);

    const hasStripeKey = config.stripe.publishableKey;

    const required = validators.required( intl.formatMessage({ id: 'StripePaymentForm.filedRequired' }));

    const { providerRules, listings } = this.props;
    const showDeliveryToHome = listings.some(listing => listing.listing.attributes.deliveryToHome);
    const showDeliveryToProviders = listings.some(
      listing => listing.listing.attributes.deliveryToProviders
    );
    const isNotDeliveryToHome = listings.filter(
      listing => !listing.listing.attributes.deliveryToHome
    );
    const isNotDeliveryToProviders = listings.filter(
      listing => !listing.listing.attributes.deliveryToProviders
    );

    const conditionsOfDelivery =
      showDeliveryToHome &&
      showDeliveryToProviders &&
      !(!!isNotDeliveryToHome.length || !!isNotDeliveryToProviders.length) &&
      !this.state.deliveryOption;

    const isProlongFor = prolongationTo && prolongFor;
    const confirmButtonDisabledProlongFor =  !values.terms;
    const confirmButtonDisabledNewRental =  !values.terms ||
      invalid ||
      submitInProgress ||
      conditionsOfDelivery ||
      !!warnings.length;

    const confirmButtonDisabled = isProlongFor
      ? confirmButtonDisabledProlongFor
      : confirmButtonDisabledNewRental;

    const isNotDeliveryToHomeTitle = isNotDeliveryToHome.map(
      listing => listing.listing.attributes.title
    );
    const isNotDeliveryToProvidersTitle = isNotDeliveryToProviders.map(
      listing => listing.listing.attributes.title
    );
    const textIsNotDeliveryToProviders =
      isNotDeliveryToProvidersTitle.length === 1
        ? 'CheckoutPage.deliverToShopOneProduct'
        : 'CheckoutPage.deliverToShopProducts';
    const textIsNotDeliveryToHome =
      isNotDeliveryToHomeTitle.length === 1
        ? 'CheckoutPage.deliverNotToHomeOneProduct'
        : 'CheckoutPage.deliverNotToHomeProducts';

    const openingHours = currentProvider.attributes.openingHours;

    const deliverAddress = currentProvider?.attributes?.location ? (
      <div className={css.addressContainer}>
        <div>
          <p className={css.addressTitle}>{authorDisplayName}</p>
          <p className={css.addressText}>{currentProvider.attributes.location.address}</p>
        </div>
        {openingHours && (
          <div>
            <p className={css.addressTitle}>{intl.formatMessage({ id: "CheckoutPage.openingHours" })}</p>
            <p className={classNames(css.addressText, css.openingHours)}>{openingHours}</p>
          </div>
        )}
      </div>
    ) : null;

    const storeSelect = this.state.deliveryOption === 'store' && (
      <FieldSelect
        id={`${formId}-deliveryLocation`}
        name="deliveryLocation"
        className={css.field}
        label={intl.formatMessage({ id: 'StripePaymentForm.deliveryLocationLabel' })}
        validate={required}
      >
        <option disabled value="">
        {intl.formatMessage({ id: 'StripePaymentForm.store' })}
        </option>
        {currentProvider.deliveryToProviders.sort((a,b) => {
          if (a.attributes.name > b.attributes.name) return 1;
          return -1;
        }).map(store => {
          return (
            <option key={store.attributes.name} value={store.id.uuid}>
              {store.attributes.name}
            </option>
          );
        })}
      </FieldSelect>
    );

    const deliveryPlace = (childrenSection) => (
      <div>
      <h3 style={{ marginTop: '20px' }}>2.{' '}<FormattedMessage id={"StripePayoutPage.placeOfDelivery"}/></h3>
      {childrenSection}
    </div>
    )

    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit} id="checkoutForm">
        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}
        {!isProlongFor ? <>
        <div className={css.paymentSection}>
          <h3>{intl.formatMessage({ id: 'StripePaymentForm.bookingDetails' })}</h3>
          <div className={css.fieldsRow}>
            <FieldTextInput
              type="text"
              id={`${formId}-customerName`}
              name="customerName"
              label={intl.formatMessage({ id: 'StripePaymentForm.name' })}
              placeholder={intl.formatMessage({ id: 'StripePaymentForm.namePlaceholder' })}
              className={css.field}
              validate={required}
            />
            <FieldTextInput
              type="text"
              id={`${formId}-customerEmail`}
              name="customerEmail"
              label={intl.formatMessage({ id: 'StripePaymentForm.email' })}
              placeholder={intl.formatMessage({ id: 'StripePaymentForm.emailPlaceholder' })}
              className={css.field}
              validate={required}
            />
          </div>
          <div className={css.fieldsRow}>
            <FieldTextInput
              type="text"
              id={`${formId}-customerPhoneNumber`}
              name="customerPhoneNumber"
              label={intl.formatMessage({ id: 'StripePaymentForm.phoneNumber' })}
              placeholder={intl.formatMessage({ id: 'StripePaymentForm.phoneNumberPlaceholder' })}
              className={css.field}
              validate={required}
            />
            <FieldTextInput
              type="text"
              id={`${formId}-receiver`}
              name="receiver"
              label={intl.formatMessage({ id: 'StripePaymentForm.receiver' })}
              placeholder={intl.formatMessage({ id: 'StripePaymentForm.receiverPlaceholder' })}
              className={css.field}
            />
          </div>

          {showDeliveryToHome && showDeliveryToProviders ? (
            <div>
              <h3 style={{ marginTop: '20px' }}>2.{' '}<FormattedMessage id={"StripePayoutPage.placeOfDelivery"}/>*</h3>
              <div className={css.methodSelector}>
                <button
                  onClick={() => this.setState({ deliveryOption: 'store' })}
                  type="button"
                  className={classNames(css.paymentMethodButton, css.paymentMethodBtnDisabled, {
                    [css.paymentMethodSelected]: this.state.deliveryOption === 'store',
                  })}
                  disabled={isNotDeliveryToProviders?.length}
                >
                  {!!isNotDeliveryToProviders?.length && <IconWarning variant={'disabled'} />}
                  {intl.formatMessage({ id: 'StripePaymentForm.deliveryToStore' })}
                  <span className={css.tooltiptext}>
                    <FormattedMessage
                      id={textIsNotDeliveryToProviders}
                      values={{ productName: isNotDeliveryToProvidersTitle.toString() }}
                    />
                  </span>
                </button>
                <button
                  onClick={() => this.setState({ deliveryOption: 'home' })}
                  type="button"
                  className={classNames(css.paymentMethodButton, css.paymentMethodBtnDisabled, {
                    [css.paymentMethodSelected]: this.state.deliveryOption === 'home',
                  })}
                  disabled={isNotDeliveryToHome?.length}
                >
                  {!!isNotDeliveryToHome?.length && <IconWarning variant={'disabled'} />}
                  {intl.formatMessage({ id: 'StripePaymentForm.homeDelivery' })}
                  <span className={css.tooltiptext}>
                    <FormattedMessage
                      id={textIsNotDeliveryToHome}
                      values={{ productName: isNotDeliveryToHomeTitle.toString() }}
                    />
                  </span>
                </button>
              </div>
              {storeSelect}
              {this.state.deliveryOption === 'home' && (
                <>
                  <h4 className={css.homeDeliveryInfo}>
                    {intl.formatMessage({ id: 'StripePaymentForm.partOneDeliveryInfo' })}{' '}
                    <span className={css.underlined}>
                      {intl.formatMessage({ id: 'StripePaymentForm.partTwoDeliveryInfo' })}
                    </span>{' '}
                    {intl.formatMessage({ id: 'StripePaymentForm.partThreeDeliveryInfo' })}
                  </h4>
                  <CheckoutCustomFields
                    additionalFields={{
                      fields: [
                        {
                          label: intl.formatMessage({ id: 'StripePaymentForm.cityLabel' }),
                          type: 'text',
                          placeholder: intl.formatMessage(
                            { id: 'StripePaymentForm.cityPlaceholder' }),
                          name: 'city',
                          required: true,
                        },
                        {
                          label: intl.formatMessage({ id: 'StripePaymentForm.addressLabel' }),
                          type: 'text',
                          placeholder: intl.formatMessage({ id: 'StripePaymentForm.addressPlaceholder' }),
                          name: 'address',
                          required: true,
                        },
                      ],
                    }}
                    intl={intl}
                  />
                </>
              )}
              {!!isNotDeliveryToProviders?.length &&
                !!isNotDeliveryToHome?.length &&
                deliverAddress}
            </div>
          ) : (showDeliveryToProviders && !showDeliveryToHome) ? (
            deliveryPlace(storeSelect)
          ) : currentProvider?.attributes?.location ? (
            deliveryPlace(deliverAddress)
          ) : null}

        </div>
        {(currentProvider.attributes.countryId === 'hu' || currentProvider.attributes.countryId === 'cz' ||
          (currentProvider.attributes.countryId === 'pl' && currentProvider.attributes.isDecathlon)) && !isPartnership && (
          <div className={css.paymentSection}>
            <h3>
              {intl.formatMessage(
                { id: 'StripePaymentForm.invoiceDetails' },
                { sectionNumber: '3.' }
              )}
            </h3>
            <FieldSelect
              id="type"
              name="customerType"
              className={css.selectCountry}
              label={intl.formatMessage({ id: 'StripePaymentForm.customerType' })}
              validate={validators.required('Type required')}
            >
              <option value="individual">
                {intl.formatMessage({ id: 'StripePaymentForm.indivdual' })}
              </option>
              <option value="company">
                {intl.formatMessage({ id: 'StripePaymentForm.company' })}
              </option>
            </FieldSelect>
            <CheckoutCustomFields
              additionalFields={{
                fields:
                  values.customerType === 'company'
                    ? COMPANY_FIELDS.map(f => ({
                        ...f,
                        required: values.customerType === 'company',
                      }))
                    : INDIVIDUAL_FIELDS.map(f => ({
                        ...f,
                        required: values.customerType === 'individual',
                      })),
              }}
              intl={intl}
              needsTranslation
            />
            <div className={classNames(css.fieldsRow, css.checkboxSaveInvoiceContainer)}>
              <FieldCheckbox
                id="saveInvoiceDetails"
                name="saveInvoiceDetails"
                label={intl.formatMessage({ id: 'StripePaymentForm.saveInvoiceDetails' })}
                size={'large'}
              />
            </div>
          </div>
        )} </>
      : null}
        <div className={classNames(css.fieldsRow, css.checkboxTermContainer)}>
          <FieldCheckbox
            id="terms"
            name="terms"
            label={ <span>
              {intl.formatMessage({ id: 'StripePaymentForm.rentingTerms1' })}{' '}
              <a href={providerRules} className={css.termsLink} target="_blank">
                {intl.formatMessage({ id: 'StripePaymentForm.rentingTerms2' })}
              </a>
            </span>}
            required
            size={'large'}
          />
        </div>
        <div className={css.submitContainer}>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={confirmButtonDisabled}
          >
            {billingDetailsNeeded ? (
              <FormattedMessage id={isPartnership ? "StripePaymentForm.submitPartnershipTx" : "StripePaymentForm.submitPaymentInfo"} />
            ) : (
              <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
            )}
          </PrimaryButton>
          <p className={css.legalInfo}>
            <FormattedMessage id="StripePaymentForm.ppLink" />
            <a
              href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/privacy-policy-${config.locale}.pdf`}
              className={css.legalLink}
              target="_blank"
            >
              <FormattedMessage id="Footer.privacyPolicy" />
            </a>
          </p>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} keepDirtyOnReinitialize />;
  }
}

StripePaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  handleCardPaymentError: null,
  confirmPaymentError: null,
};

StripePaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  handleCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};

export default injectIntl(StripePaymentForm);
