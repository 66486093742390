import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const UPDATE_PROVIDER_REQUEST = 'app/ProviderPage/UPDATE_PROVIDER_REQUEST';
export const UPDATE_PROVIDER_SUCCESS = 'app/ProviderPage/UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'app/ProviderPage/UPDATE_PROVIDER_ERROR';
export const UPDATE_PROVIDER_CLEAR = 'app/ProviderPage/UPDATE_PROVIDER_CLEAR';

export const FETCH_OPENING_EXCEPTIONS_REQUEST = 'app/ProviderPage/FETCH_OPENING_EXCEPTIONS_REQUEST';
export const FETCH_OPENING_EXCEPTIONS_SUCCESS = 'app/ProviderPage/FETCH_OPENING_EXCEPTIONS_SUCCESS';
export const FETCH_OPENING_EXCEPTIONS_ERROR = 'app/ProviderPage/FETCH_OPENING_EXCEPTIONS_ERROR';

export const CREATE_OPENING_EXCEPTIONS_REQUEST = 'app/ProviderPage/CREATE_OPENING_EXCEPTIONS_REQUEST';
export const CREATE_OPENING_EXCEPTIONS_SUCCESS = 'app/ProviderPage/CREATE_OPENING_EXCEPTIONS_SUCCESS';
export const CREATE_OPENING_EXCEPTIONS_ERROR = 'app/ProviderPage/CREATE_OPENING_EXCEPTIONS_ERROR';

export const UPDATE_OPENING_EXCEPTIONS_REQUEST = 'app/ProviderPage/UPDATE_OPENING_EXCEPTIONS_REQUEST';
export const UPDATE_OPENING_EXCEPTIONS_SUCCESS = 'app/ProviderPage/UPDATE_OPENING_EXCEPTIONS_SUCCESS';
export const UPDATE_OPENING_EXCEPTIONS_ERROR = 'app/ProviderPage/UPDATE_OPENING_EXCEPTIONS_ERROR';

export const DELETE_OPENING_EXCEPTIONS_REQUEST = 'app/ProviderPage/DELETE_OPENING_EXCEPTIONS_REQUEST';
export const DELETE_OPENING_EXCEPTIONS_SUCCESS = 'app/ProviderPage/DELETE_OPENING_EXCEPTIONS_SUCCESS';
export const DELETE_OPENING_EXCEPTIONS_ERROR = 'app/ProviderPage/DELETE_OPENING_EXCEPTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  provider: {},
  updateProviderInProgress: false,
  updateProviderError: null,
  updateProviderSuccess: false,
  openingExceptions: [],
  fetchOpeningExceptionsInProgress: false,
  fetchOpeningExceptionsError: null,
  fetchOpeningExceptionsSuccess: false,
  createOpeningExceptionsInProgress: false,
  createOpeningExceptionsError: null,
  createOpeningExceptionsSuccess: false,
  updateOpeningExceptionsInProgress: false,
  updateOpeningExceptionsError: null,
  updateOpeningExceptionsSuccess: false,
  deleteOpeningExceptionsInProgress: false,
  deleteOpeningExceptionsError: null,
  deleteOpeningExceptionsSuccess: false,
};

export default function providerReducer(state = initialState, action = {}, data = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROVIDER_REQUEST:
      return {
        ...state,
        provider: {},
        updateProviderInProgress: true,
        updateProviderError: null,
        updateProviderSuccess: false,
      };
    case UPDATE_PROVIDER_SUCCESS: {
      return {
        ...state,
        updateProviderError: null,
        updateProviderInProgress: false,
        updateProviderSuccess: true,
        provider: payload.data.data,
      };
    }
    case UPDATE_PROVIDER_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        provider: {},
        updateProviderInProgress: false,
        updateProviderError: payload,
        updateProviderSuccess: false,
      };
    case UPDATE_PROVIDER_CLEAR:
      return {
        ...state,
        provider: {},
        updateProviderInProgress: false,
        updateProviderError: null,
        updateProviderSuccess: false,
      }
    case FETCH_OPENING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        openingExceptions: [],
        fetchOpeningExceptionsInProgress: true,
        fetchOpeningExceptionsError: null,
        fetchOpeningExceptionsSuccess: false,
      };
    case FETCH_OPENING_EXCEPTIONS_SUCCESS: {
      return {
        ...state,
        openingExceptions: payload.data.data,
        fetchOpeningExceptionsError: null,
        fetchOpeningExceptionsInProgress: false,
        fetchOpeningExceptionsSuccess: true,
      };
    }
    case FETCH_OPENING_EXCEPTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        openingExceptions: [],
        fetchOpeningExceptionsInProgress: false,
        fetchOpeningExceptionsError: payload,
        fetchOpeningExceptionsSuccess: false,
      };
    case CREATE_OPENING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        createOpeningExceptionsInProgress: true,
        createOpeningExceptionsError: null,
        createOpeningExceptionsSuccess: false,
      };
    case CREATE_OPENING_EXCEPTIONS_SUCCESS: {
      return {
        ...state,
        createOpeningExceptionsError: null,
        createOpeningExceptionsInProgress: false,
        createOpeningExceptionsSuccess: true,
      };
    }
    case CREATE_OPENING_EXCEPTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        createOpeningExceptionsInProgress: false,
        createOpeningExceptionsError: payload,
        createOpeningExceptionsSuccess: false,
      };
    case UPDATE_OPENING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        updateOpeningExceptionsInProgress: true,
        updateOpeningExceptionsError: null,
        updateOpeningExceptionsSuccess: false,
      };
    case UPDATE_OPENING_EXCEPTIONS_SUCCESS: {
      return {
        ...state,
        updateOpeningExceptionsError: null,
        updateOpeningExceptionsInProgress: false,
        updateOpeningExceptionsSuccess: true,
      };
    }
    case UPDATE_OPENING_EXCEPTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateOpeningExceptionsInProgress: false,
        updateOpeningExceptionsError: payload,
        updateOpeningExceptionsSuccess: false,
      };
    case DELETE_OPENING_EXCEPTIONS_REQUEST:
      return {
        ...state,
        deleteOpeningExceptionsInProgress: true,
        deleteOpeningExceptionsError: null,
        deleteOpeningExceptionsSuccess: false,
      };
    case DELETE_OPENING_EXCEPTIONS_SUCCESS: {
      return {
        ...state,
        deleteOpeningExceptionsError: null,
        deleteOpeningExceptionsInProgress: false,
        deleteOpeningExceptionsSuccess: true,
      };
    }
    case DELETE_OPENING_EXCEPTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        deleteOpeningExceptionsInProgress: false,
        deleteOpeningExceptionsError: payload,
        deleteOpeningExceptionsSuccess: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateProviderRequest = () => ({ type: UPDATE_PROVIDER_REQUEST });

const updateProviderSuccess = response => ({
  type: UPDATE_PROVIDER_SUCCESS,
  payload: response,
});

const updateProviderError = e => ({
  type: UPDATE_PROVIDER_ERROR,
  error: true,
  payload: e,
});

const updateProviderClear = () => ({ type: UPDATE_PROVIDER_CLEAR });

const fetchOpeningExceptionsRequest = () => ({ type: FETCH_OPENING_EXCEPTIONS_REQUEST });

const fetchOpeningExceptionsSuccess = response => ({
  type: FETCH_OPENING_EXCEPTIONS_SUCCESS,
  payload: response,
});

const fetchOpeningExceptionsError = e => ({
  type: FETCH_OPENING_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

const createOpeningExceptionsRequest = () => ({ type: CREATE_OPENING_EXCEPTIONS_REQUEST });

const createOpeningExceptionsSuccess = response => ({
  type: CREATE_OPENING_EXCEPTIONS_SUCCESS,
  payload: response,
});

const createOpeningExceptionsError = e => ({
  type: CREATE_OPENING_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

const updateOpeningExceptionsRequest = () => ({ type: UPDATE_OPENING_EXCEPTIONS_REQUEST });

const updateOpeningExceptionsSuccess = response => ({
  type: UPDATE_OPENING_EXCEPTIONS_SUCCESS,
  payload: response,
});

const updateOpeningExceptionsError = e => ({
  type: UPDATE_OPENING_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

const deleteOpeningExceptionsRequest = () => ({ type: DELETE_OPENING_EXCEPTIONS_REQUEST });

const deleteOpeningExceptionsSuccess = response => ({
  type: DELETE_OPENING_EXCEPTIONS_SUCCESS,
  payload: response,
});

const deleteOpeningExceptionsError = e => ({
  type: DELETE_OPENING_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const updateProvider = (data) => (dispatch, getState, sdk) => {
  dispatch(updateProviderRequest());

  return sdk.newSdk.providers
    .update(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateProviderSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(updateProviderError(e));
      throw e;
    });
};

export const clearUpdateProvider = () => (dispatch, getState, sdk) => {
  dispatch(updateProviderClear());
}

export const fetchOpeningException = (data) => (dispatch, getState, sdk) => {
  dispatch(fetchOpeningExceptionsRequest());

  return sdk.newSdk.openingExceptions
    .query(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOpeningExceptionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOpeningExceptionsError(e));
      throw e;
    });
};

export const createOpeningException = (data) => (dispatch, getState, sdk) => {
  dispatch(createOpeningExceptionsRequest());

  return sdk.newSdk.openingExceptions
    .create(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(createOpeningExceptionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(createOpeningExceptionsError(e));
      throw e;
    });
};

export const updateOpeningException = (data) => (dispatch, getState, sdk) => {
  dispatch(updateOpeningExceptionsRequest());

  return sdk.newSdk.openingExceptions
    .update(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateOpeningExceptionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(updateOpeningExceptionsError(e));
      throw e;
    });
};

export const deleteOpeningException = (data) => (dispatch, getState, sdk) => {
  dispatch(deleteOpeningExceptionsRequest());

  return sdk.newSdk.openingExceptions
    .delete(data)
    .then(response => {
      dispatch(deleteOpeningExceptionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(deleteOpeningExceptionsError(e));
      throw e;
    });
};
