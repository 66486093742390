import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Avatar, ConfirmModal, IconSpinner, IconTick, InlineTextButton } from '../../../components';
import EditPermission from '../EditPermission/EditPermission';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './PermissionItem.css';

const PermissionItem = ({
  intl,
  user,
  deletePermissions,
  deleteInProgress,
  onManageDisableScrolling,
  deletePermissionsError,
  deletePermissionsSuccess,
  updateInProgress,
  updatePermissions,
  updatePermissionsError,
  updatePermissionsSuccess,
  permissionIdEdit,
  setPermissionIdEdit,
  clearUpdatePermissionsState,
}) => {
  const {
    attributes: { role, email, sendNotifications, registered },
    id: { uuid },
  } = user;
  const [isClicked, setIsClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const message = deletePermissionsError ? (
    <p className={css.error}>{deletePermissionsError.message}</p>
  ) : (
    <p>
      <FormattedMessage id={'TeamPage.messageConfirm'} />
    </p>
  );

  const handleDelete = event => {
    event.preventDefault();
    setIsClicked(true);
    deletePermissions({ id: uuid });
  };

  const notificationsText = sendNotifications ? 'TeamPage.yes' : 'TeamPage.no';

  const handleEdit = (event, uuid) => {
    setPermissionIdEdit(uuid);
  };
  
  useEffect(() => {
    !deleteInProgress && setIsClicked(false);
  }, [deleteInProgress]);

  useEffect(() => {
    !deleteInProgress && deletePermissionsSuccess && setIsOpen(false);
  }, [deletePermissionsSuccess, deleteInProgress]);

  return (
    <li className={css.listItem} key={uuid}>
      <div className={css.container}>
        <div className={css.item}>
          <div className={css.itemAvatar}>
            <Avatar />
          </div>
          <div className={css.itemNameDetails}>
            {registered && (
              <p className={classNames(css.text, css.user)}>
                {user.user?.attributes.firstName} {user.user?.attributes.lastName}
              </p>
            )}
            <div className={css.emailContainer}>
              <span className={classNames(css.text, css.user)}>{email} </span>
              {registered && <IconTick/>}
            </div>
            <p className={css.text}>
              <FormattedMessage id="TeamPage.role" />
              {': '} {role}
            </p>
            <p className={css.text}>
              <FormattedMessage id="TeamPage.receivesNotifications" />{' '}
              <span className={registered ? css.text : css.strikethroughText}><FormattedMessage id={notificationsText} /></span>
            </p>
          </div>
        </div>
        <div className={css.actionContainer}>
          {permissionIdEdit !== uuid && (
            <InlineTextButton className={css.button} onClick={event => handleEdit(event, uuid)}>
              <FormattedMessage id="TeamPage.edit" />
            </InlineTextButton>
          )}
          {deleteInProgress && isClicked ? (
            <IconSpinner />
          ) : (
            <InlineTextButton className={css.button} onClick={() => setIsOpen(true)}>
              {intl.formatMessage({ id: 'TeamPage.delete' })}
            </InlineTextButton>
          )}
        </div>
      </div>
      {permissionIdEdit === uuid && (
        <EditPermission
          updateInProgress={updateInProgress}
          updatePermissions={updatePermissions}
          updatePermissionsError={updatePermissionsError}
          updatePermissionsSuccess={updatePermissionsSuccess}
          role={role}
          sendNotifications={sendNotifications}
          intl={intl}
          setPermissionIdEdit={setPermissionIdEdit}
          permissionsId={uuid}
          clearUpdatePermissionsState={clearUpdatePermissionsState}
          registered={registered}
        />
      )}
      <ConfirmModal
        id={uuid}
        isOpen={isOpen}
        onCloseModal={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        text={message}
        handleSubmit={handleDelete}
        handleCancel={() => setIsOpen(false)}
        inProgress={deleteInProgress}
      />
    </li>
  );
};

export default PermissionItem;
