import React from 'react';
import classNames from 'classnames';
import { func, string, bool, oneOf } from 'prop-types';

import css from './RadioButton.css';

const VARIANTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const RadioButton = ({
  checked,
  onChange,
  label,
  labelClassName,
  inputClassName,
  textClassName,
  radioClassName,
  size,
  ...rest
}) => {
  const labelClass = classNames(labelClassName, css.label, {
    [css.largeLabel]: size === VARIANTS.LARGE,
    [css.mediumLabel]: size === VARIANTS.MEDIUM,
    [css.smallLabel]: size === VARIANTS.SMALL,
  });
  const inputClasses = classNames(inputClassName, css.radioInput);
  const textClasses = classNames(textClassName, css.text);
  const radioClass = classNames(radioClassName, css.radio, {
    [css.large]: size === VARIANTS.LARGE,
    [css.medium]: size === VARIANTS.MEDIUM,
    [css.small]: size === VARIANTS.SMALL,
  });

  return (
    <label className={labelClass}>
      <span className={textClasses}>{label}</span>
      <input
        className={inputClasses}
        type="radio"
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <span className={radioClass}></span>
    </label>
  );
};

RadioButton.defaultProps = {
  checked: false,
  labelClassName: null,
  inputClassName: null,
  textClassName: null,
  radioClassName: null,
  size: VARIANTS.MEDIUM,
};

RadioButton.propTypes = {
  checked: bool,
  onChange: func,
  label: string,
  labelClassName: string,
  inputClassName: string,
  textClassName: string,
  radioClassName: string,
  size: oneOf([VARIANTS.SMALL, VARIANTS.MEDIUM, VARIANTS.LARGE]),
};

export default RadioButton;
