/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash/debounce';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { dateFromLocalToAPI, nightsBetween, daysBetween } from '../../util/dates';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, LINE_ITEM_UNITS, DATE_TYPE_DATE } from '../../util/types';
import computeDiscount from './computeDiscount';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import { BookingBreakdown, ListingCard } from '../../components';
import calculatePriceEvent from './calculatePriceEvent';

import css from './BookingDatesForm.css';
import { speculateTransactionBeforeCart } from '../../containers/CheckoutPage/CheckoutPage.duck';
import { selectDatesEvent } from '../../analytics/gaEvents';

const { Money, UUID } = sdkTypes;

const DEBOUNCE_WAIT_TIME = 400;

const estimatedTotalPrice = (unitPrice, unitCount, provider, listing) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const numericTotalPrice = new Decimal(numericPrice).times(unitCount).toNumber();
  const discount = computeDiscount({
    price: numericPrice, quantity: unitCount, provider, listing
  });

  return new Money(
    (convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)) - discount * 100),
    unitPrice.currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the booking breakdown. This function creates
// an estimated transaction object for that use case.
const estimatedTransaction = (unitType, bookingStart, bookingEnd, unitPrice, quantity, provider, listing) => {
  const now = new Date();
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitCount = isNightly
    ? nightsBetween(bookingStart, bookingEnd)
    : isDaily
    ? daysBetween(bookingStart, bookingEnd)
    : quantity;

  const totalPrice = estimatedTotalPrice(unitPrice, unitCount, provider, listing);

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart = dateFromLocalToAPI(
    moment(bookingStart)
      .startOf('day')
      .toDate()
  );
  const serverDayEnd = dateFromLocalToAPI(
    moment(bookingEnd)
      .startOf('day')
      .toDate()
  );

  const totalBasePrice = convertMoneyToNumber(unitPrice) * unitCount * 100;
  const totalLongTermDiscount = computeDiscount({
    price: convertMoneyToNumber(unitPrice),
    quantity: unitCount,
    provider,
    listing
  }) * 100;

  const longTermDiscountMaybe = provider.countryId !== 'hu' ? [{
    code: 'long-term-discount',
    includeFor: ['customer', 'provider'],
    unitPrice: new Money(totalLongTermDiscount, unitPrice.currency),
    quantity: -1,
    lineTotal: new Money(totalLongTermDiscount * -1, unitPrice.currency),
    reversal: false,
  }] : [];

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal: totalPrice,
      payoutTotal: totalPrice,
      lineItems: [
        {
          code: unitType,
          includeFor: ['customer', 'provider'],
          unitPrice: unitPrice,
          quantity: new Decimal(unitCount),
          lineTotal: new Money(totalBasePrice, unitPrice.currency),
          reversal: false,
        },
        ...longTermDiscountMaybe
      ],
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: serverDayStart,
        end: serverDayEnd,
      },
    },
  };
};

const EstimatedBreakdownMaybe = props => {
  const [estimatedTx, setEstimatedTx] = useState(null);
  const { unitType, startDate, endDate, quantity } = props.bookingData;
  const { withDates, promotion, listing } = props;
  const canEstimatePrice = !!estimatedTx;
  const dispatch = useDispatch();

  const verify = useCallback(
    debounce((endDate, startDate, quantity) => {
      endDate && startDate && dispatch(speculateTransactionBeforeCart({
        bookingStart: startDate,
        bookingEnd: endDate,
        providerId: listing.provider.id.uuid,
        listingId: listing.id.uuid,
        quantity,
      })).then(res => {
        selectDatesEvent({
          currency: res.data.data.attributes.payinTotal.currency,
          item: {
            id: listing.id.uuid,
            name: listing.attributes.title,
            category: listing.attributes.businessCategory,
            price: listing.attributes.price.amount / 100,
            provider: listing.provider.attributes.name,
            quantity
          },
          value: res.data.data.attributes.payinTotal.amount / 100
        })
        setEstimatedTx(res.data.data);
      })
      .catch(err => {
        console.log(err)
      })
    }, DEBOUNCE_WAIT_TIME),
    []
  );

  useEffect(() => {
    canEstimatePrice && calculatePriceEvent({
      ...props.listingData,
      days: daysBetween(startDate, endDate)
    })
  }, [true]);

  useEffect(() => {
    verify(endDate, startDate, quantity)
    // endDate && startDate && dispatch(speculateTransactionBeforeCart({
    //   bookingStart: startDate,
    //   bookingEnd: endDate,
    //   providerId: listing.provider.id.uuid,
    //   listingId: listing.id.uuid,
    //   quantity,
    // })).then(res => {
    //   selectDatesEvent({
    //     currency: res.data.data.attributes.payinTotal.currency,
    //     item: {
    //       id: listing.id.uuid,
    //       name: listing.attributes.title,
    //       category: listing.attributes.businessCategory,
    //       price: listing.attributes.price.amount / 100,
    //       provider: listing.provider.attributes.name,
    //       quantity
    //     },
    //     value: res.data.data.attributes.payinTotal.amount / 100
    //   })
    //   setEstimatedTx(res.data.data);
    // })
    // .catch(err => {
    //   console.log(err)
    // })
  },[endDate, startDate, quantity])

  if (!canEstimatePrice) {
    return null;
  }

  return (
    <BookingBreakdown
      className={css.receipt}
      userRole="customer"
      unitType={unitType}
      transaction={estimatedTx}
      booking={estimatedTx.booking}
      dateType={DATE_TYPE_DATE}
      displayPromoLineItem={false}
      withDates={withDates}
      promotion={promotion}
      quantity={quantity}
    />
  );
};

export default EstimatedBreakdownMaybe;
