import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_PRODUCT_CATALOG_REQUEST =
  'app/ConsoleProductCatalogPage/FETCH_CONSOLE_PRODUCT_CATALOG_REQUEST';
export const FETCH_CONSOLE_PRODUCT_CATALOG_SUCCESS =
  'app/ConsoleProductCatalogPage/FETCH_CONSOLE_PRODUCT_CATALOG_SUCCESS';
export const FETCH_CONSOLE_PRODUCT_CATALOG_ERROR =
  'app/ConsoleProductCatalogPage/FETCH_CONSOLE_PRODUCT_CATALOG_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchProductCatalogListError: null,
  pagination: null,
  productCatalogList: [],
};

export default function consoleProductCatalogsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_PRODUCT_CATALOG_REQUEST:
      return { ...state, fetchInProgress: true, fetchProductCatalogListError: null };
    case FETCH_CONSOLE_PRODUCT_CATALOG_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        productCatalogList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_PRODUCT_CATALOG_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProductCatalogListError: payload };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchProductCatalogListRequest = () => ({ type: FETCH_CONSOLE_PRODUCT_CATALOG_REQUEST });

const fetchProductCatalogListSuccess = response => ({
  type: FETCH_CONSOLE_PRODUCT_CATALOG_SUCCESS,
  payload: response,
});

const fetchProductCatalogListError = e => ({
  type: FETCH_CONSOLE_PRODUCT_CATALOG_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, countryId = config.custom.countryId, phrase } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };
  const phraseParam = phrase ? { phrase } : undefined;

  const queryParams = {
    ...paginationParams,
    include: ['images', 'catalogItems.images'],
    countryId,
    ...phraseParam,
  };

  dispatch(fetchProductCatalogListRequest());

  return sdk.newSdk.catalogProducts
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchProductCatalogListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchProductCatalogListError(e));
      throw e;
    });
};
