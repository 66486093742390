import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { Avatar, InlineTextButton, ReviewRating, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_BY_OPERATOR,
  TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_NOT_PICKEDUP,
  TRANSITION_DECLINE,
  TRANSITION_PICK_UP,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  transitionIsReviewed,
  txIsDelivered,
  txIsReviewed,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './ActivityFeed.css';

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        <p className={css.messageContent}>{message.attributes.text}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{message.attributes.text}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
        {message.attributes.senderEmail}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal,
  review
) => {

  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;

  switch (currentTransition) {
    case 'request-payment':
    case 'transition/request-payment':
    case 'transition/request-push-payment':
      return ownRole === 'customer' ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequestInitate" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequestInitiate"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING:
    case TRANSITION_CONFIRM_PAYMENT:
    case 'confirm-payment':
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPushRequest" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionPushRequest"
          values={{ displayName, listingTitle }}
        />
      );

    case 'accept':
    case TRANSITION_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" values={{ displayName }} />
      );
    case TRANSITION_DECLINE:
    case 'decline':
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_EXPIRE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
      );
    case TRANSITION_PICK_UP:
    case 'pickup':
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPickedUp" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionPickedUp" values={{ displayName }} />
      );
    case 'cancel':
    case TRANSITION_CANCEL_BY_PROVIDER:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionCancelByProvider" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelByProvider" values={{ displayName }} />
      );
    case TRANSITION_CANCEL_BY_OPERATOR:
    case TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP:
        return <FormattedMessage id="ActivityFeed.transitionCancelByOperator" />;
    case 'complete':
    case TRANSITION_COMPLETE:
      // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
      const reviewPeriodJustStarted = txIsDelivered(transaction);

      const reviewAsFirstLink = reviewPeriodJustStarted && txRoleIsCustomer(ownRole) && !review ? (
        <InlineTextButton onClick={onOpenReviewModal}>
          <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
        </InlineTextButton>
      ) : null;

      return (
        <div>
          <FormattedMessage
            id="ActivityFeed.transitionComplete"
            values={{ reviewLink: reviewAsFirstLink }}
          />
          {
            transaction.attributes.documents &&
            <div>
              {transaction.attributes.documents.filter(d => d.type === 'invoice').map((d, i) => <a key={i} href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${d.url}`} target="_blank"><FormattedMessage id={`ActivityFeed.doc-${d.type}`} /></a>)}
            </div>
          }
        </div>
      );
    case 'complete-without-pickup':
    case TRANSITION_COMPLETE_NOT_PICKEDUP:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionCompleteNotPickedup"
        />
      );

    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      // return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionReview" values={{ displayName }} />
      );


    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal, review } = props;

  const currentTransaction = ensureTransaction(transaction);

  const customer = currentTransaction.user;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listings[0].attributes.deleted
    ? deletedListing
    : currentTransaction.listings[0].attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} nameToDisplay={customer.attributes.displayName} />
  ) : (
    <UserDisplayName user={provider} intl={intl} nameToDisplay={provider.attributes.name} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal,
    review
  );
  const currentTransition = transition.transition;

  const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
  let reviewComponent = null;

  if (transitionIsReviewed(currentTransition)) {
    const review = reviewByAuthorId(currentTransaction, customer.id);
    reviewComponent = review ? (
      <Review content={review.attributes.content} rating={review.attributes.rating} />
    ) : (
      <Review content={deletedReviewContent} />
    );
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) || item.type === 'review' ? new Date(item.attributes.createdAt) : new Date(item.createdAt));
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, review, hideOldTransitions) => {
  const reviewMaybe = review || []
  const items = messages.concat(transitions).concat(reviewMaybe).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    review,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.user);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listings[0]);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, review, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          review={review}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage = (message && message.attributes.sender && message.attributes.sender === 'customer' && !currentUser.attributes.hasProvider) ||
      (message && message.attributes.sender && message.attributes.sender === 'provider' && currentUser.attributes.hasProvider);

    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const reviewComponent = review => {
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

    return (
      <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div className={css.reviewBottomMargin}>
        <p className={css.transitionContent}>{intl.formatMessage({ id: 'ActivityFeed.review' })}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, review.attributes.createdAt)}</p>
        <Review content={review.attributes.text} rating={review.attributes.rating}/>
      </div>
    </div>
    )
  }

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (transition) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else if (item.type === 'review') {
          return reviewComponent(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
