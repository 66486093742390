import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { createSlug } from '../../util/urlHelpers';
import { withRouter } from 'react-router-dom';
import { Page } from '../../components';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink
} from '../../components';

import css from './ProductSeoPage.css';

import products from './products';

const ProductSeoPage = (props) => {
  const siteUrl = props.params.name;
  const product = products.find(product => {
    return createSlug(product.name) === createSlug(siteUrl);
  });

  return (
    <Page
      title={`${product.name} | Decathlon Rent`}
      description={`Wypożycz ${product.name} w swoim mieście. ${product.metaDescription}`}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: `Wypożycz ${product.name} w swoim mieście.`,
        name: `${product.name} | Decathlon Rent`,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>

        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <img src="https://kgcodego.b-cdn.net/rent-logo.png" className={css.logo}/>
          <div className={css.contentWrapper}>
            <div className={css.imageWrapper}>
              <img src={product.image} alt={product.image}/>
            </div>
            <div className={css.contentMain}>
              <h1>{`Wypożycz ${product.name}`}</h1>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    `address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=${product.keywords}`,
                }}
                className={css.searchLink}
              >
                Wypożycz w swoim mieście
              </NamedLink>
              <p>
                {product.description}
              </p>
              </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default ProductSeoPage;

