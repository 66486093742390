import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { func, object, string } from 'prop-types';

import { CustomDatePicker, RadioButton } from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from './ExpDate.css';

const ExpDate = ({
  intl,
  titleId,
  expDate,
  containerClass,
  wrapperClass,
  control,
  setValue,
  registerName,
  errors,
  clearErrors,
}) => {
  const wrapperClassName = wrapperClass || css.wrapper;
  const containerClassName = containerClass || css.container;
  const [indefinitePeriodOfTime, setIndefinitePeriodOfTime] = useState(!expDate);
  const minDate = new Date();

  const handleIndefinitePeriodOfTimeChange = () => {
    !indefinitePeriodOfTime && setValue(registerName, null);
    clearErrors(registerName);
    setIndefinitePeriodOfTime(prevState => !prevState);
  };

  return (
    <div className={wrapperClassName}>
      {titleId && <h3 className={css.title}>{intl.formatMessage({ id: titleId })} </h3>}
      <div className={containerClassName}>
        <div className={css.checkboxContainer}>
          <RadioButton
            checked={indefinitePeriodOfTime}
            onChange={handleIndefinitePeriodOfTimeChange}
            label={intl.formatMessage({ id:'Console.indefinitePeriodOfTime'})}
          />
        </div>
        <div className={css.inputContainer}>
          <RadioButton
            checked={!indefinitePeriodOfTime}
            onChange={handleIndefinitePeriodOfTimeChange}
            label={intl.formatMessage({ id:'Console.expDate'})}
          />
          <Controller
            name={registerName}
            control={control}
            rules={{
              required: !indefinitePeriodOfTime,
            }}
            render={({ field: { value, onChange } }) => (
              <div className={css.filterWrapper}>
                <div className={css.labelWithClearButton}>
                  <button
                    className={css.clearButton}
                    onClick={() => onChange(null)}
                    disabled={!value || indefinitePeriodOfTime}
                  >
                    {intl.formatMessage({ id: 'Console.clear' })}
                  </button>
                </div>
                <CustomDatePicker
                  showIcon
                  selected={value}
                  onChange={onChange}
                  minDate={minDate}
                  disabled={indefinitePeriodOfTime}
                />
                {errors[registerName] && (
                  <p className={css.error}>{intl.formatMessage({ id: 'Console.expDateError' })}</p>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

ExpDate.defaultProps = {
  titleId: null,
  containerClass: null,
  wrapperClass: null,
  expDate: null,
};

ExpDate.propTypes = {
  titleId: string,
  containerClass: string,
  expDate: string,
  intl: intlShape.isRequired,
  control: object.isRequired,
  setValue: func.isRequired,
  registerName: string.isRequired,
  clearErrors: func.isRequired,
  errors: object,
};

export default ExpDate;
