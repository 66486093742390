import React, { useState, useEffect } from 'react';

import { Button, InlineTextButton, IconWarning, Input, Select, Checkbox } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import config from '../../../config';

import css from './PermissionCreationCard.css';

const PermissionCreationCard = ({
  createPermissions,
  createPermissionsSuccess,
  createPermissionsError,
  createInProgress,
  setIsOpenNewUser,
  providerName,
  intl,
  providerId,
}) => {
  const [email, setEmail] = useState('');
  const [sendNotifications, setSendNotifications] = useState(false);
  const [role, setRole] = useState('employee');
  const [isConfirm, setIsConfirm] = useState(false);
  const emailRegexValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
  const isDisabled = !emailRegexValidation.test(email);

  const errorText = createPermissionsError?.message === 'Email is already used' ? "TeamPage.duplicateError" : "TeamPage.creatingError"
  const errorMessage = createPermissionsError && isConfirm ? (
    <p className={css.error}>
      <IconWarning variant="error" /> <FormattedMessage id={errorText} />
    </p>
  ) : null;

  const handleRole = event => {
    setRole(event.target.value);
  };

  const handleSendNotificationsChange = event => {
    setSendNotifications(prevState => !prevState);
  };

  const handleCancel = event => {
    event.preventDefault();
    setSendNotifications(false);
    setEmail('');
    setRole('employee');
    setIsOpenNewUser(false);
  };

  const handleCreateUser = event => {
    event.preventDefault();
    setIsConfirm(true); 
    
    createPermissions({
      providerId: providerId.toString(),
      email,
      role,
      sendNotifications,
    });
  };

  useEffect(() => {
    if (!createInProgress && createPermissionsSuccess && isConfirm) {
      setSendNotifications(false);
      setEmail('');
      setRole('employee');
      setIsOpenNewUser(false);
    }
  }, [createInProgress, createPermissionsSuccess]);

  return (
    <div className={css.formContainer}>
      <h3 className={css.title}>
        {intl.formatMessage(
          { id: 'TeamPage.addingPermissions' },
          { displayStoreName: providerName }
        )}
      </h3>
      <form onSubmit={handleCreateUser} className={css.mainForm}>
        <Input 
          label={intl.formatMessage({ id: 'TeamPage.email' })}
          placeholder={intl.formatMessage({ id: 'TeamPage.email' })}
          type="email"
          name="email"
          onChange={event => setEmail(event.target.value)}
          markRequired={true}
          isValidation={false}
        />
        <Select 
          label={intl.formatMessage({ id: 'TeamPage.role' })}
          value={role}
          onChange={handleRole}
          markRequired={true}
          isValidation={false}
        >
          {config.permissions.map(({ value, name }) => (
            <option value={value}>{name}</option>
          ))}
        </Select>
        <div>
          <Checkbox 
            label={intl.formatMessage({ id: 'TeamPage.notifications' })}
            className={css.checkboxInput}
            checked={sendNotifications}
            onChange={handleSendNotificationsChange}
          />
          <div className={css.notificationContainer}>
            <IconWarning />
            <p className={css.notification}>{intl.formatMessage({ id: 'TeamPage.receiveNotifications' })}</p>
          </div>
        </div>
        {errorMessage}
        <div className={css.actionContainer}>
          <InlineTextButton className={css.cancelButton} onClick={handleCancel}>
            <FormattedMessage id="TeamPage.cancel" />
          </InlineTextButton>
          <Button
            type="submit"
            className={css.submitButton}
            inProgress={createInProgress}
            disabled={isDisabled}
          >
            {intl.formatMessage({ id: 'TeamPage.create' })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PermissionCreationCard;
