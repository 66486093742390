import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { encodeLatLng } from '../../util/urlHelpers';
import { searchResultsEvent } from '../../analytics/gaEvents';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_PROVIDERS_REQUEST = 'app/SearchPage/FETCH_USER_PROVIDERS_REQUEST';
export const FETCH_PROVIDERS_SUCCESS = 'app/SearchPage/FETCH_USER_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_ERROR = 'app/SearchPage/FETCH_USER_PROVIDERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  fetchProvidersInProgress: false,
  fetchProvidersError: false,
  fetchProvidersSuccess: false,
  providers: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

      case FETCH_PROVIDERS_REQUEST:
        return {
          ...state,
          fetchProvidersInProgress: true,
          fetchProvidersError: null,
          fetchProvidersSuccess: false,
        };
      case FETCH_PROVIDERS_SUCCESS:
        return {
          ...state,
          fetchProvidersInProgress: false,
          fetchProvidersError: null,
          fetchProvidersSuccess: true,
          providers: payload.data.data
        };
      case FETCH_PROVIDERS_ERROR:
        return {
          ...state,
          fetchProvidersInProgress: false,
          fetchProvidersError: payload,
          fetchProvidersSuccess: false,
        };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchProvidersRequest = () => ({
  type: FETCH_PROVIDERS_REQUEST,
});

export const fetchProvidersSuccess = response => ({
  type: FETCH_PROVIDERS_SUCCESS,
  payload: response,
});

export const fetchProvidersError = (e) => ({
  type: FETCH_PROVIDERS_ERROR,
  error: true,
  payload: e
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const availabilitySearchParams = (datesParam, minDurationParam, availabilityParam) => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasValues
        ? { minDuration: minDurationParam }
        : {};

    const availabilityMaybe =
      availabilityParam && hasValues
        ? { availability: availabilityParam }
        : {};

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          ...availabilityMaybe,
          ...minDurationMaybe
        }
      : {};
  };

  const {
    bounds,
    perPage,
    price,
    origin,
    dates,
    address,
    minDuration,
    availability,
    category,
    keywords,
    sort,
    homeDelivery,
    providerId,
    countryId,
    start,
    end,
    businessCategory,
    exact,
    isLocation,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const availabilityMaybe = availabilitySearchParams(dates, minDuration, availability);
  const preferredCity = address && !providerId ? address.split(',')[0] : '';
  const categoryMaybe = category ? { category } : {};
  const homeDeliveryMaybe = homeDelivery ? { delivery: 'home' } : {};
  const providerIdMaybe = providerId ? { providerId } : {};
  const countryIdMaybe = countryId && !providerId ? { withCountryId: countryId } : {};
  const pointMaybe = origin && !providerId  ? { point: encodeLatLng(origin) } : {};
  const preferredCityMaybe = preferredCity && !isLocation ? { preferredCity } : {};
  const startMaybe = start ? { start } : {};
  const endMaybe = end ? { end } : {};
  const seatsMaybe = start && end ? { seats: 1 } : {};
  const keywordsMaybe = exact ? {} : { phrase: keywords };
  const businessCategoryMaybe = businessCategory ? {businessCategory} : businessCategory;
  const params = {
    ...rest,
    ...priceMaybe,
    ...availabilityMaybe,
    // ...categoryMaybe,
    ...homeDeliveryMaybe,
    ...providerIdMaybe,
    ...countryIdMaybe,
    ...pointMaybe,
    ...preferredCityMaybe,
    ...startMaybe,
    // ...endMaybe,
    // ...seatsMaybe,
    // availability: 'day-partial',
    // minDuration: 1,
    ...keywordsMaybe,
    ...businessCategoryMaybe,
    per_page: perPage,
    sort: (keywords && !exact) ? 'my-country-first,relevance,rating,-delivery-first,-createdAt' : sort ? `my-country-first,${sort}` : origin && !providerId ? 'rating,distance,-createdAt' : `my-country-first,rating,-delivery-first,-createdAt`
  };

    return sdk.newSdk.listings.query({...params, include: ['provider', 'images', 'provider.deliveryToProviders']})
      .then(response => {
        searchResultsEvent({
          searchTerm: keywords,
          searchCategory: category,
          searchSubcategory: businessCategory,
          searchCity: preferredCity
        });
        dispatch(addMarketplaceEntities(response));
        dispatch(searchListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.newSdk.listings.query({...params, include: ['provider', 'images']})
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const fetchProvidersForMap = (countryIdParam = undefined) => (dispatch, getState, sdk) => {
  const countryId = countryIdParam ? {countryId: countryIdParam} : {};
  dispatch(fetchProvidersRequest());

  return sdk.newSdk.providers.query({
    ...countryId,
    banned:false,
    visibleOnMap: true
  })
  .then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchProvidersSuccess(response));
    return response;
  })
  .catch(e => {
    dispatch(fetchProvidersError(storableError(e)));
    throw e;
  });
}
