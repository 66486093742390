const defaultCities = [
  {
    countryId: 'pl',
    location: 'Warszawa, Polska',
    origin: '52.2296756,21.0122287',
    bounds: '52.36799915970627,21.27109843217943,52.09787667315334,20.85128983757352',
  },
  {
    countryId: 'pl',
    location: 'Kraków, Polska',
    origin: '50.06465009999999,19.9449799',
    bounds: '50.12612589673038,20.2174975543244,49.96740536805499,19.79224845563986',
  },
  {
    countryId: 'pl',
    location: 'Wrocław, Polska',
    origin: '51.1078852,17.0385376',
    bounds: '51.21147357174232,17.17634783168821,51.04268220041629,16.8073800205415',
  },
  {
    countryId: 'pl',
    location: 'Poznań, Polska',
    origin: '52.406374,16.9251681',
    bounds: '52.50914101692316,17.07165926547468,52.29191614333686,16.73167242406996',
  },
  {
    countryId: 'pl',
    location: 'Gdańsk, Polska',
    origin: '54.3520252,18.6466384',
    bounds: '54.44721875096653,18.9512794523589,54.27495593630174,18.42877480307608',
  },
  {
    countryId: 'pl',
    location: 'Katowice, Polska',
    origin: '50.26489189999999,19.0237815',
    bounds: '50.2973176471578,19.0537815,50.13002717297118,19.0237815',
  },
  {
    countryId: 'cz',
    location: 'Prague, Česká republika',
    origin: '50.0755381,14.4378005',
    bounds: '50.17740304550632,14.7067945452399,49.94193629313551,14.22445333640556',
  },
  {
    countryId: 'cz',
    location: 'Brno, Česká republika',
    origin: '49.1950602,16.6068371',
    bounds: '49.294484956308,16.72785321479357,49.10965517428777,16.42806782678905',
  },
  {
    countryId: 'cz',
    location: 'Pardubice, Česká republika',
    origin: '50.0343092,15.7811994',
    bounds: '50.07737738946891,15.89127218268309, 49.9920054891207,15.62506872700712',
  },
  {
    countryId: 'cz',
    location: 'Ostrava, Česká republika',
    origin: '49.8209226,18.2625243',
    bounds: '49.91372919349767,18.37636478627858,49.72575366697572,18.09842416143628',
  },
  {
    countryId: 'cz',
    location: 'Pilsen, Česká republika',
    origin: '49.7384314,13.3736371',
    bounds: '49.80578596408196,13.47584653128118,49.67760841170616,13.26800009107684',
  },
  {
    countryId: 'cz',
    location: 'Hradec Králové, Česká republika',
    origin: '50.2103605,15.825211',
    bounds: '50.26794396109687,15.93554791911114,50.15989486231963,15.74673760273292',
  },
  {
    countryId: 'hu',
    location: 'Budapest, Magyarország',
    origin: '47.497912,19.040235',
    bounds: '47.61301194615275,19.33450493254384,47.34941504569791,18.92610106630945',
  },
  {
    countryId: 'hu',
    location: 'Gyor, Magyarország',
    origin: '47.6874569,17.6503974',
    bounds: '47.74758701392467,17.80788996846059,47.5912749533013,17.50947996659649',
  },
  {
    countryId: 'hu',
    location: 'Pecs, Magyarország',
    origin: '46.0727345,18.232266',
    bounds: '46.16142096221539,18.3654829326491,45.99404504751548,18.13568004961312',
  },
  {
    countryId: 'hu',
    location: 'Szekesfehervar, Magyarország',
    origin: '47.18602620000001,18.4221358',
    bounds: '47.28114004352099,18.55375401655949,47.10792900743893,18.32874991578999',
  },
  {
    countryId: 'hu',
    location: 'Miskolc, Magyarország',
    origin: '48.1034775,20.7784384',
    bounds: '48.15499801540609,20.86977894564551,48.02331194241406,20.48171989894033',
  },
  {
    countryId: 'ee',
    location: 'Tallinn, Eesti',
    origin: '59.43696079999999,24.7535746',
    bounds: '59.59157695103486,24.92628377517535,59.35180076593268,24.55019390387247',
  },
  {
    countryId: 'lv',
    location: 'Rīga, Latvija',
    origin: '56.9676941,24.1056221',
    bounds: '57.08616877578455,24.32450425785868,56.85734615440411,23.9336426403069',
  },
  {
    countryId: 'lt',
    location: 'Vilnius, Lietuva',
    origin: '54.6871555,25.2796514',
    bounds: '54.83254395107623,25.48148831340533,54.56882492385167,25.02457910232004',
  },
  {
    countryId: 'lt',
    location: 'Kaunas, Lietuva',
    origin: '54.8985207,23.9035965',
    bounds: '54.95961995228758,24.09960376082462,54.8203300289473,23.75492311520394',
  },
  {
    countryId: 'lt',
    location: 'Klaipėda, Lietuva',
    origin: '55.70329479999999,21.1442794',
    bounds: '55.79627825579485,21.23983508504624,55.61335799552277,21.07532156190404',
  },
];

export default defaultCities;
