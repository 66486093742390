import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { businessAnalysis } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  DatePicker,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  BusinessSummary,
  BusinessTable,
  Select
} from '../../components';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './BusinessResultsPage.css';

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();
const firstDay = new Date(currentYear, currentMonth, 2).toISOString().split('T')[0];
const lastDay = new Date().toISOString().split('T')[0];

export const BusinessResultsPageComponent = props => {
  const {
    scrollingDisabled,
    intl,
    location,
    history,
    onLoadData,
    currentUserProviders
  } = props;

  const { start = firstDay, end = lastDay, providerId, groupingDate = 'deliveredAt' } = parse(location.search);
  const [data, setData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [additional2Data, setAdditional2Data] = useState(null);
  const [error, setError] = useState(null);

  const selectedProvider = currentUserProviders.find(p => p.id.uuid === providerId);

  useEffect(() => {
    selectedProvider && onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency: selectedProvider.attributes.currency, providerId, businessCategory: '*' }).then(res => setData(res.data));
    selectedProvider && onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency: selectedProvider.attributes.currency, providerId, listingId: '*', include: ['listing'] }).then(res => setAdditionalData(res.data))
    selectedProvider && selectedProvider.attributes.deliveryToHome && onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency: selectedProvider.attributes.currency, providerId, deliveryLocation: '*', include: ['deliveryToProvider'] }).then(res => setAdditional2Data(res.data))
  }, [start, end, selectedProvider, groupingDate])

  const title = intl.formatMessage({id: "BusinessResultsPage.title"});

  const handleDateRangeSubmit = ({ start, end }) => {
    history.push(
      createResourceLocatorString(
        'BusinessResultsPage',
        routeConfiguration(),
        {},
        { start, end, providerId, groupingDate }
      )
    );
  }

  const handleProviderChange = (e) => {
    history.push(
      createResourceLocatorString(
        'BusinessResultsPage',
        routeConfiguration(),
        {},
        { start, end, providerId: e.target.value, groupingDate }
      )
    );
  }

  const handleGroupingDateChange = (e) => {
    history.push(
      createResourceLocatorString(
        'BusinessResultsPage',
        routeConfiguration(),
        {},
        { groupingDate: e.target.value, start, end, providerId }
      )
    );
  }

  const concatenatedData = data ? data.data.slice(1).map(provider => {
    return {
      label: provider.attributes.businessCategory || 'other',
      // providerId: providerData.id.uuid,
      providerName: provider.attributes.businessCategory,
      data: [
        { current: provider ? (provider.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: provider ? provider.attributes.itemsCount : 0 },
        { current: provider ? ((provider.attributes.incomeTotal / provider.attributes.itemsCount) / 100).toFixed(2) : 0 },
        // { current: provider ? (provider.attributes.incomeMax / 100).toFixed(2) : 0 },
        { current: provider ? (provider.attributes.avgDuration * 1).toFixed(1) : 0 }
      ],
    }
  }) : [];

  const concatenatedAdditionalData = additionalData ? additionalData.data.slice(1).map(listing => {
    const listingData = additionalData.included.find(i => i.id.uuid === listing.relationships.listing.data.id.uuid);
    return {
      label: listingData.attributes.title,
      // providerId: providerData.id.uuid,
      data: [
        { current: listing ? (listing.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: listing ? listing.attributes.itemsCount : 0 },
        { current: listing ? ((listing.attributes.incomeTotal / listing.attributes.itemsCount)  / 100).toFixed(2) : 0 },
        // { current: listing ? (listing.attributes.incomeMax / 100).toFixed(2) : 0 },
        { current: listing ? (listing.attributes.avgDuration * 1).toFixed(1) : 0 }
      ],
    }
  }) : [];

  const concatenatedAdditional2Data = additional2Data ? additional2Data.data.slice(1).map(loc => {
    const deliveryProviderData = additional2Data.included.find(i => loc.attributes.deliveryLocation === 'store' && i.id.uuid === loc.relationships.deliveryToProvider.data.id.uuid);
    return {
      label: deliveryProviderData ? deliveryProviderData.attributes.name : intl.formatMessage({id: "BusinessResultsPage.homeDelivery"}),
      // providerId: providerData.id.uuid,
      data: [
        { current: loc ? (loc.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: loc ? loc.attributes.transactionsCount : 0 },
        { current: loc ? ((loc.attributes.incomeTotal / loc.attributes.transactionsCount) / 100).toFixed(2) : 0 },
        // { current: loc ? (loc.attributes.incomeMax / 100).toFixed(2) : 0 },
        { current: loc ? (loc.attributes.avgDuration * 1).toFixed(1) : 0 }
      ],
    }
  }) : [];

  const turnoverMsg = intl.formatMessage({ id: 'BusinessResults.turnover' });
  const quantityMsg = intl.formatMessage({ id: 'BusinessResults.quantity' });
  const rentingsMsg = intl.formatMessage({ id: 'BusinessResults.rentings' });
  const avgRentingMsg = intl.formatMessage({ id: 'BusinessResults.avgRentingValue' });
  const maxRentingMsg = intl.formatMessage({ id: 'BusinessResults.maxRentingValue' });
  const avgRentingLengthMsg = intl.formatMessage({ id: 'BusinessResults.avgRentingLength' });
  const daysMsg = intl.formatMessage({ id: 'BusinessResults.days' });
  const numberMsg = intl.formatMessage({ id: 'BusinessResults.count' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="BusinessResultsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="BusinessResultsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="BusinessResultsPage" />
        <LayoutWrapperMain className={css.colorBackground}>
          <div className={css.content}>
            <div className={css.header}>
              <h1 className={css.title}>
                {intl.formatMessage({ id: 'BusinessResults.header' })}
              </h1>
              <DatePicker onSubmit={handleDateRangeSubmit} value={{ startDate: start, endDate: end }} />
            </div>
            <div className={css.filters}>
              <Select 
                label={intl.formatMessage({ id: 'BusinessResults.providerField' })}
                value={providerId} 
                onChange={handleProviderChange}
              >
                {currentUserProviders.map(provider => ( <option value={provider.id.uuid}>{provider.attributes.name}</option>))}
              </Select>
              <Select 
                label={intl.formatMessage({ id: 'BusinessDktPage.filtering' })}
                value={groupingDate} 
                onChange={handleGroupingDateChange}
              > 
                <option value="createdAt">{intl.formatMessage({ id: 'BusinessDktPage.createdAt' })}</option>
                <option value="deliveredAt">{intl.formatMessage({ id: 'BusinessDktPage.deliveredAt' })}</option>
              </Select>
            </div>
            {
              data &&
              <BusinessSummary
                currency={selectedProvider.attributes.currency}
                currentData={{
                  payinTotal: data.data[0].attributes.incomeTotal,
                  transactionsCount: data.data[0].attributes.transactionsCount,
                  payoutTotal: data.data[0].attributes.payoutTotal,
                  payinAvg: data.data[0].attributes.incomeAvg,
                  payinMax: data.data[0].attributes.incomeMax,
                  avgDuration: data.data[0].attributes.avgDuration,
                  itemsCount: data.data[0].attributes.itemsCount
                }}
              />
            }
            {
              data &&
              <BusinessTable
                rows={concatenatedData}
                isComparisonOn={false}
                sortBy={0}
                firstRowName={intl.formatMessage({ id: 'General.category' })}
                dataHeaders={[
                  { primary: turnoverMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: quantityMsg, secondary: `(${numberMsg})` },
                  { primary: avgRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  // { primary: maxRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: avgRentingLengthMsg, secondary: `(${daysMsg})` }
                ]}
                dates={{ firstDay, lastDay }}
                extendableRows={false}
              />
            }
            {
              additionalData &&
              <BusinessTable
                rows={concatenatedAdditionalData}
                isComparisonOn={false}
                sortBy={0}
                firstRowName={intl.formatMessage({ id: 'BusinessResults.product' })}
                dataHeaders={[
                  { primary: turnoverMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: quantityMsg, secondary: `(${numberMsg})` },
                  { primary: avgRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  // { primary: maxRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: avgRentingLengthMsg, secondary: `(${daysMsg})` }
                ]}
                dates={{ firstDay, lastDay }}
                extendableRows={false}
              />
            }
            {
              additional2Data &&
              <BusinessTable
                rows={concatenatedAdditional2Data}
                isComparisonOn={false}
                sortBy={0}
                firstRowName={intl.formatMessage({ id: 'BusinessResults.location' })}
                dataHeaders={[
                  { primary: turnoverMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: rentingsMsg, secondary: `(${numberMsg})` },
                  { primary: avgRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  // { primary: maxRentingMsg, secondary: `(${selectedProvider.attributes.currency})` },
                  { primary: avgRentingLengthMsg, secondary: `(${daysMsg})` }
                ]}
                dates={{ firstDay, lastDay }}
                extendableRows={false}
              />
            }
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

BusinessResultsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool } = PropTypes;

BusinessResultsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, userProviders: currentUserProviders } = state.user;
  return {
    currentUser,
    currentUserProviders,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const loadData = params => (dispatch, getState, sdk) => {
  return sdk.newSdk.analyticsBookings.query({ ...params })
    .then((res) => {
      return res;
    })
    .catch(e => {
      console.error(e, 'initial-message-send-failed', { tx: 'PLN' });
    });
};

const mapDispatchToProps = dispatch => ({
  onLoadData: (params) => dispatch(loadData(params))
});

const BusinessResultsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withRouter
)(BusinessResultsPageComponent);

export default BusinessResultsPage;