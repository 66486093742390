import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { bool, func, string } from 'prop-types';

import {
  Input,
  Textarea,
  OpeningHoursField,
  Button,
  OpeningPatternField,
} from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from '../ProviderEditForm/ProviderEditForm.css';

const FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  OPENING_HOURS: 'openingHours',
  OPENING_PATTERN: 'openingPattern',
};
const ProviderEditForm = ({
    name,
    description,
    openingHours,
    openingPattern,
    intl,
    providerId,
    updateProviderInProgress,
    updateProvider,
    isDisabled
}) => {

  const initValues = {
    [FIELDS.NAME]: name,
    [FIELDS.DESCRIPTION]: description,
    [FIELDS.OPENING_HOURS]: openingHours,
    [FIELDS.OPENING_PATTERN]: openingPattern,
  }

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    watch
  } = useForm({
    defaultValues: initValues,
  });

  const watchAllFields = watch();

  const onSubmit = data => {
    const { name, description, openingHours, openingPattern } = data;
    const nameParam = data[FIELDS.NAME] !== initValues[FIELDS.NAME]
      ? { [FIELDS.NAME]: data[FIELDS.NAME] } : undefined;
    const descriptionParam = data[FIELDS.DESCRIPTION] !== initValues[FIELDS.DESCRIPTION]
      ? { [FIELDS.DESCRIPTION]: data[FIELDS.DESCRIPTION] } : undefined;
    const openingHoursParam = data[FIELDS.OPENING_HOURS] !== initValues[FIELDS.OPENING_HOURS]
      ? { [FIELDS.OPENING_HOURS]: data[FIELDS.OPENING_HOURS] } : undefined;
    const openingPatternParma = data[FIELDS.OPENING_PATTERN] !== initValues[FIELDS.OPENING_PATTERN]
      ? { [FIELDS.OPENING_PATTERN]: data[FIELDS.OPENING_PATTERN] }  : undefined;

    updateProvider({
      id: providerId,
      ...nameParam,
      ...descriptionParam,
      ...openingHoursParam,
      ...openingPatternParma
    })
  }

  const isDisabledSubmitBtn = !!errors[FIELDS.OPENING_PATTERN]
    || ( watchAllFields[FIELDS.NAME] === initValues[FIELDS.NAME]
    && watchAllFields[FIELDS.DESCRIPTION] === initValues[FIELDS.DESCRIPTION]
    && watchAllFields[FIELDS.OPENING_HOURS] === initValues[FIELDS.OPENING_HOURS]
    && watchAllFields[FIELDS.OPENING_PATTERN] === initValues[FIELDS.OPENING_PATTERN] );

  useEffect(() => {
    reset(initValues);
  }, [providerId]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
        <Controller
          control={control}
          name={FIELDS.NAME}
          rules={{ minLength: 3 }}
          render={({ field: { onChange, value } }) => (
            <Input
              label={intl.formatMessage({ id: 'ProviderPage.name' })}
              value={value}
              onChange={onChange}
              id={FIELDS.NAME}
              placeholder={intl.formatMessage({ id: 'ProviderPage.namePlaceholder' })}
              isValidation={false}
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          control={control}
          name={FIELDS.DESCRIPTION}
          rules={{ minLength: 3 }}
          render={({ field: { onChange, value } }) => (
            <Textarea
              label={intl.formatMessage({ id: 'ProviderPage.description' })}
              value={value}
              onChange={onChange}
              id={FIELDS.DESCRIPTION}
              placeholder={intl.formatMessage({ id: 'ProviderPage.descriptionPlaceholder' })}
              isValidation={false}
              disabled={isDisabled}
            />
          )}
        />
        <OpeningHoursField
          fieldName={FIELDS.OPENING_HOURS}
          label={intl.formatMessage({ id: 'ProviderPage.openingHours' })}
          placeholder={intl.formatMessage({ id: 'ProviderPage.openingHoursPlaceholder' })}
          control={control}
        />
        <OpeningPatternField
          fieldName={FIELDS.OPENING_PATTERN}
          control={control}
          intl={intl}
          error={errors[FIELDS.OPENING_PATTERN]}
          disabled={isDisabled}
        />
        <Button
          type='submit'
          className={css.submitBtn}
          inProgress={updateProviderInProgress}
          disabled={isDisabledSubmitBtn}
        >
          {intl.formatMessage({ id: 'ProviderPage.save' })}
        </Button>
      </form>
    </div>
  )
}

ProviderEditForm.defaultProps = {
  name: '',
  description: '',
  openingHours: '',
  openingPattern: '',
}

ProviderEditForm.propTypes = {
  name: string,
  description: string,
  openingHours: string,
  openingPattern: string,
  intl: intlShape.isRequired,
  providerId: string.isRequired,
  updateProviderInProgress: bool.isRequired,
  updateProvider: func.isRequired,
  isDisabled: bool.isRequired,
}

export default ProviderEditForm;
