import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { findOptionsForSelectFilter } from '../../../util/search';
import config from '../../../config';
import businessCategoryConfig from '../../../business-category-config';
import { Modal, Accordion, Checkbox } from './../../../components';

import css from './CategoryModal.css';

const CategoryModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    handleSubmit,
    initBusinessCategory
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const [businessCategory, setBusinessCategory] = useState(initBusinessCategory || []);

  const handleBusinessCategoryChange = (event, key) => {
    setBusinessCategory( prevState => (
      event.target.checked ? [...prevState, key ] : prevState.filter(item => item !== key)
    ))
  }

  const handleCategoryChange = (event, category) => {
    const subcategories = businessCategoryConfig
      .filter(item => category === item.category)
      .map(item => item.key);

    setBusinessCategory( prevState => (
      event.target.checked
        ? [...prevState, ...subcategories ]
        : prevState.filter(item => !subcategories.includes(item))
    ))
  }

  const isCategoryChecked = (category) => {
    let isChecked = false;
    const subcategories = businessCategoryConfig.filter(item => category === item.category);
    subcategories.forEach(({key}) => {
      if (businessCategory.find(item => item === key)) {
        isChecked = true;
      }
    });
    return isChecked;
  }

  const handleClear = (event) => {
    event.preventDefault();
    setBusinessCategory([]);
    handleSubmit(undefined);
  }

  const handleSubmitClick = (event) => {
    event.preventDefault();
    handleSubmit(businessCategory.toString());
    onCloseModal();
  }

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="CategoryModal.modalTitle" />
      </p>
      <div className={css.contentContainer}>
        <div className={css.container}>
          {
            categoryOptions.map(({key, label}) => (
              <Accordion
                containerClassName={css.accordionContainer}
                openClassName={css.accordionOpen}
                key={key}
                titleComponent={
                  <Checkbox
                    label={label}
                    size={'large'}
                    checked={isCategoryChecked(key)}
                    onChange={(event) => handleCategoryChange(event,key)}
                  />
                }
              >
                <div className={css.content}>
                  {
                    businessCategoryConfig.filter(({category}) => category === key).map(
                      ({key, label}) => (
                        <Checkbox
                          label={label}
                          size={'large'}
                          key={key}
                          checked={!!businessCategory.find(item => item === key)}
                          onChange={(event) => handleBusinessCategoryChange(event,key)}
                        />
                      )
                    )
                  }
                </div>
              </Accordion>
            ))
          }

        </div>
        <div className={css.buttonSection}>
          <button className={css.clearBtn} onClick={handleClear}>
            <FormattedMessage id="CategoryModal.clearButton" />
          </button>
          <button className={css.submitBtn} onClick={handleSubmitClick}>
            <FormattedMessage id="CategoryModal.submitButton" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string, func, arrayOf } = PropTypes;

CategoryModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CategoryModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool,
  onCloseModal: func,
  onManageDisableScrolling: func,
  handleSubmit: func,
  initBusinessCategory: arrayOf(string),
};

export default CategoryModal;
