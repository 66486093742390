import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconPinMap = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.2427 11.576L8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66777 2.11529 8.50389C1.88378 7.34 2.0026 6.13361 2.45673 5.03726C2.91086 3.9409 3.6799 3.00384 4.66659 2.34455C5.65328 1.68527 6.81332 1.33337 8 1.33337C9.18669 1.33337 10.3467 1.68527 11.3334 2.34455C12.3201 3.00384 13.0891 3.9409 13.5433 5.03726C13.9974 6.13361 14.1162 7.34 13.8847 8.50389C13.6532 9.66777 13.0818 10.7369 12.2427 11.576ZM8 9.99999C8.70725 9.99999 9.38552 9.71904 9.88562 9.21894C10.3857 8.71884 10.6667 8.04057 10.6667 7.33332C10.6667 6.62608 10.3857 5.9478 9.88562 5.4477C9.38552 4.94761 8.70725 4.66666 8 4.66666C7.29276 4.66666 6.61448 4.94761 6.11438 5.4477C5.61429 5.9478 5.33334 6.62608 5.33334 7.33332C5.33334 8.04057 5.61429 8.71884 6.11438 9.21894C6.61448 9.71904 7.29276 9.99999 8 9.99999Z"
        fill="#00689D"
      />
    </svg>
  );
};

IconPinMap.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPinMap.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPinMap;
