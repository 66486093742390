const initialState = {
  activeExperiments: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}

// selectors
export const selectIsExperimentInitialized = experimentId => state => {
  return state.abTest.activeExperiments[experimentId] !== undefined;
};

export const selectExperimentVariant = experimentId => state => {
  return state.abTest.activeExperiments[experimentId];
};
