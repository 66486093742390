import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-info',
    label: 'Wprowadź lokalizację powyżej'
  },
  // {
  //   id: 'default-warsaw',
  //   predictionPlace: {
  //     address: 'Warszawa, Polska',
  //     bounds: new LatLngBounds(new LatLng(52.47094281, 21.30950476), new LatLng(51.96614075, 20.69015052)),
  //   },
  // },
  // {
  //   id: 'default-gdansk',
  //   predictionPlace: {
  //     address: 'Gdańsk, Polska',
  //     bounds: new LatLngBounds(new LatLng(54.66865618, 18.870883), new LatLng(54.18934769, 18.2515287)),
  //   },
  // },
  // {
  //   id: 'default-katowice',
  //   predictionPlace: {
  //     address: 'Katowice, Polska',
  //     bounds: new LatLngBounds(new LatLng(50.49238032, 19.28938102), new LatLng(49.92196728, 18.54780387)),
  //   },
  // },
];
