import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import { daysBetween } from '../../../util/dates';
import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { InlineTextButton, ResponsiveImage, IconTick, IconArrowHead } from '../../../components';
import { addToCartEvent } from '../../../analytics/gaEvents';
import computeDiscount from './../../../forms/BookingDatesForm/computeDiscount';

import css from './ComplementaryProducts.css';

const ComplementaryProducts = ({
  listing,
  complementaryProducts,
  intl,
  start,
  end,
  onAddToCart,
  history,
  addToCartInProgress,
  order,
}) => {
  const productsWithoutCart = complementaryProducts?.filter(product => {
    const isInCart = order?.attributes.lineItems.find(item => item.listingId === product.id.uuid);
    return !isInCart;
  });
  const productsWithoutListing = productsWithoutCart.filter(product => product.id.uuid !== listing.id.uuid);
  const productListing = productsWithoutCart.find(product => product.id.uuid === listing.id.uuid);
  const products = productsWithoutCart.length > 1 && productListing ? [...productsWithoutListing, productListing] : productsWithoutCart; 

  const [isHovering, setIsHovering] = useState(false);
  const [currentIdHover, setCurrentIdHover] = useState('');
  const [addedToCart, setAddedToCart] = useState([]);
  const [refScrollWidth, setRefScrollWidth] = useState(0);
  const [refScrollPosition, setRefScrollPosition] = useState(0);
  const divRef = useRef(null);
  const scrollInterval = 462;
  const itemWidth = 138;
  const itemGap = 16;

  const handleMouseOver = (event, id) => {
    setIsHovering(true);
    setCurrentIdHover(id);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setCurrentIdHover('');
  };

  const handleCartClick = (event, item) => {
    event.preventDefault();
    
    const days = daysBetween(start, end);
    const discount = computeDiscount({ price: item.attributes.price.amount, quantity: days, provider: item.provider.attributes, listing: item.attributes });
    addToCartEvent({
      currency: item.attributes.price.currency,
      atcType: 'recommendation',
      value: (item.attributes.price.amount * days - discount) / 100,
      item: {
        price: item.attributes.price.amount / 100,
        id: item.id.uuid,
        name: item.attributes.title,
        category: item.attributes.businessCategory,
        provider: item.provider.attributes.name,
        // TO-DO Remember when adding quantity field
        quantity: 1
      }
    });

    onAddToCart({
      listingId: item.id.uuid,
      amount: 1,
      start,
      end,
    }).then(() => {
      setAddedToCart(prevState => [...prevState, item.id.uuid]);
    });
  };

  const handleProductClick = (event, id, title) => {
    const routes = routeConfiguration();

    history.push(
      createResourceLocatorString('ListingPage', routes, { id, slug: createSlug(title) }, {})
    );
  };

  const handleLeftClick = event => {
    const endPosition =
      refScrollWidth - refScrollPosition < scrollInterval
        ? refScrollWidth - scrollInterval * 2
        : refScrollPosition - scrollInterval;
    const scrollPosition = refScrollPosition - scrollInterval < 0 ? 0 : endPosition;
    refScrollPosition !== scrollPosition && setRefScrollPosition(scrollPosition);
  };

  const handleRightClick = event => {
    const scrollPosition =
      scrollInterval + refScrollPosition > refScrollWidth
        ? refScrollWidth
        : scrollInterval + refScrollPosition;
    refScrollPosition !== scrollPosition && setRefScrollPosition(scrollPosition);
  };

  const arrowClass =
    products.length * itemWidth + (products.length - 1) * itemGap <= 550
      ? css.arrowButtonDisabled
      : css.arrowButton;

  useEffect(() => {
    return divRef?.current?.scrollTo({ left: refScrollPosition, behavior: 'smooth' });
  }, [refScrollPosition]);

  useEffect(() => {
    setRefScrollWidth(divRef?.current?.scrollWidth);
  }, [divRef?.current?.scrollWidth]);

  return !!products.length ? (
    <div className={css.container}>
      <h3 className={css.title}>
        <FormattedMessage id="ComplementaryProducts.accessories" />
      </h3>
      <div className={css.scrollContainer}>
        <button
          className={classNames(css.arrowLeftButton, arrowClass)}
          onClick={handleLeftClick}
          disabled={!refScrollPosition}
        >
          <IconArrowHead direction="left" />
        </button>
        <div className={css.itemsContainer} ref={divRef}>
          {products.map(item => {
            const {
              id: { uuid },
              attributes: { title, price },
              images,
            } = item;
            const isInCart = addedToCart.includes(uuid);

            const btnClassName =
              isHovering && currentIdHover === uuid ? css.visibleBtn : css.hiddenBtn;

            return (
              <div
                key={uuid}
                className={css.itemContainer}
                onMouseOver={event => handleMouseOver(event, uuid)}
                onMouseOut={handleMouseOut}
              >
                <div onClick={event => handleProductClick(event, uuid, title)} className={css.item}>
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    image={images[0]}
                    alt={'product'}
                    variants={['square-small']}
                  />
                  <p className={css.itemTitle}>{title}</p>
                  <p className={css.text}>
                    <b>{formatMoney(intl, price)}</b>/ <FormattedMessage id="CartModal.day" />
                  </p>
                </div>
                {isInCart ? (
                  <IconTick />
                ) : (
                  <InlineTextButton
                    className={classNames(btnClassName, css.btnAddToCart)}
                    onClick={event => handleCartClick(event, item)}
                    inProgress={addToCartInProgress && currentIdHover === uuid}
                    spinnerClassName={css.spinner}
                  >
                    <FormattedMessage id="ComplementaryProducts.addToCart" />
                  </InlineTextButton>
                )}
              </div>
            );
          })}
        </div>
        <button
          onClick={handleRightClick}
          className={classNames(css.arrowRightButton, arrowClass)}
          disabled={refScrollPosition === refScrollWidth}
        >
          <IconArrowHead direction="right" />
        </button>
      </div>
    </div>
  ) : null;
};

export default ComplementaryProducts;
