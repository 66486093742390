/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { ensureCurrentUser } from '../../util/data';

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, currentUser, viewport } = props;

  const user = ensureCurrentUser(currentUser);
  // const isOfferEnabled = !!user.attributes.profile.metadata.decathlon_user || !!user.attributes.profile.metadata.is_provider;
  const isOfferEnabled = true;
  const paymentsTab = isOfferEnabled ? [{
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
    selected: currentTab === 'StripePayoutPage',
    id: 'StripePayoutPageTab',
    linkProps: {
      name: 'StripePayoutSettingsPage',
    }}] : []

  const tabs = [
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
    //   selected: currentTab === 'ContactDetailsPage',
    //   id: 'ContactDetailsPageTab',
    //   linkProps: {
    //     name: 'ContactDetailsPage',
    //   },
    // },
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
    //   selected: currentTab === 'PasswordChangePage',
    //   id: 'PasswordChangePageTab',
    //   linkProps: {
    //     name: 'PasswordChangePage',
    //   },
    // },
    ...paymentsTab,
    // {
    //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
    //   selected: currentTab === 'PaymentMethodsPage',
    //   id: 'PaymentMethodsPageTab',
    //   linkProps: {
    //     name: 'PaymentMethodsPage',
    //   },
    // },
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(
  connect((state) => {
    return {
      currentUser: state.user.currentUser
    }
  }),
  withViewport
)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
