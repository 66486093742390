import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_CONSOLE_PROVIDERS_REQUEST =
  'app/ConsoleUserPage/FETCH_CONSOLE_PROVIDERS_REQUEST';
export const FETCH_CONSOLE_PROVIDERS_SUCCESS =
  'app/ConsoleUserPage/FETCH_CONSOLE_PROVIDERS_SUCCESS';
export const FETCH_CONSOLE_PROVIDERS_ERROR = 'app/ConsoleUserPage/FETCH_CONSOLE_PROVIDERS_ERROR';

export const UPDATE_PROVIDER_REQUEST = 'app/ConsoleUserPage/UPDATE_PROVIDER_REQUEST';
export const UPDATE_PROVIDER_SUCCESS = 'app/ConsoleUserPage/UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'app/ConsoleUserPage/UPDATE_PROVIDER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchProviderListError: null,
  providerList: [],
  updateProviderInProgress: false,
  updateProviderError: null,
};

export default function consoleProviderReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_PROVIDERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchProviderListError: null };
    case FETCH_CONSOLE_PROVIDERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        providerList: payload.data.data,
      };
    }
    case FETCH_CONSOLE_PROVIDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProviderListError: payload };
    case UPDATE_PROVIDER_REQUEST:
      return { ...state, updateProviderInProgress: true, updateProviderError: null };
    case UPDATE_PROVIDER_SUCCESS: {
      return {
        ...state,
        updateProviderInProgress: false,
        updateProviderError: payload.data.data,
      };
    }
    case UPDATE_PROVIDER_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, updateProviderInProgress: false, updateProviderError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchProviderListRequest = () => ({ type: FETCH_CONSOLE_PROVIDERS_REQUEST });

const fetchProviderListSuccess = response => ({
  type: FETCH_CONSOLE_PROVIDERS_SUCCESS,
  payload: response,
});

const fetchProviderListError = e => ({
  type: FETCH_CONSOLE_PROVIDERS_ERROR,
  error: true,
  payload: e,
});

const updateProviderRequest = () => ({ type: UPDATE_PROVIDER_REQUEST });

const updateProviderSuccess = response => ({
  type: UPDATE_PROVIDER_SUCCESS,
  payload: response,
});

const updateProviderError = e => ({
  type: UPDATE_PROVIDER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const requestUpdateProvider = data => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProviderRequest());

    return sdk.newSdk.providers
      .update(data)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(updateProviderSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(updateProviderError(e));
        throw e;
      });
  };
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { phrase } = parse(search);

  const queryParams = {
    userId: 'me',
    phrase,
  };

  dispatch(fetchProviderListRequest());

  return sdk.newSdk.providers
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchProviderListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchProviderListError(e));
      throw e;
    });
};
