import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { func } from 'prop-types';

import { propTypes } from '../../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { Modal, Button, InlineTextButton } from '../../../components';

import css from './DeliveryStateModal.css';

const DeliveryStateModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    partialCancelInProgress,
    onChangeDeliveryState,
    initialDeliveryState
  } = props;
  const [deliveryState, setDeliveryState] = useState(initialDeliveryState);
  const classes = classNames(rootClassName || css.root, className);

  const handleChange = event => {
    setDeliveryState(event.target.value);
  };

  const handleClose = () => {
    onCloseModal();
  };

  const handleSubmit = () => {
    onChangeDeliveryState(deliveryState);
    onCloseModal();
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      isOpen={isOpen}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <div className={css.modal}>
        <div className={css.modalContent}>
          <p className={css.modalTitle}>
            {intl.formatMessage({ id: 'DeliveryStateModal.changingDeliverStatus'})}
          </p>
          <select
            value={deliveryState}
            onChange={handleChange}
          >
            <option value="not-delivered">
              {intl.formatMessage({ id: 'DeliveryStateModal.notDelivered' })}
            </option>
            <option value="sent-to-store">
              {intl.formatMessage({ id: 'DeliveryStateModal.sentToStore' })}
            </option>
            <option value="received-by-store">
              {intl.formatMessage({ id: 'DeliveryStateModal.receivedByStore' })}
            </option>
            <option value="sent-to-stock">
              {intl.formatMessage({ id: 'DeliveryStateModal.sentToStock' })}
            </option>
            <option value="received">
              {intl.formatMessage({ id: 'DeliveryStateModal.received' })}
            </option>
          </select>
        </div>
        <div className={css.actionSection}>
          <InlineTextButton className={css.cancelBtn} onClick={handleClose}>
            <FormattedMessage id="PartialCancelModal.cancel" />
          </InlineTextButton>
          <Button
            className={css.saveBtn}
            inProgress={partialCancelInProgress}
            onClick={handleSubmit}
            disabled={false}
          >
            <FormattedMessage id="PartialCancelModal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

DeliveryStateModal.defaultProps = {
  className: null,
  rootClassName: null,
};

DeliveryStateModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  partialCancelInProgress: bool,
  partialCancelError: propTypes.error,
  onPartialCancelOrder: func.isRequired,
};

export default injectIntl(DeliveryStateModal);
