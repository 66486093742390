import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import classNames from 'classnames';
import { bool, node, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { IconArrowHead } from '../index';

import css from './CustomDatePicker.css';

const dayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const CustomDatePicker = (props) => {
  const {label, disabled, inputFormat, calendarClassName, children, ...datePickerProps} = props;
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const calendarClasses = classNames(css.calendar, calendarClassName)

  const handleCalendarOpen = () => {
    setIsCalendarOpened(true);
  };

  const handleCalendarClose = () => {
    setIsCalendarOpened(false);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const formattedValue = value ? moment(value).format( inputFormat ) : '';
    const defaultValue = moment(new Date()).format( inputFormat );
    const valueText = !value ? defaultValue : `${formattedValue}`;
    const activeDateClassName = !value ? classNames(css.defaultBtn, {[css.active]: isCalendarOpened }) : css.btn;
    const dateClassName = disabled ? classNames(css.defaultBtn, css.disabled) : activeDateClassName;

    return (
      <div className={css.container} ref={ref}>
        <div className={css.dateContainer}>
          <label className={css.label}>
            {label && <span className={css.text}>{label}</span>}
            <div className={dateClassName} onClick={onClick}>
              {valueText}
            </div>
          </label>
        </div>
      </div>
    );
  });

  return (
    <div className={css.wrapper}>
      <DatePicker
        customInput={<CustomInput />}
        formatWeekDay={nameOfDay => {
          const dayIndex = dayOfWeek.indexOf(nameOfDay);
          const localDaysOfDays = moment.weekdaysShort(true);
          return <p className={css.dayNameText}>{localDaysOfDays[dayIndex]}</p>;
        }}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        renderCustomHeader={({
               date,
               decreaseMonth,
               increaseMonth,
               prevMonthButtonDisabled,
               nextMonthButtonDisabled,
         }) => {
          return (
            <div className={css.headerContainer}>
              <p className={css.calendarTitle}>
                <FormattedMessage id="FieldDateRange.chooseDates" />
              </p>
              <div className={css.monthContainer}>
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className={css.arrowBtn}
                  type="button"
                >
                  <IconArrowHead direction={'left'} />
                </button>
                <p className={classNames(css.month)}>
                  {moment(date).format('MMMM')} {moment(date).format('YYYY')}
                </p>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className={css.arrowBtn}
                  type="button"
                >
                  <IconArrowHead direction={'right'} />
                </button>
              </div>
            </div>
          );
        }}
        dateFormat="yyyy-MM-dd"
        calendarClassName={calendarClasses}
        popperClassName={css.popper}
        disabled={disabled}
        {...datePickerProps}
      >
        {children}
      </DatePicker>
    </div>
  )
}


CustomDatePicker.defaultProps = {
  label: '',
  disabled: false,
  inputFormat: 'dd, D MMM',
  calendarClassName: null,
};

CustomDatePicker.propTypes = {
  label: string,
  disabled: bool,
  inputFormat: string,
  calendarClassName: string,
  children: node,
};

export default CustomDatePicker;
