import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { exchangeDecathlonAuthCode } from '../../ducks/Auth.duck';
import { parse } from '../../util/urlHelpers'
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

export class ExchangeCodePageComponent extends Component {
  async componentDidMount() {
    try {
      const { code } = parse(this.props.location.search);
      await this.props.onExchangeCode(code);
      const currentUser = await this.props.onFetchCurrentUser();
      if (currentUser && currentUser.attributes.terms) {
        this.props.history.push('/');
      } else {
        this.props.history.push('/profile-settings?page=true');
      } 
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { scrollingDisabled } = this.props;

    return (
      <Page title="Exchange" scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div>
                <FormattedMessage id="ExchangeCodePage.waitAWhile" />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onExchangeCode: (code) => dispatch(exchangeDecathlonAuthCode(code)),
  onFetchCurrentUser: () => dispatch(fetchCurrentUser())
});

const ExchangeCodePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExchangeCodePageComponent);

export default ExchangeCodePage;
