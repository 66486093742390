import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, externalLink, isDecathlon } = props;

  const externalLinkWithUtm = `${externalLink}?rent=1&utm_campaign=product_page&utm_medium=referral&utm_source=decathlon_rent`;

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      {
        externalLink && isDecathlon &&
        <p className={css.description}>
          <a href={externalLinkWithUtm} className={css.externalLink} target="_blank">
            <FormattedMessage id="ListingPage.productWebsite" />
          </a>
        </p>
      }
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
