import React, { useState, useEffect, forwardRef, useRef } from 'react';
import classNames from 'classnames';

import config from '../../../config';
import businessCategoryConfig from '../../../business-category-config';
import defaultSearches from '../default-searches';
import DefaultSearches from './DefaultSearches/DefaultSearches';
import LastSearches from './LastSearches/LastSearches';

import css from './AutocompleteListing.css';
import Suggestions from './Suggestions/Suggestions';

const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const KEY_CODE_BACKSPACE = 8;
const NUMBER_OF_LAST_SEARCHES = 3;
const NUMBER_OF_SUGGESTIONS = 5;

const AutocompleteListing = (props, ref) => {
  const {
    value,
    onChange,
    placeholder,
    inputClassName,
    inputFocusClassName,
    predictionsClassName,
    suggestionsWrapperClassName,
    suggestions,
    onLocationFocus,
    lastSearches,
    setLastSearches,
    setLocationField,
    intl,
    wrapperClassName
  } = props;
  const langLocal = config.locale;
  const [suggestionsOpened, setSuggestionsOpened] = useState(false);
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState(-1);
  const categories = config.custom.filters.find(filter => filter.id === 'category').config.options;
  const predictionsClasses = classNames(css.predictions, predictionsClassName);
  const suggestionsWrapperClasses = classNames(suggestionsWrapperClassName, css.suggestionsWrapper);
  const wrapperClasses = classNames(wrapperClassName,css.wrapper)
  const inputClasses = classNames(inputClassName, {
    [inputFocusClassName]: suggestionsOpened && value,
  });
  const filteredLastSearches = lastSearches
    .filter(item => !!item.keyword)
    .slice(0, NUMBER_OF_LAST_SEARCHES);
  const refSuggestions = useRef(null);

  const filteredDefaultSearches = defaultSearches
    .filter(item => item.countryId === config.custom.countryId)
    .slice(0, NUMBER_OF_SUGGESTIONS);

  const filteredSuggestions = suggestions?.filter(suggestion => {
    return suggestion?.attributes?.keywords?.filter(({ text, lang }) => {
      return langLocal === lang && value.keywords && text.includes(value.keywords.toLowerCase());
    }).length;
  });

  const simplifiedSuggestions = [];
  filteredSuggestions.forEach(suggestion => {
    const { attributes } = suggestion;

    const keywordsSuggestions = attributes.keywords?.filter(({ text }) => {
      return value.keywords && text.includes(value.keywords.toLowerCase());
    });

    let isExact = false;
    keywordsSuggestions.forEach(element => {
      const { exact } = element;

      if (!isExact || !exact) {
        simplifiedSuggestions.push({
          ...suggestion,
          attributes: { parentId: attributes.parentId, keyword: element },
        });
      }

      if (!isExact && exact) {
        isExact = true;
      }
    });
  });

  const sortFlatSuggestions = simplifiedSuggestions.slice(0, NUMBER_OF_SUGGESTIONS);

  const handleChange = e => {
    onChange({ keywords: e.target.value, category: '', subcategory: '' });
  };

  const handleClick = (keyword, category, subcategory, exact = false) => {
    onChange({ keywords: keyword, category, subcategory, exact });
    setSuggestionsOpened(false);
    ref.current.blur();
    onLocationFocus();
  };

  const handleLastSearchClick = recentSearch => {
    const { keyword, category, subcategory, location, bounds, origin, exact } = recentSearch;
    handleClick(keyword, category, subcategory, exact);
    setLocationField(location, origin, bounds);
  };

  const suggestionsOnChange = (value, index) => {
    const {
      attributes: {
        keyword: { text, exact },
        parentId,
      },
      id: { uuid },
    } = value[index];
    const subcategory = parentId ? uuid : '';
    const category = !parentId ? uuid : parentId;
    const categoryLabel = !parentId && categories.find(option => option.key === uuid);
    const subcategoryLabel = parentId && businessCategoryConfig.find(({ key }) => key === uuid);
    const labelValue = parentId ? subcategoryLabel : categoryLabel;
    const keywords = exact ? intl.formatMessage({ id: labelValue.translation }) : text || value;

    onChange({ keywords, category, subcategory, exact });
  };

  const defaultSearchesOnChange = (value, index) => {
    if (!value) return;
    const { keyword = '', category = '', subcategory = '', exact = false } = value[index];
    onChange({ keywords: keyword, category, subcategory, exact });
  };

  const finalize = () => {
    setSuggestionsOpened(false);
    ref.current.blur();
    setHighlightedSuggestionIndex(-1);
  };

  const handleKeyDown = event => {
    const suggestionsLength =
      sortFlatSuggestions.length || filteredDefaultSearches.length + filteredLastSearches.length;

    if (event.keyCode === KEY_CODE_ARROW_DOWN || event.keyCode === KEY_CODE_ARROW_UP) {
      event.preventDefault();
      const value = event.keyCode === KEY_CODE_ARROW_DOWN ? 1 : -1;

      const index =
        highlightedSuggestionIndex === -1
          ? 0
          : (highlightedSuggestionIndex + value) % suggestionsLength;

      setHighlightedSuggestionIndex(index);
    } else if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault();
      event.stopPropagation();

      if (highlightedSuggestionIndex !== -1) {
        if (!!sortFlatSuggestions.length) {
          highlightedSuggestionIndex >= 0 &&
            suggestionsOnChange(sortFlatSuggestions, highlightedSuggestionIndex);
        } else {
          const isIndexOfLastSearches = highlightedSuggestionIndex < filteredLastSearches.length;
          const searches = isIndexOfLastSearches ? filteredLastSearches : filteredDefaultSearches;
          const highlightedIndex = isIndexOfLastSearches
            ? highlightedSuggestionIndex
            : highlightedSuggestionIndex - filteredLastSearches.length;
          defaultSearchesOnChange(searches, highlightedIndex);
        }
      }
      finalize();
      onLocationFocus();
    } else if (event.keyCode === KEY_CODE_BACKSPACE) {
      setSuggestionsOpened(true);
    } else if (event.keyCode === KEY_CODE_ESC || event.keyCode === KEY_CODE_TAB) {
      finalize();
    }
  };

  const handleClearClick = () => {
    setLastSearches([]);
    ref.current.focus();
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      const isSuggestionsClosed =
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.parentElement?.id !== 'clearButton' &&
        event.target?.id !== 'clearButton' &&
        !refSuggestions.current?.contains(event.target);

      if (isSuggestionsClosed) {
        setSuggestionsOpened(false);
        setHighlightedSuggestionIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={wrapperClasses}>
      <input
        value={value.keywords}
        onChange={handleChange}
        onFocus={() => setSuggestionsOpened(true)}
        placeholder={placeholder}
        className={inputClasses}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
      {suggestionsOpened && value && (
        <div className={suggestionsWrapperClasses} ref={refSuggestions}>
          {!!filteredLastSearches.length && !sortFlatSuggestions.length && (
            <LastSearches
              handleClearClick={handleClearClick}
              filteredLastSearches={filteredLastSearches}
              categories={categories}
              handleLastSearchClick={handleLastSearchClick}
              highlightedSuggestionIndex={highlightedSuggestionIndex}
              predictionsClasses={predictionsClasses}
            />
          )}
          {!!sortFlatSuggestions.length ? (
            <Suggestions
              value={value}
              intl={intl}
              categories={categories}
              handleClick={handleClick}
              highlightedSuggestionIndex={highlightedSuggestionIndex}
              predictionsClasses={predictionsClasses}
              sortFlatSuggestions={sortFlatSuggestions}
            />
          ) : (
            <DefaultSearches
              defaultSearches={filteredDefaultSearches}
              categories={categories}
              handleClick={handleClick}
              lengthOfLastSearches={filteredLastSearches.length}
              highlightedSuggestionIndex={highlightedSuggestionIndex}
              predictionsClasses={predictionsClasses}
              intl={intl}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default forwardRef(AutocompleteListing);
