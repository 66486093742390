import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  IconLogo,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook className={css.mediaIcon} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.mediaIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.mediaIcon} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.topLinks}>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li><h4 className={css.linkHeader}><FormattedMessage id="Footer.shortcut" /></h4></li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            {
              process.env.REACT_APP_COUNTRY_ID === 'pl' && (
                <div className={css.infoLinks}>
                  <ul className={css.list}>
                    <li><h4 className={css.linkHeader}><FormattedMessage id="Footer.offer" /></h4></li>
                    <li className={css.listItem}>
                      <NamedLink name="EquipmentRentPage" className={css.link}>
                        Wypożyczalnia sprzętu sportowego
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="BikeRentPage" className={css.link}>
                        Wypożyczalnia rowerów
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="TourismRentPage" className={css.link}>
                        Wypożyczalnia namiotów
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="KayakRentPage" className={css.link}>
                        Wypożyczalnia kajaków
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="SupRentPage" className={css.link}>
                        Wypożyczalnia desek SUP
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="TrademillPage" className={css.link}>
                        Wypożyczalnia trenażerów
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="FitnessPage" className={css.link}>
                        Wypożyczalnia sprzętu fitness
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="SkiRentPage" className={css.link}>
                        Wypożyczalnia nart
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              )
            }

            <div className={css.extraLinks}>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li><h4 className={css.linkHeader}><FormattedMessage id="Footer.importantInfo" /></h4></li>
                  <li>
                    <NamedLink name="LegalInformationPage" className={css.privacy}>
                      <FormattedMessage id="Footer.legalInformation" />
                    </NamedLink>
                  </li>
                  <li>
                    <a
                      href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/terms-${config.locale === 'hu' ? `${config.locale}-2023-09-12` : config.locale
                        }.pdf`}
                      className={css.legalLink}
                      target="_blank"
                    >
                      <FormattedMessage id="Footer.terms" />
                    </a>
                    {/* <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink> */}
                  </li>
                  <li>
                    <a
                      href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/privacy-policy-${config.locale === 'hu' ? `${config.locale}-2023-03-21` : config.locale
                        }.pdf`}
                      className={css.legalLink}
                      target="_blank"
                    >
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </a>
                  </li>
                  {
                    config.locale === 'pl' && (
                      <li>
                        <a
                          href="https://www.decathlon.pl/help/app/dsa-form"
                          className={css.legalLink}
                          target="_blank"
                        >
                          <FormattedMessage id="Footer.report" />
                        </a>
                      </li>)
                  }
                </ul>
              </div>
            </div>

          </div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <IconLogo decathlonColor={"#FFF"} rentColor={"#FFF"} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <p className={css.organizationCopyright}>
                  <a
                    className={css.copyrightLink}
                    href={`https://www.decathlon.${process.env.REACT_APP_COUNTRY_ID}`}
                    target="_blank"
                  >
                    {' '}
                    <FormattedMessage id="Footer.copyright" />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div>
            <NamedLink name="LandingPage" className={css.logoLinkMobile}>
              <IconLogo decathlonColor={"#FFF"} rentColor={"#FFF"} />
            </NamedLink>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="LegalInformationPage" className={css.legalLink}>
                <FormattedMessage id="Footer.legalInformation" />
              </NamedLink>
              {/* <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink> */}
              <a
                href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/terms-${config.locale === 'hu' ? `${config.locale}-2023-09-12` : config.locale
                  }.pdf`}
                className={css.legalLink}
                target="_blank"
              >
                <FormattedMessage id="Footer.terms" />
              </a>
              {/* <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink> */}
              <a
                href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/privacy-policy-${config.locale}.pdf`}
                className={css.legalLink}
                target="_blank"
              >
                <FormattedMessage id="Footer.privacyPolicy" />
              </a>
              {
                config.locale === 'pl' && (
                  <a
                    href="https://www.decathlon.pl/help/app/dsa-form"
                    className={css.legalLink}
                    target="_blank"
                  >
                    <FormattedMessage id="Footer.report" />
                  </a>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
