import React, { useState } from 'react';
import classNames from 'classnames';
import { bool, func } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { intlShape } from '../../../util/reactIntl';
import { FormattedMessage } from '../../../util/reactIntl';
import { ensureTransaction, ensureUser, userDisplayNameAsString } from '../../../util/data';
import { propTypes } from '../../../util/types';
import { isMobileSafari } from '../../../util/userAgent';
import {
  UserDisplayName,
  Form,
  FieldTextInput,
  Button,
  ModalInMobile,
  Modal,
} from '../../../components';

import css from './SendMessageSection.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const BLUR_TIMEOUT_MS = 100;

const SendMessageSection = ({
  transaction,
  sendMessageInProgress,
  sendMessageError,
  onSendMessage,
  intl,
  currentUser,
  rootClassName,
  className,
}) => {
  const [sendMessageFormFocused, setSendMessageFormFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.user);
  const currentProvider = ensureUser(currentTransaction.provider);
  const isCustomerLoaded = !!transaction.user.id;
  const isProviderLoaded = !!currentProvider.id;
  const isCustomerBanned = isCustomerLoaded && transaction.user.attributes.banned;
  const isCustomerDeleted = isCustomerLoaded && currentCustomer.attributes.deleted;
  const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
  const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
  const { otherUserDisplayNameString } = displayNames(
    currentUser,
    currentProvider,
    currentCustomer,
    intl
  );
  const sendMessageFormName = 'TransactionPanel.SendMessageForm';
  const sendMessagePlaceholder = intl.formatMessage(
    { id: 'SendMessageSection.sendMessagePlaceholder' },
    { name: otherUserDisplayNameString }
  );

  const showSendMessageForm =
    !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

  const sendingMessageNotAllowed = intl.formatMessage({
    id: 'TransactionPanel.sendingMessageNotAllowed',
  });

  const classes = classNames(rootClassName || css.root, className);
  const isMobSaf = isMobileSafari();
  let blurTimeoutId = null;

  const onSendMessageFormFocus = () => {
    setSendMessageFormFocused(true);

    if (isMobSaf) {
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  };

  const onSendMessageFormBlur = () => {
    setSendMessageFormFocused(false);
  };

  const scrollToMessage = messageId => {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false);
  };

  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
  };

  const onMessageSubmit = (values, form) => {
    const message = values.message ? values.message.trim() : null;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) return;

    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });

      closeModal();
  };

  const handleFocus = () => {
    onSendMessageFormFocus();
    window.clearTimeout(blurTimeoutId);
  };

  const handleBlur = () => {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    blurTimeoutId = window.setTimeout(() => {
      onSendMessageFormBlur();
    }, BLUR_TIMEOUT_MS);
  };

  const sendMessageForm = (
    <FinalForm
      onSubmit={onMessageSubmit}
      render={({ invalid, form, handleSubmit }) => {
        const submitDisabled = invalid || sendMessageInProgress;

        return (
          <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
            <FieldTextInput
              rootClassName={css.inputRoot}
              inputRootClass={css.textarea}
              type="textarea"
              id={sendMessageFormName ? `${sendMessageFormName}.message` : 'message'}
              name="message"
              placeholder={sendMessagePlaceholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              isValidation={false}
            />
            <div className={css.submitContainer}>
              <div className={css.errorContainer}>
                {sendMessageError ? (
                  <p className={css.error}>
                    <FormattedMessage id="SendMessageForm.sendFailed" />
                  </p>
                ) : null}
              </div>
              <Button
                className={css.submitButton}
                inProgress={sendMessageInProgress}
                disabled={submitDisabled}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <FormattedMessage id="SendMessageSection.sendMessage" />
              </Button>
              <p className={css.infoText}>
                {' '}
                <FormattedMessage id="SendMessageSection.text" />
              </p>
            </div>
          </Form>
        );
      }}
    />
  );

  return (
    <div className={classes}>
      {showSendMessageForm ? (
        <div className={css.desktop}>
          <p className={css.title}>
            <FormattedMessage id="SendMessageSection.message" />
          </p>
          {sendMessageForm}
        </div>
      ) : (
        <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
      )}
      <Modal
        className={css.mobile}
        containerClassName={css.modalContainer}
        scrollLayerClassName={css.modalScrollLayer}
        closeButtonClassName={css.modalCloseButton}
        closeButtonMessage=" "
        intl={intl}
        id="ModalSendMessageSection"
        isOpen={isOpen}
        onClose={closeModal}
        onManageDisableScrolling={() => true}
      >
        {showSendMessageForm ? (
          <div className={classNames(css.mobile)}>
            <p className={css.title}>
              <FormattedMessage id="SendMessageSection.message" />
            </p>
            <p className={css.messageText}>
            <FormattedMessage id="SendMessageSection.messageText" />
            </p>
            {sendMessageForm}
          </div>
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </Modal>
      <button onClick={openModal} className={css.contactButton}>
        <FormattedMessage id="SendMessageSection.contact" />
      </button>
    </div>
  );
};

SendMessageSection.propTypes = {
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onSendMessage: func.isRequired,
  intl: intlShape,
};

export default SendMessageSection;
