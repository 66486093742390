export const sortDistanceProviders = providers => {
  return providers.sort((a, b) => {
    return a.distance - b.distance;
  });
};

export const sortSelectedProviders = (providers, providerIds) => {
  const selectedProviders = providerIds ? providerIds.split(',') : [];
  const notSelectedProvers = providers.filter(item => !selectedProviders.includes(item.id.uuid));
  const filteredSelectedProvers = providers.filter(item => selectedProviders.includes(item.id.uuid));
  return [...filteredSelectedProvers, ...notSelectedProvers];
};

export const sortCountryProviders = (providers, countryId) => {
  const countryProviders = providers.filter(item => item.attributes.countryId === countryId);
  const otherProvers = providers.filter(item => item.attributes.countryId !== countryId);
  return [...countryProviders, ...otherProvers];
}
