import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Button } from '../../components';
import css from './ManageAvailabilityCalendar.css';
import classNames from 'classnames';

const EditSeats = ({ closeModal, date, dateData, onDateChange }) => {
  const [seats, setSeats] = useState(0);
  const [reason, setReason] = useState('');

  const handleClick = () => {
    onDateChange(date, parseInt(seats), reason || null);
    closeModal();
  };

  return (
    <div className={css.modalContentContainer}>
      <h3 className={css.headingModal}>
        <FormattedMessage id="EditSeats.title" /><br />
        <span className={css.dateInEditSeats}>{date.format('DD MMMM YYYY')}</span>
      </h3>
      <div className={css.editSeatsFields}>
        {
          seats < 0 && (
            <div className={classNames(css.seatContainer, css.seatInput, css.reasonInput)}>
              <label>
                <FormattedMessage id="EditSeats.negativeStockReason" />
              </label>
              <textarea
                type="textarea"
                value={reason}
                onChange={e => setReason(e.target.value)}
                className={css.seatsInput}
                rows="2"
              />
            </div>
          )
        }
        <div className={classNames(css.seatContainer, css.seatInput)}>
          <label>
            <FormattedMessage id="EditSeats.editStockAvailable" />
          </label>
          <input
            type="number"
            value={seats}
            onChange={e => setSeats(e.target.value)}
            className={css.seatsInput}
          />
        </div>
        <div className={classNames(css.seatContainer, css.bookedSeats)}>
          <label>
            <FormattedMessage id="EditSeats.rentedStock" />
          </label>
          <span>{dateData.bookedSeats}</span>
        </div>
        <div className={classNames(css.seatContainer, css.bookedSeats)}>
          <label>
            {' '}
            <FormattedMessage id="EditSeats.currentStock" />
          </label>
          <span>{dateData.totalSeats}</span>
        </div>
      </div>
      <Button
        disabled={dateData.bookedSeats > parseInt(seats) + dateData.totalSeats || (seats < 0 && !reason)}
        onClick={handleClick}
        className={css.modalSubmit}
      >
        <FormattedMessage id="EditSeats.confirm" />
      </Button>
    </div>
  );
};

export default EditSeats;
