import React from 'react';
import PropTypes from 'prop-types';

const IconBasket = props => {
  const { className, color } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      className={className}
      width="24"
      height="24"
    >
      <path
        d="M8.30346 8.04449C8.60721 7.35599 8.96327 6.74455 9.35871 6.24112C9.91502 5.53349 10.8223 4.68975 11.9861 4.68806H11.9901C13.1539 4.68806 14.0635 5.53068 14.622 6.23774C15.0203 6.7423 15.378 7.35486 15.6846 8.04505H17.5088C17.0965 6.94762 16.57 5.98181 15.9467 5.19206C14.8138 3.75769 13.4464 3 11.9901 3H11.9839C10.527 3.00225 9.16071 3.76219 8.03233 5.19824C7.41246 5.98687 6.88989 6.95043 6.48096 8.04449H8.30402H8.30346Z"
        fill={color}
      >
      </path>
      <path
        d="M3 9.73233V14.6188C3 15.0991 3.14794 15.5682 3.42356 15.962L6.23831 19.9827C6.6765 20.6088 7.39313 20.9817 8.15756 20.9817H15.8475C16.6097 20.9817 17.3241 20.611 17.7628 19.9878L20.5731 15.9963C20.8509 15.6014 21 15.1306 21 14.6474V9.7312H3V9.73233ZM19.3125 14.8561L16.1867 19.2948H7.81612L4.6875 14.8252V11.4198H19.3125V14.8561Z"
        fill={color}
      >
      </path>
    </svg>
  );
};

IconBasket.defaultProps = { className: null, color: "#4a4a4a" };

const { string } = PropTypes;

IconBasket.propTypes = { className: string, color: string };

export default IconBasket;
