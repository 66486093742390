import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { IconWarning } from '..';

import css from './WarningComponent.css';

const WarningComponent = (props) => {
  const { className, rootClassName, message } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <IconWarning />
        <p className={css.text}>
          <FormattedMessage id={message} />
        </p>
      </div>
    </div>
  )
};

WarningComponent.propTypes = {
  className: string,
  rootClassName: string,
  message: string.isRequired,
};

export default WarningComponent;
