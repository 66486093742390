import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCart.css';

const IconCart = props => {
  const { rootClassName, className, colorVariant = '#001018' } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31393 2.90284L-0.125977 1.48373L0.126445 0.516113L5.87645 2.01611L6.18654 2.09701L6.24254 2.41255L6.96792 6.49992H23.0002H23.6179L23.4893 7.10407L21.5726 16.1041L21.4883 16.4999H21.0836H8.14607H7.72699L7.65376 16.0873L6.05654 7.08729L5.31393 2.90284ZM7.14539 7.49992L8.56515 15.4999H20.6788L22.3825 7.49992H7.14539ZM11.0002 20.9999C11.5525 20.9999 12.0002 20.5522 12.0002 19.9999C12.0002 19.4476 11.5525 18.9999 11.0002 18.9999C10.448 18.9999 10.0002 19.4476 10.0002 19.9999C10.0002 20.5522 10.448 20.9999 11.0002 20.9999ZM11.0002 21.9999C12.1048 21.9999 13.0002 21.1045 13.0002 19.9999C13.0002 18.8954 12.1048 17.9999 11.0002 17.9999C9.89567 17.9999 9.00023 18.8954 9.00023 19.9999C9.00023 21.1045 9.89567 21.9999 11.0002 21.9999ZM19.5002 19.9999C19.5002 20.5522 19.0525 20.9999 18.5002 20.9999C17.948 20.9999 17.5002 20.5522 17.5002 19.9999C17.5002 19.4476 17.948 18.9999 18.5002 18.9999C19.0525 18.9999 19.5002 19.4476 19.5002 19.9999ZM20.5002 19.9999C20.5002 21.1045 19.6048 21.9999 18.5002 21.9999C17.3957 21.9999 16.5002 21.1045 16.5002 19.9999C16.5002 18.8954 17.3957 17.9999 18.5002 17.9999C19.6048 17.9999 20.5002 18.8954 20.5002 19.9999Z"
        fill={colorVariant}
        stroke={colorVariant}
        strokeWidth="0.4"
      />
    </svg>
  );
};

IconCart.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconCart.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconCart;
