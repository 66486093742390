import React from 'react';
import { Controller } from 'react-hook-form';
import { string } from 'prop-types';

import { Textarea } from '../index';

const OpeningHoursField = ({
   containerClassName,
   textareaClassName,
   fieldName,
   label,
   placeholder,
   control
}) => {

  return (
    <div className={containerClassName}>
      <Controller
        control={control}
        name={fieldName}
        rules={{ minLength: 3 }}
        render={({ field: { onChange, value } }) => (
          <Textarea
            label={label}
            value={value}
            onChange={onChange}
            id={fieldName}
            placeholder={placeholder}
            isValidation={false}
            textareaClassName={textareaClassName}
          />
        )}
      />
    </div>
  )
}

OpeningHoursField.defaultProps = {
  containerClassName: null,
  textareaClassName: null,
  fieldName: '',
  label: '',
  placeholder: '',
}

OpeningHoursField.propTypes = {
  containerClassName: string,
  textareaClassName: string,
  fieldName: string,
  label: string,
  placeholder: string,
}

export default OpeningHoursField;
