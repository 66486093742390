import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { oneOf, string } from 'prop-types';

import { IconArrowHead } from '../index';

import css from './ScrollToTop.css'

const ScrollToTop = ({ containerClassName, buttonClassName, iconClassName }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const containerClasses = classNames(containerClassName, css.container);
  const buttonClasses = classNames(buttonClassName, css.btn);
  const iconClasses = classNames(iconClassName, css.iconStyle);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("keyup", handleScroll);
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={containerClasses}>
      {showTopBtn && (
        <button onClick={goToTop} className={buttonClasses}>
          <IconArrowHead
            direction='up'
            className={iconClasses}
          />
        </button>
      )}
    </div>
  );
};

ScrollToTop.defaultProps = {
  containerClassName: null,
  buttonClassName: null,
  iconClassName: null,
};

ScrollToTop.propTypes = {
  containerClassName: oneOf([null, string]),
  buttonClassName: oneOf([null, string]),
  iconClassName: oneOf([null, string]),
};

export default ScrollToTop;
