import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearchSecond.css';

const IconSearchSecond = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.814 20.899L18.531 16.617C19.8082 15.0237 20.5029 13.042 20.5 11C20.5 6.032 16.468 2 11.5 2C6.532 2 2.5 6.032 2.5 11C2.5 15.968 6.532 20 11.5 20C13.542 20.0029 15.5237 19.3082 17.117 18.031L21.399 22.314L22.814 20.899ZM18.5 11C18.5029 12.8204 17.7941 14.5699 16.525 15.875L16.375 16.025C15.0699 17.2941 13.3204 18.0029 11.5 18C7.632 18 4.5 14.867 4.5 11C4.5 7.132 7.632 4 11.5 4C15.367 4 18.5 7.132 18.5 11Z"
        fill="white"
      />
    </svg>
  );
};

IconSearchSecond.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearchSecond.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearchSecond;
