import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews, ReviewRating } from '../../components';

import css from './ListingPage.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, rating } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  if (!reviews.length) return null;

  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" />
        <span className={css.ratingValue}>{rating.toFixed(2)}</span>
        <ReviewRating
          rating={Math.round(rating)}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <span className={css.reviewsCount}><FormattedMessage id="ListingPage.reviewsCount" values={{count: reviews.length}} /></span>
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={reviews} />
    </div>
  );
};

export default SectionReviews;
