import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import {
  LINE_ITEM_DAY,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_PARTNERSHIP_DISCOUNT,
  LINE_ITEM_PROMO_DISCOUNT, LINE_ITEM_SERVICE_FEE,
  LINE_ITEM_EVENING_DISCOUNT
} from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';
import ServiceFeeItem from './ServiceFeeItem';
import ListingsItem from './ListingsItem/ListingsItem';

import css from '../OrderItemInfo.css';

const CancelledItems = ({ cancelList, listings, intl }) => {
    const serviceFeeLineItem = cancelList.filter(item => item?.code === LINE_ITEM_SERVICE_FEE);

    if (serviceFeeLineItem.length) {
      return (
        < ServiceFeeItem
          serviceFeeLineItem={serviceFeeLineItem}
          intl={intl}
          idMessage="OrderInfo.refund"
        />
      );
    }

    const products = cancelList.filter(item => item?.code === LINE_ITEM_DAY);
    const updateProducts = products.map(item => {
      return {
        ...item,
        seats: -item.seats,
      };
    });

    const discount = cancelList.find(item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT);
    const eveningDiscount = cancelList.find(item => item?.code === LINE_ITEM_EVENING_DISCOUNT);
    const promoDiscount = cancelList.find(item => item?.code === LINE_ITEM_PROMO_DISCOUNT);
    const partnershipDiscount = cancelList.find(item => item?.code === LINE_ITEM_PARTNERSHIP_DISCOUNT);
    const sum = products.reduce((sum, currentValue) => sum + currentValue.lineTotal.amount, 0);
    const currency = products[0].lineTotal.currency;
    const subTotalWithDiscount = sum + (discount?.lineTotal?.amount || 0) + (eveningDiscount?.lineTotal?.amount || 0);

    const amountSubTotal = promoDiscount
      ? subTotalWithDiscount + promoDiscount.lineTotal.amount
      : subTotalWithDiscount;
    const subTotal = { amount: amountSubTotal, currency };

    return (
      <ListingsItem
        key={`cancel${amountSubTotal}`}
        products={updateProducts}
        listings={listings}
        intl={intl}
        longTermDiscount={discount}
        eveningDiscount={eveningDiscount}
        promoDiscount={promoDiscount}
        partnershipDiscount={partnershipDiscount}
        money={subTotal}
        idMessage="OrderInfo.refund"
      >
        <p className={css.subtitle}>
          <FormattedMessage id="OrderInfo.canceled" />
        </p>
      </ListingsItem>
    );
}

CancelledItems.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  cancelList: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  )};

export default CancelledItems;
