export default function(checkout) {
  const { name, productId, price, category, brand, days } = checkout;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      currencyCode: 'PLN',
      checkout: {
        products: [{
          name,
          id: productId,
          price,
          brand,
          category,
          quantity: days
       }]
      }
    }
  });
}