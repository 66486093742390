import React, { useState, memo, useEffect } from 'react';

import css from './ReviewItem.css';

const ReviewItem = ({ text, intl, isExpandedReview }) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedReview);

  const handleClick = event => {
    event.preventDefault();
    setIsExpanded(prevState => !prevState);
  };

  const isTextTooLong = text.length > 80;
  const reviewText = isExpanded || !isTextTooLong ? text : text.slice(0, 80) + '...';
  const btnText = intl.formatMessage({ id: isExpanded ? 'Console.collapse' : 'Console.expand' });

  useEffect(() => {
    setIsExpanded(isExpandedReview);
  }, [isExpandedReview]);

  return (
    <div className={css.container}>
      <p className={css.text}>{reviewText}</p>
      {isTextTooLong && (
        <button className={css.btn} onClick={handleClick}>
          {btnText}
        </button>
      )}
    </div>
  );
};

export default memo(ReviewItem);
