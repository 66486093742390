import React from 'react';
import { number, string, func, arrayOf, object } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../../util/types';
import { FormattedMessage } from '../../../../util/reactIntl';
import businessCategoryConfig from '../../../../business-category-config';
import { IconClose } from '../../../../components';

import css from './../AutocompleteListing.css';

const LastSearches = ({
  handleClearClick,
  filteredLastSearches,
  categories,
  handleLastSearchClick,
  highlightedSuggestionIndex,
  predictionsClasses,
}) => {
  return (
    <div className={css.container}>
      <div className={css.subheadingContainer}>
        <p className={css.subheading}>
          <FormattedMessage id={'MainFilters.latestSearch'} />
        </p>
        <button className={css.clearBtn} onClick={handleClearClick} id="clearButton">
          <FormattedMessage id={'MainFilters.clear'} />
          <IconClose className={css.icon} />
        </button>
      </div>
      <ul className={predictionsClasses}>
        {filteredLastSearches.map((item, index) => {
          const { keyword, category, subcategory, location } = item;
          const categoryLabel = categories.find(option => option.key === category)?.label;
          const subcategoryLabel = businessCategoryConfig.find(({ key }) => key === subcategory)
            ?.label;
          const label = subcategoryLabel || categoryLabel;
          return (
            <li
              key={index}
              onClick={() => handleLastSearchClick(item)}
              className={classNames(css.searchWrapper, css.listItem, {
                [css.highlightedSuggestion]: index === highlightedSuggestionIndex,
              })}
            >
              <div>
                <span className={css.text}>{keyword}</span>
                {label && <p className={css.subtext}>{label}</p>}
              </div>
              <p className={css.subtext}>{location}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

LastSearches.defaultProps = {
  predictionsClasses: null,
};

LastSearches.propTypes = {
  predictionsClasses: string,
  handleClearClick: func.isRequired,
  filteredLastSearches: arrayOf(object),
  categories: arrayOf(propTypes.categoriesSuggestions).isRequired,
  handleLastSearchClick: func.isRequired,
  highlightedSuggestionIndex: number.isRequired,
};

export default LastSearches;
