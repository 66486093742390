import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  confirmP24Payment,
  sendPurchaseEvent
} from './CheckoutPage.duck';
import {
  fetchTransactionOnly
} from '../TransactionPage/TransactionPage.duck';

import {
  Page,
  Logo,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  NamedLink
} from '../../components';

import { parse } from '../../util/urlHelpers';

import css from './CheckoutPage.css';
import { intlShape, FormattedMessage, injectIntl } from '../../util/reactIntl';

const INCLUDE_LISTING_AND_BOOKING = true;

const { UUID } = sdkTypes;

class P24Status extends Component {
  componentDidMount() {
    const { redirect_status } = parse(this.props.location.search);

    if (redirect_status === 'failed') {
      return
    }
    this.confirmTransaction();
  }

  confirmTransaction = async () => {
    const { onConfirmP24Payment, params } = this.props;
    const txId = new UUID(params.id);

    const orderDetailsSuccessPath = pathByRouteName('OrderDetailsSuccessPage', routeConfiguration(), { id: params.id });

    try {
      const transaction = await onConfirmP24Payment({
        transactionId: txId,
      });

      sendPurchaseEvent(transaction);
      this.props.history.push(orderDetailsSuccessPath);
    } catch (error) {
      this.props.history.push(orderDetailsSuccessPath);
      // throw error;
    }
  }

  topbar = () => (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title="Decathlon Rent"
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt="Decathlon Rent"
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  succeededPaymentInfo = () => (
    <div className={css.paymentStatus}>
      <h2>
        <FormattedMessage id={"P24Status.paymentSuccessful"} />
      </h2>
      <div className={css.paymentStatusInfo}>
        <FormattedMessage id={"P24Status.confirmationOfBookingPartOne"} />{' '}
        <span className={css.bolded}>
        <FormattedMessage id={"P24Status.confirmationOfBookingPartTwo"} />{', '}
        </span>
        <FormattedMessage id={"P24Status.confirmationOfBookingPartThree"} />
      </div>
      <div className={css.loader} />
    </div>
  );

  failedPaymentInfo = () => (
    <div className={css.paymentStatus}>
      <h2 className={css.failed}> <FormattedMessage id={"P24Status.paymentFailed"} /> </h2>
      <div className={css.paymentStatusInfo}><FormattedMessage id={"P24Status.paymentFailedInfo"} /> </div>
      <NamedLink name="PaymentPage" params={{ transactionId: this.props.params.id }} className={css.payAgain}>Ponów płatność</NamedLink>
    </div>
  );

  render() {
    const { intl } = this.props;
    const { redirect_status } = parse(this.props.location.search);

    return (
      <Page
        title={intl.formatMessage({id: "P24Status.confirmationOfTransaction"})}
      >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {this.topbar()}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainRoot}>
          {
            redirect_status === 'succeeded' ? this.succeededPaymentInfo() : this.failedPaymentInfo()
          }
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    null,
    dispatch => ({
      dispatch,
      onConfirmP24Payment: params => dispatch(confirmP24Payment(params)),
      onFetchTransaction: (txId, txRole) => dispatch(fetchTransactionOnly(txId, txRole, INCLUDE_LISTING_AND_BOOKING))
    })
  )
)(P24Status);

