import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './AboutPage.css';
import image from './about-us.jpg';

const AboutPage = ({ intl }) => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const countryEmails = [
    {country: 'pl', email: 'kontakt.rent@decathlon.com'},
    {country: 'hu', email: 'ugyfelszolgalat@decathlon.hu'},
    {country: 'lt', email: 'pagalba@decathlon.com'},
    {country: 'lv', email: 'riga@decathlon.lv'},
    {country: 'cz', email: 'rentcz@decathlon.com'},
  ];

  const countryEmailPL = countryEmails.find(({country}) => 'pl' === country); 
  const countryEmail  = countryEmails.find(({country}) => process.env.REACT_APP_COUNTRY_ID === country); 
  const rentEmail = countryEmail ? countryEmail.email : countryEmailPL.email;

  const phoneNumberCzechRepublic  = process.env.REACT_APP_COUNTRY_ID === 'cz' ? <p className={css.phone}>+420 228 800 008</p> : null
  // prettier-ignore
  return (
    <StaticPage
      title={intl.formatMessage({ id: 'AboutPage.pageTitle' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: intl.formatMessage({ id: 'AboutPage.pageTitle' }),
        name: intl.formatMessage({ id: 'AboutPage.pageTitle' }),
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}><FormattedMessage id="AboutPage.title" /></h1>
          <img className={css.coverImage} src={image} alt="Bicycle trip at sunset." />
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h2 className={css.aligned}>
                <FormattedMessage id="AboutPage.paragraph1" />
              </h2>

              <p className={css.aligned}>
                <FormattedMessage id="AboutPage.paragraph2" />
              </p>

              <p>
                <FormattedMessage id="AboutPage.contact" /><br />
                <ExternalLink href={`mailto:${rentEmail}`}>{rentEmail}</ExternalLink>
                {phoneNumberCzechRepublic}
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(AboutPage);
