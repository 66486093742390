import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';

// ================ Action types ================ //

export const FETCH_CONSOLE_COUPONS_REQUEST = 'app/ConsoleCouponsPage/FETCH_CONSOLE_COUPONS_REQUEST';
export const FETCH_CONSOLE_COUPONS_SUCCESS = 'app/ConsoleCouponsPage/FETCH_CONSOLE_COUPONS_SUCCESS';
export const FETCH_CONSOLE_COUPONS_ERROR = 'app/ConsoleCouponsPage/FETCH_CONSOLE_COUPONS_ERROR';

export const FETCH_CONSOLE_PARTNERS_REQUEST =
  'app/ConsoleCouponsPage/FETCH_CONSOLE_PARTNERS_REQUEST';
export const FETCH_CONSOLE_PARTNERS_SUCCESS =
  'app/ConsoleCouponsPage/FETCH_CONSOLE_PARTNERS_SUCCESS';
export const FETCH_CONSOLE_PARTNERS_ERROR = 'app/ConsoleCouponsPage/FETCH_CONSOLE_PARTNERS_ERROR';

export const CREATE_COUPONS_REQUEST = 'app/ConsoleCouponsPage/CREATE_COUPONS_REQUEST';
export const CREATE_COUPONS_SUCCESS = 'app/ConsoleCouponsPage/CREATE_COUPONS_SUCCESS';
export const CREATE_COUPONS_ERROR = 'app/ConsoleCouponsPage/CREATE_COUPONS_ERROR';
export const CREATE_COUPONS_CLEAR = 'app/ConsoleCouponsPage/CREATE_COUPONS_CLEAR';

export const UPDATE_COUPONS_REQUEST = 'app/ConsoleCouponsPage/UPDATE_COUPONS_REQUEST';
export const UPDATE_COUPONS_SUCCESS = 'app/ConsoleCouponsPage/UPDATE_COUPONS_SUCCESS';
export const UPDATE_COUPONS_ERROR = 'app/ConsoleCouponsPage/UPDATE_COUPONS_ERROR';
export const UPDATE_COUPONS_CLEAR = 'app/ConsoleCouponsPage/UPDATE_COUPONS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchCouponsError: null,
  fetchCouponsSuccess: false,
  pagination: null,
  coupons: [],
  fetchPartnersInProgress: false,
  fetchPartnersError: null,
  fetchPartnersSuccess: false,
  partners: [],
  createInProgress: false,
  createCouponsError: null,
  createCouponsSuccess: false,
  createdCoupons: [],
  updateInProgress: false,
  updateCouponsError: null,
  updateCouponsSuccess: false,
};

export default function consoleCouponsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_COUPONS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchCouponsError: null,
        fetchCouponsSuccess: false,
      };
    case FETCH_CONSOLE_COUPONS_SUCCESS: {
      return {
        ...state,
        fetchCouponsError: null,
        fetchInProgress: false,
        fetchCouponsSuccess: true,
        coupons: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_COUPONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchCouponsError: payload,
        fetchCouponsSuccess: false,
      };

    case FETCH_CONSOLE_PARTNERS_REQUEST:
      return {
        ...state,
        fetchPartnersInProgress: true,
        fetchPartnersError: null,
        fetchPartnersSuccess: false,
      };
    case FETCH_CONSOLE_PARTNERS_SUCCESS: {
      return {
        ...state,
        fetchPartnersInProgress: false,
        fetchPartnersSuccess: true,
        partners: payload.data.data,
      };
    }
    case FETCH_CONSOLE_PARTNERS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchPartnersInProgress: false,
        fetchPartnersError: payload,
        fetchPartnersSuccess: false,
      };

    case CREATE_COUPONS_REQUEST:
      return {
        ...state,
        createInProgress: true,
        createCouponsError: null,
        createCouponsSuccess: false,
      };
    case CREATE_COUPONS_SUCCESS: {
      return {
        ...state,
        createInProgress: false,
        createCouponsSuccess: true,
        createdCoupons: payload.data.included,
        coupons: [...payload.data.included, ...state.coupons ],
      };
    }
    case CREATE_COUPONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        createInProgress: false,
        createCouponsError: payload,
        createCouponsSuccess: false,
      };
    case CREATE_COUPONS_CLEAR:
      return {
        ...state,
        createInProgress: false,
        createCouponsError: null,
        createCouponsSuccess: false,
      };

    case UPDATE_COUPONS_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateCouponsError: null,
        updateCouponsSuccess: false,
      };
    case UPDATE_COUPONS_SUCCESS: {
      const updateCoupons = state.coupons.map(coupon =>
        coupon.id.uuid === payload.data.data.id.uuid ? payload.data.data : coupon
      );

      return {
        ...state,
        updateInProgress: false,
        updateCouponsSuccess: true,
        coupons: updateCoupons,
      };
    }
    case UPDATE_COUPONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateInProgress: false,
        updateCouponsError: payload,
        updateCouponsSuccess: false,
      };
    case UPDATE_COUPONS_CLEAR:
      return {
        ...state,
        updateInProgress: false,
        updateCouponsError: null,
        updateCouponsSuccess: false,
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchCouponsRequest = () => ({ type: FETCH_CONSOLE_COUPONS_REQUEST });

const fetchCouponsSuccess = response => ({
  type: FETCH_CONSOLE_COUPONS_SUCCESS,
  payload: response,
});

const fetchCouponsError = e => ({
  type: FETCH_CONSOLE_COUPONS_ERROR,
  error: true,
  payload: e,
});

const fetchPartnersRequest = () => ({ type: FETCH_CONSOLE_PARTNERS_REQUEST });

const fetchPartnersSuccess = response => ({
  type: FETCH_CONSOLE_PARTNERS_SUCCESS,
  payload: response,
});

const fetchPartnersError = e => ({
  type: FETCH_CONSOLE_PARTNERS_ERROR,
  error: true,
  payload: e,
});

const createCouponsRequest = () => ({ type: CREATE_COUPONS_REQUEST });

const createCouponsSuccess = response => ({
  type: CREATE_COUPONS_SUCCESS,
  payload: response,
});

const createCouponsError = e => ({
  type: CREATE_COUPONS_ERROR,
  error: true,
  payload: e,
});

const createCouponsClear = () => ({ type: CREATE_COUPONS_CLEAR });

const updateCouponsRequest = () => ({ type: UPDATE_COUPONS_REQUEST });

const updateCouponsSuccess = response => ({
  type: UPDATE_COUPONS_SUCCESS,
  payload: response,
});

const updateCouponsError = e => ({
  type: UPDATE_COUPONS_ERROR,
  error: true,
  payload: e,
});

const updateCouponsClear = () => ({ type: UPDATE_COUPONS_CLEAR });

// ================ Thunks ================ //

const getPartners = () => (dispatch, getState, sdk) => {
  dispatch(fetchPartnersRequest());

  return sdk.newSdk.voucherPartners
    .query()
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchPartnersSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchPartnersError(e));
      throw e;
    });
};

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, codes, partnerId = null, countryId } = parse(search);

  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    include: ['partner'],
    codes: [codes],
    partnerId,
    countryId
  };

  dispatch(fetchCouponsRequest());
  dispatch(getPartners());

  return sdk.newSdk.vouchers
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCouponsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchCouponsError(e));
      throw e;
    });
};

export const createCoupons = data => (dispatch, getState, sdk) => {
  dispatch(createCouponsRequest());

  return sdk.newSdk.voucherGenerationTasks
    .create(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(createCouponsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(createCouponsError(e));
      throw e;
    });
};

export const updateCoupons = data => (dispatch, getState, sdk) => {
  dispatch(updateCouponsRequest());

  return sdk.newSdk.vouchers
    .update(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateCouponsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(updateCouponsError(e));
      throw e;
    });
};

export const createCouponsClearState = () => (dispatch, getState, sdk) => {
  dispatch(createCouponsClear());
};

export const updateCouponsClearState = () => (dispatch, getState, sdk) => {
  dispatch(updateCouponsClear());
};
