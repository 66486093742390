import React, { useEffect, useState, useRef, forwardRef, useCallback, useMemo } from 'react';
import { any, bool, func, shape, string, oneOfType } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { decodeLatLng, decodeLatLngBounds } from '../../../util/urlHelpers';
import config from '../../../config';
import defaultCities from '../default-cities';
import { IconSpinner } from '../../../components';
import IconHourGlass from '../../../components/LocationAutocompleteInput/IconHourGlass';
import Geocoder, {
  CURRENT_LOCATION_ID,
} from '../../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import LocationPredictionsList from './LocationPredictionsList/LocationPredictionsList';
import LocationDefaultList from './LocationDefaultList/LocationDefaultList';

import css from './AutocompleteLocation.css';

export const defaultPredictions = (config.maps.search.suggestCurrentLocation
  ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
  : []
).concat(config.maps.search.defaults);
const NUMBER_OF_LAST_SEARCHES = 3;

const DEBOUNCE_WAIT_TIME = 300;
const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap
const NUMBER_OF_DEGREES_FROM_ORIGIN = 0.003;

const currentValue = props => {
  const value = props.input.value || {};
  const { search = '', predictions = [], selectedPlace = null } = value;
  return { search, predictions, selectedPlace };
};

const unique = (arr, keyProps) => {
  return Object.values(
    arr.reduce((uniqueMap, entry) => {
      const key = keyProps.map(k => entry[k]).join('|');
      if (!(key in uniqueMap)) uniqueMap[key] = entry;
      return uniqueMap;
    }, {})
  );
};

const AutocompleteLocation = (props, ref) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [selectionInProgress, setSelectionInProgress] = useState(false);
  const [touchStartedFrom, setTouchStartedFrom] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // -1 means no highlight
  const [highlightedSuggestionsIndex, setHighlightedSuggestionsIndex] = useState(-1);
  const [fetchingPlaceDetails, setFetchingPlaceDetails] = useState(false);
  const [fetchingPredictions, setFetchingPredictions] = useState(false);
  const [isSwipe, setIsSwipe] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [errorUserLocation, setErrorUserLocation] = useState(null);
  const [userLocationIsLoading, setUserLocationIsLoading] = useState(false);
  const {
    autoFocus,
    rootClassName,
    className,
    iconClassName,
    inputFocusClassName,
    inputClassName,
    predictionsClassName,
    predictionsAttributionClassName,
    validClassName,
    placeholder,
    input,
    meta,
    useDefaultPredictions,
    citiesOnly,
    latestSearches,
    setLatestSearches,
    onKeywordsFocus,
    intl,
  } = props;
  const { search: searchParams, selectedPlace } = currentValue(props);
  const { name, onFocus } = input;
  const { touched, valid } = meta || {};
  const isValid = valid && touched;
  const rootClass = classNames(rootClassName || css.root, className);
  const iconClass = classNames(iconClassName || css.icon);
  // const isFocus = inputHasFocus && ((input.value.search && predictions?.length) || !input.value.search)
  // const inputClass = classNames(inputClassName || css.input, { [validClassName]: isValid }, {[inputFocusClassName]: isFocus});
  const filterLatestSearches = useMemo(() => latestSearches.filter(item => !!item.location), [
    latestSearches,
  ]);
  const uniqueLatestSearches = unique(filterLatestSearches, ['location']).filter(
    (item, index) => index < NUMBER_OF_LAST_SEARCHES
  );

  const _geocoderRef = useRef();
  const defaultCountryCities = defaultCities.filter(
    item => item.countryId === config.custom.countryId
  );

  const getGeocoder = () => {
    if (!_geocoderRef.current) {
      _geocoderRef.current = new Geocoder();
    }
    return _geocoderRef.current;
  };

  const currentPredictions = () => {
    const { search, predictions: fetchedPredictions } = currentValue(props);
    const hasFetchedPredictions = fetchedPredictions && fetchedPredictions.length > 0;
    const showDefaultPredictions = !search && !hasFetchedPredictions && useDefaultPredictions;

    return showDefaultPredictions ? defaultPredictions : fetchedPredictions;
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_UP) {
      e.preventDefault();
      changeHighlight(DIRECTION_UP);
    } else if (e.keyCode === KEY_CODE_ARROW_DOWN) {
      e.preventDefault();
      changeHighlight(DIRECTION_DOWN);
    } else if (e.keyCode === KEY_CODE_ENTER) {
      if (!selectedPlace) {
        e.preventDefault();
        e.stopPropagation();
        selectItemIfNoneSelected();
        onKeywordsFocus();
        ref.current.blur();
      }
    } else if (e.keyCode === KEY_CODE_TAB) {
      selectItemIfNoneSelected();
      ref.current.blur();
    } else if (e.keyCode === KEY_CODE_ESC && ref.current) {
      ref.current.blur();
    }
  };

  const handleSuggestionSelect = (suggestion, highlightedSuggestionIndex = -1) => {
    const { location = '', origin = '', bounds = '' } =
      highlightedSuggestionIndex === -1 ? suggestion : suggestion[highlightedSuggestionIndex];

    input.onChange({
      search: location,
      predictions: [],
      selectedPlace: {
        address: location,
        origin: decodeLatLng(origin),
        bounds: decodeLatLngBounds(bounds),
      },
      isUserLocation: false,
    });
  };

  const finalize = () => {
    onKeywordsFocus();
    setInputHasFocus(false);
    ref.current.blur();
  };

  const handleSuggestionsKeyDown = e => {
    const suggestionsLength = defaultCountryCities.length + uniqueLatestSearches.length + 1;

    if (e.keyCode === KEY_CODE_ARROW_DOWN) {
      e.preventDefault();

      if (!highlightedSuggestionsIndex && highlightedSuggestionsIndex !== 0) {
        setHighlightedSuggestionsIndex(0);
      } else {
        setHighlightedSuggestionsIndex((highlightedSuggestionsIndex + 1) % suggestionsLength);
      }
    } else if (e.keyCode === KEY_CODE_ARROW_UP) {
      e.preventDefault();

      if (!highlightedSuggestionsIndex && highlightedSuggestionsIndex !== 0) {
        setHighlightedSuggestionsIndex(0);
      } else {
        setHighlightedSuggestionsIndex(
          (suggestionsLength + highlightedSuggestionsIndex - 1) % suggestionsLength
        );
      }
    } else if (e.keyCode === KEY_CODE_ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (highlightedSuggestionsIndex <= uniqueLatestSearches.length) {
        if (highlightedSuggestionsIndex === 0) {
          handleUserLocationSelectEnd();
        } else {
          handleSuggestionSelect(uniqueLatestSearches, highlightedSuggestionsIndex - 1);
          finalize();
        }
      } else {
        const highlightedIndex = highlightedSuggestionsIndex - uniqueLatestSearches.length - 1;
        handleSuggestionSelect(defaultCountryCities, highlightedIndex);
        finalize();
      }
    } else if (e.keyCode === KEY_CODE_ESC || e.keyCode === KEY_CODE_TAB) {
      setInputHasFocus(false);
      ref.current.blur();
    }
  };

  const predict = search => {
    const onChange = input.onChange;
    setFetchingPredictions(true);

    return getGeocoder()
      .getPlacePredictions(search, citiesOnly)
      .then(results => {
        setFetchingPredictions(false);
        onChange({
          search: results.search,
          predictions: results.predictions,
          selectedPlace: null,
          isUserLocation: false,
        });
      })
      .catch(e => {
        setFetchingPredictions(false);
        // eslint-disable-next-line no-console
        console.error(e);
        const value = currentValue(props);
        onChange({
          ...value,
          selectedPlace: null,
        });
      });
  };

  const predictDebounce = useCallback(debounce(predict, DEBOUNCE_WAIT_TIME, { leading: true }), []);

  const onInputChange = e => {
    const onChange = input.onChange;
    const predictions = currentPredictions();
    const newValue = e.target.value;

    onChange({
      search: newValue,
      predictions: newValue ? predictions : [],
      selectedPlace: null,
      isUserLocation: false,
    });

    setHighlightedIndex(-1);
    predictDebounce(newValue);
  };

  const changeHighlight = direction => {
    setHighlightedIndex(prevState => {
      const predictions = currentPredictions();
      const currentIndex = prevState;
      let index = currentIndex;

      if (direction === DIRECTION_UP) {
        index = currentIndex === 0 || currentIndex - 1;
      } else if (direction === DIRECTION_DOWN) {
        index = currentIndex + 1;
      }

      if (index < 0) {
        index = -1;
      } else if (index >= predictions.length) {
        index = predictions.length - 1;
      }

      return index;
    });
  };

  const selectPrediction = prediction => {
    input.onChange({
      ...input,
      selectedPlace: null,
    });

    setFetchingPlaceDetails(true);

    getGeocoder()
      .getPlaceDetails(prediction)
      .then(place => {
        if (!isMounted) return;

        setFetchingPlaceDetails(false);
        input.onChange({
          search: place.address,
          predictions: [],
          selectedPlace: place,
          isUserLocation: false,
        });
      })
      .catch(e => {
        setFetchingPlaceDetails(false);
        // eslint-disable-next-line no-console
        console.error(e);
        input.onChange({
          ...input.value,
          selectedPlace: null,
        });
      });
  };

  const selectItemIfNoneSelected = () => {
    if (fetchingPredictions) return;

    const predictions = currentPredictions();

    if (!selectedPlace) {
      if (predictions && predictions.length > 0) {
        const index = highlightedIndex !== -1 ? highlightedIndex : 0;
        selectPrediction(predictions[index]);
      } else {
        predictDebounce(searchParams);
      }
    }
  };

  const handleOnBlur = () => {
    if (!selectionInProgress) {
      setInputHasFocus(false);
      setHighlightedIndex(-1);
      setHighlightedSuggestionsIndex(-1);
    }
  };

  const handlePredictionsSelectStart = touchCoordinates => {
    setSelectionInProgress(true);
    setTouchStartedFrom(touchCoordinates);
    setIsSwipe(false);
  };

  const handlePredictionsSelectMove = touchCoordinates => {
    setIsSwipe(prevState => {
      const isTouchAction = !!touchStartedFrom;
      const isSwipeValue = isTouchAction
        ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
        : false;

      return isSwipeValue;
    });

    setSelectionInProgress(false);
  };

  const selectEnd = (selectItem, item) => {
    const selectAndFinalize = !isSwipe;
    setIsSwipe(false);
    setTouchStartedFrom(null);
    setSelectionInProgress(false);

    if (selectAndFinalize) {
      item && selectItem(item);
      onKeywordsFocus();
      ref.current.blur();
    }
  };

  const handleClearSelectEnd = () => {
    setIsSwipe(false);
    setTouchStartedFrom(null);
    setSelectionInProgress(false);
    setLatestSearches([]);
  };

  const handlePredictionsSelectEnd = prediction => {
    selectEnd(selectPrediction, prediction);
  };

  const handleSuggestionSelectEnd = suggestion => {
    selectEnd(handleSuggestionSelect, suggestion);
  };

  const successGetUserLocation = position => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setUserLocation({ latitude, longitude });
    setUserLocationIsLoading(false);
  };

  const errorGetUserLocation = error => {
    setUserLocationIsLoading(false);
    setErrorUserLocation(error);
    if (error.code === 1) {
      window.alert(intl.formatMessage({ id: 'AutocompleteLocation.geolocationTurnOff' }));
    } else {
      window.alert(intl.formatMessage({ id: 'AutocompleteLocation.unableToRetrieveGeolocation' }));
    }
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successGetUserLocation, errorGetUserLocation);
    } else {
      window.alert(intl.formatMessage({ id: 'AutocompleteLocation.browserNotSupportGeolocation' }));
    }
  };

  const handleUserLocationSelectEnd = () => {
    setUserLocationIsLoading(true);
    handleLocationClick();
  };

  const handleUserLocationSelect = userLocation => {
    const { latitude, longitude } = userLocation;
    const boundsValue = `${latitude + NUMBER_OF_DEGREES_FROM_ORIGIN},${longitude +
      NUMBER_OF_DEGREES_FROM_ORIGIN},${latitude - NUMBER_OF_DEGREES_FROM_ORIGIN},${longitude -
      NUMBER_OF_DEGREES_FROM_ORIGIN}`;

    input.onChange({
      search: intl.formatMessage({ id: 'AutocompleteLocation.yourLocation' }),
      predictions: [],
      selectedPlace: {
        address: intl.formatMessage({ id: 'AutocompleteLocation.yourLocation' }),
        origin: decodeLatLng(`${latitude},${longitude}`),
        bounds: decodeLatLngBounds(boundsValue),
      },
      isUserLocation: true,
    });
  };

  const handleOnFocus = e => {
    setInputHasFocus(true);
    onFocus(e);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    userLocation && selectEnd(handleUserLocationSelect, userLocation);
  }, [userLocation]);

  useEffect(() => {
    errorUserLocation && selectEnd(() => {}, null);
    setInputHasFocus(false);
  }, [errorUserLocation]);

  const predictions = currentPredictions();
  const geocoder = getGeocoder();
  const isFocus =
    inputHasFocus && ((input.value.search && predictions?.length) || !input.value.search);
  const inputClass = classNames(
    inputClassName || css.input,
    { [validClassName]: isValid },
    { [inputFocusClassName]: isFocus }
  );

  return (
    <div className={rootClass}>
      <div className={iconClass}>
        {fetchingPlaceDetails ? <IconSpinner className={css.iconSpinner} /> : <IconHourGlass />}
      </div>
      <input
        className={inputClass}
        type="search"
        autoComplete="off"
        autoFocus={autoFocus}
        placeholder={placeholder}
        name={name}
        value={searchParams}
        disabled={fetchingPlaceDetails}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={onInputChange}
        onKeyDown={input.value.search ? onKeyDown : handleSuggestionsKeyDown}
        ref={ref}
        id="locationInput"
      />
      {inputHasFocus &&
        (input.value.search ? (
          <LocationPredictionsList
            className={predictionsClassName}
            attributionClassName={predictionsAttributionClassName}
            predictions={predictions}
            geocoder={geocoder}
            highlightedIndex={highlightedIndex}
            onSelectStart={handlePredictionsSelectStart}
            onSelectMove={handlePredictionsSelectMove}
            onSelectEnd={handlePredictionsSelectEnd}
          />
        ) : (
          <LocationDefaultList
            className={predictionsClassName}
            highlightedIndex={highlightedSuggestionsIndex}
            latestSearches={uniqueLatestSearches}
            clearSelectEnd={handleClearSelectEnd}
            defaultCountryCities={defaultCountryCities}
            onSelectStart={handlePredictionsSelectStart}
            onSelectMove={handlePredictionsSelectMove}
            onSelectEnd={handleSuggestionSelectEnd}
            userLocationSelectEnd={handleUserLocationSelectEnd}
            userLocationIsLoading={userLocationIsLoading}
          />
        ))}
    </div>
  );
};

AutocompleteLocation.defaultProps = {
  autoFocus: false,
  rootClassName: null,
  className: null,
  iconClassName: null,
  inputClassName: null,
  predictionsClassName: null,
  predictionsAttributionClassName: null,
  validClassName: null,
  placeholder: '',
  useDefaultPredictions: true,
  meta: null,
  inputRef: null,
};

AutocompleteLocation.propTypes = {
  autoFocus: bool,
  rootClassName: string,
  className: string,
  iconClassName: string,
  inputClassName: string,
  predictionsClassName: string,
  predictionsAttributionClassName: string,
  validClassName: string,
  placeholder: string,
  useDefaultPredictions: bool,
  input: shape({
    name: string.isRequired,
    value: oneOfType([
      shape({
        search: string,
        predictions: any,
        selectedPlace: propTypes.place,
      }),
      string,
    ]),
    onChange: func.isRequired,
    onFocus: func.isRequired,
    onBlur: func.isRequired,
  }).isRequired,
  meta: shape({
    valid: bool.isRequired,
    touched: bool.isRequired,
  }),
  inputRef: func,
  intl: intlShape.isRequired,
};

export default forwardRef(AutocompleteLocation);
