import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { func, shape, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../../../../util/reactIntl';
import { propTypes } from '../../../../../util/types';
import DateRangeInput from '../../../../../components/FieldDateRangeInput/DateRangeInput';
import config from '../../../../../config';

import css from './DateFilter.css';

const checkValue = value => {
  const { startDate, endDate } = value;
  const isEmptyStartDate = !startDate && !!endDate;
  const isEmptyEndDate = !!startDate && !endDate;
  const errorClass = isEmptyStartDate ? css.startError : isEmptyEndDate ? css.endError : null;
  return { isEmptyStartDate, isEmptyEndDate, errorClass };
};

const dateFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};

const DateFilter = ({ intl, value, onChange, unitType, onBlur, name, setErrorDates }) => {
  const [error, setError] = useState(checkValue(value));
  const bookingStartLabel = intl.formatMessage({
    id: 'BookingDatesForm.bookingStartTitle',
  });
  const bookingEndLabel = intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' });
  const startDateErrorMessage = intl.formatMessage({
    id: 'FieldDateRangeInput.invalidStartDate',
  });
  const endDateErrorMessage = intl.formatMessage({
    id: 'FieldDateRangeInput.invalidEndDate',
  });

  const nowDay = moment();
  const today = nowDay.startOf('day').toDate();
  const tomorrow = nowDay
    .startOf('day')
    .add(1, 'days')
    .toDate();
  const startDatePlaceholderText = intl.formatDate(today, dateFormatOptions);
  const endDatePlaceholderText = intl.formatDate(tomorrow, dateFormatOptions);

  useEffect(() => {
    setError(checkValue(value));
  }, [value]);

  useEffect(() => {
    const errorObject = error.isEmptyStartDate
      ? { message: startDateErrorMessage }
      : error.isEmptyEndDate
      ? { message: endDateErrorMessage }
      : null;
    setErrorDates(errorObject);
  }, [error]);

  return (
    <div className={css.container}>
      <div className={css.labels}>
        <label className={css.label} htmlFor={`FilterModal.bookingStartDate`}>
          {bookingStartLabel}
        </label>
        <label className={css.label} htmlFor={`FilterModal.bookingEndDate`}>
          {bookingEndLabel}
        </label>
      </div>
      <DateRangeInput
        className={classNames(css.dateFilters, error.errorClass)}
        unitType={unitType}
        startDateId={`FilterModal.bookingStartDate`}
        startDatePlaceholderText={startDatePlaceholderText}
        endDateId={`FilterModal.bookingEndDate`}
        endDatePlaceholderText={endDatePlaceholderText}
        name={name}
        intl={intl}
        useMobileMargins
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={() => {}}
      />
      <div className={css.errorContainer}>
        <p className={css.error}>{error.isEmptyStartDate && startDateErrorMessage}</p>
        <p className={css.error}>{error.isEmptyEndDate && endDateErrorMessage}</p>
      </div>
    </div>
  );
};

DateFilter.defaultProps = {
  unitType: config.bookingUnitType,
};

DateFilter.propTypes = {
  intl: intlShape.isRequired,
  unitType: propTypes.bookingUnitType,
  value: shape({
    startDate: string || null,
    endDate: string || null,
  }).isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  name: string.isRequired,
  setErrorDates: func.isRequired,
};

export default DateFilter;
