import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingCheckoutFieldsForm } from '../../forms';

import css from './EditListingFeaturesPanel.css';

const EditListingCustomFormPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData: { checkoutFields } } = currentListing.attributes;

  const initialValues = checkoutFields ? {
    fields: checkoutFields.fields.map(value => {
      const initValue = { ...value };
      
      if(initValue.options) {
        const options = { main: initValue.options.slice(0, 2) };
        if(initValue.options.length > 2) options.add = initValue.options.slice(2);
        initValue.options = options;
      }

      initValue.required = initValue.required && ["tak"];

      return initValue;
    })
   } : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );
  
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingCheckoutFieldsForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={values => {
          const updateValues = values && values.fields ? {
            fields: values.fields.map((field, i) => {
            const { name = `field${Date.now()}${i}` } = field;

            const updateField = {
              ...field,
              name,
              required: true
            };

            if(updateField.options) {
              const optionsMain = Object.values(updateField.options.main);
              const optionsAdd = updateField.options && updateField.options.add ? Object.values(updateField.options.add) : null;
              updateField.options = optionsAdd ? optionsMain.concat(optionsAdd) : optionsMain;
            }

            return updateField;
          })} : null

          const submitValues = {
            publicData: {
              checkoutFields: updateValues
            },
          };
          onSubmit(submitValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCustomFormPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCustomFormPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCustomFormPanel;