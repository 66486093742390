import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_PERMISSIONS_REQUEST = 'app/TeamPage/FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'app/TeamPage/FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_ERROR = 'app/TeamPage/FETCH_PERMISSIONS_ERROR';

export const DELETE_PERMISSIONS_REQUEST = 'app/TeamPage/DELETE_PERMISSIONS_REQUEST';
export const DELETE_PERMISSIONS_SUCCESS = 'app/TeamPage/DELETE_PERMISSIONS_SUCCESS';
export const DELETE_PERMISSIONS_ERROR = 'app/TeamPage/DELETE_PERMISSIONS_ERROR';

export const CREATE_PERMISSIONS_REQUEST = 'app/TeamPage/CREATE_PERMISSIONS_REQUEST';
export const CREATE_PERMISSIONS_SUCCESS = 'app/TeamPage/CREATE_PERMISSIONS_SUCCESS';
export const CREATE_PERMISSIONS_ERROR = 'app/TeamPage/CREATE_PERMISSIONS_ERROR';

export const UPDATE_PERMISSIONS_REQUEST = 'app/TeamPage/UPDATE_PERMISSIONS_REQUEST';
export const UPDATE_PERMISSIONS_SUCCESS = 'app/TeamPage/UPDATE_PERMISSIONS_SUCCESS';
export const UPDATE_PERMISSIONS_ERROR = 'app/TeamPage/UPDATE_PERMISSIONS_ERROR';
export const UPDATE_PERMISSIONS_CLEAR = 'app/TeamPage/UPDATE_PERMISSIONS_CLEAR'; 

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchPermissionsError: null,
  permissions: [],
  deleteInProgress: false,
  deletePermissionsError: null,
  deletePermissionsSuccess: false,
  createInProgress: false,
  createPermissionsError: null,
  createPermissionsSuccess: false,
  updateInProgress: false,
  updatePermissionsError: null,
  updatePermissionsSuccess: false,
};

export default function teamUsersReducer(state = initialState, action = {}, data = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PERMISSIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchPermissionsError: null };
    case FETCH_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        permissions: payload.data.data,
      };
    }
    case FETCH_PERMISSIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPermissionsError: payload };

    case DELETE_PERMISSIONS_REQUEST:
      return {
        ...state,
        deleteInProgress: true,
        deletePermissionsError: null,
        deletePermissionsSuccess: false,
      };
    case DELETE_PERMISSIONS_SUCCESS: {
      const users = state.permissions.filter(user => user.id.uuid !== payload.id);
      return {
        ...state,
        deleteInProgress: false,
        deletePermissionsSuccess: true,
        permissions: [...users],
      };
    }
    case DELETE_PERMISSIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        deleteInProgress: false,
        deletePermissionsError: payload,
        deletePermissionsSuccess: false,
      };

    case CREATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        createInProgress: true,
        createPermissionsError: null,
        createPermissionsSuccess: false,
      };
    case CREATE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        createInProgress: false,
        createPermissionsSuccess: true,
        permissions: [...state.permissions, payload.data.data],
      };
    }
    case CREATE_PERMISSIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        createInProgress: false,
        createPermissionsError: payload,
        createPermissionsSuccess: false,
      };

    case UPDATE_PERMISSIONS_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updatePermissionsError: null,
        updatePermissionsSuccess: false,
      };
    case UPDATE_PERMISSIONS_SUCCESS: {
      const users = state.permissions.map(user =>
        user.id.uuid === payload.data.data.id.uuid ? payload.data.data : user
      );

      return {
        ...state,
        updateInProgress: false,
        updatePermissionsSuccess: true,
        permissions: users,
      };
    }
    case UPDATE_PERMISSIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateInProgress: false,
        updatePermissionsError: payload,
        updatePermissionsSuccess: false,
      };
    case UPDATE_PERMISSIONS_CLEAR: 
      return {
        ...state,
        updateInProgress: false,
        updatePermissionsError: null,
        updatePermissionsSuccess: false,
      }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchPermissionsRequest = () => ({ type: FETCH_PERMISSIONS_REQUEST });

const fetchPermissionsSuccess = response => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  payload: response,
});

const fetchPermissionsError = e => ({
  type: FETCH_PERMISSIONS_ERROR,
  error: true,
  payload: e,
});

const deletePermissionsRequest = () => ({ type: DELETE_PERMISSIONS_REQUEST });

const deletePermissionsSuccess = response => ({
  type: DELETE_PERMISSIONS_SUCCESS,
  payload: response,
});

const deletePermissionsError = e => ({
  type: DELETE_PERMISSIONS_ERROR,
  error: true,
  payload: e,
});

const createPermissionsRequest = () => ({ type: CREATE_PERMISSIONS_REQUEST });

const createPermissionsSuccess = response => ({
  type: CREATE_PERMISSIONS_SUCCESS,
  payload: response,
});

const createPermissionsError = e => ({
  type: CREATE_PERMISSIONS_ERROR,
  error: true,
  payload: e,
});

const updatePermissionsRequest = () => ({ type: UPDATE_PERMISSIONS_REQUEST });

const updatePermissionsSuccess = response => ({
  type: UPDATE_PERMISSIONS_SUCCESS,
  payload: response,
});

const updatePermissionsError = e => ({
  type: UPDATE_PERMISSIONS_ERROR,
  error: true,
  payload: e,
});

const updatePermissionsClear = () => ({ type: UPDATE_PERMISSIONS_CLEAR });

// ================ Thunks ================ //
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { providerId } = params;

  const queryParams = {
    providerId,
    include: ['user'],
  };

  dispatch(fetchPermissionsRequest());

  return sdk.newSdk.permissions
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchPermissionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchPermissionsError(e));
      throw e;
    });
};

export const deletePermissions = data => (dispatch, getState, sdk) => {
  dispatch(deletePermissionsRequest());

  return sdk.newSdk.permissions
    .delete(data)
    .then(response => {
      dispatch(
        deletePermissionsSuccess({
          id: data.id,
        })
      );
      return response;
    })
    .catch(e => {
      dispatch(deletePermissionsError(e));
      throw e;
    });
};

export const createPermissions = data => (dispatch, getState, sdk) => {
  dispatch(createPermissionsRequest());

  return sdk.newSdk.permissions
    .create(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(createPermissionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(createPermissionsError(e));
      throw e;
    });
};

export const updatePermissions = data => (dispatch, getState, sdk) => {
  dispatch(updatePermissionsRequest());

  return sdk.newSdk.permissions
    .update(data)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updatePermissionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(updatePermissionsError(e));
      throw e;
    });
};

export const clearUpdatePermissions = () => (dispatch, getState, sdk) => {
  dispatch(updatePermissionsClear());
}
