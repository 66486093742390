import React, { useState } from 'react';
import classNames from 'classnames';

import { parse } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, IconArrowHead,
} from '../../components';
import { StaticPage, TopbarContainer } from '../../containers';

import css from './FAQPage.css';

const content = [
  { id: '0', question: 'FAQPage.q1', answer: 'FAQPage.a1' },
  { id: '1', question: 'FAQPage.q2', answer: 'FAQPage.a2' },
  { id: '2', question: 'FAQPage.q3', answer: 'FAQPage.a3' },
  { id: '3', question: 'FAQPage.q4', answer: 'FAQPage.a4' },
  { id: '4', question: 'FAQPage.q5', answer: 'FAQPage.a5' },
  { id: '5', question: 'FAQPage.q6', answer: 'FAQPage.a6' },
  { id: '6', question: 'FAQPage.q7', answer: 'FAQPage.a7' },
  { id: '7', question: 'FAQPage.q8', answer: 'FAQPage.a8' },
  { id: '8', question: 'FAQPage.q9', answer: 'FAQPage.a9' },
  { id: '9', question: 'FAQPage.q10', answer: 'FAQPage.a10' },
  { id: '10', question: 'FAQPage.q11', answer: 'FAQPage.a11' },
  { id: '11', question: 'FAQPage.q12', answer: 'FAQPage.a12' },
  { id: '12', question: 'FAQPage.q13', answer: 'FAQPage.a13' },
  { id: '13', question: 'FAQPage.q14', answer: 'FAQPage.a14' },
  { id: '14', question: 'FAQPage.q15', answer: 'FAQPage.a15' },
  { id: 'q10', question: 'FAQPage.productOrderTitle', answer: 'FAQPage.productOrder' }
];

const FAQPage = props => {
  const [opened, setOpened] = useState(parse(props.location.search).q || 0);
  const { intl } = props;
  // prettier-ignore

  const isOpenNewClass = (id) => opened === id ? css.questionWrapper : classNames(css.questionWrapper, css.notOpen);
  return (
    <StaticPage
      title={intl.formatMessage({ id: 'FAQPage.pageTitle' })}
      description={intl.formatMessage({ id: 'FAQPage.pageDesc' })}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.mainWrapper}>
            <h1 className={css.mainHeader}>
              <FormattedMessage id="FAQPage.title" />
            </h1>
            {content.map(({ id, question, answer }) => (
              <div className={isOpenNewClass(id)} id={id}>
                <div className={css.questionContainer} onClick={() => setOpened(id)}>
                  <h2>
                    <FormattedMessage id={question} />
                  </h2>
                  <IconArrowHead direction={'up'} className={classNames(css.icon,opened === id ? css.reversed : '')} />
                </div>
                {opened === id && (
                  <div>
                    <p className={css.answer}>
                      <FormattedMessage id={answer} values={{ br: < br/> }} />
                    </p>
                  </div>
                )}
              </div>
            ))}
          </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default injectIntl(FAQPage);
