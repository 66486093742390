import React, { Fragment } from 'react';
import classNames from 'classnames';
import { object, string, arrayOf, func } from 'prop-types';

import css from './Table.css';

const Table = ({ data, config, keyFn, rootClassName, className, classNameTable, classNameHeader, classNameRow, classNameCell }) => {
  const rootClasses = rootClassName || css.rootClasses;
  const classes = classNames(rootClasses, className);
  const tableClasses = classNames(classNameTable, css.resultsTable);
  const headerClasses = classNames(classNameHeader, css.tableHeader);
  const rowClasses = classNames(classNameRow, css.row);
  const cellClasses = classNames(classNameCell, css.td);

  const renderedHeaders = config.map(column => {
    if (column.header) {
      return <Fragment key={column.label}>{column.header()}</Fragment>;
    }

    return <th key={column.label}>{column.label}</th>;
  });

  const renderedRows = data.map(rowData => {
    const renderedCells = config.map(column => {
      return (
        <td key={column.label} className={cellClasses} data-label={column.label}>
          {column.render(rowData)}
        </td>
      );
    });

    return (
      <tr key={keyFn(rowData)} className={rowClasses}>
        {renderedCells}
      </tr>
    );
  });

  return (
    <div className={classes}>
      <table className={tableClasses}>
        <thead className={css.resultHead}>
          <tr className={headerClasses}>{renderedHeaders}</tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {
  className: null,
  rootClassName: null,
  classNameTable: null,
  classNameHeader: null, 
  classNameRow: null, 
  classNameCell: null,
};


Table.propTypes = {
  className: string,
  rootClassName: string,
  classNameTable: string,
  classNameHeader: string, 
  classNameRow: string, 
  classNameCell: string,
  config: arrayOf(object).isRequired,
  data: arrayOf(object).isRequired,
  keyFn: func.isRequired,
};

export default Table;
