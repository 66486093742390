import moment from 'moment';
import memoize from 'lodash/memoize';
import { isSameDay, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';

import { ensureTimeSlot } from '../../util/data';
import { START_DATE, END_DATE, dateFromAPIToLocalNoon } from '../../util/dates';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, TIME_SLOT_DAY } from '../../util/types';
import config from '../../config';

// Days that are blocked
const BLOCKED_DAYS = [
  { date: '25', month: '12' },
  { date: '26', month: '12' },
  { date: '06', month: '01' },
  { date: '11', month: '11' },
  { date: '01', month: '11' },
  { date: '01', month: '01' },
  { date: '01', month: '05' },
  { date: '03', month: '05' },
  { date: '03', month: '06' },
  { weekday: '6' }
];

// Days that are exceptions from blocking rg. trading Sundays
const NOT_BLOCKED_DAYS = [
  { date: '12', month: '12' },
  { date: '19', month: '12' },
  { date: '02', month: '01' },
  { date: '09', month: '01' },
  { date: '16', month: '01' },
  { date: '23', month: '01' },
  { date: '30', month: '01' }
];

// Checks if time slot (propTypes.timeSlot) start time equals a day (moment)
const timeSlotEqualsDay = (timeSlot, day) => {
  if (ensureTimeSlot(timeSlot).attributes.type === TIME_SLOT_DAY) {
    // Time slots describe available dates by providing a start and
    // an end date which is the following day. In the single date picker
    // the start date is used to represent available dates.
    const localStartDate = dateFromAPIToLocalNoon(timeSlot.attributes.start);
    return isSameDay(day, moment(localStartDate)) && timeSlot.attributes.seats && timeSlot.attributes.seats > 0;
  } else {
    return false;
  }
};

/**
 * Return a boolean indicating if given date can be found in an array
 * of tile slots (start dates).
 */
const timeSlotsContain = (timeSlots, date) => {
  const timeSlotIndex = timeSlots.findIndex(slot => timeSlotEqualsDay(slot, date))
  return timeSlotIndex > -1 && timeSlots[timeSlotIndex].attributes.seats > 0;
};

/**
 * Find first blocked date between two dates.
 * If none is found, null is returned.
 *
 * @param {Array} timeSlots propTypes.timeSlot objects
 * @param {Moment} startDate start date, exclusive
 * @param {Moment} endDate end date, exclusive
 */
const firstBlockedBetween = (timeSlots, startDate, endDate) => {
  const firstDate = moment(startDate).add(1, 'days');
  if (firstDate.isSame(endDate, 'date')) {
    return null;
  }

  return timeSlotsContain(timeSlots, firstDate)
    ? firstBlockedBetween(timeSlots, firstDate, endDate)
    : firstDate;
};

/**
 * Find last blocked date between two dates.
 * If none is found, null is returned.
 *
 * @param {Array} timeSlots propTypes.timeSlot objects
 * @param {Moment} startDate start date, exclusive
 * @param {Moment} endDate end date, exclusive
 */
const lastBlockedBetween = (timeSlots, startDate, endDate) => {
  const previousDate = moment(endDate).subtract(1, 'days');
  if (previousDate.isSame(startDate, 'date')) {
    return null;
  }

  return timeSlotsContain(timeSlots, previousDate)
    ? lastBlockedBetween(timeSlots, startDate, previousDate)
    : previousDate;
};

/**
 * Check if a blocked date can be found between two dates.
 *
 * @param {Array} timeSlots propTypes.timeSlot objects
 * @param {Moment} startDate start date, exclusive
 * @param {Moment} endDate end date, exclusive
 */
export const isBlockedBetween = (timeSlots, startDate, endDate) =>
  !!firstBlockedBetween(timeSlots, startDate, endDate);

export const isStartDateSelected = (timeSlots, startDate, endDate, focusedInput) =>
  timeSlots && startDate && (!endDate || focusedInput === END_DATE) && focusedInput !== START_DATE;

export const isSelectingEndDateNightly = (timeSlots, startDate, endDate, focusedInput, unitType) =>
  timeSlots && !startDate && !endDate && focusedInput === END_DATE && unitType === LINE_ITEM_NIGHT;

export const apiEndDateToPickerDate = (unitType, endDate) => {
  const isValid = endDate instanceof Date;
  const isDaily = unitType === LINE_ITEM_DAY;

  if (!isValid) {
    return null;
  } else if (isDaily) {
    // API end dates are exlusive, so we need to shift them with daily
    // booking.
    return moment(endDate).subtract(1, 'days');
  } else {
    return moment(endDate);
  }
};

export const pickerEndDateToApiDate = (unitType, endDate) => {
  const isValid = endDate instanceof moment;
  const isDaily = unitType === LINE_ITEM_DAY;

  if (!isValid) {
    return null;
  } else if (isDaily) {
    // API end dates are exlusive, so we need to shift them with daily
    // booking.
    return endDate.add(1, 'days').toDate();
  } else {
    return endDate.toDate();
  }
};

/**
 * Returns an isDayBlocked function that can be passed to
 * a react-dates DateRangePicker component.
 */
const blockDaysFn = (day, blockedDays = BLOCKED_DAYS, notBlockedDays = NOT_BLOCKED_DAYS, timeSlots) => {
  if (timeSlots) {
    const daySlot = timeSlots.find(slot => timeSlotEqualsDay(slot, day));
    return !daySlot || !daySlot.attributes.opened;
  }

  return false;

  const statusBlocked = blockedDays.some(dayObj => {
    const { date, month, year, weekday } = dayObj;

    if (timeSlots) {
      const daySlot = timeSlots.find(slot => timeSlotEqualsDay(slot, day));
      return !daySlot || !daySlot.attributes.opened;
    }
    
    if(date && month && year) return date == moment(day).date() && month == moment(day).month() + 1 && year == moment(day).year();

    if(date && month) return date == moment(day).date() && month == moment(day).month() + 1;

    if(weekday) return weekday == moment(day).weekday();
    
    return date == moment(day).date();
  });

  const statusNotBlocked = notBlockedDays.some(dayObj => {
    const { date, month, year, weekday } = dayObj;
    
    if(date && month && year) return date == moment(day).date() && month == moment(day).month() + 1 && year == moment(day).year();

    if(date && month) return date == moment(day).date() && month == moment(day).month() + 1;

    if(weekday) return weekday == moment(day).weekday();
    
    return date == moment(day).date();
  });

  return statusBlocked && !statusNotBlocked;
}

const blockDays = memoize(blockDaysFn, (...args) => {
  return `${args[0].format('YYYY-MM-DD')}${JSON.stringify(args[1])}${JSON.stringify(args[2])}`;
});

const blockFollowingServiceDays = (timeSlots, day, serviceDaysCount) => {
  const lastAndNextServiceDayBlocked = !timeSlotsContain(timeSlots, moment(day).add(-1, 'days')) || !timeSlotsContain(timeSlots, moment(day).add(1, 'days'));
  if (!serviceDaysCount || serviceDaysCount === 1) {
    return lastAndNextServiceDayBlocked;
  }
  const moreServiceDays = [...Array(serviceDaysCount - 1).keys()].map(x => {
    return !timeSlotsContain(timeSlots, moment(day).add(-x - 2, 'days')) || !timeSlotsContain(timeSlots, moment(day).add(x + 2, 'days'))
  }).includes(true);

  return lastAndNextServiceDayBlocked || moreServiceDays;
}

const blockServiceDays = (timeSlots, day, bookingConditions) => {
  // get store booking conditions
  const { todayAvailable = true, tomorrowAvailableUntil = null, blockedDays, notBlockedDays, serviceDaysCount } = bookingConditions || {};
  
  // check if today is available for booking
  const blockToday = !todayAvailable && moment(day).isSame(new Date(), 'days');

  // check if tomorrow is available for booking
  const blockDayAfter = !todayAvailable && tomorrowAvailableUntil && moment(day).add(-1, 'days').isSame(new Date(), 'days') && moment().hour() >= tomorrowAvailableUntil;
  
  const maxNumbersOffInRow = 4;
  
  const maxdaysOffNextArray = Array.from({length: maxNumbersOffInRow}, (e,i)=>i+1);
  const statusArrayNext = maxdaysOffNextArray.map(num => {
    const result = maxdaysOffNextArray.slice(0, num).reduce((acc, cur) => acc && blockDays(moment(day).add(cur, 'days'), blockedDays, notBlockedDays), true);

    return result && !timeSlotsContain(timeSlots, moment(day).add(num + 1, 'days'));
  })

  const maxdaysOffPrevArray = Array.from({length: maxNumbersOffInRow}, (e,i)=>(i+1)*-1);
  const statusArrayPrev = maxdaysOffPrevArray.map((num, i) => {
    const result = maxdaysOffPrevArray.slice(0, i+1).reduce((acc, cur) => acc && blockDays(moment(day).add(cur, 'days'), blockedDays, notBlockedDays), true);

    return result && !timeSlotsContain(timeSlots, moment(day).add(num - 1, 'days'));
  })

  const status = blockToday || blockDayAfter || blockFollowingServiceDays(timeSlots, day, serviceDaysCount) || statusArrayNext.indexOf(true) !== -1 || statusArrayPrev.indexOf(true) !== -1;
  // const status = !timeSlotsContain(timeSlots, moment(day).add(-1, 'days')) || !timeSlotsContain(timeSlots, moment(day).add(1, 'days')) || statusArrayNext.indexOf(true) !== -1 || statusArrayPrev.indexOf(true) !== -1;
  return status;
}

export const isDayBlockedFn = (timeSlots, startDate, endDate, focusedInput, unitType, isServiceDaysOn, bookingConditions) => {
  const endOfRange = config.dayCountAvailableForBooking - 1;
  const lastBookableDate = moment().add(endOfRange, 'days');
  // start date selected, end date missing
  const startDateSelected = isStartDateSelected(timeSlots, startDate, endDate, focusedInput);

  // find the next booking after a start date
  const nextBookingStarts = startDateSelected
    ? firstBlockedBetween(timeSlots, startDate, moment(lastBookableDate).add(1, 'days'))
    : null;

  // end date is focused but no dates are selected
  const selectingEndDate = isSelectingEndDateNightly(
    timeSlots,
    startDate,
    endDate,
    focusedInput,
    unitType
  );

  const { blockedDays, notBlockedDays } = bookingConditions || {};

  if (selectingEndDate) {
    // if end date is being selected first, block the day after a booked date
    // (as a booking can end on the day the following booking starts)
    return day =>
      !timeSlots.find(timeSlot => timeSlotEqualsDay(timeSlot, moment(day).subtract(1, 'days'))) || blockDays(day, blockedDays, notBlockedDays, timeSlots) || (isServiceDaysOn && blockServiceDays(timeSlots, day, bookingConditions));
  } else if (nextBookingStarts || !timeSlots) {
    // a next booking is found or time slots are not provided
    // -> booking range handles blocking dates
    return day => blockDays(day, blockedDays, notBlockedDays, timeSlots);
  } else {
    // otherwise return standard timeslots check
    // return day => false;
    return day => !timeSlots.find(timeSlot => timeSlotEqualsDay(timeSlot, day)) || blockDays(day, blockedDays, notBlockedDays, timeSlots);
  }
};

/**
 * Returns an isOutsideRange function that can be passed to
 * a react-dates DateRangePicker component.
 */
export const isOutsideRangeFn = (timeSlots, startDate, endDate, focusedInput, unitType) => {
  const endOfRange = config.dayCountAvailableForBooking - 1;
  const lastBookableDate = moment().add(endOfRange, 'days');

  // start date selected, end date missing
  const startDateSelected = isStartDateSelected(timeSlots, startDate, endDate, focusedInput);
  const nextBookingStarts = startDateSelected
    ? firstBlockedBetween(timeSlots, startDate, moment(lastBookableDate).add(1, 'days'))
    : null;

  if (nextBookingStarts) {
    // end the range so that the booking can end at latest on
    // nightly booking: the day the next booking starts
    // daily booking: the day before the next booking starts
    return day => {
      const lastDayToEndBooking = apiEndDateToPickerDate(unitType, nextBookingStarts.toDate());

      return (
        !isInclusivelyAfterDay(day, startDate) || !isInclusivelyBeforeDay(day, lastDayToEndBooking)
      );
    };
  }

  // end date selected, start date missing
  // -> limit the earliest start date for the booking so that it
  // needs to be after the previous booked date
  const endDateSelected = timeSlots && endDate && !startDate && focusedInput !== END_DATE;
  const previousBookedDate = endDateSelected
    ? lastBlockedBetween(timeSlots, moment(), endDate)
    : null;

  if (previousBookedDate) {
    return day => {
      const firstDayToStartBooking = moment(previousBookedDate).add(1, 'days');
      return (
        !isInclusivelyAfterDay(day, firstDayToStartBooking) ||
        !isInclusivelyBeforeDay(day, lastBookableDate)
      );
    };
  }

  // standard isOutsideRange function
  return day => {
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };
};
