import React, { useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { parseInt } from 'lodash';

import { Button, CustomDatePicker } from '../../index';
import { propTypes } from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';

import css from './CreateOpeningException.css';

const CreateOpeningException = ({
    containerClassName,
    calendarClassName,
    intl,
    providerId,
    openingExceptions,
    openingPattern,
    addOpeningException,
    createOpeningExceptionsInProgress,
    createOpeningExceptionsError,
}) => {
  const containerClasses = classNames(css.container, containerClassName)
  const [newException, setNewException] = useState(null);
  const openingPatternArray = openingPattern.split('');
  const calendarClasses = classNames(css.calendar, {
    [css.sunday]: !parseInt(openingPatternArray[0]),
    [css.monday]: !parseInt(openingPatternArray[1]),
    [css.tuesday]: !parseInt(openingPatternArray[2]),
    [css.wednesday]: !parseInt(openingPatternArray[3]),
    [css.thursday]: !parseInt(openingPatternArray[4]),
    [css.friday]: !parseInt(openingPatternArray[5]),
    [css.saturday]: !parseInt(openingPatternArray[6]),
  },
    calendarClassName);

  const openedExceptions = openingExceptions.filter(item => item.attributes?.opened);
  const closedExceptions = openingExceptions.filter(item => !item.attributes?.opened);

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1 react-datepicker__day--excluded react-datepicker__day--disabled": [
        ...openedExceptions.map(item => new Date(item.attributes?.date))
      ],
    },
    {
      "react-datepicker__day--highlighted-custom-2 react-datepicker__day--excluded react-datepicker__day--disabled": [
        ...closedExceptions.map(item => new Date(item.attributes?.date))
      ],
    },
  ];

  const handleAddExceptionClick = (event) => {
    event.preventDefault();
    newException && addOpeningException({
      providerId,
      date: moment(newException).format('YYYY-MM-DD'),
      opened: !parseInt(openingPatternArray[moment(newException).day()])
    });
  }

  return (
    <div className={containerClasses}>
      <CustomDatePicker
        showIcon
        selected={newException}
        onChange={setNewException}
        label={intl.formatMessage({ id: 'OpeningException.addOpeningExceptionLabel' })}
        inputFormat={'dd, D MMM YYYY'}
        highlightDates={highlightWithRanges}
        calendarClassName={calendarClasses}
        calendarStartDay={1}
        excludeDates={[
          ...openingExceptions.map(item => new Date(item.attributes?.date))
          ]}
        children={
          <div className={css.legendContainer}>
            <p className={css.legendTitle}>Wyjątki</p>
            <div className={css.legendItem}>
              <span className={css.legendOpenedColor} />
              <span className={css.legendText}>
                {intl.formatMessage({ id: 'OpeningException.opened' })}
              </span>
            </div>
            <div className={css.legendItem}>
              <span className={css.legendClosedColor} />
              <span className={css.legendText}>
                {intl.formatMessage({ id: 'OpeningException.closed' })}
              </span>
            </div>
          </div>
        }
      />
      {createOpeningExceptionsError &&
        <p className={classNames(css.error, css.mobile)}>
          {intl.formatMessage({ id: 'OpeningException.filedCreation' })}
        </p>}
      <Button
        className={css.btn}
        onClick={handleAddExceptionClick}
        disabled={!newException}
        inProgress={createOpeningExceptionsInProgress}
      >
        {intl.formatMessage({ id: 'OpeningException.add' })}
      </Button>
      {createOpeningExceptionsError &&
        <p className={classNames(css.error, css.desktop)}>
          {intl.formatMessage({ id: 'OpeningException.filedCreation' })}
        </p>
      }
    </div>
  )
}

CreateOpeningException.defaultProps = {
  containerClassName: null,
  calendarClassName: null,
}

CreateOpeningException.propTypes = {
  containerClassName: string,
  calendarClassName: string,
  openingExceptions: arrayOf(propTypes.openingException),
  intl: intlShape.isRequired,
  providerId: string.isRequired,
  openingPattern: string.isRequired,
  createOpeningExceptionsInProgress: bool,
  createOpeningExceptionsError: propTypes.error,
  addOpeningException: func.isRequired,
}

export default CreateOpeningException;
