import React, { useEffect } from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconClose, IconTick } from '../../../components';

import css from './CreatedCoupons.css';

const CreatedCoupons = ({ createdCoupons, intl, setIsOpenCreatedCoupons, countryCurrency, couponType }) => {
  const couponTypeName = couponType.find(item => item.value === createdCoupons[0]?.attributes.type).name;
  const currency = countryCurrency
    .find(item => item.countryId === createdCoupons[0]?.attributes.countryId ).currency;

  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [ ]);

  return (
    <div className={css.cardContainer}>
      <div className={css.heading}>
        <p className={css.cartTitle}>
          <IconTick className={css.iconTick} />
          <FormattedMessage id="Console.createdCoupons" />
        </p>
        <button className={css.closeBtn} onClick={() => setIsOpenCreatedCoupons(false)}>
          <IconClose />
        </button>
      </div>
      <div className={css.info}>
        {createdCoupons[0]?.attributes.discountAmount && (
          <p className={css.discount}>{createdCoupons[0]?.attributes.discountAmount / 100} {currency}</p>
        )}
        {createdCoupons[0]?.attributes.discountPercentage && (
          <p className={css.discount}>{createdCoupons[0]?.attributes.discountPercentage}%</p>
        )}
        <div>
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.expDate' })}:{' '}
            <span>
              {createdCoupons[0]?.attributes.exp ||
                intl.formatMessage({ id: 'Console.indefinitePeriodOfTime' })}
            </span>
          </p>
          <p className={css.text}>
            {intl.formatMessage({ id: couponTypeName })}
          </p>
        </div>
      </div>
      <ul className={css.list}>
        {createdCoupons?.map(coupon => {
          const { code } = coupon?.attributes;
          const { uuid } = coupon?.id;
          return (
            <li className={css.listItem} key={uuid}>
              <p className={css.code}>{code}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CreatedCoupons;
