import React from 'react';

import { required } from '../../util/validators';
import { FieldSelect } from '../../components';
import businessCategoryConfig from '../../business-category-config';

import css from './EditProductForm.css';

const BusinessCategorySelectFieldMaybe = props => {
  const { name, id, intl, category, markRequired } = props;
  const subcategories = businessCategoryConfig.filter(item => item.category === category);

  const businessCategoryLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.businessCategoryLabel',
  });
  const businessCategoryPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.businessCategoryPlaceholder',
  });
  const businessCategoryRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.businessCategoryRequired',
    })
  );

  return businessCategoryConfig ? (
    <FieldSelect
      className={category ? css.category : css.categoryDisabled}
      name={name}
      id={id}
      label={businessCategoryLabel}
      validate={businessCategoryRequired}
      disabled={!category}
      markRequired={markRequired}
    >
      <option disabled value="">
        {businessCategoryPlaceholder}
      </option>
      {subcategories &&
        subcategories.map(({ key, translation }) => (
          <option key={key} value={key}>
            {intl.formatMessage({ id: translation })}
          </option>
        ))}
    </FieldSelect>
  ) : null;
};

export default BusinessCategorySelectFieldMaybe;
