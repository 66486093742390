import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
} from '../../util/urlHelpers';
import { parse } from '../../util/urlHelpers';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Page } from '../../components';
import { EditItemForm } from '../../forms';
import { TopbarContainer } from '..';

import {
  requestCreateItem,
  requestUpdateItem,
  requestImageUpload,
  updateImageOrder,
  removeItemImage,
  loadData,
} from './EditItemPage.duck';

import css from './EditItemPage.css';

const { UUID } = sdkTypes;

export const EditItemPageComponent = props => {
  const {
    getOwnItem,
    getCatalogProducts,
    history,
    intl,
    onImageUpload,
    onRemoveItemImage,
    onManageDisableScrolling,
    onUpdateImageOrder,
    page,
    params,
    scrollingDisabled,
    onCreateItem,
    onUpdateItem,
    catalogProducts,
    location
  } = props;

  const { id, type } = params;
  const { productId } = parse(location.search);
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;
  const itemId = id && id !== '00000-0000-0000-0000' ? new UUID(id) : null;
  const currentListing = getOwnItem(itemId);

  const showForm = isNewURI || currentListing?.id;

  if (showForm) {
    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];

    const imageOrder = page.imageOrder || [];
    const unattachedImages = Object.keys(page.images).map(k => page.images[k]);

    const allImages = currentListingImages.concat(unattachedImages);
    const removedImageIds = page.removedImageIds || [];
    const images = allImages
      .filter(img => {
        return !removedImageIds.includes(img.id);
      })
      .sort((a, b) => {
        if (!a) return -1;
        return imageOrder.indexOf(a.id.uuid) - imageOrder.indexOf(b.id.uuid);
      })

    const pageTitle = isNewListingFlow
      ? intl.formatMessage({ id: 'EditItemPage.titleCreateItem' })
      : intl.formatMessage({ id: 'EditItemPage.titleEditItem' });

    const buttonMsg = isNewListingFlow
      ? intl.formatMessage({ id: 'EditItemPage.add' })
      : intl.formatMessage({ id: 'EditItemPage.update' });

    const { title, description, articleCode, externalLink } = currentListing?.attributes || {};
    const { updateItemInProgress, createItemInProgress } = page;

    // When user has update draft listing, he should be redirected 'edit''
    const redirectAfterDraftUpdate = (itemId, params, history) => {
      const currentPathParams = {
        ...params,
        type: 'edit',
        id: itemId,
      };
      const routes = routeConfiguration();

      // Replace current "new" path to "draft" path.
      // Browser's back button should lead to editing current draft instead of creating a new one.
      if (params.type === 'new') {
        const editURI = createResourceLocatorString('EditItemPage', routes, currentPathParams, {});
        history.replace(editURI);
      }
    };

    const { catalogProduct } = currentListing || {};

    const initialCatalogProduct = isNewListingFlow ? productId : catalogProduct?.id.uuid;

    return (
      <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.root}>
          <div className={css.headerWrapper}>
            <h1 className={css.title}>{pageTitle}</h1>
          </div>
          <EditItemForm
            className={css.form}
            initialValues={{ title, description, articleCode, externalLink, catalogProductId: initialCatalogProduct, images }}
            saveActionMsg={buttonMsg}
            images={images}
            onSubmit={values => {
              const { title, description, articleCode, externalLink, catalogProductId, images } = values;
              const updateValues = {
                title: title?.trim(),
                description: description || '',
                articleCode,
                externalLink,
                catalogProductId,
                imageIds: images.map(i => (typeof i.id === 'string' ? i.imageId.uuid : i.id.uuid))
              };
              if (currentListing?.id?.uuid && params.type === 'edit') {
                onUpdateItem({ id: currentListing.id.uuid, ...updateValues });
              } else {
                onCreateItem(updateValues)
                  .then(res => redirectAfterDraftUpdate(res.data.data.id.uuid, params, history));;
              }
            }}
            disabled={false}
            ready={false}
            updated={false}
            submitInProgress={updateItemInProgress || createItemInProgress}
            fetchErrors={null}
            catalogProducts={catalogProducts}
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveItemImage}
            isUpdate={!!currentListing?.id}
            keepDirtyOnReinitialize
            onUpdateImageOrder={onUpdateImageOrder}
          />
        </div>
      </Page>
    );
  } else {
    const loadingPageMsg = {
      id: 'EditItemPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled} />
    );
  }
};

EditItemPageComponent.defaultProps = {
  createStripeAccountError: null,
  fetchStripeAccountError: null,
  getAccountLinkError: null,
  getAccountLinkInProgress: null,
  stripeAccountFetched: null,
  currentUser: null,
  stripeAccount: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
};

const mapStateToProps = state => {
  const page = state.EditItemPage;
  const { currentUser } = state.user;

  const getOwnItem = id => {
    const items = getMarketplaceEntities(state, [{ id, type: 'catalog-item' }]);
    return items.length === 1 ? items[0] : null;
  };


  return {
    currentUser,
    fetchInProgress: false,
    getOwnItem,
    page,
    scrollingDisabled: isScrollingDisabled(state),
    catalogProducts: getMarketplaceEntities(state, page.catalogProducts)
  }
};

const mapDispatchToProps = dispatch => ({
  onUpdateItem: (values) => dispatch(requestUpdateItem(values)),
  onCreateItem: values => dispatch(requestCreateItem(values)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveItemImage: imageId => dispatch(removeItemImage(imageId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditItemPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(EditItemPageComponent));

EditItemPage.loadData = loadData;

export default EditItemPage;
