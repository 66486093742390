export default function(calculation) {
  const { name, productId, price, category, brand, days } = calculation;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'calculate_price',
    ecommerce: {
      currencyCode: 'PLN',
      add: {
        products: [{
          name,
          id: productId,
          price,
          brand,
          category,
          quantity: days
        }]
      }
    },
    value: price,
    items: [
      {
        id: productId, 
        google_business_vertical: 'retail'
      }
    ]
  });
}