import React, { useState } from 'react';
import cx from 'classnames';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Input, Textarea } from '../../components';
import Search from './search_v2.png';
import Check from './check.png';

import css from './NoResultsForm.css';

function productRequestEvent(request) {
  const { product, location } = request;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'productRequest',
    product,
    location,
  });
}

const NoResultsForm = ({ intl }) => {
  const [step, setStep] = useState(1);
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');

  const handleSubmit = () => {
    productRequestEvent({ product: description, location });
    setStep(3);
  };

  const btnProps = {
    1: { onClick: () => setStep(2), label: <FormattedMessage id="NoResultsForm.letUsKnow" /> },
    2: { onClick: () => handleSubmit(), label: <FormattedMessage id="NoResultsForm.send" /> },
  };

  return (
    <div className={css.noResultsWrapper}>
      <img src={step === 3 ? Check : Search} className={css.searchIcon} />
      <p className={css.noResultsHeader}>
        {step === 3 ? (
          <FormattedMessage id="NoResultsForm.thankYou" />
        ) : step === 2 ? (
          <FormattedMessage id="NoResultsForm.fillForm" />
        ) : (
          <FormattedMessage id="NoResultsForm.basicTitle" />
        )}
      </p>
      <p className={css.noResultsText}>
        {step === 3 ? (
          <FormattedMessage id="NoResultsForm.messageSent" />
        ) : step === 2 ? (
          <FormattedMessage id="NoResultsForm.letUsKnowWhatYouNeed" />
        ) : (
          <FormattedMessage id="NoResultsForm.changeCriteria" />
        )}
      </p>
      {step === 2 && (
        <>
        <Textarea
          containerClassName={ css.noResultsContainer}
          textareaClassName={css.noResultsTextArea}
          placeholder={intl.formatMessage({ id: 'NoResultsForm.what' })}
          rows="4"
          value={description}
          onChange={e => setDescription(e.target.value)}
          isExpanded={false}
        />
        <Input
           type="text"
           containerClassName={css.noResultsContainer}
           inputClassName={css.noResultsInput}
           placeholder={intl.formatMessage({ id: 'NoResultsForm.where' })}
           value={location}
           onChange={e => setLocation(e.target.value)}
        />
        </>
      )}
      {step !== 3 && (
        <button className={css.noResultsBtn} {...btnProps[step]}>
          {btnProps[step].label}
        </button>
      )}
    </div>
  );
};

export default injectIntl(NoResultsForm);
