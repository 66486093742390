import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const QUERY_FUTURE_TRANSACTIONS_REQUEST = 'app/PayoutFuturePageComponent/QUERY_FUTURE_TRANSACTIONS_REQUEST';
export const QUERY_FUTURE_TRANSACTIONS_SUCCESS = 'app/PayoutFuturePageComponent/QUERY_FUTURE_TRANSACTIONS_SUCCESS';
export const QUERY_FUTURE_TRANSACTIONS_ERROR = 'app/PayoutFuturePageComponent/QUERY_FUTURE_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  futureTransactions: [],
  queryFutureTransactionsInProgress: false,
  queryFutureTransactionsError: null,
  queryFutureTransactionsSuccess: false,
};

const futureTransactionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case QUERY_FUTURE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryFutureTransactionsInProgress: true,
        queryFutureTransactionsError: null,
        queryFutureTransactionsSuccess: false,
      };

    case QUERY_FUTURE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        queryFutureTransactionsSuccess: true,
        queryFutureTransactionsInProgress: false,
        queryFutureTransactionsError: false,
        futureTransactions: payload.data,
        pagination: payload.meta,
      };

    case QUERY_FUTURE_TRANSACTIONS_ERROR:
      return {
        ...state,
        queryFutureTransactionsInProgress: true,
        queryFutureTransactionsError: payload,
        queryFutureTransactionsSuccess: false,
      };

    default:
      return state;
  }
};

export default futureTransactionsPageReducer;

// ================ Action creators ================ //

export const queryFutureTransactionsRequest = () => ({
  type: QUERY_FUTURE_TRANSACTIONS_REQUEST,
});

export const queryFutureTransactionsSuccess = response => ({
  type: QUERY_FUTURE_TRANSACTIONS_SUCCESS,
  payload: response.data,
});

export const queryFutureTransactionsError = () => ({
  type: QUERY_FUTURE_TRANSACTIONS_ERROR,
});

// ================ Thunks ================ //

const TRANSACTIONS_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1 } = parse(search);
  const { id } = params;
  const paginationParams = {
    page,
    per_page: TRANSACTIONS_PAGE_SIZE,
  };

  dispatch(queryFutureTransactionsRequest());

  return sdk.newSdk.transactions
    .query({
      forFuturePayoutByProviderStripeAccountId: id,
      ...paginationParams
    })
    .then(response => {
      dispatch(queryFutureTransactionsSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(queryFutureTransactionsError(error));
      throw error;
    });
};
