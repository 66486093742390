import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { stringify } from '../../../util/urlHelpers';
import { IconArrowHead, InlineTextButton, NamedLink } from '../../../components';
import CouponEdit from './../CouponEdit/CouponEdit';

import css from './CouponItem.css';

const CouponItem = ({
  intl,
  coupon,
  updateCoupon,
  updateInProgress,
  updateCouponsError,
  updateCouponsSuccess,
  couponIdEdit,
  setCouponIdEdit,
  couponIdExpand,
  setCouponIdExpand,
  updateCouponsClearState,
  couponType,
  methodsOfSettlingCoupons,
  countryCurrency
}) => {
  const {
    id: { uuid },
    attributes: {
      code,
      type,
      exp,
      description,
      active,
      expense,
      isPaymentSource,
      countryId,
      usageCount,
      usable,
      start,
      end,
    }
  } = coupon;
  const typeMessage = couponType.find(({ value }) => value === type).name;
  const currency = countryCurrency.find(item => item.countryId === countryId ).currency;
  const methodOfSettlingCoupons = methodsOfSettlingCoupons
    .find((item) => (item.expense === expense && item.isPaymentSource === isPaymentSource))

  const handleEdit = (event, uuid) => {
    setCouponIdEdit(uuid);
  };

  const handleExpand = (event, uuid) => {
    setCouponIdExpand(prevState => (prevState === uuid ? null : uuid));
  };

  const usableMessage = usable ? 'Console.activeCoupon' : 'Console.couponInactive';
  const expDateClass = usable ? css.active : css.inactive;

  const showTransactionLink = (
    <NamedLink
      className={css.link}
      name="ConsoleTransactionPage"
      params={{ tab: 'transactions' }}
      to={{ search: stringify({ voucherCode: code }) }}
    >
      <FormattedMessage id="Console.transactionsTabTitle" />
    </NamedLink>
  );

  const showCouponDetails = couponIdExpand === uuid && (
    <div className={css.expandedContainer}>
      <div className={css.detailContainer}>
        <div>
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.expDate' })}:{' '}
            <span className={css.boldText}>
              {exp || intl.formatMessage({ id: 'Console.indefinitePeriodOfTime' })}
            </span>
          </p>
          {coupon?.partner?.attributes.name && <p className={css.text}>
            {intl.formatMessage({ id: 'Console.partner' })}:{' '}
            <span className={css.boldText}>{coupon.partner.attributes.name}</span>
          </p>}
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.numberOfUses' })}: {usageCount}
          </p>
        </div>
        <div>
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.dateStart' })}:{' '}
            {start ? intl.formatMessage({ id: start }) : ' -'}
          </p>
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.dateEnd' })}:{' '}
            {end ? intl.formatMessage({ id: end }) : ' -'}
          </p>
        </div>
        <div>
          {methodOfSettlingCoupons &&
            <p className={css.text}>
              {intl.formatMessage({ id: methodOfSettlingCoupons.message })}
            </p>
          }
        </div>
        {description ? (
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.description' })}:{' '}{description}
          </p>
        ) : null}
      </div>
      {showTransactionLink}
    </div>
  );

  return (
    <li className={css.listItem} key={uuid}>
      <div className={css.container}>
        <div className={css.itemNameDetails}>
          <div className={css.itemContainer}>
            <span className={classNames(css.text, css.code)}>{code} </span>
            {coupon.attributes?.discountAmount !== null && (
              <span
                className={css.discount}>{coupon.attributes.discountAmount / 100} {currency}</span>
            )}
            {coupon.attributes?.discountPercentage !== null && (
              <span className={css.discount}>{coupon.attributes.discountPercentage}%</span>
            )}
          </div>
          <div className={css.itemContainer}>
          {!active ? (
              <span className={css.bannedText}>
                {' '}
                <FormattedMessage id="Console.banned" />
              </span>
            ) :
            <p className={classNames(css.text, expDateClass)}>
              {intl.formatMessage({ id: usableMessage })}
            </p> }
            <p className={css.text}>
              <span className={css.upperCase}>{countryId}{' '}</span>
              <FormattedMessage id={typeMessage} />
            </p>
          </div>
        </div>
        <div className={css.actionContainer}>
          {couponIdEdit !== uuid && (
            <InlineTextButton className={css.button} onClick={event => handleEdit(event, uuid)}>
              <FormattedMessage id="Console.edit" />
            </InlineTextButton>
          )}
          {couponIdExpand !== uuid ? (
            <div className={css.arrow} onClick={event => handleExpand(event, uuid)}>
              <IconArrowHead direction="down" />
            </div>
          ) : (
            <div className={css.arrow} onClick={event => handleExpand(event, uuid)}>
              <IconArrowHead direction="up" />
            </div>
          )}
        </div>
      </div>
      {showCouponDetails}
      {couponIdEdit === uuid && (
        <CouponEdit
          updateCoupon={updateCoupon}
          updateInProgress={updateInProgress}
          updateCouponsError={updateCouponsError}
          updateCouponsSuccess={updateCouponsSuccess}
          setCouponIdEdit={setCouponIdEdit}
          couponId={uuid}
          couponType={type}
          intl={intl}
          updateCouponsClearState={updateCouponsClearState}
          active={active}
          expDate={exp}
          description={description}
          currency={currency}
          startDate={start}
          endDate={end}
        />
      )}
    </li>
  );
};

export default CouponItem;
