import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { loadData as loadCart } from './CartPage.duck';

import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { stringify } from '../../util/urlHelpers';
import { IconCheckmark, Input, Modal, PrimaryButton } from '../../components';

import css from './CartPage.css';
import { set } from 'lodash';

const AddToUserModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    cartListings,
    onAddCartToUser,
  } = props;
  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setEmail('');
  }, [isOpen]);

  const handleEmailChange = e => setEmail(e.target.value);

  const handleSubmit = async () => {
    setIsError(false);
    setIsSuccess(false);
    try {
      setInProgress(true);
      await onAddCartToUser(id, email);
      setInProgress(false);
      setIsSuccess(true);
    } catch (e) {
      setInProgress(false);
      setIsError(true);
    }
  }

  const handleCloseModal = () => {
    if (isSuccess) {
      dispatch(loadCart());
    }
    onCloseModal();
  }

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'AddToUserModal.later' });

  const emailValid = email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={handleCloseModal}
      onManageDisableScrolling={() => { }}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <div>
        <h2><FormattedMessage id="AddToUserModal.header" /></h2>
        {
          isSuccess ?
            <div className={css.success}>
              <IconCheckmark className={css.icon} />
              <FormattedMessage id="AddToUserModal.success"/>
            </div>
            :
            <>
              <div>
                {cartListings.map(listing => {
                  return (
                    <div key={listing.id.uuid} className={css.product}>
                      <img src={listing.listing.images[0]?.attributes.variants['square-small'].url} alt={listing.listing.attributes.title} />
                      <p>{listing.listing.attributes.title}</p>
                    </div>)
                })}
              </div>
              <div className={css.userField}>
                <Input
                  id="user"
                  label={intl.formatMessage({ id: 'AddToUserModal.emailLabel' })}
                  placeholder={intl.formatMessage({ id: 'AddToUserModal.emailPlaceholder' })}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              {isError && <div className={css.error}><FormattedMessage id="AddToUserModal.error"/></div>}
              <PrimaryButton
                type="button"
                inProgress={inProgress}
                disabled={!email || !emailValid}
                onClick={handleSubmit}
              >
                <FormattedMessage id="AddToUserModal.submit" />
              </PrimaryButton>
            </>
        }
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

AddToUserModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

AddToUserModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

export default injectIntl(AddToUserModal);
