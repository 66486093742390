import React, { useState, useEffect } from 'react';
import { string, bool, func } from 'prop-types';

import { IconEdit, IconSpinner, IconClose, Button, Input } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './ProviderItem.css';

const ProviderItem = ({
  providerId,
  name,
  banned,
  language,
  updateProvider,
  updateProviderInProgress,
  intl
}) => {
  const [providerName, setProviderName] = useState(name);
  const [isEdit, setIsEdit] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const MIN_LENGTH_NAME = 6;

  const handleEdit = () => {
    setIsEdit(prevValue => !prevValue);
  };

  const handleBanned = () => {
    setIsClicked(true);
    updateProvider({ id: providerId, banned: !banned });
  };

  const handleSubmit = event => {
    event.preventDefault();
    updateProvider({ id: providerId, name: providerName });
    setIsEdit(false);
  };

  const editForm = (
    <form onSubmit={handleSubmit} className={css.editForm}>
      <div>
        <Input
          id="providerName"
          name="providerName"
          placeholder={intl.formatMessage({ id: 'Console.name' })}
          type="text"
          className={css.inputName}
          value={providerName}
          onChange={event => {
            setProviderName(event.target.value);
          }}
          minlength={MIN_LENGTH_NAME}
          label={intl.formatMessage({ id: 'Console.name' })}
          labelClassName={css.labelName}
        />
      </div>
      <Button type="submit" className={css.submitButton}>
        <FormattedMessage id="Console.save" />
      </Button>
    </form>
  );

  const loading = isEdit ? (
    updateProviderInProgress ? (
      <div className={css.listItemsLoading}>
        <IconSpinner />
      </div>
    ) : (
      editForm
    )
  ) : null;

  useEffect(() => {
    !updateProviderInProgress && setIsClicked(false);
  }, [updateProviderInProgress]);

  return (
    <li className={css.listItem}>
      <div>
        <p className={css.item}>
          <span>{name}</span> <span className={css.language}>({language})</span>{' '}
          {banned && <span className={css.bannedText}> <FormattedMessage id="Console.banned" /></span>}
        </p>
        {loading}
      </div>
      <div className={css.actionContainer}>
        <button onClick={handleEdit} className={css.editButton}>
          {isEdit ? <IconClose /> : <IconEdit className={css.editIcon} />}
        </button>
        <Button onClick={handleBanned} className={css.bannedButton} inProgress={updateProviderInProgress && isClicked}>
          <FormattedMessage id={banned ? 'Console.unblock' : 'Console.block'} />
        </Button>
      </div>
    </li>
  );
};

ProviderItem.propTypes = {
  providerId: string.isRequired,
  name: string.isRequired,
  banned: bool.isRequired,
  language: string.isRequired,
  updateProvider: func.isRequired,
  updateProviderInProgress: bool.isRequired,
};

export default ProviderItem;
