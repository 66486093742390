import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { string, node, oneOf } from 'prop-types';

import IconArrowHead from '../IconArrowHead/IconArrowHead';

import css from './Accordion.css';

const Accordion = ({
  title,
  titleComponent,
  children,
  containerClassName,
  titleClassName,
  contentContainerClassName,
  titleContainerClassName,
  openClassName
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const containerClass = classNames(containerClassName, css.container, {[openClassName]: isOpened });
  const titleContainerClass = titleContainerClassName || css.titleContainer;
  const titleTextClass = titleClassName || css.title;
  const contentContainerClasses = classNames(contentContainerClassName, css.contentContainer);

  return (
    <div className={containerClass}>
      <div className={titleContainerClass} onClick={() => setIsOpened(prev => !prev)}>
        {titleComponent ||
          <p className={titleTextClass}>
            <FormattedMessage id={title} />
          </p>
        }
        <div>
          <IconArrowHead direction={isOpened ? 'up' : 'down'} />
        </div>
      </div>
      {isOpened && <div className={contentContainerClasses}>{children}</div>}
    </div>
  );
};

Accordion.defaultProps = {
  containerClassName: null,
  contentContainerClassName: null,
  titleClassName: null,
  titleContainerClassName: null,
  titleComponent: null,
  openClassName: null,
};

Accordion.propTypes = {
  title: string,
  containerClassName: string,
  contentContainerClassName: string,
  titleClassName: string,
  titleContainerClassName: string,
  children: node,
  titleComponent: oneOf([node,null]),
  openClassName: string,
};

export default Accordion;
