import React, { Component } from 'react';
import moment from 'moment';
import { START_DATE } from '../../util/dates';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import PropTypes from 'prop-types';
// import { DateRangeController } from './components';
import { IconArrowHead, OutsideClickHandler, Button, IconSpinner } from '../../components';
import {
  DayPickerRangeController,
  isInclusivelyBeforeDay
} from 'react-dates';
import classNames from 'classnames';

import css from './DatePicker.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }
  
  state = {
    startDate: this.props.value && this.props.value.startDate ? moment(this.props.value.startDate) : null,
    endDate: this.props.value && this.props.value.endDate ? moment(this.props.value.endDate) : null,
    prevStartDate: this.props.value && this.props.value.prevStartDate ? moment(this.props.value.prevStartDate) : null,
    prevEndDate: this.props.value && this.props.value.prevEndDate ? moment(this.props.value.prevEndDate) : null,
    focusedInput: START_DATE,
    isOpen: false,
    isComparisonPrevYear: false,
  }

  componentWillUnmount(){
    if(this.timer){
        clearTimeout(this.timer);
    }
}

  onFocusChange = (focusedInput) => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  onDatesChange = (values) => {
    const { startDate, endDate } = values;
    this.setState({ startDate, endDate });
  }

  onPrevDatesChange = (values) => {
    const { startDate, endDate } = values;
    this.setState({ prevStartDate: startDate, prevEndDate: endDate });
  }

  handleNewDateRangeSubmit = () => {
    const { startDate, endDate, prevStartDate, prevEndDate } = this.state;

    const start = startDate ? startDate.add(3, 'hours').toDate().toISOString().split('T')[0] : null;
    const end = endDate ? endDate.add(3, 'hours').toDate().toISOString().split('T')[0] : null;
    const prevStart = prevStartDate && prevEndDate ? prevStartDate.add(3, 'hours').toDate().toISOString().split('T')[0] : null;
    const prevEnd = prevStartDate && prevEndDate ? prevEndDate.add(3, 'hours').toDate().toISOString().split('T')[0] : null;
    this.props.onSubmit({
      start,
      end,
      prevStart,
      prevEnd
    })

    this.setState({ isOpen: false })
  }

  handleCloseDatePicker = () => {
    this.setState({
      isOpen: false,
      startDate: moment(this.props.value.startDate),
      endDate: moment(this.props.value.endDate),
      prevStartDate: moment(this.props.value.prevStartDate),
      prevEndDate: moment(this.props.value.prevEndDate),
    });
  }

  onClickDatePicker = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      startDate: moment(this.props.value.startDate),
      endDate: moment(this.props.value.endDate),
      prevStartDate: this.props.value.prevStartDate ? moment(this.props.value.prevStartDate) : null,
      prevEndDate: this.props.value.prevStartDate ? moment(this.props.value.prevEndDate) : null
    }))
  }

  handleComparePrevYearClick = (event) => {
    event.preventDefault();
    const prevYearStart = moment(this.state.startDate).subtract(1,'years'); 
    const prevYearEnd = moment(this.state.endDate).subtract(1,'years'); 
    this.setState({prevStartDate: prevYearStart, prevEndDate: prevYearEnd}); 
    this.setState({isComparisonPrevYear: true});
    this.timer = setTimeout(() => this.setState({isComparisonPrevYear: false}), 150); 
  }

  render() {
    const { startDate, endDate, prevStartDate, prevEndDate, isOpen, isComparisonPrevYear } = this.state;
    const { startDate: startFromProps, endDate: endFromProps } = this.props.value;
    const { intl } = this.props;

    return (
      <OutsideClickHandler onOutsideClick={this.handleCloseDatePicker}>
        <div className={css.dateRangePickerWrapper}>
          <div onClick={this.onClickDatePicker} className={css.dateRangePickerPlaceholder}>
            <span>
              {intl.formatDate(moment(startFromProps), {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              })}
            </span>
            <span className={css.datesSeparator}>-</span>
            <span>
              {intl.formatDate(moment(endFromProps), {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              })}
            </span>
            <IconArrowHead direction={isOpen ? 'up' : 'down'} className={css.arrow} />
          </div>
          {isOpen && (
            <div className={css.dateRangePicker}>
              <DayPickerRangeController
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.focusedInput}
                onFocusChange={this.onFocusChange}
                hideKeyboardShortcutsPanel
                daySize={28}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                noBorder
                minimumNights={0}
              />
              {this.props.showComparison && (
                <>
                  <h2 className={css.comparison}>
                    <FormattedMessage id="DatePicker.compare" />
                  </h2>
                  <div className={css.compareContainer}>
                    <p className={css.text}> <FormattedMessage id="DatePicker.setText" /></p>
                    <button
                      className={css.submitButton}
                      onClick={this.handleComparePrevYearClick}
                      type="button"
                    >
                       <FormattedMessage id="DatePicker.set" />
                    </button>
                  </div>
                  <div className={css.comparisonCalendar}>
                    {isComparisonPrevYear ? (
                      <div className={css.skeleton}>
                      </div>
                    ) : (
                      <DayPickerRangeController
                        startDate={prevStartDate}
                        endDate={prevEndDate}
                        onDatesChange={this.onPrevDatesChange}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={this.onFocusChange}
                        hideKeyboardShortcutsPanel
                        daySize={28}
                        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                        noBorder
                        minimumNights={0}
                      />
                    )}
                  </div>
                </>
              )}
              <div className={css.footer}>
                <button className={css.submitButton} onClick={this.handleNewDateRangeSubmit}>
                  <FormattedMessage id="DatePicker.submit" />
                </button>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default injectIntl(DatePicker);
