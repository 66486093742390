import React from 'react';
import classNames from 'classnames';
import { string, node, bool } from 'prop-types';

import css from './Select.css';

const Select = ({
  selectClassName,
  className,
  errorClassName,
  labelClassName,
  containerClassName,
  label,
  error,
  children,
  markRequired,
  isValidation,
  ...rest
}) => {
  const containerClasses = classNames(
    css.container,
    { [css.validation]: isValidation },
    containerClassName
  );
  const classes = classNames(selectClassName, css.select, className, {
    [css.selectError]: !!error,
  });
  const labelClasses = classNames(css.text, labelClassName);
  const errorClasses = classNames(css.error, errorClassName);

  return (
    <div className={containerClasses}>
      <label className={css.label}>
        {label && (
          <span className={labelClasses}>
            {label}
            {markRequired && '*'}
          </span>
        )}
        <select className={classes} {...rest} required>
          {children}
        </select>
      </label>
      {error && isValidation && <span className={errorClasses}>{error}</span>}
    </div>
  );
};

Select.defaultProps = {
  selectClassName: null,
  className: null,
  errorClassName: null,
  labelClassName: null,
  containerClassName: null,
  label: null,
  error: null,
  children: null,
  markRequired: false,
  isValidation: true,
};

Select.propTypes = {
  selectClassName: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  containerClassName: string,
  label: string,
  error: string,
  children: node,
  markRequired: bool,
  isValidation: bool,
};

export default Select;
