import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { stringify } from '../../util/urlHelpers';
import { Modal, NamedLink, ResponsiveImage, IconTick } from '..';
import ComplementaryProducts from './ComplementaryProducts/ComplementaryProducts';

import css from './CartModal.css';

const format = (intl, date) =>
  intl.formatDate(date, {
    month: 'short',
    day: 'numeric',
  });

const CartModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    listing,
    bookingDatesStart,
    bookingDatesEnd,
    amount,
    complementaryProducts,
    onAddToCart,
    rawParams,
    history,
    addToCartInProgress,
    cartListing,
    complementaryBusinessCategory,
    fetchListingInProgress
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'CartModal.later' });
  const { provider } = listing;
  const formatCountry = config.countryNames.find(
    ({ countryId }) => countryId === provider?.attributes?.countryId
  )?.country;
  const providerCountry = intl.formatMessage({ id: formatCountry || 'pl'}) ;
  const order = cartListing.find(item => {
    const {
      provider: {
        id: { uuid },
      },
      attributes: { start, end },
    } = item;
    return (
      start === bookingDatesStart && end === bookingDatesEnd && uuid === listing.provider.id.uuid
    );
  });

  useEffect(() => {
    onCloseModal();
  }, [rawParams]);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <IconTick className={css.iconTick} />
        <FormattedMessage id="CartModal.modalTitle" />
      </p>
      <div className={css.contentContainer}>
        <div className={css.container}>
          <div className={css.productSection}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={listing.images[0]}
              alt={'product'}
              variants={['square-small', 'square-small2x']}
            />
            <div className={css.productInfo}>
              <p className={css.text}>{listing.attributes.title}</p>
              <div className={css.details}>
                <p className={css.text}>
                  <FormattedMessage id="CartModal.quantity" /> <b>{amount}</b>
                </p>
                <p className={css.text}>
                  {' '}
                  <FormattedMessage id="CartModal.when" />{' '}
                  <b>
                    {format(intl, moment(bookingDatesStart))} -{' '}
                    {format(intl, moment(bookingDatesEnd).subtract(1, 'days'))}
                  </b>
                </p>
                <p className={css.text}>
                  <b>{formatMoney(intl, listing.attributes.price)}</b>/
                  <FormattedMessage id="CartModal.day" />
                </p>
              </div>
            </div>
          </div>
        </div>
        {!!complementaryProducts.length &&
          complementaryBusinessCategory &&
          !fetchListingInProgress && (
            <ComplementaryProducts
              listing={listing}
              intl={intl}
              start={bookingDatesStart}
              end={bookingDatesEnd}
              onAddToCart={onAddToCart}
              history={history}
              addToCartInProgress={addToCartInProgress}
              order={order}
              complementaryProducts={complementaryProducts}
            />
          )}
        <div className={css.buttonSection}>
          {provider?.attributes?.location && (
            <NamedLink
              name="SearchPage"
              to={{
                search: stringify({
                  providerId: provider.id.uuid,
                }),
                state: {
                  bounds: `${provider.attributes.location.lat + 0.0015},${provider.attributes
                    .location.lng + 0.0015},${provider.attributes.location.lat - 0.0015},${provider
                    .attributes.location.lng - 0.0015}`,
                },
              }}
              className={css.browseButton}
            >
              <FormattedMessage id="CartModal.browseButton" />
            </NamedLink>
          )}
          <NamedLink name="CartPage" className={css.cartButton}>
            <FormattedMessage id="CartModal.cartButton" />
          </NamedLink>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

CartModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

CartModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

export default injectIntl(CartModal);
