import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { string, bool } from 'prop-types';
import autosize from 'autosize';

import css from './Textarea.css';

const Textarea = ({
  textareaClassName,
  className,
  errorClassName,
  labelClassName,
  containerClassName,
  label,
  error,
  value,
  isExpanded,
  isValidation,
  markRequired,
  ...rest
}) => {
  const containerClasses = classNames(css.container, { [css.validation]: isValidation }, containerClassName);
  const classes = classNames(textareaClassName, css.textarea, className, {
    [css.textareaError]: !!error, [css.textareaExpanded]: isExpanded
  });
  const labelClasses = classNames(css.text, labelClassName);
  const errorClasses = classNames(css.error, errorClassName);
  const ref = useRef(null);

  const update = () => autosize.update(ref.current);

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      if (isExpanded) {
        autosize(ref.current);
        window.addEventListener('resize', update);
      }
    }, 100);

    return () => {
      window.clearTimeout(timeoutId);
      if (isExpanded) {
        autosize.destroy(ref.current);
        window.removeEventListener('resize', update);
      }

    };
  }, []);

  useEffect(() => {
    isExpanded && update();
  }, [value]);

  return (
    <div className={containerClasses}>
      <label className={css.label}>
        {label && <span className={labelClasses}>{label}{markRequired && '*'}</span>}
        <textarea className={classes} {...rest} ref={ref} value={value} />
      </label>
      {error && isValidation && <span className={errorClasses}>{error}</span>}
    </div>
  );
};

Textarea.defaultProps = {
  textareaClassName: null,
  className: null,
  errorClassName: null,
  labelClassName: null,
  containerClassName: null,
  label: null,
  error: null,
  isExpanded: true,
  markRequired: false,
  isValidation: true,
};

Textarea.propTypes = {
  textareaClassName: string,
  className: string,
  errorClassName: string,
  labelClassName: string,
  containerClassName: string,
  label: string,
  error: string,
  isExpanded: bool,
  markRequired: bool,
  isValidation: bool
};

export default Textarea;
