import React from 'react';
import { Controller } from 'react-hook-form';
import { bool, object, shape, string } from 'prop-types';
import { parseInt } from 'lodash';
import classNames from 'classnames';

import { Checkbox } from '../index';
import { FormattedMessage, intlShape } from '../../util/reactIntl';

import css from './OpeningPatternField.css';

const daysOfWeek = [
  { position: 1, dayMessage: "OpeningPatternField.Monday" },
  { position: 2, dayMessage: "OpeningPatternField.Tuesday" },
  { position: 3, dayMessage: "OpeningPatternField.Wednesday" },
  { position: 4, dayMessage: "OpeningPatternField.Thursday" },
  { position: 5, dayMessage: "OpeningPatternField.Friday" },
  { position: 6, dayMessage: "OpeningPatternField.Saturday" },
  { position: 0, dayMessage: "OpeningPatternField.Sunday" },
]

const OpeningPatternField = ({
   containerClassName,
   titleClassName,
   checkboxContainerClassName,
   fieldName,
   control,
   intl,
   error,
    disabled
}) => {
  const containerClasses = classNames(css.container, containerClassName);
  const titleClasses = classNames(css.title, titleClassName);
  const checkboxContainerClasses = classNames(css.checkboxContainer, checkboxContainerClassName);

  const handleChange = (event, position, openingPattern, onChange) => {
    const openingPatternArray = openingPattern.split('');
    const value = event.target.checked ? 1 : 0;
    const updateOpeningPattern = openingPatternArray.map((item, index) => (
      position !== index ? item : value
    )).join('');

    onChange(updateOpeningPattern);
  }

  const validateOpeningPattern = (value) => {
    const numberOfOpenDays = value.split('1').length - 1;
    return numberOfOpenDays > 2;
  }

  return (
    <div>
      <Controller
        control={control}
        name={fieldName}
        rules={{ validate: validateOpeningPattern }}
        render={({ field: { onChange, value } }) => {
          const openingPatternArray = value.split('');

          return (
            <div className={containerClasses}>
              <p className={titleClasses} >
                {intl.formatMessage({ id: 'OpeningPatternField.openingPattern' })}
              </p>
              <div className={checkboxContainerClasses}>
              {
                daysOfWeek.map(({position, dayMessage}) => (
                  <div className={css.wrapper}>
                    <Checkbox
                      checked={!!parseInt(openingPatternArray[position])}
                      onChange={event => handleChange(event, position, value, onChange)}
                      label={<FormattedMessage id={dayMessage} />}
                      size='medium'
                      key={position}
                      disabled={disabled}
                    />
                  </div>
                ))
              }
              </div>
              {!!error
                ? <p className={css.error}>
                    {intl.formatMessage({ id: 'OpeningPatternField.openingPatternError' })}
                  </p>
                : null}
            </div>
          )
        }}
      />
    </div>
  )
}

OpeningPatternField.defaultProps = {
  containerClassName: null,
  titleClassName: null,
  checkboxContainerClassName: null,
  fieldName: '',
  disabled: false,
}

OpeningPatternField.propTypes = {
  containerClassName: string,
  titleClassName: string,
  checkboxContainerClassNam: string,
  fieldName: string,
  intl: intlShape.isRequired,
  control: object,
  error: shape({
    message: string,
    ref: shape({
      name: string,
      }),
    type: string,
  }),
  disabled: bool,
}

export default OpeningPatternField;
