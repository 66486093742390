import SuitoImg from './trenazer-rowerowy-interaktywny-suito-t.jpeg';
import TuoImg from './trenazer-interaktywny-tuo.jpeg';
import DiretoXRTImg from './trenazer-rowerowy-elite-direto-xr-t-smart.jpeg';

export default [
  {
    name: 'Trenażer rowerowy interaktywny SUITO-T',
    description: 'Obawiasz się, że zimą utracisz swoją kondycję? Nic bardziej mylnego! Dzięki trenażerowi SUITO możesz ćwiczyć przez cały rok! Wypożycz trenażer interaktywny SUITO w całej Polsce. Warto wspomnieć, iż magnetyczna jednostka oporowa w przypadku tego produktu jest sterowana elektronicznie z bezstopniową regulacją. Waga koła zamachowego jest równa 3,5 kg, a dokładność pomiaru mocy to około 2,5%. Opór jest sterowany komputerowo przez ANT + (FE-C) lub Bluetooth. Obsługa trenażera jest banalnie prosta! Wystarczy rozłożyć nogi tego akcesorium, a następnie podłączyć je do prądu.',
    keywords: 'trenażer rowerowy interaktywny suito-t',
    image: SuitoImg,
    metaDescription: 'Pozwoli on wiernie odwzorować trening rowerowy w domowym zaciszu. Korzystaj jak długo potrzebujesz'
  },
  {
    name: 'Trenażer interaktywny TUO',
    description: 'Zima i jesień to czas, gdy wiele osób obawia się o utratę kondycji fizycznej, jaką wypracowały wiosną i latem. Warto jednak wiedzieć, że tak wcale nie musi być! Wypożycz trenażer interaktywny TOU w całej Polsce i pozostań w ruchu przez 365 dni w roku. Ten produkt przeznaczony jest pod tylne koło, a dodatkowo pozostaje kompatybilny ze Zwift. Maksymalne symulowane nachylenie w tym konkretnym przypadku wynosi aż 10%. Co jeszcze warto wiedzieć o trenażerze interaktywnym TOU? Przede wszystkim to, iż jest modelem cichym i mocnym (moc: 550W przy 20 km/h i 1250W przy 40 km/h).',
    keywords: 'trenażer interaktywny tuo',
    image: TuoImg,
    metaDescription: 'Elegencki design i wykonanie na wysokim poziomie. Teraz możesz wypożyczyć i korzystać tyle ile potrzebujesz.'
  },
  {
    name: 'Trenażer Elite Direto XR-T',
    description: 'Inteligentny trenażer rowerowy DIRETO XR zapewnia niezwykle realistyczne odczucia podczas odbywanego treningu. Warto wspomnieć, iż urządzenie to jest w stanie pracować w trybie bez dźwięku, co czyni ten sprzęt odpowiednim do użytkowania go w zaciszu domowym. Wypożycz trenażer rowerowy interaktywny DIRETO XR w… i sprawdź jego możliwości samodzielnie. Sterowanie urządzeniem następuje poprzez Bluetooth lub ANT+. Trenażer ten jest kompatybilny ze Zwift oraz z systemami WIN i Mac. Posiada także zintegrowany czujnik mocy.',
    keywords: 'trenażer rowerowy Elite Direto XR-T',
    image: DiretoXRTImg,
    metaDescription: 'Inteligentny trenażer rowerowy DIRETO XR zapewnia niezwykle realistyczne odczucia podczas odbywanego treningu. Wypożycz i ciesz się tak długo jak potrzebujesz.'
  }
]