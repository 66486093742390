import React, { useState, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classnames from 'classnames';
import useOutsideClick from "./useOutsideClick";
import css from './AutocompleteTextboxField.css';

const AutocompleteTextboxField = ({
  value, name, suggestions, onChange, placeholder, inputClassName, predictionsClassName
}) => {
  const [suggestionsOpened, setSuggestionsOpened] = useState(false);
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState(null);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setSuggestionsOpened(false);
  });
  
  const filteredSuggestions = suggestions.filter(suggestion => {
    return value && suggestion.startsWith(value.toLowerCase());
  }).splice(0, 6);

  const handleChange = (e) => {
    onChange(e.target.value);
  }

  const handleFocus = () => {
    setSuggestionsOpened(true);
  }

  const handleClick = (value) => {
    onChange(value);
    setSuggestionsOpened(false);
    ref.current.blur();
  }

  const handleKeyDown = (e) => {
    const suggestionsLength = filteredSuggestions.length;

    if (e.keyCode === 40) {
      e.preventDefault();
      if (!highlightedSuggestionIndex && highlightedSuggestionIndex !== 0) {
        setHighlightedSuggestionIndex(0);
      } else {
        setHighlightedSuggestionIndex((highlightedSuggestionIndex + 1) % suggestionsLength);
      }
    } else if (e.keyCode === 38) {
      e.preventDefault();
      if (!highlightedSuggestionIndex && highlightedSuggestionIndex !== 0) {
        setHighlightedSuggestionIndex(0);
      } else {
        setHighlightedSuggestionIndex((suggestionsLength + highlightedSuggestionIndex - 1) % suggestionsLength);
      }
    } else if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      onChange(filteredSuggestions[highlightedSuggestionIndex] || value);
      setSuggestionsOpened(false);
      ref.current.blur();
    }
  }

  return (
    <div className={css.wrapper}>
      <input
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        className={inputClassName}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
      {
        suggestionsOpened && value &&
        <div className={css.suggestionsWrapper}>
          <ul className={predictionsClassName}>
            {
              filteredSuggestions.map((suggestion, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => handleClick(suggestion)}
                    className={classnames({ [css.highlightedSuggestion]: index === highlightedSuggestionIndex })}
                  >
                    <span>{value.toLowerCase()}</span>
                    <strong>{suggestion.substring(value.length)}</strong>
                  </li>
                );
              })
            }
            <li className={css.findProductsInfo} key="info">
              <span><FormattedMessage id="AutocompleTextField.find"/> "{value}"</span>
            </li>
          </ul>
        </div>
      }
    </div>
  )
};

export default AutocompleteTextboxField;