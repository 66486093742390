import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';

import css from './BookingDatesForm.css';

const QuantityField = ({ name, bookingDates, timeSlots }) => (
  <Field name={name}>
    {({ input }) => (
      <QuantitySelector
        initialValue={input.value}
        onQuantityChange={input.onChange}
        bookingDates={bookingDates}
        timeSlots={timeSlots}
      />
    )}
  </Field>)


const QuantitySelector = ({ initialValue = 1, onQuantityChange, bookingDates = {}, timeSlots }) => {
  const [quantity, setQuantity] = useState(initialValue);
    useEffect(() => {
      setQuantity(initialValue);
    } , [initialValue]);

  const selectedTimeSlots = timeSlots ? timeSlots?.filter(slot => {
    if (!(bookingDates.startDate && bookingDates.endDate)) return false;
    const slotDate = moment(slot.attributes.start);
    return slotDate.isBetween(bookingDates.startDate, bookingDates.endDate, 'days', '[]');
  }) : [];

  const maxAvailableSlots = Math.min(...selectedTimeSlots.map(slot => slot.attributes.seats));

  const handleDecrease = () => {
    if (quantity > 1) {
      const newQuantity = parseInt(quantity - 1);
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const handleIncrease = () => {
    const newQuantity = parseInt(quantity + 1);
    setQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const handleInputChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  const isDisabled = !(bookingDates && bookingDates.startDate && bookingDates.endDate);

  return (
    <div className={css.quantityWrapper}>
      <div onClick={handleDecrease} type="button" className={classNames(css.quantityButton, {[css.disabled]: isDisabled || quantity <= 1})}>-</div>
      <div className={classNames(css.quantityValue, {[css.disabled]: isDisabled})}>{quantity}</div>
      <input
        type="number"
        value={quantity}
        onQuantityChange={handleInputChange}
        className={css.quantityInput}
      />
      <div onClick={handleIncrease} type="button" className={classNames(css.quantityButton, {[css.disabled]: isDisabled || quantity >= maxAvailableSlots})}>+</div>
    </div>
  );
};

export default QuantityField;