import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { createResourceLocatorString, findRouteByRouteName } from '../../../util/routes';

import routeConfiguration from '../../../routeConfiguration';

import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Modal, Select, Button } from '../../../components';

import css from './ProlongationModal.css';
import { initiateOrder } from '../../../containers/CheckoutPage/CheckoutPage.duck';

const ProlongationModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    canBeProlongedTo,
    history,
    transactionId,
    callSetInitialValues,
    onInitializeCardPaymentData,
    listings,
    provider,
    transaction,
    actualOrderedProducts
  } = props;

  const [endDate, setEndDate] = useState(canBeProlongedTo[0]);
  const dispatch = useDispatch();

  const handleChange = event => {
    setEndDate(event.target.value);
  };

  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = (values) => {
    const day = canBeProlongedTo[0];
    const formattedStartDay = moment(day).subtract(1,'days').format('YYYY-MM-DD');
    const start = canBeProlongedTo.length ? formattedStartDay : null;
    const end = moment(endDate).add(1,'days').format('YYYY-MM-DD');
    const currentListings = listings.filter(item => {
       const actualListing = actualOrderedProducts.find(el => el.listingId === item.id.uuid);
       return actualListing.seats > 0;
   })

    const initialValues = {
      bookingDates: {
        bookingStart: start,
        bookingEnd: end,
      },
      confirmPaymentError: null,
      listings: currentListings.map(listing => ({
        attributes: { amount: transaction.attributes.lineItems.findLast(l => l?.listingId === listing.id.uuid)?.seats },
        id: { uuid: listing.id.uuid},
        listing
      })),
      provider: provider,
      totalNumberOfOrders: currentListings.length,
    };

    const routes = routeConfiguration();

    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { cartId: 'prolonged' },
        { prolongFor: transactionId, end }
      )
    );
  }


  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ProlongationModal.title" />
      </p>
      <Select
        value={endDate}
        onChange={handleChange}
        label={intl.formatMessage( { id: 'ProlongationModal.description' })}
      >
        {
          canBeProlongedTo.slice(0, 14).map((date) => (
            <option key={date} value={date}>
              {moment(date).format('ddd, D MMMM')}
            </option>
          ))

        }
      </Select>
      <Button onClick={handleSubmit} >{ intl.formatMessage( { id:'ProlongationModal.submit'}) }</Button>
    </Modal>
  );
};

const { bool, string } = PropTypes;

ProlongationModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ProlongationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default withRouter(injectIntl(ProlongationModal));
