import React from 'react';
import classNames from 'classnames';
import moneyIcon from './moneyIcon.png';
import transactionsIcon from './transactionsIcon.png';
import css from './BusinessSummary.css';
import { FormattedMessage } from 'react-intl';

const displayDifference = (curr, prev) => {
  const delta = !prev && curr ? String.fromCharCode('0x221E') : (((curr / prev) - 1) * 100).toFixed();
  return (
    <span>
      <span className={classNames(css.arrow, { [css.positive]: delta > 0 || !prev, [css.negative]: delta < 0, [css.neutral]: delta == 0 })}/>
      <span className={css.deltaValue}>{`${delta > 0 ? '+' : ''}${delta}%`}</span>
    </span>
  );
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const BusinessSummary = ({ currentData, previousData, currency }) => {
  const { payinTotal, transactionsCount, itemsCount, avgDuration } = currentData;

  const currentTransactionsAvg = transactionsCount > 0 ? payinTotal / transactionsCount : 0;
  const prevTransactionsAvg = previousData && previousData.transactionsCount > 0 ? previousData.payinTotal / previousData.transactionsCount : 0;

  return (
    <div className={css.panel}>
      <div className={css.panelRow}>
        <div className={classNames(css.panelSection, css.width50)}>
          <div className={css.panelContent}>
            <img className={css.panelIcon} src={moneyIcon} />
            <div className={css.panelValues}>
              <span className={css.mainValue}>{numberWithCommas((payinTotal / 100).toFixed(2))} {currency}</span>
              <span className={css.valueTitle}><FormattedMessage id="BusinessResults.turnover"/></span>
              {
                previousData && <span className={css.versus}>{`vs. ${numberWithCommas((previousData.payinTotal / 100).toFixed(2))} ${currency}`}</span>
              }
            </div>
            { previousData && <div className={css.difference}>{displayDifference(payinTotal, previousData.payinTotal)}</div> }
          </div>
        </div>
        <div className={classNames(css.panelSection, css.width50)}>
          <div className={css.panelContent}>
            <img className={css.panelIcon} src={transactionsIcon} />
            <div className={css.panelValues}>
              <span className={css.mainValue}>{transactionsCount}</span>
              <span className={css.valueTitle}><FormattedMessage id="BusinessResults.rentings"/></span>
              {
                previousData &&
                  <span className={css.versus}>{`vs. ${previousData.transactionsCount}`}</span>
              }
            </div>
            { previousData && <div className={css.difference}>{displayDifference(transactionsCount, previousData.transactionsCount)}</div> }
          </div>
        </div>
        <div className={classNames(css.panelSection, css.width50)}>
          <div className={css.panelContent}>
            <img className={css.panelIcon} src={transactionsIcon} />
            <div className={css.panelValues}>
              <span className={css.mainValue}>{itemsCount}</span>
              <span className={css.valueTitle}><FormattedMessage id="BusinessResults.pieces"/></span>
              {
                previousData &&
                  <span className={css.versus}>{`vs. ${previousData.itemsCount}`}</span>
              }
            </div>
            { previousData && <div className={css.difference}>{displayDifference(itemsCount, previousData.itemsCount)}</div> }
          </div>
        </div>
      </div>
      <div className={css.panelRow}>
        <div className={classNames(css.panelSection, css.width50)}>
          <div className={css.panelValues}>
            <span className={css.normalValue}>{numberWithCommas((currentTransactionsAvg / 100).toFixed(2))} {currency}</span>
            <span className={css.valueTitle}><FormattedMessage id="BusinessResults.avgRentingValue"/></span>
            {
                previousData &&
                  <span className={css.versus}>{`vs. ${numberWithCommas((prevTransactionsAvg / 100).toFixed(2))} ${currency}`}</span>
              }
          </div>
          { previousData && <div className={css.difference}>{displayDifference(currentTransactionsAvg, prevTransactionsAvg)}</div> }
        </div>
        {/* <div className={classNames(css.panelSection, css.width33)}>
          <div className={css.panelValues}>
            <span className={css.normalValue}>{(payinMax / 100).toFixed(2)} {currency}</span>
            <span className={css.valueTitle}><FormattedMessage id="BusinessResults.maxRentingValue"/></span>
            {
                previousData &&
                  <span className={css.versus}>{`vs. ${(previousData.payinMax / 100).toFixed(2)}`}</span>
            }
          </div>
          { previousData && <div className={css.difference}>{displayDifference(payinMax, previousData.payinMax)}</div> }
        </div> */}
        <div className={classNames(css.panelSection, css.width50)}>
          <div className={css.panelValues}>
            <span className={css.normalValue}>{(avgDuration * 1).toFixed(1)}</span>
            <span className={css.valueTitle}><FormattedMessage id="BusinessResults.avgRentingLength"/></span>
            {
                previousData &&
                  <span className={css.versus}>{`vs. ${(previousData.avgDuration * 1).toFixed(1)}`}</span>
              }
          </div>
          { previousData && <div className={css.difference}>{displayDifference(avgDuration, previousData.avgDuration)}</div> }
        </div>
      </div>
    </div>
  );
}

export default BusinessSummary;