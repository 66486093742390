import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { IconClose, RadioButton, Input } from '../../../../components';

import css from './CodesSection.css';

const CodesSection = ({
  intl,
  control,
  isGeneratedAutomatically,
  setIsGeneratedAutomatically,
  setValue,
  errors,
  clearErrors,
  setError,
  fields,
}) => {
  const [codeName, setCodeName] = useState('');

  const handleCheckboxChange = () => {
    setIsGeneratedAutomatically(prevState => !prevState);
  };

  const handleCodeNameChange = event => {
    setCodeName(event.target.value);
  };

  const validateCodes = value => {
    const isMinThreeSign = !value.find(item => item.length < 3);
    let noDuplicate = true;
    value.forEach((item, index) => {
      value.forEach((el, i) => {
        if (i !== index) {
          if (item === el) noDuplicate = false;
        }
      });
    });
    return !!value.length && isMinThreeSign && noDuplicate;
  };

  const handleClick = (event, value) => {
    event.preventDefault();
    const newValue = [...value, codeName.toUpperCase()];
    setValue(fields.CODES, newValue);
    validateCodes(newValue) && clearErrors(fields.CODES);
    setCodeName('');
  };

  const handleDelete = (event, value, name) => {
    event.preventDefault();
    const newValue = value?.filter(item => item !== name);
    setValue(fields.CODES, [...newValue]);
    !validateCodes([...newValue]) && setError(fields.CODES);
  };

  return (
    <div className={css.codeSection}>
      <h3 className={css.title}>{intl.formatMessage({ id: 'Console.stepOne' })}</h3>
      <RadioButton
        checked={isGeneratedAutomatically}
        onChange={handleCheckboxChange}
        label={intl.formatMessage({ id: 'Console.generateCodeNames'})}
        labelClass={css.label}
        name="isGeneratedAutomatically"
      />
      <div className={css.container}>
        <Controller
          control={control}
          name={fields.AMOUNT}
          rules={{
            valueAsNumber: true,
            min: 1,
            max: 1000,
            required: true,
            disabled: !isGeneratedAutomatically,
            validate: value => !!value,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              type="number"
              id={fields.AMOUNT}
              placeholder={intl.formatMessage({ id: 'Console.enterCodesPlaceholder' })}
              label={intl.formatMessage({ id: 'Console.enterCodes' })}
              value={value}
              onChange={onChange}
              error={errors.amount && intl.formatMessage({ id: 'Console.amountError' })}
              disabled={!isGeneratedAutomatically}
            />
          )}
        />
        <Controller
          control={control}
          name={fields.PREFIX}
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              id={fields.PREFIX}
              placeholder={intl.formatMessage({ id: 'Console.prefixPlaceholder' })}
              label={intl.formatMessage({ id: 'Console.prefix' })}
              value={value}
              onChange={onChange}
              disabled={!isGeneratedAutomatically}
            />
          )}
        />
      </div>
      <RadioButton
        checked={!isGeneratedAutomatically}
        onChange={handleCheckboxChange}
        label={intl.formatMessage({ id:'Console.addCodeNames'})}
        labelClass={css.label}
      />
      <Controller
        name={fields.CODES}
        control={control}
        rules={{
          required: true,
          disabled: isGeneratedAutomatically,
          validate: validateCodes,
        }}
        render={({ field: { value } }) => (
          <div>
            <div className={css.addCodeContainer}>
              <Input
                value={codeName}
                onChange={handleCodeNameChange}
                placeholder={intl.formatMessage({ id: 'Console.enterCodeNamePlaceholder' })}
                disabled={isGeneratedAutomatically}
                error={errors.codes && intl.formatMessage({ id: 'Console.codesError' })}
              />
              <button
                type="button"
                onClick={event => handleClick(event, value)}
                className={css.btn}
                disabled={
                  isGeneratedAutomatically ||
                  !codeName ||
                  codeName.length < 3 ||
                  value.includes(codeName.toUpperCase())
                }
              >
                {intl.formatMessage({ id: 'Console.add' })}
              </button>
            </div>
            <div className={css.codeContainer}>
              {value.map(code => (
                <button
                  type="button"
                  onClick={event => handleDelete(event, value, code)}
                  className={css.codeButton}
                  disabled={isGeneratedAutomatically}
                >
                  <span className={css.textButton}>{code}</span>
                  <IconClose />
                </button>
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CodesSection;
