import React from 'react';

import css from './DashboardSkeleton.css';

const DashboardSkeleton = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.statusSection}>
        <div className={css.statusContainer}>
          <div className={css.skeleton}> </div>
          <div className={css.skeleton}> </div>
        </div>
        <div className={css.statusContainer}>
          <div className={css.skeleton}> </div>
          <div className={css.skeleton}> </div>
        </div>
        <div className={css.statusContainer}>
          <div className={css.skeleton}> </div>
          <div className={css.skeleton}> </div>
        </div>
        <div className={css.statusContainer}>
          <div className={css.skeleton}> </div>
          <div className={css.skeleton}> </div>
        </div>
      </div>
      <div className={css.skeletonBtn}> </div>
    </div>
  );
};

export default DashboardSkeleton;
