import React from 'react';

import businessCategoryConfig from '../../../business-category-config';

import css from './CatalogItemInfo.css';
const CatalogItemInfo = ({ itemId, productId, catalogProducts, intl, categories}) => {
  if (!itemId || !productId) return null;

  const product = catalogProducts.find(item => item.id.uuid === productId);
  const catalogItems = product && product.catalogItems;
  const catalogItem = catalogItems.find(item => itemId === item.id.uuid);

  if (!product || !catalogItem) return null;

  const { parts, minBookingDays, businessCategory, modelCode } = product.attributes;
  const businessCategoryItem = businessCategoryConfig.find(item => item.key === businessCategory);
  const category = categories.find(item => item.key === businessCategoryItem.category);
  const price = `${product.attributes.price.amount/100} ${product.attributes.price.currency}`;
  const { articleCode } = catalogItem.attributes;
  const { externalLink } = product.attributes;
  const title = `${product.attributes.title} ${catalogItem.attributes.title}`;

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.title' })}
        </p>
        <p className={css.text}>
          {title}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.description' })}
        </p>
        <p className={css.text}>
          {product.attributes.description} <br/>
          {catalogItem.attributes.description}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.modelCode' })}
        </p>
        <p className={css.text}>
          {modelCode}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.articleCode' })}
        </p>
        <p className={css.text}>
          {articleCode}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.externalLink' })}
        </p>
        <p className={css.text}>
          {externalLink}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.category' })}
        </p>
        <p className={css.text}>
          {category.label}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.businessCategory' })}
        </p>
        <p className={css.text}>
          {businessCategoryItem.label}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.price' })}
        </p>
        <p className={css.text}>
          {price}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.minBookingDays' })}
        </p>
        <p className={css.text}>
          {minBookingDays}
        </p>
      </div>
      <div className={css.wrapper}>
        <p className={css.title}>
          {intl.formatMessage({ id: 'CatalogItemInfo.parts' })}
        </p>
        <p className={css.text}>
          {parts}
        </p>
      </div>
    </div>
  )
}

export default CatalogItemInfo;
