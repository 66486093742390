import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const VERTICAL_VARIANT = 'vertical';
const HORIZONTAL_VARIANT = 'horizontal';

const LogoMedicoverSport = props => {
  const { className, rootClassName, variant } = props;
  const classes = classNames(rootClassName, className);

  switch (variant) {
    case VERTICAL_VARIANT:
      return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width="226.462"
             height="138.643"
             viewBox="0 0 226.462 138.643"
             className={classes}
        >
          <g transform="translate(-355.916 -56.741)">
            <path className="a"
                  d="M410.082,64.174c11.946-4.425,25.736-2.581,37.387,1.771a50.184,50.184,0,0,1,26.989,23.67C469.3,76.857,460.447,67.123,448.649,62.7S422.4,57.98,410.082,64.174Z"
                  transform="translate(39.858 1.988)"
                  fill="#00539f"
            />
            <rect className="a" width="6.858" height="26.399"
                  transform="translate(442.198 142.556)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M409.127,108.907a14.071,14.071,0,0,0-7.891-2.654c-.958,0-1.916-.075-3.022-.075h-9.882v26.4h9.882c1.106,0,2.064,0,3.022-.075a12.508,12.508,0,0,0,6.858-1.918,12.941,12.941,0,0,0,6.2-11.208A12.693,12.693,0,0,0,409.127,108.907Zm-7.891,18.214a8.218,8.218,0,0,1-1.4.148H394.97V111.563h4.866a8.545,8.545,0,0,1,1.4.073c3.762.59,6.268,3.612,6.268,7.742S405,126.531,401.236,127.121Z"
                  transform="translate(23.853 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M393.581,127.637H382.225v-6.268h10.545v-4.94H382.225v-5.309h10.987v-4.941H375.588v26.4h17.992Z"
                  transform="translate(14.476 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M370.364,116.871l-8.406-10.692h-5.825v26.4h6.489v-16.3l7.743,9.66,7.89-9.66v16.3h6.489v-26.4h-5.825Z"
                  transform="translate(0.159 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M485.291,124.982l3.394,7.6h7.447l-4.2-9a6.416,6.416,0,0,0-3.1-3.392c3.171-1.4,4.572-3.466,4.572-6.343,0-5.235-3.392-7.594-10.248-7.669h-10.03v26.4h6.636V121.886c.3,0,.517-.075.812-.075a6.5,6.5,0,0,1,2.581.443A4.9,4.9,0,0,1,485.291,124.982Zm-2.359-7.964H479.76V110.9h3.394c2.211.075,3.392,1.18,3.392,3.024,0,2.064-1.107,3.022-3.392,3.1Z"
                  transform="translate(86.247 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M451.97,109.684a14.105,14.105,0,0,0-10.177-3.76,14.282,14.282,0,0,0-10.248,3.76,13.887,13.887,0,0,0,0,19.763,15.518,15.518,0,0,0,20.426,0,12.535,12.535,0,0,0,3.612-5.753,14.481,14.481,0,0,0,0-8.185A13.143,13.143,0,0,0,451.97,109.684Zm-10.177,17.992c-4.423,0-7.594-3.317-7.594-8.112,0-4.718,3.171-8.037,7.594-8.037,4.352,0,7.523,3.319,7.523,8.037C449.316,124.359,446.145,127.676,441.793,127.676Z"
                  transform="translate(52.612 36.191)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M466.931,121.369h10.545v-4.94H466.931v-5.309h10.987v-4.941H460.295v26.4h17.992v-4.941H466.931Z"
                  transform="translate(76.807 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M456.871,121.59l-6.489-15.412H443.3l.59,1.254,11.43,25.44h2.8l12.831-26.694H463.8Z"
                  transform="translate(64.303 36.378)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M418.651,119.564c0-5.013,3.244-8.11,8.627-8.11a16.6,16.6,0,0,1,8.776,2.8v-6.121a20.212,20.212,0,0,0-9.219-2.211c-9.217,0-14.968,5.678-14.968,13.64a13.612,13.612,0,0,0,4.055,9.955,14.481,14.481,0,0,0,10.25,3.762,19.24,19.24,0,0,0,10.1-2.729v-6.121a17.313,17.313,0,0,1-9.587,3.319C421.747,127.751,418.651,124.506,418.651,119.564Z"
                  transform="translate(41.171 36.191)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M423.12,62.844a53.881,53.881,0,0,1,37.091-.885c-12.389-5.973-26.694-6.931-38.419-2.286S401.514,74.2,396.574,87.1A50.74,50.74,0,0,1,423.12,62.844Z"
                  transform="translate(29.918 0)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M432.871,62.357c-11.578.516-22.122,6.563-30.308,16.3a46.545,46.545,0,0,1,30.455-13.125c11.429-.517,24.113,1.769,33.257,9.07C457.279,65.676,444.522,61.842,432.871,62.357Z"
                  transform="translate(34.325 4.099)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M417.389,80.373a6.268,6.268,0,1,0,6.268-6.268A6.311,6.311,0,0,0,417.389,80.373Z"
                  transform="translate(45.235 12.777)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M495.13,78.566c-15.192,0-40.631,1.474-52.061,12.9-11.429-11.431-36.869-12.9-52.06-12.9C406.125,80.777,432.3,89.4,432.3,108.431h21.531C453.834,89.4,480.013,80.777,495.13,78.566Z"
                  transform="translate(25.823 16.06)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M360.936,134.342a22.753,22.753,0,0,1-2.17-1.47,2.007,2.007,0,0,1-.79-1.493,1.569,1.569,0,0,1,.625-1.272,2.54,2.54,0,0,1,1.654-.507,5.72,5.72,0,0,1,3.486,1.535V128.7a6.461,6.461,0,0,0-3.4-1.029,4.536,4.536,0,0,0-3.2,1.128,3.718,3.718,0,0,0-1.227,2.859,3.624,3.624,0,0,0,.965,2.543,16.97,16.97,0,0,0,2.871,2.126,11.14,11.14,0,0,1,1.863,1.359,2.112,2.112,0,0,1,.635,1.512,1.718,1.718,0,0,1-.635,1.413,2.708,2.708,0,0,1-1.753.516,5.377,5.377,0,0,1-3.923-2.191v2.828a6.183,6.183,0,0,0,4.055,1.423,4.559,4.559,0,0,0,3.2-1.12,3.665,3.665,0,0,0,1.227-2.833,4.438,4.438,0,0,0-.92-2.875A11.073,11.073,0,0,0,360.936,134.342Z"
                  transform="translate(0 52.195)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M390.143,127.836h-4.668v14.97H387.6v-6.443h2.541a5.434,5.434,0,0,0,3.673-1.128,4.392,4.392,0,0,0-.012-6.258A5.417,5.417,0,0,0,390.143,127.836Zm2.029,6.053a3.718,3.718,0,0,1-2.248.545H387.6v-4.668h2.322a3.718,3.718,0,0,1,2.248.545,2.6,2.6,0,0,1,0,3.578Z"
                  transform="translate(21.751 52.315)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M423.113,127.7a7.832,7.832,0,0,0-5.633,2.257,7.248,7.248,0,0,0-2.345,5.414,7.42,7.42,0,0,0,2.335,5.534,7.8,7.8,0,0,0,5.643,2.269,7.9,7.9,0,0,0,5.655-2.236,7.229,7.229,0,0,0,2.345-5.435,7.446,7.446,0,0,0-2.324-5.523A7.792,7.792,0,0,0,423.113,127.7Zm4.055,11.769a5.52,5.52,0,0,1-4.055,1.666,5.463,5.463,0,0,1-4.044-1.677,5.527,5.527,0,0,1-1.654-4.022,5.449,5.449,0,0,1,1.7-4.065,5.539,5.539,0,0,1,4-1.656,5.454,5.454,0,0,1,4.055,1.71,5.562,5.562,0,0,1,1.665,4.012A5.487,5.487,0,0,1,427.168,139.467Z"
                  transform="translate(43.576 52.213)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M455.3,135.661a3.6,3.6,0,0,0,2.807-3.725,3.807,3.807,0,0,0-1.229-3.026,5.166,5.166,0,0,0-3.506-1.075h-4.383v14.97h2.126v-6.685h.639a2.966,2.966,0,0,1,2,.724,11.333,11.333,0,0,1,1.585,2.038l2.566,3.923h2.586l-3.638-5.478A4.952,4.952,0,0,0,455.3,135.661Zm-2.4-1.337h-1.783v-4.668H452.9a3.838,3.838,0,0,1,2.267.536,2.07,2.07,0,0,1,.727,1.786Q455.89,134.325,452.9,134.325Z"
                  transform="translate(68.486 52.315)"
                  fill="#00539f"
            />
            <path className="a" d="M478.583,129.766h5.5v13.041h2.126V129.766h5.5v-1.929H478.583Z"
                  transform="translate(90.264 52.315)"
                  fill="#00539f"
            />
          </g>
        </svg>
      );
    case HORIZONTAL_VARIANT:
      return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width="209.088"
             height="40.677"
             viewBox="0 0 209.088 40.677"
             className={classes}
        >
          <g transform="translate(0 0)">
            <rect className="a" width="4.055" height="15.625"
                  transform="translate(124.596 8.981)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M266.006,120.423h4.449l-2.5-5.341a3.605,3.605,0,0,0-1.874-1.969c1.874-.853,2.774-2.086,2.774-3.793,0-3.131-2.069-4.524-6.326-4.524h-5.813v15.627h3.942v-6.347c.2,0,.311-.037.469-.037a2.648,2.648,0,0,1,2.892,1.9Zm-3.44-9.206h-1.909V107.55h1.909c1.452,0,2.145.684,2.145,1.813C264.712,110.6,264.051,111.217,262.566,111.217Z"
                  transform="translate(-61.938 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M151.283,113.765H157.6v-2.894h-6.318V107.7h6.629v-2.9H147.339v15.627h10.767v-2.942h-6.824Z"
                  transform="translate(-53.969 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M174.06,106.423c-1.95-1.47-3.979-1.627-6.518-1.627h-5.891v15.627h5.891c2.308,0,4.141-.045,5.9-1.163a7.622,7.622,0,0,0,3.71-6.649A7.4,7.4,0,0,0,174.06,106.423Zm-5.537,10.822h-2.929v-9.277h2.929a4.639,4.639,0,0,1,0,9.277Z"
                  transform="translate(-55.012 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M246.257,113.765h6.324v-2.894h-6.324V107.7h6.637v-2.9H242.317v15.627h10.768v-2.942h-6.828Z"
                  transform="translate(-60.889 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M134.077,111.1,129,104.8h-3.474v15.627h3.866V110.75l4.682,5.727,4.681-5.727v9.673h3.9V104.8h-3.51Z"
                  transform="translate(-52.38 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M214.022,104.5a8.517,8.517,0,0,0-6.13,2.243,8.171,8.171,0,0,0,0,11.68,9.373,9.373,0,0,0,12.259,0,8.163,8.163,0,0,0,0-11.68A8.566,8.566,0,0,0,214.022,104.5Zm0,12.88a4.809,4.809,0,1,1,4.489-4.793A4.494,4.494,0,0,1,214.022,117.384Z"
                  transform="translate(-58.202 -95.796)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M231.4,113.883l-3.943-9.087h-4.216l7.183,15.775h1.719l7.689-15.775h-4.3Z"
                  transform="translate(-59.499 -95.817)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M192.062,112.591c0-2.981,1.95-4.835,5.153-4.835a9.635,9.635,0,0,1,5.233,1.7v-3.672a12.292,12.292,0,0,0-5.509-1.278c-5.5,0-8.978,3.325-8.978,8.087a7.984,7.984,0,0,0,2.461,5.911,8.726,8.726,0,0,0,6.128,2.208,11.832,11.832,0,0,0,6.053-1.628v-3.635a9.991,9.991,0,0,1-5.741,1.976A4.506,4.506,0,0,1,192.062,112.591Z"
                  transform="translate(-56.929 -95.796)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M76.191,118.093a3.745,3.745,0,1,0,3.747-3.715A3.731,3.731,0,0,0,76.191,118.093Z"
                  transform="translate(-48.786 -96.515)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M68.711,98.75A32.7,32.7,0,0,1,91,98.2c-7.457-3.556-16-4.1-23.027-1.356s-12.179,8.621-15.145,16.284A30.058,30.058,0,0,1,68.711,98.75Z"
                  transform="translate(-47.083 -95.111)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M68.024,100.944c7.145-2.675,15.377-1.552,22.365.995a30.365,30.365,0,0,1,16.2,14.04c-3.16-7.543-8.431-13.342-15.5-15.933C84.024,97.415,75.4,97.222,68.024,100.944Z"
                  transform="translate(-48.191 -95.329)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M46.632,119.339c9.02,1.319,24.709,6.46,24.709,17.716H84.225c0-11.256,15.692-16.4,24.745-17.716-9.093,0-24.32.854-31.185,7.66C70.957,120.193,55.692,119.339,46.632,119.339Z"
                  transform="translate(-46.632 -96.876)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M77.713,101.323c-6.95.309-13.236,3.908-18.153,9.669a28,28,0,0,1,18.227-7.776c6.875-.309,14.442,1.046,19.945,5.38A27.12,27.12,0,0,0,77.713,101.323Z"
                  transform="translate(-47.574 -95.562)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M128.822,133.122a13.319,13.319,0,0,1-1.264-.857,1.171,1.171,0,0,1-.46-.87.913.913,0,0,1,.364-.741,1.485,1.485,0,0,1,.964-.295,3.331,3.331,0,0,1,2.03.894v-1.417a3.772,3.772,0,0,0-1.979-.6,2.643,2.643,0,0,0-1.864.657,2.166,2.166,0,0,0-.715,1.666,2.114,2.114,0,0,0,.562,1.481,9.989,9.989,0,0,0,1.673,1.238,6.549,6.549,0,0,1,1.085.792,1.232,1.232,0,0,1,.37.881,1,1,0,0,1-.37.823,1.576,1.576,0,0,1-1.021.3,3.13,3.13,0,0,1-2.285-1.277v1.647a3.6,3.6,0,0,0,2.361.83,2.661,2.661,0,0,0,1.864-.652,2.139,2.139,0,0,0,.715-1.649,2.581,2.581,0,0,0-.537-1.675A6.429,6.429,0,0,0,128.822,133.122Z"
                  transform="translate(-52.407 -97.598)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M161.91,129.416h-2.718v8.719h1.238v-3.753h1.481a3.165,3.165,0,0,0,2.139-.657,2.559,2.559,0,0,0-.006-3.645A3.153,3.153,0,0,0,161.91,129.416Zm1.182,3.525a2.165,2.165,0,0,1-1.309.317H160.43v-2.719h1.354a2.165,2.165,0,0,1,1.309.318,1.517,1.517,0,0,1,0,2.084Z"
                  transform="translate(-54.833 -97.611)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M197.242,129.264a4.561,4.561,0,0,0-3.281,1.316,4.221,4.221,0,0,0-1.366,3.152,4.323,4.323,0,0,0,1.36,3.224,4.543,4.543,0,0,0,3.287,1.321,4.6,4.6,0,0,0,3.293-1.3,4.211,4.211,0,0,0,1.366-3.166,4.341,4.341,0,0,0-1.353-3.217A4.543,4.543,0,0,0,197.242,129.264Zm2.361,6.855a3.213,3.213,0,0,1-2.361.971,3.178,3.178,0,0,1-2.355-.977,3.212,3.212,0,0,1-.964-2.342,3.178,3.178,0,0,1,.989-2.368,3.229,3.229,0,0,1,2.33-.964,3.173,3.173,0,0,1,2.361,1,3.239,3.239,0,0,1,.971,2.336A3.2,3.2,0,0,1,199.6,136.119Z"
                  transform="translate(-57.266 -97.6)"
                  fill="#00539f"
            />
            <path className="a"
                  d="M234.246,133.973a2.1,2.1,0,0,0,1.634-2.17,2.218,2.218,0,0,0-.715-1.762,3.01,3.01,0,0,0-2.042-.625h-2.553v8.719h1.239v-3.894h.372a1.727,1.727,0,0,1,1.168.422,6.528,6.528,0,0,1,.923,1.187l1.495,2.285h1.506l-2.119-3.191A2.89,2.89,0,0,0,234.246,133.973Zm-1.4-.779h-1.038v-2.719h1.038a2.233,2.233,0,0,1,1.321.313,1.21,1.21,0,0,1,.424,1.04Q234.591,133.194,232.846,133.194Z"
                  transform="translate(-60.033 -97.611)"
                  fill="#00539f"
            />
            <path className="a" d="M263.9,129.416v1.123h3.2v7.6h1.238v-7.6h3.2v-1.123Z"
                  transform="translate(-62.462 -97.611)"
                  fill="#00539f"
            />
          </g>
        </svg>
      );

    default:
      return null;
  }
};

LogoMedicoverSport.defaultProps = {
  className: null,
  rootClassName: null,
  variant: HORIZONTAL_VARIANT,
};

LogoMedicoverSport.propTypes = {
  className: string,
  rootClassName: string,
  variant: string,
};

export default LogoMedicoverSport;
