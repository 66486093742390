import React, { memo } from 'react';
import css from './Topbar.css';

class PromoBar extends React.Component {
  // componentDidMount() {
  //   console.log(this.props.code)
  // }

  render() {
    const { title } = this.props;
  
    return (
      <div className={css.promo}>
        {title}
      </div>
    );
  }
}

export default memo(PromoBar);