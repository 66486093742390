const LONG_TERM_DISCOUNT_INDICATOR = 0.9;
const MIN_PRICE_PER_DAY_INDICATOR = 0.4;

let daysWithDiscounting = 0;
let x = 1;
while (x * LONG_TERM_DISCOUNT_INDICATOR > MIN_PRICE_PER_DAY_INDICATOR) {
  x = x * LONG_TERM_DISCOUNT_INDICATOR;
  daysWithDiscounting++
}

export const calculateLongTermDiscount = (amount, quantity, isBikeTreadmill = false) => {
  if (isBikeTreadmill && quantity > 29) return amount * quantity * 0.6;
  const firstDayPrice = amount;
  const secondDayPrice = quantity > 1 ? amount : 0;
  const restDaysPrice = quantity > 2 ? amount * ((1 - (Math.pow(LONG_TERM_DISCOUNT_INDICATOR, Math.min(quantity - 2, daysWithDiscounting)))) / (1 - LONG_TERM_DISCOUNT_INDICATOR)) : 0;
  const restRestDaysPrice = quantity > daysWithDiscounting + 2 ? (quantity - daysWithDiscounting - 2) * amount * MIN_PRICE_PER_DAY_INDICATOR : 0;
  return amount * quantity - firstDayPrice - secondDayPrice - restDaysPrice - restRestDaysPrice;
}

export const calculateLongTermPeriodDiscount = (amount, quantity, periodDiscounts) => {
  if (quantity < 4) return 0;
  const totalPrice = amount * quantity;

  if (quantity > 29) return totalPrice * periodDiscounts.month;
  if (quantity > 6) return totalPrice * periodDiscounts.week;
  if (quantity >= 4) return totalPrice * periodDiscounts.fourDays;
}