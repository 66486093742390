import React, { Component } from 'react';
import { arrayOf, bool, func, node, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parseDateFromISO8601, stringifyDateToISO8601, daysBetween } from '../../util/dates';
import { FieldDateRangeController, FilterPopup, FilterPlain, FieldRadioButton, FieldTextInput } from '../../components';
import css from './BookingDateRangeLengthFilter.css';
import { minDurationLength } from '../../util/validators';
import debounce from 'lodash/debounce';
import cx from 'classnames';

// Parse query parameter, which should look like "2020-05-28,2020-05-31"
const parseInitialValues = initialValues => {
  const { dates, minDuration, availability } = initialValues || {};
  const rawDateValuesFromParams = dates ? dates.split(',') : [];
  const [startDate, endDate] = rawDateValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialDates =
    initialValues && startDate && endDate ? { dates: { startDate, endDate } } : { dates: null };
  const initialMinDuration = minDuration ? parseInt(minDuration, 10) : null;
  const initialAvailability = availability ? availability : null;
  return { ...initialDates, minDuration: initialMinDuration, availability: initialAvailability };
};
// Format dateRange value for the query. It's given by FieldDateRangeInput:
// { dates: { startDate, endDate } }
const formatValues = (values, dateQueryParam, minDurationParam, availabilityParam) => {
  const { startDate, endDate } = values && values[dateQueryParam] ? values[dateQueryParam] : {};
  const start = startDate ? stringifyDateToISO8601(startDate) : null;
  const end = endDate ? stringifyDateToISO8601(endDate) : null;
  const datesValue = start && end ? `${start},${end}` : null;
  const availabilityValue = values && values[availabilityParam] && datesValue ? values[availabilityParam] : null;
  const minDurationValue = values && values[minDurationParam] && availabilityValue === 'day-partial' ? values[minDurationParam] : null;
  return { [dateQueryParam]: datesValue, [minDurationParam]: minDurationValue, [availabilityParam]: availabilityValue };
};

export class BookingDateRangeFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.popupControllerRef = null;
    this.plainControllerRef = null;
  }

  render() {
    const {
      className,
      rootClassName,
      showAsPopup,
      initialValues: initialValuesRaw,
      id,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      label,
      intl,
      ...rest
    } = this.props;

    const parsedInitialValues = initialValuesRaw ? parseInitialValues(initialValuesRaw) : {};
    const { dates: initialDates, minDuration: initialMinDuration, availability: initialAvailability } = parsedInitialValues;
    const { startDate, endDate } = initialDates || {};

    const isSelected = !!initialDates && !!startDate && !!endDate;

    const format = {
      month: 'short',
      day: 'numeric',
    };

    const formattedStartDate = isSelected ? intl.formatDate(startDate, format) : null;
    const formattedEndDate = isSelected ? intl.formatDate(endDate, format) : null;

    const initialValues = {
      dates: initialDates,
      minDuration: initialMinDuration || 1,
      availability: initialAvailability || 'day-full'
    };

    const labelForPlain = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPlain' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : label
      ? label
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPlain' });

    const labelForPopup = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPopup' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : label
      ? label
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPopup' });

    const handleSubmit = values => {
      if (values && values.availability === 'day-partial' && !values.minDuration) return 
      
      onSubmit(formatValues(values, 'dates', 'minDuration', 'availability'));
    };

    const onClearPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onClear: () => this.popupControllerRef.onReset(null, null) }
        : {};

    const onCancelPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onCancel: () => this.popupControllerRef.onReset(startDate, endDate) }
        : {};

    const onClearPlainMaybe =
      this.plainControllerRef && this.plainControllerRef.onReset
        ? { onClear: () => this.plainControllerRef.onReset(null, null) }
        : {};

    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={labelForPopup}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialValues}
        {...rest}
      >
        <StateManager
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
          intl={intl}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={isSelected}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={debounce(handleSubmit, 500)}
        {...onClearPlainMaybe}
        initialValues={initialValues}
        {...rest}
      >
        <StateManager
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
          intl={intl}
        />
      </FilterPlain>
    );
  }
}

BookingDateRangeFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

BookingDateRangeFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  showAsPopup: bool,
  liveEdit: bool,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeFilter = injectIntl(BookingDateRangeFilterComponent);

export default BookingDateRangeFilter;

const StateManager = ({ formValues, controllerRef, onChangeFieldValue, intl }) => {
  const datesSet = formValues.dates && formValues.dates.startDate && formValues.dates.endDate;
  const maxDuration = formValues.availability === 'day-partial' && datesSet ? daysBetween(formValues.dates.startDate, formValues.dates.endDate) + 1 : 99;
  const fields = (
    <div className={css.availabilityFieldsContainer}>
      <h3>
        <FormattedMessage id="BookingDateRangeFilter.availability" />
      </h3>
      <FieldRadioButton
        id="day-full"
        name="availability"
        label={intl.formatMessage({id: "BookingDateRangeFilter.availabilityDayFull"})}
        value="day-full"
        className={css.availabilityRadioButton}
      />
      <FieldRadioButton
        id="day-partial"
        name="availability"
        label={<SuperField
                minDurationValue={formValues.minDuration}
                maxDuration={maxDuration}
                onChangeFieldValue={onChangeFieldValue}
                partialAvailabilitySelected={formValues.availability === 'day-partial'}
              />}
        value="day-partial"
        className={css.availabilityRadioButton}
      />
    </div>)

  return (
    <div>
      <FieldDateRangeController
        name="dates"
        controllerRef={controllerRef}
      />
      {datesSet && fields}
    </div>
  )
}

const SuperField = ({ minDurationValue, onChangeFieldValue, maxDuration, partialAvailabilitySelected }) => {
  const handleClickDecrease = () => {
    if (minDurationValue === 1) return;
    onChangeFieldValue('minDuration', minDurationValue - 1);
  }

  const handleClickIncrease = () => {
    if (minDurationValue === maxDuration) return;
    onChangeFieldValue('minDuration', minDurationValue + 1);
  }

  return (
    <div className={css.partialAvailabilityRadio}>
      <span>
        <FormattedMessage id="BookingDateRangeFilter.min" />
      </span>
      <div className={css.dayField}>
        <div className={css.dayButton} onClick={handleClickDecrease}>-</div>
        <FieldTextInput
          id="minDuration"
          name="minDuration"
          placeholder="1"
          type="number"
          className={cx(css.minDurationField, { [css.notSelected]: !partialAvailabilitySelected })}
          disabled={true}
        />
        <div className={css.dayButton} onClick={handleClickIncrease}>+</div>
      </div>
      <span>
        <FormattedMessage id="BookingDateRangeFilter.daysInRow" />
      </span>
    </div>
  );
}
