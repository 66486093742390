import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: October 30, 2017</p> */}
      <h2>Wstęp</h2>
      <h3>ZASADY FUNKCJONOWANIA DECATHLON RENT</h3>
      <p>
        Usługa Decathlon Rent umożliwia osobom, które założyły konto na platformie Decathlon Rent, będącymi osobami pełnoletnimi (dalej: „Klienci”), dokonanie rezerwacji oraz następnie      wypożyczanie sprzętu sportowego marki Decathlon lub marek, których jedynym dystrybutorem na terenie kraju jest Decathlon, jak również sprzętu wypożyczalni zewnętrznych (dalej: „Wypożyczalnia”), niezwiązanych z Decathlon, będących podmiotami świadczącymi usługi polegające na wypożyczaniu sprzętu sportowego w ramach usługi Decathlon Rent. Wypożyczalnia każdorazowo przechodzi weryfikację dokonywaną przez Decathlon tak, aby zagwarantować Klientom Decathlon, korzystającym z usługi Decathlon Rent możliwie maksymalny poziom zadowolenia z oferowanych w ramach Decathlon Rent usług (zarówno przez Decathlon jak i Wypożyczalnie).
      </p>
      <p>
        Niezależnie od powyższych postanowień, <strong>Decathlon nie ponosi odpowiedzialności za oferowany do wypożyczenia przez Wypożyczalnie za pośrednictwem Decathlon Rent sprzęt sportowy.</strong>
      </p>
      <p>Klient uzyskuje za pośrednictwem strony internetowej dostępnej pod adresem URL: https://rent.decathlon.pl/ informacje dotyczące Wypożyczalni oferującej sprzęt sportowy do wypożyczenia za pośrednictwem Decathlon Rent. Klient jest informowany o tym, czy sprzęt sportowy pochodzi od Decathlon, czy od Wypożyczalni za pośrednictwem informacji umieszczonej na stronie internetowej, o której mowa w zdaniu powyżej, po wybraniu określonego sprzętu, w drodze informacji o podmiocie odpowiedzialnym (logo i/lub znak towarowy Decathlon lub Wypożyczalni umieszczony na stronie sprzętu). Ponadto, w chwili dokonania  rezerwacji sprzętu za pośrednictwem strony internetowej Decathlon Rent, Klient informowany jest o nazwie podmiotu, z którym zawierał będzie następnie we wskazanym Klientowi miejscu, umowę wypożyczenia sprzętu. W przypadku, gdy sprzęt sportowy pochodzi od Wypożyczalni, Wypożyczalnia jest podmiotem wyłącznie odpowiedzialnym za umowę wypożyczenia zawartą z Klientem. Odpowiedzialność, o której mowa w zdaniu powyżej dotyczy w szczególności jakości wypożyczonego sprzętu, a w przypadku podjęcia decyzji o jego zakupie, również gwarancji jakości oraz dokumentów potwierdzających dokonanie zakupu, jak również obsługę posprzedażową (z zastrzeżeniem bezwzględnie obowiązujących przepisów prawa). Decathlon Rent umożliwia Klientowi składanie rezerwacji na sprzęt sportowy zarówno Decathlon jak i Wypożyczalni. Decathlon Rent to także dostęp do historii wypożyczonego sprzętu sportowego przez Klienta oraz możliwość oceny wypożyczonego sprzętu sportowego pochodzącego od określonej Wypożyczalni lub od Decathlon. Dzięki systemowi ocen, na Decathlon Rent widnieje sprzęt sportowy Wypożyczalni, które systematycznie otrzymują wysokie oceny od Klientów. W ten sposób, Decathlon Rent zmierza do zapewnienia swoim Klientom najwyższej jakości sprzętu sportowego.</p>
      <h3>WYPOŻYCZANIE SPRZĘTU OD WYPOŻYCZALNI</h3>
      <p>Niniejszy Regulamin określa warunki wypożyczania sprzętu sportowego pochodzącego od Decathlon oraz warunki dokonania rezerwacji zarówno sprzętu sportowego pochodzącego od Decathlon jak i od Wypożyczalni. W zakresie jednak  warunków wypożyczania sprzętu sportowego od Wypożyczalni zastosowanie znajdą postanowienia  umowy wypożyczenia (płatności, odbiór i zwrot sprzętu sportowego, obsługa Klienta, kontakt i inne), która zawarta zostaje przez Klienta z Wypożyczalnią w Wypożyczalni, w momencie odbioru zarezerwowanego przez Klienta sprzętu sportowego. Płatności za sprzęt wypożyczany zarówno od Decathlon jak i Wypożyczalni są dokonywane w taki sam sposób i zasady w tym zakresie zostały uregulowane w treści pkt. 10 poniżej Regulaminu, z jednoczesnym zastrzeżeniem, że płatności dokonywane na rzecz Wypożyczalni będą pokrywać wyłącznie opłaty za wypożyczenie sprzętu, z wyłączeniem opłat określonych w pkt. 10 lit. (i) – (iv) Regulaminu (odszkodowanie za opóźnienie, brak zwrotu sprzętu, opłaty serwisowe, koszty naprawy)</p>
      <h3>ZGŁASZANIE NARUSZEŃ</h3>
      <p>
        W przypadku, gdy Klient Decathlon zidentyfikuje, że którakolwiek spośród Wypożyczalni, oferujących sprzęt sportowy do wypożyczenia za pośrednictwem Decathlon Rent narusza zasady wynikające z powszechnie obowiązujących przepisów prawa, dobrych obyczajów lub zasad współżycia społecznego, powinien niezwłocznie o tym poinformować Decathlon. Zgłoszenie, o którym mowa w zdaniu poprzednim może być dokonane za pośrednictwem procedury „zgłoś nadużycie”, poprzez przesłanie do Decathlon opisu naruszenia w sposób wystarczający do rozpatrzenia zgłoszenia.
      </p>

      <h3>POZOSTAŁE POSTANOWIENIA REGULAMINU</h3>

      <p>
      1. Spółka Decathlon spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie przy ul. Geodezyjnej 76 (03 – 290 Warszawa) wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000007163, NIP: 951-18-55-233, REGON: 013102058, BDO: 000005259 (dalej: „Decathlon”) umożliwia wypożyczanie sprzętu sportowego (dalej: „sprzęt”) Decathlon lub/i marek powiązanych z Decathlon, jak również sprzętu sportowego Wypożyczalni. Sprzęt Decathlon lub/i marek powiązanych z Decathlon może być wypożyczany przez Klientów w prowadzonych przez Decathlon sklepach sportowych na zasadach przewidzianych w niniejszym Regulaminie. Celem wypożyczenia jest możliwość czasowego odpłatnego używania przez klientów Decathlon danego sprzętu w celach związanych głównie z wypoczynkiem, w tym w celach sportowych, czy rekreacyjnych.
      </p>
      <p>
      2. Klientem wypożyczającym sprzęt Decathlon lub/i marek powiązanych z Decathlon może być wyłącznie osoba pełnoletnia, która założyła konto na platformie Decathlon Rent oraz która zaakceptuje Regulamin poprzez podpisanie protokołu wypożyczania oraz okaże przy wypożyczeniu ważny dokument tożsamości (dowód osobisty lub paszport) (dalej „Klient”).
      </p>
      <p>
      3. Decathlon ustala listę sklepów, w których będzie możliwe wypożyczenie sprzętu oraz rodzaj sprzętu, który może zostać wypożyczony w danym sklepie Decathlon, z zastrzeżeniem, że dany sprzęt może być niedostępny w wybranym terminie lub w danym sklepie Decathlon. Dostępność sprzętu przeznaczonego do wypożyczenia, określona jest na stronie internetowej https://rent.decathlon.pl/. W odniesieniu do sprzętu wypożyczanego od Wypożyczalni zewnętrznych, lista punktów odbioru oraz dostępność sprzętu jest każdorazowo ustalana przez Wypożyczalnię. Informacje w powyższym zakresie będą dostępne na stronie internetowej https://rent.decathlon.pl/ oraz dodatkowo wysyłane do Klienta mailowo, wraz z potwierdzeniem dokonania rezerwacji sprzętu pochodzącego od Wypożyczalni. W braku takich informacji, Klient powinien skontaktować się uprzednio z Decathlon lub Wypożyczalnią celem określenia lokalizacji sprzętu oraz jego dostępności.
      </p>
      <p>
        4. W przypadku braku dostępności danego sprzętu w terminie wskazanym przez Klienta z przyczyn niezależnych od Decathlon, np. z uwagi na niezwrócenie sprzętu w terminie przez innych Klientów, istnieje możliwość dokonania zmiany daty wypożyczenia sprzętu w ramach jego dostępności. Decathlon nie ponosi odpowiedzialności za brak dostępności sprzętu w wybranym przez Klienta terminie z przyczyn niezawinionych. W sytuacji jak opisana powyżej, Klient może dokonać zmiany daty wypożyczenia sprzętu, albo odstąpić od umowy wypożyczenia.
      </p>
      <p>
      5. Klient zobowiązany jest do zwrotu wypożyczonego sprzętu do sklepu Decathlon, w którym nastąpiło jego przekazanie do wypożyczenia, w dacie zwrotu sprzętu, najpóźniej godzinę przed zamknięciem sklepu.
      </p>
      <p>
      6. Wraz z podpisaniem protokołu wypożyczania w wybranym sklepie Decathlon, Klient zawiera z Decathlon umowę wypożyczenia sprzętu (dalej: “umowa wypożyczenia”) zgodnie z warunkami określonymi w niniejszym Regulaminie oraz protokole wypożyczania, w ramach której Decathlon zobowiązuje się do wypożyczenia Klientowi, w ustalonym terminie sprzętu celem możliwości czasowego używania danego sprzętu, zaś Klient zobowiązany jest do używania wypożyczonego sprzętu na warunkach określonych w niniejszym Regulaminie i protokole wypożyczania oraz do zapłaty wynagrodzenia za każdy dzień wypożyczenia sprzętu zgodnie z cennikiem wypożyczania obowiązującym w Decathlon, umieszczonym na stronie internetowej https://rent.decathlon.pl/ pod pozycją danego sprzętu do wypożyczenia. Opłata za wypożyczenie sprzętu pobierana jest za cały okres wypożyczenia w dniu dokonania przez Klienta rezerwacji sprzętu na stronie internetowej Decathlon Rent, zgodnie z treścią pkt 10 poniżej, w którym to momencie Klient zawiera z Decathlon umowę rezerwacyjną na odległość, w ramach której Klient może anulować rezerwację, zgodnie z treścią pkt 11 poniżej.
      </p>
      <p>
      7. W chwili zwrotu sprzętu, w protokole wypożyczania lub innym dokumencie stwierdza się stan sprzętu, w szczególności terminowość jego zwrotu oraz ewentualne uszkodzenia lub braki, jak również zabrudzenie. W uzasadnionych przypadkach wykonuje się dokumentację fotograficzną.
      </p>
      <p>
      8. Sprzęt będący przedmiotem umowy wypożyczenia jest własnością Decathlon. Klient zobowiązany jest do używania sprzętu wyłącznie w celu i w terminie określonym w umowie wypożyczenia, bez prawa dalszego wypożyczenia/ udostępnienia sprzętu osobom trzecim. Klient nie może używać wypożyczonego sprzętu do celów zawodowych.
      </p>
      <p>
      9. Umowa wypożyczenia oraz umowa rezerwacyjna mogą być zawarte tylko na pełne dni, przyjmując jeden dzień jako najmniejszy możliwy czas wypożyczenia sprzętu.
      </p>
      <p>
      10. Klient dokonuje zapłaty za wypożyczenie sprzętu z góry, w momencie dokonania rezerwacji sprzętu na stronie internetowej https://rent.decathlon.pl/ według wybranego przez siebie sposobu zapłaty, tj. za pomocą wskazania danych z karty płatniczej lub za pomocą systemu „Przelewy24”. Oprócz dokonania opłaty za wypożyczenie sprzętu, Klient, po oddaniu sprzętu do wskazanego sklepu Decathlon może zostać zobowiązany do uiszczenia w kasie sklepu następujących dodatkowych opłat: (i)odszkodowania za opóźnienie w zwrocie wypożyczonego sprzętu, o którym mowa w ust. 15 poniżej, (ii) opłaty za brak zwrotu wypożyczonego sprzętu, o którym mowa w ust. 15 poniżej, (iii) opłaty serwisowej za zwrot brudnego lub mokrego sprzętu, o której mowa w ust. 17 poniżej oraz (iv) kosztów naprawy, o których mowa w ust. 18 poniżej. Podmiotem odpowiedzialnym za przetwarzanie danych osobowych przy dokonywaniu płatności za wypożyczenie sprzętu  jest Stripe Payments Europe Ltd., spółka zarejestrowana w Irlandii z siedzibą pod adresem 1 Grand Canal Street Lower, Grand Canal Dock, Dublin.
      </p>
      <p>
      11. Zapoznając się z treścią niniejszego Regulaminu Klient niniejszym oświadcza, iż został poinformowany o braku możliwości skorzystania z ustawowego prawa odstąpienia od umowy rezerwacyjnej zawartej na odległość ze względu na fakt, iż usługa rezerwacyjna zostaje wykonana w całości przez Decathlon w momencie wprowadzenia do systemu teleinformatycznego informacji o rezerwacji przez Klienta wybranego sprzętu we wskazanej dacie oraz Klient oświadcza, iż jest świadomy faktu, że pomimo powyższego wyłączenia, nadal przysługuje mu możliwość anulowania rezerwacji za zwrotem pieniędzy, do dnia wskazanego w rezerwacji jako pierwszy dzień wypożyczenia sprzętu.
      </p>
      <p>
      12. Klient zobowiązany jest sprawdzić stan techniczny oraz sprawność sprzętu przed rozpoczęciem wypożyczenia. Klient oświadcza, że posiada niezbędną wiedzę na temat zasad prawidłowego używania wypożyczonego sprzętu i zobowiązuje się do używania go w sposób niezagrażający zdrowiu lub życiu własnemu i osób trzecich oraz bez narażenia sprzętu na zniszczenie lub uszkodzenie.
      </p>
      <p>
      13. Klient podpisując protokół wypożyczenia sprzętu potwierdza, że otrzymał sprzęt w stanie technicznym odpowiadającym opisowi zawartemu w protokole wypożyczania. Klient zobowiązuje się zwrócić sprzęt w stanie niepogorszonym, z uwzględnieniem normalnego zużycia i w terminie ustalonym w protokole wypożyczenia.
      </p>
      <p>
      14. Klient ponosi odpowiedzialność cywilną oraz karną za kradzież, zaginięcie, zniszczenie, uszkodzenie lub/i zdekompletowanie wypożyczonego sprzętu.</p>
      <p>
      15. JJeżeli Klient nie może zwrócić wypożyczonego sprzętu w ustalonym terminie, powinien przed upływem terminu zwrotu poinformować o tym telefonicznie sklep, w którym nastąpiło wypożyczenie lub skorzystać z systemu komunikacji z Decathlon, znajdującego się na stronie https://rent.decathlon.pl/. Przedłużenie okresu wypożyczenia wymaga uzyskania akceptacji Decathlon. W przypadku, kiedy Klient nie zwróci sprzętu w terminie i nie poinformuje o tym Decathlon lub nie uzyska akceptacji Decathlon na przedłużenie okresu wypożyczenia, jest zobowiązany do zapłaty odszkodowania za opóźnienie w zwrocie wypożyczonego sprzętu za każdy dzień opóźnienia. Odszkodowanie za każdy dzień opóźnienia w zwrocie wypożyczonego sprzętu wynosi równowartość 100% opłaty dziennej za wypożyczenie danego sprzętu, ustalonej na podstawie cennika wypożyczania obowiązującego w Decathlon, umieszczonego na stronie internetowej https://rent.decathlon.pl/ pod pozycją danego sprzętu do wypożyczenia. Powyższe odszkodowanie naliczane będzie  maksymalnie do wysokości kwoty odpowiadającej pełnej wartości takiego nowego sprzętu obowiązującej w sklepie Decathlon zgodnie z cennikiem umieszczonym na stronie internetowej www.decathlon.pl.
      </p>
      <p>
      16. W uzasadnionych przypadkach, gdy Klient nie miał możliwości zwrotu sprzętu przed upływem terminu, o którym mowa w ostatnim zdaniu ust. 15, Decathlon, po rozpatrzeniu indywidualnie każdego przypadku braku możliwości zwrotu wypożyczonego sprzętu w terminie, zwróci Klientowi pobrane środki odpowiadające kwocie odszkodowania za brak zwrotu sprzętu, pobierając jedynie kwotę odpowiadającą kwocie odszkodowania za każdy nieusprawiedliwiony dzień opóźnienia w zwrocie sprzętu, do daty zwrotu sprzętu, nieprzekraczającej jednak 50% wartości takiego nowego sprzętu oraz, jeśli zajdzie taka konieczność, należności, o których mowa w ust. 17 i 18.
      </p>
      <p>
      17. Klient zobowiązuje się zwrócić czysty i suchy sprzęt. W przypadku zwrócenia brudnego lub mokrego sprzętu, Klient jest zobowiązany uiścić jednorazową opłatę serwisową w wysokości 80 zł, odpowiadającej kosztowi czyszczenia sprzętu w kasie sklepu.
      </p>
      <p>
      18. W przypadku uszkodzenia lub/i zdekompletowania wypożyczonego sprzętu, zostaną Klient obowiązany jest do zapłaty sumy odpowiadającej kosztom naprawy przez Decathlon wypożyczonego sprzętu, ustalonej na podstawie wystawionego w tym celu przez Decathlon dokumentu serwisowego lub/i dokumentu księgowego zawierającego kosztorys naprawy. Ceny poszczególnych części zamiennych sprzętu oraz ceny serwisu sprzętu dostępne są odpowiednio na stronie internetowej Decathlon pod poniżej wskazanym adresem:
      - ceny serwisu: www.decathlon.pl/serwis.html, 
      - ceny części zamiennych: www.decathlon.pl/C-995348.
      W przypadku braku ceny danej części zamiennej lub usługi serwisu na stronie internetowej, o której mowa w zdaniu poprzednim, ceny te ustalone będą według średnich cen rynkowych obowiązujących w danym momencie w serwisach specjalistycznych.
      </p>
      <p>
      19. Miejscem wykonania umowy wypożyczenia jest sklep, w którym nastąpiło udostępnienie sprzętu do wypożyczenia.
      </p>
      <p>
      20. W celu zapłaty wynagrodzenia za umowę wypożyczenia, Klient może posłużyć się kuponami rabatowymi (dalej: „Kupony rabatowe”), wydawanymi przez uprawnione podmioty współpracujące z Decathlon, wskazane na stronie internetowej https://rent.decathlon.pl/. W takim przypadku, Klient wpisuje podczas dokonywania rezerwacji kod Kuponu rabatowego, uprawniającego do otrzymania zniżki za wypożyczany sprzęt we wskazanym miejscu na wyżej wskazanej stronie internetowej w zakresie wypożyczanego sprzętu. W chwili wpisania kodu Kuponu rabatowego, wartość zamówienia ulega odpowiedniemu obniżeniu o wartość Kuponu rabatowego. W przypadku, gdy wartość Kuponu rabatowego jest niższa, niż wysokość należności z tytułu wypożyczanego sprzętu, Klient jest zobowiązany do pokrycia różnicy pozostającej do uregulowania w drodze płatności za wypożyczany sprzęt, dokonanej za pośrednictwem płatności online (operator płatności, o którym mowa w pkt. 10 powyżej). W przypadku, gdy wartość Kuponu rabatowego jest wyższa, niż wysokość należności z tytułu wypożyczanego sprzętu, Klient nie jest uprawniony do otrzymania zwrotu różnicy pomiędzy kwotą należną z tytułu zawartej umowy wypożyczenia, a kwoty uiszczonej w drodze Kuponu rabatowego. Kupony rabatowe nie podlegają łączeniu z innymi promocjami Decathlon Rent. Kupony rabatowe nie podlegają wymianie na gotówkę, na żaden inny środek płatniczy, ani nie mogą być wykorzystane w formie rekompensaty.
      </p>
      <p>21. W związku z obowiązkiem wynikającym z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.Urz.UE.L Nr 119, str. 1) (dalej: RODO), informujemy, że w odniesieniu do umów wypożyczenia zawieranych na podstawie Regulaminu z Decathlon:</p>
      <ul>
        <li>A. Administratorem danych osobowych Klientów jest Decathlon sp. z o. o. z siedzibą w Warszawie (kod pocztowy: 03-290 Warszawa), ul. Geodezyjna 76.</li>
        <li>B. W sprawach związanych z ochroną danych osobowych Klienci mogą skontaktować się z Inspektorem ochrony danych pod adresem mailowym iod@decathlon.net lub z Administratorem pisemnie na adres siedziby Administratora.</li>
        <li>C. Dane osobowe Klientów przetwarzane będą w celu: wykonania umowy wypożyczenia, zawartej na podstawie zaakceptowanego przez Klienta Regulaminu (podstawę przetwarzania stanowi art. 6 ust. 1 lit. b RODO), spełnienia zobowiązań prawnych, w szczególności realizacji obowiązku prowadzenia sprawozdawczości finansowej i rozliczeń podatkowych (podstawę przetwarzania stanowi art. 6 ust. 1 lit. c RODO), dochodzenia lub obrony przed ewentualnymi roszczeniami (podstawę przetwarzania stanowi w tym przypadku art. 6 ust. 1 lit b RODO)</li>
        <li>D. Odbiorcami danych osobowych Klientów mogą być podmioty wchodzące w skład grupy DECATHLON S.A. jak również instytucje płatnicze, określone w ust. 10 powyżej Regulaminu. Ponadto, dane osobowe Klientów mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie Administratora, m.in. dostawcom usług IT, kancelariom prawnym lub firmom księgowym – przy czym takie podmioty przetwarzają dane na podstawie umowy z Administratorem i wyłącznie zgodnie z poleceniami Administratora.</li>
        <li>E. Dane osobowe Klientów przetwarzane będą do momentu: zakończenia okresu wypożyczenia sprzętu sportowego zgodnie z Regulaminem, przedawnienia roszczeń wynikających z zaakceptowanego przez Klienta Regulaminu, wygaśnięcia obowiązku przechowywania danych wynikającego z przepisów prawa.</li>
        <li>F. W związku z przetwarzaniem danych osobowych, Klienci posiadają następujące uprawnienia: prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
        <li>G. Klienci posiadają prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uznają, iż przetwarzanie ich danych osobowych narusza przepisy RODO.</li>
        <li>H. Dane Klientów mogą być przekazywane do państw trzecich w ramach infrastruktury informatycznej, z której korzysta Administrator. W przypadkach wskazanego przekazania stosowane są odpowiednie i właściwe zabezpieczenia wymagane przez przepisy o ochronie danych osobowych oraz umożliwia się uzyskanie kopii danych przekazanych do państw trzecich.</li>
        <li>I. Podanie przez Klientów danych osobowych jest dobrowolne, jednak jest niezbędne do prawidłowego przebiegu wypożyczania sprzętu na podstawie zaakceptowanego przez Klienta Regulaminu. Brak przekazania danych osobowych będzie podstawą do odmowy możliwości wypożyczenia sprzętu objętego niniejszym Regulaminem.</li>
      </ul>
      <p>Dane osobowe Klientów wypożyczających sprzęt od Wypożyczalni są przetwarzane przez Wypożyczalnie, zgodnie z powszechnie obowiązującymi przepisami prawa, na zasadach wskazanych na stronie Wypożyczalni. Za przetwarzanie danych Klienta, który wypożyczył sprzęt od Wypożyczalni odpowiedzialna jest wyłącznie Wypożyczalnia, jako administrator danych osobowych Klienta.</p>
      <p>22. Klient oświadcza, że korzystając z usługi wypożyczenia sprzętu na podstawie niniejszego Regulaminu wyraził zgodę na otrzymywanie od Decathlon informacji potrzebnych do wykonania przedmiotu umowy wypożyczenia, przesyłanych środkami komunikacji elektronicznej.</p>
      <p>23. Klient oświadcza, że zapoznał się z polityką prywatności Decathlon, regulującą w sposób kompleksowy kwestie związane z ochroną danych osobowych, plików cookies i innych, bezpośrednio wpływających na dane osobowe i inne dane dotyczące Klienta, dostępną pod adresem elektronicznym: https://www.decathlon.pl/polityka-prywatnosci.html.</p>
      <p>24. Klient oświadcza, że na podstawie art. 106n ust. 1 ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (Dz. U. 2018, poz. 2174, z późn. zm.) wyraża zgodę na przesyłanie faktur z tytułu wypożyczenia sprzętu oraz innych opłat zgodnie z niniejszym Regulaminem, duplikatów ww. faktur oraz ich korekt w formie elektronicznej na adres e-mail wskazany przez Klienta przy zakładaniu konta na platformie Decathlon Rent wypożyczenia. W przypadku zmiany adresu e-mail, Klient zobowiązany jest do niezwłocznego powiadomienia Decathlon o nowym adresie e-mail, pod rygorem uznania doręczenia przedmiotowych faktur, ich duplikatów oraz korekt za skuteczne na ostatni znany adres e-mail Klienta.</p>
      <p>25. Klient oświadcza, że akceptuje treść niniejszego Regulaminu.</p>
      <p>
      26. Decathlon zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn obejmujących:
      <ul>
        <li>zmiany w prawie;</li>
        <li>przyczyny organizacyjne, w szczególności:</li>
        <li>zmiany w zakresie świadczonej  przez Decathlon Rent usługi wypożyczania w postaci zmian jej profilu, zmian zakresu sprzętu, wprowadzania nowych usług lub udogodnień związanych z projektem Decathlon Rent lub też rozszerzenia zakresu usług Decathlon Rent o nowe usługi powiązane z projektem Decathlon Rent, zmiany w zakresie polityki handlowej projektu Decathlon Rent;</li>
        <li>zmiany w zakresie: danych adresowych, nazwy lub formy prawnej Decathlon,</li>
        <li>inne zmiany techniczne związane z projektem Decathlon Rent.</li>
      </ul>
      <p>
        Klient zostanie poinformowany o treści zmian Regulaminu poprzez umieszczenie na Stronie internetowej informacji o zmianie Regulaminu nie później niż na 14 dni przed momentem wejścia w życie nowych postanowień Regulaminu.

        Dodatkowo, każdy Klient, który posiada konto na Decathlon Rent, zostanie także powiadomiony o zmianie Regulaminu przez przesłanie na adres jego poczty elektronicznej informacji o zmianie Regulaminu i dniu jego wejścia w życie, nie później niż 14 dni przed tym dniem. 
        W razie zmiany Regulaminu wszystkie umowy rezerwacji i wypożyczenia zawarte przed datą wejścia w życie tej zmiany będą realizowane zgodnie z Regulaminem w brzmieniu obowiązującym w dniu zawarcia Umowy sprzedaży.
        </p>
      </p>
      <p>27. Niniejszy Regulamin obowiązuje od dnia 06.08.2021 r.</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
