import React, { useState } from 'react';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { stringify } from '../../util/urlHelpers';
import { Input, Select } from '../../components';

import css from './ManageListingsPage.css';

const STATE_OPTIONS = [
  { key: 'all', label: "ManageListingsPage.all" },
  { key: 'published', label: "ManageListingsPage.published" },
  { key: 'draft', label: "ManageListingsPage.draft" },
  { key: 'closed', label: "ManageListingsPage.closed" },
];

const SORT_OPTIONS = [
  { key: 'createdAt', label: "ManageListingsPage.oldest" },
  { key: '-createdAt', label: "ManageListingsPage.newest" },
];

const OwnListingsFilter = ({ history, queryParams, intl }) => {
  const [productName, setProductName] = useState(queryParams.keywords || '');
  const [state, setState] = useState(queryParams.state || 'all');
  const [sort, setSort] = useState(queryParams.sort || '-createdAt');

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`${history.location.pathname}?${stringify({ ...queryParams, keywords: productName, page: 1 })}`);
  }

  const handleStateChange = (e) => {
    setState(e.target.value);
    history.push(`${history.location.pathname}?${stringify({ ...queryParams, state: e.target.value, page: 1 })}`);
  }

  const handleSortChange = (e) => {
    setSort(e.target.value);
    history.push(`${history.location.pathname}?${stringify({ ...queryParams, sort: e.target.value, page: 1 })}`);
  }

  return (
    <form className={css.ownListingsFilterWrapper} onSubmit={handleSearch}>
      <div className={css.fields}>
        <Select
          value={state}
          onChange={handleStateChange}
          label={intl.formatMessage({id: "ManageListingsPage.statusField" })}
          isValidation={false}
        >
          {
            STATE_OPTIONS.map(state => {
              return (
                <option value={state.key}>{intl.formatMessage({ id: state.label })}</option>
              )
            }
            )
          }
        </Select>
        <Select
          value={sort}
          onChange={handleSortChange}
          label={intl.formatMessage({id: "ManageListingsPage.sortField" })}
          isValidation={false}
        >
          {
            SORT_OPTIONS.map(sort => {
              return (
                <option value={sort.key}>{intl.formatMessage({ id: sort.label })}</option>
              )
            }
            )
          }
        </Select>
      </div>
      <div className={css.ownListingsPhraseFilterWrapper}>
        <Input
          type="text"
          onChange={(e)=>setProductName(e.target.value)}
          value={productName}
          placeholder={intl.formatMessage({ id: 'OwnFilters.searchProduct' })}
          isValidation={false}
          containerClassName={css.containerLabel}
        />
        <button type="submit" className={css.submitBtn}><SearchIcon /></button>
      </div>
    </form>
  );
}

export default injectIntl(OwnListingsFilter);


function SearchIcon() {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="8" r="7" stroke="white" strokeWidth="2" />
      <circle cx="10" cy="8" r="5" stroke="#3643BA" fill="#3643BA" strokeWidth="2" className={css.innerCircle}/>
      <path d="M6 13.5L1 18.5" stroke="white" strokeWidth="2" />
    </svg>
  );
}
