import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, LocationAutocompleteInputField } from '../../components';

import css from './BusinessSettingsForm.css';

const identity = v => v;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  render() {
    const isDecathlonUser = !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.decathlon_user);
    const isExternalProvider = !isDecathlonUser && !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.is_provider);
    const displayCompanySection = isDecathlonUser || isExternalProvider;

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadInProgress,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // Business name
          const businessNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.businessNameLabel',
          });
          const businessNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.businessNamePlaceholder',
          });
          const businessNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.businessNameRequired',
          });
          const businessNameRequired = validators.required(businessNameRequiredMessage);

          // Business location
          const businessLocationLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.businessLocationLabel',
          });
          const businessLocationPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.businessLocationPlaceholder',
          });

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              {
                displayCompanySection &&
                <div className={css.sectionContainer}>
                  <FieldTextInput
                    className={css.businessName}
                    type="text"
                    id="businessName"
                    name="businessName"
                    label={businessNameLabel}
                    placeholder={businessNamePlaceholder}
                  />
                  <LocationAutocompleteInputField
                    rootClassName={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    autoFocus
                    name="businessLocation"
                    label={businessLocationLabel}
                    placeholder={businessLocationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.businessLocation}
                  />
                </div>
              }
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
