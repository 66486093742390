import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { func } from 'prop-types';

import { ResponsiveImage } from '../..';
import { propTypes } from '../../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { Modal, Button, InlineTextButton } from '../../../components';

import css from './PartialCancelModal.css';

const PartialCancelModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    partialCancelInProgress,
    partialCancelError,
    partialCancelSuccess,
    onPartialCancelOrder,
    transaction,
    actualOrderedProducts,
  } = props;
  const { listings } = transaction;
  const classes = classNames(rootClassName || css.root, className);
  const productsWithActualSeats = actualOrderedProducts.reduce(
    (accumulator, { listingId, seats }) => {
      accumulator[listingId] = accumulator[listingId] ? accumulator[listingId] : 0;
      accumulator[listingId] += seats;
      return accumulator;
    },
    {}
  );
  const [productSeats, setProductSeats] = useState(productsWithActualSeats);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmQuestion, setIsConfirmQuestion] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = event => {
    setProductSeats(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClose = () => {
    setProductSeats(productsWithActualSeats);
    setIsConfirmQuestion(false);
    onCloseModal();
    setIsError(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (isConfirmQuestion) {
      const items = [];
      for (let key in productSeats) {
        const { seats } = actualOrderedProducts.find(({ listingId }) => listingId === key);
        const amount = seats - productSeats[key];
        amount && items.push({ listingId: key, amount });
      }

      onPartialCancelOrder(transaction.id.uuid, items);
    } else {
      setIsConfirmQuestion(true);
    }
  };

  const compareSeats = (productSeats, actualOrderedProducts) => {
    let isNotChanged = true;

    actualOrderedProducts.forEach(({ listingId, seats }) => {
      if (seats !== productSeats[listingId]) isNotChanged = false;
    });

    return isNotChanged;
  };

  const checkSeatsOfProduct = productSeats => {
    let isZeroQuantity = true;

    for (let key in productSeats) {
      if (productSeats[key] > 0) isZeroQuantity = false;
    }

    return isZeroQuantity;
  };

  useEffect(() => {
    const condition =
      checkSeatsOfProduct(productSeats) || compareSeats(productSeats, actualOrderedProducts);
    setIsDisabled(condition);
  }, [productSeats]);

  useEffect(() => {
    partialCancelSuccess && handleClose();
    partialCancelError && setIsError(true);
  }, [partialCancelSuccess, partialCancelError]);

  const questionConfirmClass = isError ? css.error : css.confirmQuestion;

  const selectNumber = itemNumber => {
    const arrayItems = [];
    for (let i = itemNumber; i >= 0; i--) {
      arrayItems.push(i);
    }

    return arrayItems.map(item => <option value={item}>{item}</option>);
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      isOpen={isOpen}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <div className={css.modal}>
        <div className={css.modalContent}>
          <p className={css.modalTitle}>
            <FormattedMessage id="PartialCancelModal.title" />
          </p>
          {isConfirmQuestion ? (
            <p className={classNames(css.text, questionConfirmClass)}>
              <FormattedMessage
                id={isError ? 'PartialCancelModal.error' : 'PartialCancelModal.confirmQuestion'}
              />
            </p>
          ) : (
            actualOrderedProducts.map(({ listingId, seats }) => {
              const listing = listings.find(({ id: { uuid } }) => uuid === listingId);
              const { uuid } = listing.id;
              const image = listing?.images[0];
              const { title } = listing.attributes;
              return productsWithActualSeats[listingId] ? (
                <div className={css.productContainer} key={uuid}>
                  {image && (
                    <ResponsiveImage
                      rootClassName={css.rootForImage}
                      image={image}
                      alt={'product'}
                      variants={['square-small', 'square-small2x']}
                    />
                  )}
                  <div className={css.productContent}>
                    <p className={css.productName}>{title.toLowerCase()}</p>
                    <div className={css.productDetails}>
                      <p className={css.text}>
                        <FormattedMessage id="PartialCancelModal.quantity" /> <b>{seats}</b>
                      </p>
                      <div>
                        <label className={css.label}>
                          <FormattedMessage id="PartialCancelModal.label" />
                        </label>
                        <select
                          value={productSeats[listingId]}
                          name={listingId}
                          onChange={handleChange}
                        >
                          {selectNumber(productsWithActualSeats[listingId])}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null;
            })
          )}
        </div>
        <div className={css.actionSection}>
          <InlineTextButton className={css.cancelBtn} onClick={handleClose}>
            <FormattedMessage id="PartialCancelModal.cancel" />
          </InlineTextButton>
          <Button
            className={css.saveBtn}
            inProgress={partialCancelInProgress}
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            <FormattedMessage id="PartialCancelModal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

PartialCancelModal.defaultProps = {
  className: null,
  rootClassName: null,
};

PartialCancelModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  partialCancelInProgress: bool,
  partialCancelError: propTypes.error,
  onPartialCancelOrder: func.isRequired,
};

export default injectIntl(PartialCancelModal);
