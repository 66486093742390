import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { bool, shape, string, oneOf }  from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData } from './PayoutTransactionsPage.duck';
import {
  Page,
  PaginationLinks,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperBusinessSideNav,
  Footer,
  Table,
  NamedLink,
  IconArrowHead,
  TableSkeleton,
} from '../../components';
import { TopbarContainer } from '..';

import css from './PayoutTransactionsPage.css';

const PayoutTransactionsPageComponent = props => {
  const {
    intl,
    params,
    location: { search },
    scrollingDisabled,
    transactions,
    queryPayoutTransactionsInProgress,
    queryPayoutTransactionsError,
    queryPayoutTransactionsSuccess,
    pagination,
    name,
  } = props;
  const title = intl.formatMessage({ id: 'PayoutTransactionPage.title' });
  const { id } = params;

  const paginationLinks =
    !queryPayoutTransactionsInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={params}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = queryPayoutTransactionsError ? (
    <p className={css.error}>{queryPayoutTransactionsError?.message}</p>
  ) : null;

  const noResultsMessage =
    !queryPayoutTransactionsInProgress && !transactions?.length && !queryPayoutTransactionsError ? (
      <p className={css.noResults}>
        <FormattedMessage id="PayoutTransactionPage.noPayoutFound" />
      </p>
    ) : null;

  const tableConfig = [
    {
      label: intl.formatMessage({ id: 'PayoutTransactionPage.reservationNumber' }),
      render: transaction => { 
        return transaction?.attributes?.code && transaction?.id?.uuid ?
       (
          <NamedLink
            className={css.code} 
            name={'SaleDetailsPage'}
            params={{ id: transaction?.id?.uuid }}
          >
            {transaction?.attributes?.code}
          </NamedLink>
        ) : null
      }
    },
    {
      label: intl.formatMessage({ id: 'PayoutTransactionPage.date' }),
      render: transaction =>
        intl.formatDate(moment(transaction?.attributes?.createdAt), {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {
      label: intl.formatMessage({ id: 'PayoutTransactionPage.amount' }),
      render: transaction => formatMoney(intl, transaction?.attributes?.payoutTotal),
    },
    {
      label: intl.formatMessage({ id: 'PayoutTransactionPage.FirstAndLastName' }),
      render: transaction =>
        `${transaction?.attributes?.firstName} ${transaction?.attributes?.lastName}`,
    },
  ];

  const keyFn = payouts => payouts?.id.uuid;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={name} />
          <UserNav selectedPageName={name} />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="PayoutPage" />
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.navigation}>
            <NamedLink
              className={classNames(css.linkText, css.link)}
              name="PayoutPage"
              params={{ id }}
            >
              {intl.formatMessage({ id: "PayoutTransactionPage.payoutDetails" })}
            </NamedLink>
            <IconArrowHead direction={'right'} className={css.arrow} />
            <span className={css.linkText}>
            {intl.formatMessage({ id: "PayoutTransactionPage.transactionList" })}
              </span>
          </div>
          <div className={css.title}>
            {intl.formatMessage(
              { id: 'PayoutTransactionPage.header' },
              { date: parse(search)?.date }
            )}
          </div>
            {queryPayoutTransactionsInProgress ? (
               <div className={css.wrapper}>
                <TableSkeleton rowNumber={8} columnNumber={tableConfig.length} classNameCell={css.cell}/>
              </div>
            ) : (
              <div className={css.wrapper}>
                {transactions?.length ? (
                  <Table
                    data={transactions}
                    config={tableConfig}
                    keyFn={keyFn}
                    classNameCell={css.cell}
                  />
                ) : null}
                 {paginationLinks}
              </div>
            )}
          {errorMessage}
          {noResultsMessage}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutTransactionsPageComponent.propTypes = {
  pagination: propTypes.pagination,
  queryPayoutTransactionsInProgress: bool.isRequired,
  queryPayoutTransactionsError: oneOf([bool, propTypes.error]),
  queryPayoutTransactionsSuccess: bool,
  transaction: propTypes.transaction,
  name: string,
  scrollingDisabled: bool.isRequired,
  params:  shape({ id: string, payoutId: string }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    pagination,
    transactions,
    queryPayoutTransactionsInProgress,
    queryPayoutTransactionsError,
    queryPayoutTransactionsSuccess,
  } = state.PayoutTransactionsPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    transactions,
    queryPayoutTransactionsInProgress,
    queryPayoutTransactionsError,
    queryPayoutTransactionsSuccess,
    pagination,
  };
};

const PayoutTransactionsPage = compose(
  connect(mapStateToProps, null),
  injectIntl,
  withRouter
)(PayoutTransactionsPageComponent);

PayoutTransactionsPage.loadData = loadData;

export default withRouter(PayoutTransactionsPage);
