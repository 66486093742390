export default function(purchase) {
  const { currency, transactionId, revenue, product: { name, productId, price, category, brand, days }} = purchase;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'booking',
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: transactionId,
          revenue,
        },
        products: [{
          name,
          id: productId,
          price,
          category,
          quantity: days,
          brand
         }]
      }
    },
    value: revenue,
    items: [
      {
        id: productId, 
        google_business_vertical: 'retail'
      }
    ]
  });
}