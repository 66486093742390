import {
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
} from '../../util/transaction';
import { LINE_ITEM_DAY } from '../../util/types';


const CONFIRM_PAYMENT = 'confirm-payment';
const PAYMENTLESS_ACCEPT =  "paymentless-accept";

export const setOrderedProducts = (lineItems, transitions) => {

  const confirmPaymentStatus = [
    TRANSITION_CONFIRM_PAYMENT,
    CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
    TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
    PAYMENTLESS_ACCEPT
  ];

  const productsWithActualSeats = lineItems.reduce((accumulator, item) => {
    if (item?.code === LINE_ITEM_DAY) {
      const { listingId, reversal, seats } = item;
      accumulator[listingId] = accumulator[listingId] ? accumulator[listingId] : 0;
      accumulator[listingId] += (reversal ? -1 : 1) * seats;
    }
    return accumulator;
  }, {});

  const confirmTransaction = transitions.find(({ transition }) =>
    confirmPaymentStatus.includes(transition)
  );

  const confirmLineItems = confirmTransaction
    ? lineItems.slice(
        confirmTransaction.relLineItemIndex,
        confirmTransaction.relLineItemIndex + confirmTransaction.relLineItemsAmount
      )
    : [];

  const actualProductList = confirmLineItems
    .filter(item => item?.code === LINE_ITEM_DAY)
    .map(item => ({
      ...item,
      seats: productsWithActualSeats[item.listingId],
    }));

  const sortedProducts = actualProductList
    .filter(item => item.seats > 0)
    .sort((a, b) => b.unitPrice.amount - a.unitPrice.amount);

  return [...sortedProducts, ...actualProductList.filter(item => item.seats === 0)];
};
