import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import css from './AddImages.css';
import RemoveImageButton from './RemoveImageButton';

const ItemTypes = {
  IMAGE: 'image',
};

const ThumbnailWrapper = props => {
  const { className, image, savedImageAltText, onRemoveImage, index, moveImage, dragable } = props;

  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => {
      return { id: image.id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  dragable && drag(drop(ref));

  const handleImageLeft = () => {
    moveImage(index, index - 1)
  }

  const handleImageRight = () => {
    moveImage(index, index + 1)
  }

  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };

  const opacity = isDragging ? 0.5 : 1;

  if (image.file) {
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <div ref={ref} style={{ opacity }} className={classNames(css.thumbnail, className)}>
        <ImageFromFile
          id={image.id}
          className={className}
          rootClassName={css.thumbnail}
          file={image.file}
        >
          {removeButton}
          {uploadingOverlay}
        </ImageFromFile>
      </div>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div ref={ref} style={{ opacity }} className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
        {/* <button onClick={handleImageLeft}>{'<'}</button>
        <button onClick={handleImageRight}>{'>'}</button> */}
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  index: PropTypes.number.isRequired,
  moveImage: PropTypes.func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onUpdateImageOrder,
    form,
    dragable
  } = props;

  const imageList = images;

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...imageList];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    onUpdateImageOrder(updatedImages.map(i => i.id.uuid))
    // form.change('images', updatedImages)
  };

  const removeImage = (id) => {
    // form.change('images', images.filter(im => im.id.uuid !== id.uuid));
    onRemoveImage(id);
  }

  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      {imageList.map((image, index) => {
        return (
          <ThumbnailWrapper
            image={image}
            index={index}
            key={image.id.uuid || image.id}
            className={thumbnailClassName}
            savedImageAltText={savedImageAltText}
            onRemoveImage={removeImage}
            moveImage={moveImage}
            dragable={dragable}
          />
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [], dragable: true };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImagesExport = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AddImages {...props} />
    </DndProvider>
  )
}

export default AddImagesExport;