import React from 'react';
import { FormattedMessage } from './util/reactIntl';

const seasonalSkiCategoryMaybe = process.env.REACT_APP_COUNTRY_ID == 'cz' ? [{ key: 'wintersports_seasonalski', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsSeasonalSki" />, translation: 'FiltersConfig.wintersportsSeasonalSki'}] : [];

const businessCategoryConfig = [
    { key: 'bikes_electric', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesElectric" />, translation: "FiltersConfig.bikesElectric" },
    { key: 'bikes_mountain', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesMountain" />, translation: "FiltersConfig.bikesMountain" },
    { key: 'bikes_gravel', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesGravel" />, translation: "FiltersConfig.bikesGravel" },
    { key: 'bikes_city', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesCity" />, translation: "FiltersConfig.bikesCity" },
    { key: 'bikes_road', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesRoad" />, translation: "FiltersConfig.bikesRoad" },
    { key: 'bikes_kids', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesKids" />, translation: "FiltersConfig.bikesKids" },
    { key: 'bikes_other', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesOther" />, translation: "FiltersConfig.bikesOther" },
    { key: 'bikes_trailer', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesTrailer" />, translation: "FiltersConfig.bikesTrailer" },
    { key: 'bikes_seat', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesSeat" />, translation: "FiltersConfig.bikesSeat" },
    { key: 'bikes_accessories', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesAccessories" />, translation: "FiltersConfig.bikesAccessories" },
    { key: 'bikes_treadmill', category: 'bikes', label: <FormattedMessage id="FiltersConfig.bikesTreadmill" />, translation: "FiltersConfig.bikesTreadmill" },
    { key: 'camping_tent', category: 'camping', label: <FormattedMessage id="FiltersConfig.campingTent" />, translation: "FiltersConfig.campingTent" },
    { key: 'camping_accessories', category: 'camping', label: <FormattedMessage id="FiltersConfig.campingAccessories" />, translation: "FiltersConfig.campingAccessories" },
    { key: 'camping_campers', category: 'camping', label: <FormattedMessage id="FiltersConfig.campingCampers" />, translation: "FiltersConfig.campingCampers" },
    { key: 'camping_other', category: 'camping', label: <FormattedMessage id="FiltersConfig.campingOther" />, translation: "FiltersConfig.campingOther" },
    { key: 'watersports_sup', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsSup" />, translation: "FiltersConfig.watersportsSup" },
    { key: 'watersports_kayak', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsKayak" />, translation: "FiltersConfig.watersportsKayak" },
    { key: 'watersports_other', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsOther" />, translation: "FiltersConfig.watersportsOther" },
    { key: 'watersports_lifejacket', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsLifejacket" />, translation: "FiltersConfig.watersportsLifejacket" },
    { key: 'watersports_accessories', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsAccessories" />, translation: "FiltersConfig.watersportsAccessories" },
    { key: 'watersports_suit', category: 'watersports', label: <FormattedMessage id="FiltersConfig.watersportsSuit" />, translation: "FiltersConfig.watersportsSuit" },
    { key: 'transport_bikes', category: 'transport', label: <FormattedMessage id="FiltersConfig.transportBikes" />, translation: "FiltersConfig.transportBikes" },
    { key: 'transport_box', category: 'transport', label: <FormattedMessage id="FiltersConfig.transportBox" />, translation: "FiltersConfig.transportBox" },
    { key: 'transport_accessories', category: 'transport', label: <FormattedMessage id="FiltersConfig.transportAccessories" />, translation: "FiltersConfig.transportAccessories" },
    { key: 'transport_other', category: 'transport', label: <FormattedMessage id="FiltersConfig.transportOther" />, translation: "FiltersConfig.transportOther" },
    { key: 'tourism_tent', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismTent" />, translation: "FiltersConfig.tourismTent" },
    { key: 'tourism_backpack', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismBackpack" />, translation: "FiltersConfig.tourismBackpack" },
    { key: 'tourism_sticks', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismSticks" />, translation: "FiltersConfig.tourismSticks" },
    { key: 'tourism_crampons', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismCrampons" />, translation: "FiltersConfig.tourismCrampons" },
    { key: 'tourism_axe', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismAxe" />, translation: "FiltersConfig.tourismAxe" },
    { key: 'tourism_carrier', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismCarrier" />, translation: "FiltersConfig.tourismCarrier" },
    { key: 'tourism_other', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismOther" />, translation: "FiltersConfig.tourismOther" },
    { key: 'tourism_snowshoes', category: 'tourism', label: <FormattedMessage id="FiltersConfig.tourismSnowshoes" />, translation: "FiltersConfig.tourismSnowshoes" },
    { key: 'wintersports_alpineskiing', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsAlpineskiing" />, translation: "FiltersConfig.wintersportsAlpineskiing" },
    { key: 'wintersports_snowboard', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsSnowboard" />, translation: "FiltersConfig.wintersportsSnowboard" },
    { key: 'wintersports_skitouring', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsSkitouring" />, translation: "FiltersConfig.wintersportsSkitouring" },
    { key: 'wintersports_crosscountry', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsCrosscountry" />, translation: "FiltersConfig.wintersportsCrosscountry" },
    ...seasonalSkiCategoryMaybe,
    { key: 'wintersports_other', category: 'wintersports', label: <FormattedMessage id="FiltersConfig.wintersportsOther" />, translation: "FiltersConfig.wintersportsOther" },
    { key: 'scooter_electric', category: 'scooter', label: <FormattedMessage id="FiltersConfig.scooterElectric" />, translation: "FiltersConfig.scooterElectric" },
    { key: 'scooter_city', category: 'scooter', label: <FormattedMessage id="FiltersConfig.scooterCity" />, translation: "FiltersConfig.scooterCity" },
    { key: 'fitness_cardio', category: 'fitness', label: <FormattedMessage id="FiltersConfig.fitnessCardio" />, translation: "FiltersConfig.fitnessCardio" },
    { key: 'fitness_strength', category: 'fitness', label: <FormattedMessage id="FiltersConfig.fitnessStrength" />, translation: "FiltersConfig.fitnessStrength" },
    { key: 'fitness_massage', category: 'fitness', label: <FormattedMessage id="FiltersConfig.fitnessMassage" />, translation: "FiltersConfig.fitnessMassage" },
    { key: 'fitness_other', category: 'fitness', label: <FormattedMessage id="FiltersConfig.fitnessOther" />, translation: "FiltersConfig.fitnessOther" },
    { key: 'recreation_racketsports', category: 'recreation', label: <FormattedMessage id="FiltersConfig.recreationRacketsports" />, translation: "FiltersConfig.recreationRacketsports" },
    { key: 'recreation_roller', category: 'recreation', label: <FormattedMessage id="FiltersConfig.recreationRoller" />, translation: "FiltersConfig.recreationRoller" },
    { key: 'recreation_other', category: 'recreation', label: <FormattedMessage id="FiltersConfig.recreationOther" />, translation: "FiltersConfig.recreationOther" },
    { key: 'recreation_fishing', category: 'recreation', label: <FormattedMessage id="FiltersConfig.recreationFishing" />, translation: "FiltersConfig.recreationFishing" },
]

export default businessCategoryConfig; 
