import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { parse, stringify } from '../../../util/urlHelpers';
import { Input } from '../../../components';

import css from './SearchCoupon.css';

const initValues = {
  searchPhrase: '',
};

const SEARCH_PHRASE_FIELD = 'searchPhrase';

const SearchCoupon = ({ codes, intl }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({
    defaultValues: initValues,
  });

  const onSubmit = ({ searchPhrase } ) => {
    const codes = searchPhrase || undefined;
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), codes, page: 1 })}`,
    });
  };

  useEffect(() => {
    !codes && reset({ searchPhrase: '' });
  }, [codes]);

  return (
    <div className={css.filter}>
      <form onSubmit={handleSubmit(onSubmit)} className={css.searchInputWrapper}>
      <Controller
      name={SEARCH_PHRASE_FIELD}
      control={control}
      rules={{
        minLength: 3
      }}
      render={({ field: { value, onChange } }) => (
        <Input
          type="text"
          id={SEARCH_PHRASE_FIELD}
          placeholder={intl.formatMessage({ id: 'Console.searchCoupon' })}
          label={intl.formatMessage({ id: 'Console.search' })}
          error={errors.searchPhrase && intl.formatMessage({ id: 'Console.minLength' })}
          value={value}
          onChange={onChange}
        />
      )} />
      </form>
    </div>
  );
};

export default SearchCoupon;
