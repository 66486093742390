import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import peopleImg from './people.jpg';

import css from './SectionThreeSteps.css';

const SectionThreeSteps = ({ intl }) => {

  return (
    <div className={css.container}>
      <div className={css.stepsContainer}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        </h2>
        <div className={css.steps}>
          <div className={css.step}>
            <h3 className={css.stepTitle}>
              1. <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h3>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part1Text" />
            </p>
          </div>
          <div className={css.step}>
            <h3 className={css.stepTitle}>
              2. <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h3>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part2Text" />
            </p>
          </div>
          <div className={css.step}>
            <h3 className={css.stepTitle}>
              3. <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h3>
            <p className={css.stepText}>
              <FormattedMessage id="SectionHowItWorks.part3Text" />
            </p>
          </div>
        </div>
      </div>
      <div className={css.imgContainer}>
        <img
          src={peopleImg}
          className={css.image}
          alt={intl.formatMessage({ id: 'SectionHowItWorks.titleLineOne'})}
        />
      </div>
    </div>
  )
}

export default SectionThreeSteps;
