import React from 'react';

import { ExternalLink, NamedLink, ResponsiveImage } from '../../../components';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import css from './CatalogItem.css';

const CatalogItem = ({
  catalogItem,
  intl,
  catalogProductId
}) => {
  const {
    articleCode,
    description,
    externalLink,
    title
  } = catalogItem.attributes;
  const catalogItemId = catalogItem.id.uuid;

  const image =
    catalogItem.images && catalogItem.images.length > 0 ? catalogItem.images[0] : null;

  return (
    <div className={css.itemContainer} key={catalogItemId}>
      <div className={css.imgContainer}>
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={image}
            variants={['scaled-medium']}
          />
        </div>
      </div>
      <div className={css.detailsContainer}>
        <div className={css.details}>
          <p className={css.title}>{title}</p>
          <p className={css.text}>
            {intl.formatMessage({ id: 'Console.catalogItemCode' })}: {articleCode}
          </p>
          <p className={css.text}>{description}</p>
        </div>
        <div className={css.linkContainer}>
          <NamedLink className={css.link} name="EditItemPage" params={{ id: catalogItemId, type: 'edit' }}>
            <FormattedMessage id="Console.editCatalogItem" />
          </NamedLink>
          {externalLink &&
            <>
              <span> • </span>
              <ExternalLink href={externalLink} className={css.link}>
                {intl.formatMessage({ id: "Console.externalLink" })}
              </ExternalLink>
            </>
          }
        </div>
      </div>
    </div>
  );
};

CatalogItem.defaultProps = {
  catalogItem: null,
};

CatalogItem.propTypes = {
  catalogItem: propTypes.catalogItem,
  intl: intlShape.isRequired,
};

export default CatalogItem;
