import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ReviewRating, NamedLink } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.css';

const SectionHeading = props => {
  const {
    richTitle,
    hostLink,
    showContactUser,
    onContactUser,
    rating,
    reviewsCount,
    listing,
    isOwnListing,
    editParams,
  } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {
            !!reviewsCount &&
              <>
                <span className={css.ratingValue}>{rating.toFixed(2)}</span>
                <ReviewRating
                  rating={Math.round(rating)}
                  className={css.mobileReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                <span className={css.reviewsCount}><FormattedMessage id="ListingPage.reviewsCount" values={{count: reviewsCount}} /></span>
                <span className={css.separator}>•</span>
              </>
          }
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {false && showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
          {
            isOwnListing &&
            <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams}>
              <EditIcon className={css.editIcon} />
              <FormattedMessage id="SectionHeading.edit" />
            </NamedLink>
          }
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
