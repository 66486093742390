import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { parseInt } from 'lodash';
import moment from 'moment';

import {
  Button,
  InlineTextButton,
  IconWarning,
  Checkbox,
  Textarea,
  Input
} from '../../../components';
import ExpDate from './../ExpDate/./ExpDate';
import RentalDates from '../RentalDates/RentalDates';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './CouponEdit.css';

const FIELDS = {
  DESCRIPTION: 'descriptionCoupon',
  ACTIVE: 'activeCoupon',
  EXPIRATION_DATE: 'expirationDate',
  DIFFERENCE_AMOUNT: 'differenceAmount',
  REASON: 'reason',
  START_DATE: 'start',
  END_DATE: 'end'
}

const CouponEdit = ({
  updateCoupon,
  updateInProgress,
  updateCouponsError,
  updateCouponsSuccess,
  intl,
  setCouponIdEdit,
  couponId,
  couponType,
  updateCouponsClearState,
  active,
  expDate,
  description,
  currency,
  startDate,
  endDate
}) => {
  const initValues = {
    activeCoupon: !active,
    expirationDate: expDate && new Date(expDate),
    descriptionCoupon: description,
    differenceAmount: 0,
    reason: '',
    start: startDate && new Date(startDate),
    end: endDate && new Date(endDate),
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: initValues,
  });
  const [isClicked, setIsClicked] = useState(false);
  const watchAllFields = watch();

  const onSubmit = data => {
    const { activeCoupon, expirationDate, descriptionCoupon, differenceAmount, reason, start, end } = data;
    const differenceAmountParam = (differenceAmount && couponType === 'gift-card')
      ? { differenceAmount: parseInt(differenceAmount)*100 }
      : undefined;
    const reasonParam = (differenceAmount && couponType === 'gift-card' && reason)
      ? { reason } : undefined;

    setIsClicked(true);
    updateCoupon({
      id: couponId,
      active: !activeCoupon,
      description: descriptionCoupon,
      exp: expirationDate && moment(expirationDate).format('YYYY-MM-DD'),
      start: start && moment(start).format('YYYY-MM-DD'),
      end: end && moment(end).format('YYYY-MM-DD'),
      ...differenceAmountParam,
      ...reasonParam,
    });
  };

  const errorMessage = updateCouponsError ? (
    <p className={css.error}>
      {' '}
      <IconWarning variant="error" /> <FormattedMessage id="Console.editingError" />
    </p>
  ) : null;

  const handleChangeActive = (event, onChange) => {
    onChange(event.target.checked);

    if (event.target.checked) {
      setValue(FIELDS.DIFFERENCE_AMOUNT, 0, { shouldValidate: true });
      setValue(FIELDS.REASON, '', { shouldValidate: true });
    }
  }
  const handleChangeReason = (event, onChange) => {
    onChange(event);

    if (!parseInt(event.target.value)) {
      setValue(FIELDS.REASON, '', { shouldValidate: true })
    }
  }

  const handleCancel = event => {
    event.preventDefault();
    reset({ defaultValues: initValues });
    setCouponIdEdit(null);
  };

  useEffect(() => {
    updateCouponsClearState();
  }, []);

  useEffect(() => {
    !updateInProgress && setIsClicked(false);
  }, [updateInProgress]);

  useEffect(() => {
    !updateInProgress && updateCouponsSuccess && isClicked && setCouponIdEdit(null);
  }, [updateInProgress, updateCouponsSuccess]);

  return (
    <div className={css.container}>
      <h3 className={css.title}>{intl.formatMessage({ id: 'Console.editCoupon' })}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={css.formContainer}>
          <div className={css.details}>
            <div className={css.itemContainer}>
              <Controller
                control={control}
                name={FIELDS.ACTIVE}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id={FIELDS.ACTIVE}
                    checked={value}
                    onChange={event => handleChangeActive(event, onChange)}
                    label={intl.formatMessage({ id: 'Console.banned' })}
                  />
                )}
              />
            </div>
            <ExpDate
              intl={intl}
              containerClass={css.expDateContainer}
              setValue={setValue}
              control={control}
              registerName={FIELDS.EXPIRATION_DATE}
              expDate={expDate}
              errors={errors}
              clearErrors={clearErrors}
            />
          </div>
            <RentalDates
              intl={intl}
              control={control}
              startName={FIELDS.START_DATE}
              endName={FIELDS.END_DATE}
              containerClass={css.dateContainer}
            />
          {couponType === 'gift-card' ? (
            <div className={css.amountContainer}>
              <Controller
                name={FIELDS.DIFFERENCE_AMOUNT}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    id={FIELDS.DIFFERENCE_AMOUNT}
                    placeholder={intl.formatMessage({ id: 'Console.differentAmountPlaceholder' })}
                    label={intl.formatMessage({ id: 'Console.differentAmount' }, { currency })}
                    type="number"
                    value={value}
                    onChange={event => handleChangeReason(event, onChange)}
                    disabled={watchAllFields[FIELDS.ACTIVE]}
                    onWheel={(e) => e.target.blur()}
                  />
                )} />
              <Controller
                name={FIELDS.REASON}
                control={control}
                rules={{
                  required: !!parseInt(watchAllFields[FIELDS.DIFFERENCE_AMOUNT]),
                  minLength: !!parseInt(watchAllFields[FIELDS.DIFFERENCE_AMOUNT]) ? 3 : 0,

                }}
                render={({ field: { value, onChange } }) => {
                  const disabledValue = !parseInt(watchAllFields[FIELDS.DIFFERENCE_AMOUNT]);
                  const errorValue = !!parseInt(watchAllFields[FIELDS.DIFFERENCE_AMOUNT])
                    && errors[FIELDS.REASON]
                    && intl.formatMessage({ id: 'Console.reasonError' });

                  return (
                    <Textarea
                      id={FIELDS.REASON}
                      placeholder={intl.formatMessage({ id: 'Console.reasonPlaceholder' })}
                      label={intl.formatMessage({ id: 'Console.reason' })}
                      value={value}
                      onChange={onChange}
                      disabled={disabledValue}
                      error={errorValue}
                    />
                  );
                }} />
            </div>
          ) : null}
          <div className={classNames(css.itemContainer, css.textareaContainer)}>
            <Controller
              name={FIELDS.DESCRIPTION}
              control={control}
              rules={{
                minLength: 3,
              }}
              render={({ field: { value, onChange } }) => (
                <Textarea
                  id={FIELDS.DESCRIPTION}
                  placeholder={intl.formatMessage({ id: 'Console.descriptionPlaceholder' })}
                  className={css.descriptionTextarea}
                  label={intl.formatMessage({ id: 'Console.description' })}
                  error={errors[FIELDS.DESCRIPTION] && intl.formatMessage({ id: 'Console.minLength' })}
                  value={value}
                  onChange={onChange}
                />
              )} />
          </div>
        </div>
        {errorMessage}
        <div className={css.actionContainer}>
          <InlineTextButton className={css.cancelButton} onClick={handleCancel}>
            <FormattedMessage id="Console.cancel" />
          </InlineTextButton>
          <Button
            type="submit"
            className={css.submitButton}
            inProgress={updateInProgress && isClicked}
            disabled={
              watchAllFields[FIELDS.ACTIVE] === initValues.activeCoupon &&
              watchAllFields[FIELDS.EXPIRATION_DATE]?.toString() === initValues.expirationDate?.toString() &&
              watchAllFields[FIELDS.DESCRIPTION] === initValues.descriptionCoupon &&
              watchAllFields[FIELDS.DIFFERENCE_AMOUNT] === initValues.differenceAmount &&
              watchAllFields[FIELDS.START_DATE]?.toString() === initValues.start?.toString() &&
              watchAllFields[FIELDS.END_DATE]?.toString() === initValues.end?.toString()
            }
          >
            {intl.formatMessage({ id: 'Console.save' })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CouponEdit;
