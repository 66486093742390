import React from 'react';

import { NamedLink } from '../../components';
import config from '../../config';

import Tent from './quechua_tent.png';
import Surfboard from './itiwit_sup.png';
import Bike from './triban.png';
import Fitness from './fitness.avif';
import Treadmill from './bikes_treadmill.png';
import SurfboardCZ from './sup.png';
import BikeCZ from './gravel.png';
import Carrier from './carrier.png';
import Ski from './ski.avif';
import SkiCross from './skicross.png';

import css from './LandingPage.css';

const countryConfiguration = [
  {
    countryId: 'pl',
    firstImg: Treadmill,
    firstRedirection: 'businessCategory=bikes_treadmill&&exact=true',
    secondImg: Bike,
    secondRedirection: 'keywords=rower',
    thirdImg: Carrier,
    thirdRedirection: 'businessCategory=tourism_carrier&category=tourism&exact=true',
  },
  {
    countryId: 'cz',
    firstImg: SkiCross,
    firstRedirection: 'businessCategory=wintersports_crosscountry&exact=true',
    secondImg: Fitness,
    secondRedirection: 'businessCategory=fitness_cardio&category=fitness&exact=true',
    thirdImg: Ski,
    thirdRedirection: 'businessCategory=wintersports_alpineskiing&exact=true',
  },
  {
    countryId: 'ee',
    firstImg: Surfboard,
    firstRedirection: 'businessCategory=watersports_sup&category=watersports&exact=true',
    secondRedirection: 'keywords=ratas',
  },
  {
    countryId: 'hu',
    firstImg: Surfboard,
    firstRedirection: 'businessCategory=watersports_sup&category=watersports&exact=true',
    secondRedirection: 'keywords=kerékpár',
  },
  {
    countryId: 'lt',
    firstImg: Surfboard,
    firstRedirection: 'businessCategory=watersports_sup&category=watersports&exact=true',
    secondRedirection: 'keywords=dviratis',
  },
  {
    countryId: 'lv',
    firstImg: Surfboard,
    firstRedirection: 'businessCategory=watersports_sup&category=watersports&exact=true',
    secondRedirection: 'keywords=velosipēds',
  }
];

const countryId = config.custom.countryId;
const defaultConfig = countryConfiguration.find(item => item.countryId === 'pl');
const countryConfig = countryConfiguration.find(item => item.countryId === countryId);

const SectionPopularCategories = props => {
  const { intl } = props;
  const configuration = {};

  Object.keys(defaultConfig).forEach(key => {
    const value = countryConfig?.hasOwnProperty(key) ? countryConfig[key] : defaultConfig[key];
    configuration[key] = value;
  })

  const categoriesData = [
    {
      img: configuration.firstImg,
      name: intl.formatMessage({ id: 'LandingPage.popularCategories.cat1' }),
      to: configuration.firstRedirection,
    },
    {
      img:  configuration.secondImg,
      name: intl.formatMessage({ id: 'LandingPage.popularCategories.cat2' }),
      to:  configuration.secondRedirection,
    },
    {
      img:  configuration.thirdImg,
      name: intl.formatMessage({ id: 'LandingPage.popularCategories.cat3' }),
      to:  configuration.thirdRedirection,
    },
  ];

  return (
    <div className={css.popularCategories}>
      {categoriesData.map(({ img, name, category, to }, key) => (
        <NamedLink
          name="SearchPage"
          to={{
            search: to,
          }}
          key={key}
          className={css[`category-${key + 1}`]}
        >
          <div>
            <p>{name}</p>
          </div>
          <img src={img} alt={name} />
        </NamedLink>
      ))}
    </div>
  );
};

export default SectionPopularCategories;
