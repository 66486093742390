import React from 'react';
import DepositImg from './deposit.png';
import CancelImg from './cancel.png'
import BookingImg from './booking.png'

import css from './ListingPage.css';
import { FormattedMessage } from 'react-intl';

const SectionFeaturesMaybe = props => {
  return null;
  return (
    <div className={css.sectionFeatures}>
      <div className={css.feature}>
        <img src={DepositImg} />
        <span><FormattedMessage id="SectionFeaturesMaybe.price"/></span>
      </div>
      <div className={css.feature}>
        <img src={BookingImg} />
        <span><FormattedMessage id="SectionFeaturesMaybe.booking"/></span>
      </div>
      <div className={css.feature}>
        <img src={CancelImg} />
        <span>M<FormattedMessage id="SectionFeaturesMaybe.cancel"/></span>
      </div>
    </div>
  );
};

export default SectionFeaturesMaybe;
