
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func, object } from 'prop-types';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData } from './ConsoleProductCatalogPage.duck';
import {
  PaginationLinks,
  ConsoleWrapper,
  IconSpinner,
  Select,
  Input,
  Button, NamedLink,
} from '../../components';
import ProductItem from './ProductItem/ProductItem';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';

import css from './ConsoleProductCatalogPage.css';

const countries = [
  { countryId: 'all', country: 'Console.all' },
  ...config.countryNames
];

const ConsoleProductCatalogPageComponent = props => {
  const {
    productCatalog,
    fetchInProgress,
    scrollingDisabled,
    fetchProductCatalogListError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    name,
    tab,
    currentUser,
    onManageDisableScrolling,
  } = props;
  const { countryId = config.custom.countryId, page, phrase } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);
  const [isExpandedItems, setIsExpandedItems] = useState(false);

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = fetchProductCatalogListError ? (
    <p className={css.error}>{fetchProductCatalogListError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !productCatalog?.length && !fetchProductCatalogListError ? (
      <p>
        <FormattedMessage id="Console.noProductFound" />
      </p>
    ) : null;

  const handleCountryChange = event => {
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), countryId: searchCountryId, page: 1 })}`,
    });
  };

  const adminCountries = currentUser?.attributes.isAdmin
    ? countries
    : countries.filter(country =>
        currentUser?.attributes.adminForCountries.includes(country.countryId)
      );

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), phrase: searchPhrase, page: 1 })}`,
    });
  };

  const handleExpandClick = event => {
    event.preventDefault();
    setIsExpandedItems(prevState => !prevState);
  };

  useEffect(() => {
    if (!page) {
      setSearchPhrase('');
      history.push({ pathname, search: `${stringify({ ...parse(search), page: 1 })}` });
    }
  }, [page]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
        <Input
          value={searchPhrase}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'Console.searchCatalogProduct' })}
          label={intl.formatMessage({ id: 'Console.search' })}
          isValidation={false}
        />
      </form>
      <div className={css.filterContainer}>
        <div className={css.selectWrapper}>
          <Select
            value={countryId}
            onChange={handleCountryChange}
            label={intl.formatMessage({ id: 'Console.country' })}
            isValidation={false}
          >
            <option hidden value="">
              {intl.formatMessage({ id: 'Console.select' })}
            </option>
            {adminCountries.map(({ countryId, country }) => (
              <option value={countryId}>{intl.formatMessage({ id: country })}</option>
            ))}
          </Select>
        </div>
        <div className={css.actionContainer}>
          <NamedLink className={css.link} name="EditProductPage" params={{ id: '00000000-0000-0000-0000-000000000000', type: 'new' }}>
            <FormattedMessage id="Console.addCatalogProduct" />
          </NamedLink>
          <Button className={css.btn} onClick={handleExpandClick}>
            {intl.formatMessage({
              id: isExpandedItems ? 'Console.collapseCatalogItems' : 'Console.expandCatalogItems',
            })}
          </Button>
        </div>
      </div>
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <>
          {!!productCatalog.length && <p className={css.totalItems}>
            {intl.formatMessage({ id: 'Console.numberOfProducts' })}:{' '}
            <span>{pagination.totalItems}</span>
          </p>}
          <ul className={css.list}>
            {productCatalog.map(item => (
              <ProductItem catalogProduct={item} intl={intl} isExpandedItems={isExpandedItems} />
            ))
            }

          </ul>
        </>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleProductCatalogPageComponent.defaultProps = {
  productCatalog: [],
  fetchInProgress: false,
  fetchTransactionListError: null,
  pagination: null,
};

ConsoleProductCatalogPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchTransactionListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  productCatalog: arrayOf(propTypes.catalogProduct),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchProductCatalogListError,
    pagination,
    productCatalogList,
  } = state.ConsoleProductCatalogPage;
  const { currentUser } = state.user;
  return {
    fetchInProgress,
    fetchProductCatalogListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    productCatalog: getMarketplaceEntities(state, productCatalogList),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ConsoleProductCatalogPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleProductCatalogPageComponent);

ConsoleProductCatalogPage.loadData = loadData;

export default ConsoleProductCatalogPage;
