import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconSearchThird = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName, className);
  
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8762 13.9323L12.0208 11.0777C12.8723 10.0155 13.3354 8.69434 13.3335 7.33301C13.3335 4.02101 10.6455 1.33301 7.3335 1.33301C4.0215 1.33301 1.3335 4.02101 1.3335 7.33301C1.3335 10.645 4.0215 13.333 7.3335 13.333C8.69483 13.3349 10.016 12.8718 11.0782 12.0203L13.9328 14.8757L14.8762 13.9323ZM12.0002 7.33301C12.0021 8.54663 11.5296 9.71293 10.6835 10.583L10.5835 10.683C9.71342 11.5291 8.54712 12.0016 7.3335 11.9997C4.75483 11.9997 2.66683 9.91101 2.66683 7.33301C2.66683 4.75434 4.75483 2.66634 7.3335 2.66634C9.9115 2.66634 12.0002 4.75434 12.0002 7.33301Z"
        fill="#8996A2"
      />
    </svg>
  );
};

IconSearchThird.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearchThird.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearchThird;
