import React, { useState } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parse, stringify } from '../../util/urlHelpers';
import {
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPickedUp,
  txIsRequested,
  txIsDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData } from './InboxPage.duck';
import {
  NotificationBadge,
  PaginationLinks,
  IconSpinner,
  InboxWrapper,
  Select,
} from '../../components';
import TransactionsFilter from './TransactionsFilter';
import InboxItem from './InboxItem';
import { NotFoundPage } from '../index';

import css from './InboxPage.css';
import AutocompleteSelect from '../../components/AutocompleteSelect/AutocompleteSelect';

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';
  if (txIsEnquired(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx)) {
    const requested = isOrder
      ? {
        nameClassName: css.nameNotEmphasized,
        bookingClassName: css.bookingNoActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.stateRequested',
        }),
      }
      : {
        nameClassName: css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.statePending',
        }),
      };

    return requested;
  } else if (txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsPickedUp(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePickedUp',
      }),
    };
  } else if (txIsDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

const checkStatus = (tx) => {
  const { attributes: { processState, start, end } } = tx;
  const now = new Date();

  if (processState === 'accepted' || processState == 'expired') {
    return moment(start).isBefore(now, 'day');
  }
  if (processState === 'picked-up') {
    return moment(end).subtract(1, 'day').isBefore(now, 'day');
  }
  return false;
}

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    location,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    currentUserProviders,
    history,
    name
  } = props;
  //additional filters
  const [deliveryLocationFilter, setDeliveryLocationFilter] = useState('');

  const { tab, providerId } = params;

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={classNames(css.listItem, { [css.overlay]: tx.attributes.state === 'cancelled' })}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          lineItems={tx.attributes.lineItems}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;


  // const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
  //   return isOrdersTab
  //     ? user.id && tx && tx.length > 0 && tx[0].user.id === user.id
  //     : user.id && tx && tx.length > 0 && tx[0].provider.id === user.id;
  // };

  // const hasTransactions =
  //   !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);

  const pagingLinks =
    !fetchInProgress && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pageSearchParams={parse(location.search)}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const handleProviderChange = ({ value }) => {
    if (!value) return;

    history.push({
      pathname: `/inbox/sales/${value}`,
      search: `${stringify({ ...parse(location.search), deliveryToProviderId: undefined })}`,
    });
  }

  return (
    <InboxWrapper
      currentUserProviders={currentUserProviders}
      intl={intl}
      scrollingDisabled={scrollingDisabled}
      name={name}
      tab={tab}
      providerNotificationBadge={providerNotificationBadge}
      currentUser={currentUser}
    >
        <>
          {error}
          {
            !isOrders &&
            <>
              <div className={css.providerSelect}>
                <AutocompleteSelect
                  key="providerFilter"
                  inputValue={{ value: providerId }}
                  onChange={handleProviderChange}
                  label={intl.formatMessage({ id: "Inbox.provider" })}
                  options={currentUserProviders.map(p => ({ value: p.id.uuid, label: p.attributes.name }))}
                  placeholder={intl.formatMessage({ id: "Console.select"})}
                />
                {/* <Select
                  value={providerId}
                  onChange={handleProviderChange}
                  label={intl.formatMessage({ id: "Inbox.provider" })}
                >
                  <option>{intl.formatMessage({ id: 'InboxPage.choose' })}</option>
                  <option value="me">{intl.formatMessage({ id: "InboxPage.all" })}</option>
                  {
                    currentUserProviders.map(provider => {
                      return (
                        <option value={provider.id.uuid}>{provider.attributes.name}</option>
                      )
                    })
                  }
                </Select> */}
              </div>
            </>
          }
          <TransactionsFilter
            setDeliveryLocationFilter={setDeliveryLocationFilter}
            deliveryLocationFilter={deliveryLocationFilter}
            currentUserProviders={currentUserProviders}
            providerId={providerId}
            isSales={!isOrders}
          />
          <ul className={css.itemList}>
            {!fetchInProgress ? (
              transactions
                .map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {pagingLinks}
        </>
    </InboxWrapper>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    userProviders: currentUserProviders
  } = state.user;

  return {
    currentUser,
    currentUserProviders,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const InboxPage = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
