//NOTE The structure should reflect Google Optimize experiments, variants and variants weights

export const ExperimentId = {
  // TEST: 'U-fMHcXUR968knSGn_IF7g',
  // HOME_SEARCH_BUTTON: 'rIucT54wTmm8VcE9_IVatw',
  HOME_TITLE: 'Q40hB_UbQb-rDrPqnDEqgw'

};

export const ExperimentVariant = {
  ORIGINAL: '0',
  A: '1',
  B: '2',
  C: '3',
};

export const ExperimentsWithVariants = {
  // [ExperimentId.TEST]: {
  //   variants: [ExperimentVariant.ORIGINAL, ExperimentVariant.A, ExperimentVariant.B],
  //   weights: [33, 33, 33],
  // },
  [ExperimentId.HOME_TITLE]: {
    variants: [ExperimentVariant.ORIGINAL, ExperimentVariant.A],
    weights: [50, 50],
  },
};

export { initMissingAbExperiments } from './utils';
