import { storableError } from '../util/errors';
import { addMarketplaceEntities } from './marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_CATEGORIES_SUGGESTIONS_REQUEST = 'app/categoriesSuggestions/FETCH_CATEGORIES_SUGGESTIONS_REQUEST';
export const FETCH_CATEGORIES_SUGGESTIONS_SUCCESS = 'app/categoriesSuggestions/FETCH_CATEGORIES_SUGGESTIONS_SUCCESS';
export const FETCH_CATEGORIES_SUGGESTIONS_ERROR = 'app/categoriesSuggestions/FETCH_CATEGORIES_SUGGESTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCategoriesSuggestionsInProgress: false,
  fetchCategoriesSuggestionsError: false,
  fetchCategoriesSuggestionsSuccess: false,
  categoriesSuggestions: [],
};

const categoriesSuggestionsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
      case FETCH_CATEGORIES_SUGGESTIONS_REQUEST:
        return {
          ...state,
          fetchCategoriesSuggestionsInProgress: true,
          fetchCategoriesSuggestionsError: null,
          fetchCategoriesSuggestionsSuccess: false,
        };
      case FETCH_CATEGORIES_SUGGESTIONS_SUCCESS:
        return {
          ...state,
          fetchCategoriesSuggestionsInProgress: false,
          fetchCategoriesSuggestionsError: null,
          fetchCategoriesSuggestionsSuccess: true,
          categoriesSuggestions: payload.data.data
        };
      case FETCH_CATEGORIES_SUGGESTIONS_ERROR:
        return {
          ...state,
          fetchCategoriesSuggestionsInProgress: false,
          fetchCategoriesSuggestionsError: payload,
          fetchCategoriesSuggestionsSuccess: false,
        };

    default:
      return state;
  }
};

export default categoriesSuggestionsReducer;

// ================ Action creators ================ //

export const fetchCategoriesSuggestionsRequest = () => ({
  type: FETCH_CATEGORIES_SUGGESTIONS_REQUEST,
});

export const fetchCategoriesSuggestionsSuccess = response => ({
  type: FETCH_CATEGORIES_SUGGESTIONS_SUCCESS,
  payload: response,
});

export const fetchCategoriesSuggestionsError = (e) => ({
  type: FETCH_CATEGORIES_SUGGESTIONS_ERROR,
  error: true,
  payload: e
});

export const fetchCategoriesSuggestions = () => (dispatch, getState, sdk) => {
  dispatch(fetchCategoriesSuggestionsRequest());

  return sdk.newSdk.categories.query({})
  .then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchCategoriesSuggestionsSuccess(response));
    return response;
  })
  .catch(e => {
    dispatch(fetchCategoriesSuggestionsError(storableError(e)));
    throw e;
  });
}
