import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconChevronRight.css';

const IconChevronRight = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="#FFF" fillRule="nonzero" d="M8.293 6.707a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 1 1-1.414-1.414L13.586 12 8.293 6.707z"/>
    </svg>
  );
};

IconChevronRight.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconChevronRight.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconChevronRight;
