import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';
import prev from './prev.svg';
import next from './next.svg';

import css from './ListingPage.css';

const SectionImages = props => {
  const {
    title,
    listing,
    intl
  } = props;

  const { images = [] } = listing;
  const imagesCount = images.length;
  const hasImages = images && imagesCount > 0;
  const firstImage = hasImages ? images[0] : null;
  const [mobileCarouselOpen, setMobileCarouselOpen] = useState(false);
  const [activeImg, setActiveImg] = useState(firstImage);
  const [firstImageIndex, setFirstImageIndex] = useState(0); 

  const prevButton = imagesCount > 3 && <button className={css.carouselArrow} onClick={() => setFirstImageIndex((firstImageIndex - 1 + imagesCount) % imagesCount)}><img src={prev} /></button>
  const nextButton = imagesCount > 3 && <button className={css.carouselArrow} onClick={() => setFirstImageIndex((firstImageIndex + 1 + imagesCount) % imagesCount)}><img src={next} /></button>

  const firstImageToShow = images[firstImageIndex];
  const secondImageToShow = imagesCount > 1 ? [images[(firstImageIndex + 1) % imagesCount]] : [];
  const thirdImageToShow = imagesCount > 2 ? [images[(firstImageIndex + 2) % imagesCount]] : [];
  const imagesToShow = [firstImageToShow, ...secondImageToShow, ...thirdImageToShow];

  useEffect(() => {
    setActiveImg(hasImages ? images[0] : null)
  }, [listing])

  return (
    <div className={css.sectionImages}>
      {/* <div className={css.aspectWrapper} onClick={handleViewPhotosClick}> */}
      <div className={css.images}>
        <div className={css.carousel}>
          {prevButton}
          <div className={css.carouselImagesWrapper}>
            {
              imagesToShow.map(
                img => (
                  <a className={css.carouselImageLink} onClick={() => setActiveImg(img)}>
                    <ResponsiveImage
                      rootClassName={css.rootForImage}
                      alt={title}
                      image={img}
                      className={css.carouselImage}
                      variants={[
                        'scaled-small',
                      ]}
                    />
                  </a>
                )
              )
            }
          </div>
          {nextButton}
        </div>
        <div className={css.activeImageWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={activeImg || firstImage}
            variants={[
              'scaled-medium',
              // 'landscape-crop2x',
              // 'landscape-crop4x',
              // 'landscape-crop6x',
            ]}
          />
        </div>
        <div className={css.moreImagesWrapper}>
          <button className={css.moreImages} onClick={()=>setMobileCarouselOpen(true)}>{intl.formatMessage({ id: 'SectionImages.morePhotos' })} ({imagesCount})</button>
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={mobileCarouselOpen}
        onClose={()=>setMobileCarouselOpen(false)}
        usePortal
        onManageDisableScrolling={()=>{}}
      >
        <ImageCarousel images={images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
