import jsPdf from 'jspdf';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import css from './BookingBreakdown.css';
import './Roboto-Regular-normal.js';
import './Roboto-Regular-bold.js';
import decathlonLogo from './decathlonLogo.png';

const CustomerDetails = ({ transaction, intl, isProvider }) => {
  const { firstName, lastName, phone: customerPhoneNumber, email: customerEmail, receiverName: receiver, start, end, deliveryLocation, checkoutFields = [], documents = [] } = transaction.attributes || {};
  const { displayName } = transaction.user.attributes;
  const { isDecathlon, countryId } = transaction.provider.attributes;
  const { lastTransition } = transaction.attributes;
  const { parts = '', title } = transaction.listings[0].attributes;

  // const displayProtocolPrinter = isDecathlon && lastTransition === 'transition/accept' || lastTransition === 'transition/expire' || lastTransition === 'transition/pickup' || lastTransition === 'transition/confirm-payment-instant-booking' || lastTransition === 'transition/confirm-payment-instant-booking-by-operator';
  const displayProtocolPrinter = isProvider && isDecathlon && countryId === 'pl';
  const customerName = `${firstName} ${lastName}`;

  const printPDF = () => {
    const pdf = new jsPdf();
    const pageMargin = 15;
    const pageWidth = pdf.internal.pageSize.width - pageMargin * 2;
    const pageCenter = pdf.internal.pageSize.width / 2;
    const listMargin = pageMargin + 5;
    const listMaxWIdth = pageWidth - 5;

    pdf.addImage(decathlonLogo, 'PNG', pageCenter - 35, pageMargin, 70, 17);
    pdf.setFont('Roboto-Regular', 'bold');
    pdf.setFontSize(12);
    pdf.text(pageMargin, 38, 'PROTOKÓŁ ZDAWCZO - ODBIORCZY');

    pdf.setFontSize(10);
    pdf.text(pageMargin, 45, `Rezerwujący: ${customerName}`, { maxWidth: pageCenter - pageMargin });
    pdf.text(pageMargin, 52, `Odbierający: ${receiver}`, { maxWidth: pageCenter - pageMargin });
    pdf.text(pageCenter, 45, `/ Wypożyczone: ${intl.formatDate(start, { day: 'numeric', month: 'long', year: 'numeric' })} - ${intl.formatDate(moment(end).subtract(1, 'days'), { day: 'numeric', month: 'long', year: 'numeric' })}`, { maxWidth: pageCenter - pageMargin });
    pdf.text(pageMargin, 59, `Nr dowodu osobistego odbierającego: _________________________________________`);
    pdf.text(pageMargin, 66, `PESEL / Numer paszportu odbierającego: _________________________________________`);
    pdf.text(pageCenter, 52, `/ Tel. kontaktowy: ${customerPhoneNumber}`, { maxWidth: pageCenter - pageMargin });

    pdf.setFontSize(14);
    pdf.setTextColor(19, 130, 193);
    pdf.text(pageMargin, 74, 'I. Wydanie sprzętu sportowego');

    pdf.setFontSize(12);
    pdf.setTextColor('#000000');
    pdf.text(pageMargin, 82, `Nazwa produktu: ${title}`, { maxWidth: pageWidth });

    pdf.rect(pageMargin, 88, pageWidth, 8);
    pdf.setFontSize(10);

    pdf.text(pageCenter, 93, 'Opis stanu technicznego produktów', { align: 'center' });
    pdf.rect(pageMargin, 96, pageWidth, 20);

    pdf.setFont('Roboto-Regular', 'normal');
    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text(pageMargin, 124, '1.');
    pdf.text(listMargin, 124, 'Oświadczam, iż mój stan zdrowia umożliwia mi wykonywanie ćwiczeń fizycznych, uprawiania sportu oraz używania produktu ', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(pageMargin, 134, '2.');
    pdf.text(listMargin, 134, 'Oświadczam, iż znam podstawowe reguły bezpiecznego użytkowania produktu, który będę wypożyczał. ', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(pageMargin, 142, '3.');
    pdf.text(listMargin, 142, 'Oświadczam, iż wypożyczając produkt robię to na własne ryzyko i wyłącznie moją odpowiedzialność.', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(pageMargin, 150, '4.');
    pdf.text(listMargin, 150, 'Oświadczam, iż stan wypożyczonego produktu jest zgodny z opisem powyżej i biorę pełną odpowiedzialność za pogorszenie stanu produktu w trakcie jego użytkowania.', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(pageMargin, 162, '5.');
    pdf.text(listMargin, 162, 'Oświadczam, iż zapoznałem się z "Regulaminem wypożyczania Decathlon" z dnia 01.04.2023r i akceptuję jego treść.', { align: 'justify', maxWidth: listMaxWIdth });

    pdf.setFont('Roboto-Regular');
    pdf.setTextColor('#fc101c');
    pdf.text(listMargin, 172, 'W szczególności jest mi znany pkt. 6  par. 4:');
    pdf.setFontSize(10);
    pdf.text(listMargin, 178, 'Klient zobowiązuje się zwrócić czysty i suchy sprzęt. W przypadku zwrócenia brudnego lub mokrego sprzętu, Klient jest zobowiązany uiścić jednorazową opłatę serwisową, odpowiadającej kosztowi czyszczenia sprzętu w kasie sklepu. Opłata serwisowa za czyszczenie namiotu wynosi 250 zł , opłata serwisowa za pozostały sprzęt wynosi 80 zł.', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(listMargin, 196, 'oraz pkt. 8 par.4:', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(listMargin, 202, 'W przypadku zwrotu przez Klienta namiotu z poniższymi wadami: - mocz kota, - stęchlizna, - silny zapach pleśni (pleśń występująca na ponad 10% powierzchni namiotu)', { align: 'justify', maxWidth: listMaxWIdth });
    pdf.text(listMargin, 212, 'Decathlon uznaje taki namiot za zniszczony, gdyż nie ma możliwości technologicznych aby usunąć powyższe zapachy. W takim przypadku Klient zobowiązany jest do pokrycia kosztów w wysokości 100% wartości namiotu w cenie zakupu w kasie sklepu.', { align: 'justify', maxWidth: listMaxWIdth });

    pdf.setFontSize(10);
    pdf.setTextColor('#000000');

    pdf.line(15, 265, 40, 265);
    pdf.line(45, 265, 90, 265);
    pdf.text(23, 270, 'Data');
    pdf.text(53, 270, 'Podpis klienta');

    pdf.line(110, 265, 135, 265);
    pdf.line(140, 265, 190, 265);
    pdf.text(120, 270, 'Data');
    pdf.text(145, 270, 'Podpis pracownika sklepu');


    pdf.addPage();

    pdf.setFontSize(14);
    pdf.setTextColor(19, 130, 193);
    pdf.setFont('Roboto-Regular', 'bold');
    pdf.text(pageMargin, 30, 'II. Zwrot wypożyczonego produktu');

    pdf.setFontSize(12);
    pdf.setTextColor('#000000');
    pdf.text(pageMargin, 40, `Nazwa produktu: ${title}`, { maxWidth: pageWidth });

    pdf.setFont('Roboto-Regular', 'normal');
    pdf.text(pageMargin, 52, 'Produkt został oddany w stanie niepogorszonym');

    pdf.setFont('Roboto-Regular', 'bold');
    pdf.rect(pageMargin, 60, pageWidth, 8);
    pdf.setFontSize(10);
    pdf.setTextColor('#000000');
    pdf.text(pageMargin + 2, 65, 'Produkt posiada uszkodzenia / opis stanu technicznego produktu');
    pdf.rect(pageMargin, 68, pageWidth, 30);

    pdf.line(15, 120, 40, 120);
    pdf.line(45, 120, 90, 120);
    pdf.text(23, 125, 'Data');
    pdf.text(53, 125, 'Podpis klienta');

    pdf.line(110, 120, 135, 120);
    pdf.line(140, 120, 190, 120);
    pdf.text(120, 125, 'Data');
    pdf.text(145, 125, 'Podpis pracownika sklepu');

    pdf.setFillColor('#1382c1');
    pdf.rect(0, 275, pdf.internal.pageSize.width, pdf.internal.pageSize.height - 275, 'F');
    pdf.setTextColor('#ffffff');
    pdf.text(pageCenter, 282, 'DECATHLON Sp. z o.o., ul. Geodezyjna 76, 03-290 Warszawa, tel.: +48 (22) 333 70 00 KRS: 000007163, NIP: 951 18 55 233, REGON: 013102058, BDO: 000005259', { align: 'center', maxWidth: pageWidth });

    if (parts && parts.split("\n").length) {
      pdf.addPage();

      pdf.addImage(decathlonLogo, 'PNG', pageCenter - 35, pageMargin, 70, 17);

      pdf.setTextColor('#000000');
      pdf.text(pageMargin, 45, 'Przy odbiorze produktu sprawdź kompletność następujących części:');

      parts.split("\n").map((part, i) => {
        pdf.rect(pageMargin, 50 + i * 10, 6, 6);
        pdf.text(pageMargin + 10, 54 + i * 10, part)
      })

      pdf.setFillColor('#1382c1');
      pdf.rect(0, 275, pdf.internal.pageSize.width, pdf.internal.pageSize.height - 275, 'F');
      pdf.setTextColor('#ffffff');
      pdf.text(pageCenter, 282, 'DECATHLON Sp. z o.o., ul. Geodezyjna 76, 03-290 Warszawa, tel.: +48 (22) 333 70 00 KRS: 000007163, NIP: 951 18 55 233, REGON: 013102058, BDO: 000005259', { align: 'center', maxWidth: pageWidth });
    }

    pdf.save('protokol_odbioru.pdf');
  }

  return (
    <div className={css.customerDetails}>
      <h3 className={css.customerHeader}>
        {isProvider ? <FormattedMessage id="CustomerDetails.header" /> : <span><FormattedMessage id="CustomerDetails.bookingDetails" /></span>}
      </h3>
      <div>
        <span className={css.label}><FormattedMessage id="CustomerDetails.customerName" /></span>
        <span className={css.value}>{customerName || displayName}</span>
      </div>
      {customerEmail &&
        <div>
          <span className={css.label}><FormattedMessage id="CustomerDetails.customerEmail" className={css.label} /></span>
          <span className={css.value}>{customerEmail}</span>
        </div>
      }
      {customerPhoneNumber &&
        <div>
          <span className={css.label}><FormattedMessage id="CustomerDetails.customerPhoneNumber" className={css.label} /></span>
          <span className={css.value}>{customerPhoneNumber}</span>
        </div>
      }
      <div>
        <span className={css.label}><FormattedMessage id="CustomerDetails.receiver" className={css.label} /></span>
        <span className={css.value}>{receiver || customerName || displayName}</span>
      </div>
      {/* {deliveryLocation &&
        <div>
          <span className={css.label}><FormattedMessage id="CustomerDetails.customerDeliveryLocation" className={css.label} /></span>
          <span className={css.value}>{deliveryLocation}</span>
        </div>
      } */}
      {/* {
        checkoutFields.map(field => (
          <div>
            <span className={css.label}>{field.label}:</span>
            <span className={css.value}>{field.value}</span>
          </div>
        ))
      } */}
      {isProvider && displayProtocolPrinter && <button className={css.protocolBtn} onClick={printPDF}>{intl.formatMessage({id: "CustomerDetails.downloadProtocol" })}</button>}

      {
        documents.filter(d => d.type === 'invoice' || d.type === 'invoice-correction').length > 0 &&
        <div>
          <h3 className={css.customerHeader}>
          {intl.formatMessage({id: "CustomerDetails.invoice" })}
          </h3>
          {documents.filter(d => d.type === 'invoice').map(d => {
            return (
              <div>
                <a className={css.downloadInvoice} href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${d.url}`} target="_blank"> {intl.formatMessage({id: "CustomerDetails.downloadInvoice" })} </a>
              </div>
            )
          })}
          {documents.filter(d => d.type === 'invoice-correction').map(d => {
            return (
              <div>
                <a className={css.downloadInvoice} href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${d.url}`} target="_blank">Pobierz korektę faktury</a>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
}

export default injectIntl(CustomerDetails);


