import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Modal, Button, InlineTextButton } from '..';

import css from './ConfirmModal.css';

const ConfirmModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    text,
    handleSubmit,
    handleCancel,
    inProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      closeButtonClassName={css.closeButton}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalContent}>
        <div className={css.container}>
          <p>{text}</p>
        </div>
        <div className={css.buttonSection}>
          <InlineTextButton className={css.cancelButton} onClick={handleCancel}>
            <FormattedMessage id="PartialCancelModal.cancel" />
          </InlineTextButton>
          <Button onClick={handleSubmit} className={css.confirmButton} inProgress={inProgress}>
            <FormattedMessage id="ConfirmModal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

ConfirmModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ConfirmModal.propTypes = {
  className: string,
  rootClassName: string,
};

export default injectIntl(ConfirmModal);
