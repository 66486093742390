import React from 'react';
import { object, string } from 'prop-types';
import { Controller } from 'react-hook-form';

import { CustomDatePicker } from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from './RentalDates.css';

const RentalDates = ({
  intl,
  titleId = null,
  containerClass,
  control,
  startName,
  endName,
}) => {
  const containerClassName = containerClass || css.container;
  const minDate = new Date();

  return (
    <div>
      {titleId && <h3 className={css.title}>{intl.formatMessage({ id: titleId })} </h3>}
      <div className={containerClassName}>
        <Controller
          name={startName}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={css.filterWrapper}>
              <div className={css.labelWithClearButton}>
                <label>{intl.formatMessage({ id: 'Console.dateStart' })}</label>
                <button
                  className={css.clearButton}
                  onClick={() => onChange(null)}
                  disabled={!value}
                >
                  {intl.formatMessage({ id: 'Console.clear' })}
                </button>
              </div>
              <CustomDatePicker
                showIcon
                selected={value}
                onChange={onChange}
                minDate={minDate}
              />
            </div>
          )}
        />
        <Controller
          name={endName}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={css.filterWrapper}>
              <div className={css.labelWithClearButton}>
                <label>{intl.formatMessage({ id: 'Console.dateEnd' })}</label>
                <button
                  className={css.clearButton}
                  onClick={() => onChange(null)}
                  disabled={!value}
                >
                  {intl.formatMessage({ id: 'Console.clear' })}
                </button>
              </div>
              <CustomDatePicker
                showIcon
                selected={value}
                onChange={onChange}
                minDate={minDate}
              />
            </div>
          )}
            />
      </div>
    </div>
  );
};

RentalDates.defaultProps = {
  titleId: null,
  containerClass: null,
};

RentalDates.propTypes = {
  titleId: string,
  containerClass: string,
  startName: string.isRequired,
  endName: string.isRequired,
  intl: intlShape.isRequired,
  control: object.isRequired,
};

export default RentalDates;
