import { calculateLongTermDiscount, calculateLongTermPeriodDiscount } from '../../util/discounts';

// Function that calculates longterm discount. Depends on provider type: external provider or decathlon user


export default function computeDiscount({ price, quantity, provider, listing }) {
  const isBikeTreadmill = listing && listing.businessCategory === 'bikes_treadmill' && provider.countryId === 'pl';
  // if provider is decathlon store calculate standard long term discount
  if (provider.countryId === 'cz' && listing.businessCategory === 'fitness_cardio') return 0;
  if (provider.isDecathlon) return calculateLongTermDiscount(price, quantity, isBikeTreadmill);
  
  // if provider doesnt have is_provider flag return 0;
  if (!provider.discountsSchema || provider.discountsSchema.type !== 'period') return 0;
  
  return calculateLongTermPeriodDiscount(price, quantity, provider.discountsSchema.params);
}