import React, { useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { LocationAutocompleteInputField } from '../../components';
import SearchIcon from './SearchIcon';
import AutocompleteTextboxField from './AutocompleteTextboxField';
import Geocoder from '../LocationAutocompleteInput/GeocoderGoogleMaps';

import css from './SectionHero.css';

const suggestions = [  
  'rower','rower górski','rower mtb','rower elektryczny','rower szosowy','rower gravelowy','rower miejski',
  'rower dzięcięcy', 'rower elektryczny dziecięcy', 'rower elektryczny miejski', 'rower składany', 'rower elektryczny składany',
  'przyczepka rowerowa','rower trekkingowy','rower elektryczny trekkingow','fotelik rowerowy dziecięcy',
  'trenażer','trenażer interaktywny','sakwy rowerowe','box dachowy','bagażnik dachowy','bagażnik rowerowy','bagażnik na rowery',
  'namiot kempingowy','namiot dmuchany','materac kempingowy','namiot','raki','rakiety śnieżne','czekan','narty','narty biegowe','biegówki',
  'narty zjazdowe','narty skitourowe','skitour','orbitrek','rower stacjonarny','kuchnia kempingowa','szafa turystyczna','szafa kempingowa',
  'deska sup','stand up paddle','kajak dmuchany','kamizelka','hulajnoga','hulajnoga elektryczna','thule','łóżko polowe',
  'rower eliptyczny','wioślarz','namiot karpiowy','echosonda','rakieta tenisowa','plecak','kijki trekkingowe','kijki narciarskie',
  'rower rehabilitacyjny','kombinezon','budka turystyczna','worek żeglarski','pianka','pływadełko','kijek turystyczny',
  'namiot kopułowy','deskorolka','sup','hamax','nosidełko','krzesło kempingowe','bieżnia','wózek dziecięcy',
  'snowboard', 'deska snowboardowa', 'narty dla dzieci', 'narty junior', 'zestaw lawinowy'
];

const suggestionsHU = [
  "kerékpár", "MTB kerékpár","elektromos kerékpár", "országúti kerékpár",  "városi kerékpár", "gyerek kerékpár", "elektromos gyerek bicikli", "elektromos városi kerékpár", "összecsukható kerékpár", "összecsukható elektromos kerékpár", "kerékpár utánfutó", "trekking kerékpár", "elektromos trekking kerékpár", "kerékpáros gyerekülés", "kerékpár görgő", "interaktív edzőgörgő", "kerékpártartó", "tetőbox", "tetőcsomagtartó", "kerékpártartó", "kerékpártartó", "kempingsátor", "felfújható sátor", "kempingmatrac", "sátor", "csúszásgátló", "hótalp", "jégcsákány", "sífutóléc", "sífutás", "lesiklóléc", "túrasíléc", "túrasíléc", "elliptikus tréner",  "kemping konyha", "stand up paddle",  "SUP deszka", "stand up paddle", "felfújható kajak", "úszást segítő mellény", "roller", "elektromos roller", "Thule", "kempingágy",  "evezőpad", "családi kempingsátor", "halradar", "teniszütő", "hátizsák", "túrabot", "síbot", "úszóoverall", "vízhatlan táska", "búvárruha", "float tube", "túrabot", "sátor", "gördeszka", "SUP", "Hamax", "babahordozó", "kempingszék", "futópad", "kerékpár utánfutó", "snowboard", "snowboard deszka", "gyerek síléc", "gyerek síléc",
];

const suggestionsLT = [
  "dviratis", "kalnų dviratis", "mtb dviratis", "elektrinis dviratis", "plento dviratis", "žvyrkelių dviratis", "miesto dviratis",
  "vaikiškas dviratis", "vaikiškas elektrinis dviratis", "miesto elektrinis dviratis", "sulankstomas dviratis", "sulankstomas elektrinis dviratis",
  "dviračio priekaba", "žygių dviratis", "elektrinis žygių dviratis", "vaikiška dviračio kėdutė",
  "treniruoklis", "interaktyvus treniruoklis", "dviračio krepšys", "stogo dėžė", "stogo bagažinė", "dviračio bagažinė", "bagažinė dviračiams",
  "stovyklavimo palapinė", "pripučiama palapinė", "stovyklavimo čiužinys", "palapinė", "alpinistinės katės", "sniegbačiai", "kirvukas", "slidės", "lygumų slidės", "lygumų slidės",
  "kalnų slidės", "žygių slidės", "žygiai slidėmis", "treniruoklis", "stacionarus dviratis", "stovyklavimo virtuvė", "turistinė spinta", "stovyklavimo spinta",
  "vandenlentė", "vandenlentės irklas", "pripučiama baidarė", "liemenė", "paspirtukas", "elektrinis paspirtukas", "thule", "sulankstoma lova",
  "elipsinis treniruoklis", "irklavimo treniruoklis", "karpių žvejybos palapinė", "echolotas", "teniso raketė", "kuprinė", "žygių lazdos", "slidinėjimo lazdos",
  "reabilitacinis dviratis", "kombinezonas", "turistinė pavėsinė", "buriavimo maišas", "porolonas", "plaustas", "turistinė lazda",
  "kupolinė palapinė", "riedlentė", "vandenlentė", "vaikiška kėdutė", "nešynė", "turistinė kėdė", "bėgimo takelis", "vaikiškas vežimėlis",
  "snieglentė", "snieglentė", "slidės vaikams", "slidės jaunuoliams", "gelbėjimosi nuo lavinų rinkinys"
]

const suggestionsCZ = [
  "snowboard", "dětské lyže", "juniorské lyže", "lavinová sada", "kolo", "horské kolo", "mtb kolo",
  "elektrické kolo", "silniční kolo", "gravel kolo", "městské kolo", "dětské kolo", "dětské elektrokolo",
  "městské elektrokolo", "skládací kolo", "skládací elektrické kolo", "vozík za kolo", "trekingové kolo",
  "trekingové elektrokolo", "dětská sedačka na kolo", "trenažér", "interaktivní trenažér", "brašny na kolo",
  "střešní box", "střešní nosič", "nosič jízdního kola", "nosič na jízdní kola", "kempingový stan", "nafukovací stan",
  "kempingová matrace", "stan", "mačky", "sněžnice", "cepín", "lyže", "běžecké lyže", "běžky", "sjezdové lyže", "lyže na skialpinismus",
  "skialpy", "eliptický trenažér", "rotoped", "kempingová kuchyně", "turistická skříň", "kempingový šatník", "paddleboard", "nafukovací kajak",
  "vesta", "koloběžka", "elektrická koloběžka", "thule", "lehátko", "eliptický stroj", "veslařský trenažér", "stan na lov kaprů",
  "echosonda", "tenisová raketa", "batoh", "trekingové hole", "lyžařské hole", "rehabilitační trenažér", "kombinéza", "turistický přístřešek",
  "vodotěsný vak", "kombinéza", "rybářský člun", "turistické hole", "kopulovitý stan", "skateboard", "sup", "hamax",
  "dětské nosítko", "kempingová židle", "běžecký pás", "dětský vozík"
]

const suggestionsLV = [
  "velosipēds", "kalnu velosipēds", "mtb velosipēds", "elektriskais velosipēds", "ceļa velosipēds", "grants velosipēds", "pilsētas velosipēds",
  'bērnu velosipēds', 'bērnu elektriskais velosipēds', 'pilsētas elektriskais velosipēds', 'saliekamais velosipēds', 'saliekamais elektriskais velosipēds',
  'velosipēda piekabe', 'pārgājienu velosipēds', 'pārgājienu elektriskais velosipēds', 'bērnu velosipēda sēdeklis',
  'trenažieri', 'interaktīvais trenažieris', 'velosipēdu pakas', 'jumta kaste', 'jumta bagāžnieks', 'velosipēdu bagāžnieks', 'velosipēdu bagāžnieks',
  'kempinga telts', 'piepūšamā telts', 'kempinga matracis', 'telts', 'krampji', 'sniega kurpes', 'cirvis', 'slēpes', 'distanču slēpes', 'distanču slēpes',
  "kalnu slēpes", "slēpošanas tūrisma slēpes", "slēpošanas slēpes", "eliptiskais trenažieris", "stacionārais velosipēds", "kempinga virtuve", "tūristiskais skapis", "kempinga skapis",
  'sup board', "stand up paddle", "piepūšamā kajaks", "veste", "skūteris", "elektriskais skrejritenis", "thule", "telpa gulta",
  'eliptiskais velosipēds', 'airētājs', 'karpu telts', 'eholote', 'tenisa rakete', 'mugursoma', 'pārgājienu nūjas', 'slēpošanas nūjas',
  'rehabilitācijas velosipēds', 'kombinezons', 'tūristu kabīne', 'buru soma', 'hidrotērps', 'pludiņš', 'tūristu stabs',
  'kupola telts', 'skeitbords', 'sup', 'hamax', 'bērnu pārvadātājs', 'kempinga krēsls', 'skrejceliņš', 'zīdaiņu ratiņi',
  'snovbords', 'snovbords', 'bērnu slēpes', 'junioru slēpes', 'lavīnu komplekts'
];

const suggestionsEE = [
  'jalgratas', 'mägijalgratas', 'mtb bike', 'elektriratas', 'maanteeratas', 'kruusaratas', 'linnaratas',
  'lasteratas', 'laste elektrijalgratas', 'linna elektrijalgratas', 'kokkupandav jalgratas', 'kokkupandav elektrijalgratas',
  'jalgrattahaagis', 'matkaratas', 'matka elektrijalgratas', 'laste jalgrattaiste',
  'treener', 'interaktiivne treener', 'jalgrattahoidjad', 'katuseboks', 'katuseraam', 'jalgrattaraam', 'jalgrattaraam',
  'matkatelk', 'täispuhutav telk', 'matkamadrats', 'telk', 'kramplid', 'räätsad', 'kirves', 'suusad', 'murdmaasuusad', 'murdmaasuusad',
  'mäesuusad', 'suusamatka suusad', 'skitour', 'elliptiline trenažöör', 'statsionaarne jalgratas', 'matkaköök', 'turismigarderoob', 'matkariidekapp',
  'sup board', 'stand up paddle', 'täispuhutav kajak', 'vest', 'tõukeratas', 'elektritõukeratas', 'thule', 'laagrivoodi',
  'elliptiline jalgratas', 'sõudja', 'karpkalatelk', 'kajaloodi', 'tennisereket', 'seljakott', 'matkakepid', 'suusakepid',
  'rehabilitatsiooniratas', 'kombinesoon', 'turistiputka', 'purjekast', 'märguülikond', 'ujuk', 'turismikepp',
  'kuplitelk', 'rula', 'sup', 'hamax', 'lapsekandja', 'matkatool', 'jooksurada', 'beebikäru',
  'lumelaud', 'lumelaud', 'laste suusad', 'juunioride suusad', 'laviinikomplekt'
];

const SUGGESTIONS = {
  et: suggestionsEE, 
  lv: suggestionsLV,
  cz: suggestionsCZ,
  lt: suggestionsLT,
  hu: suggestionsHU,
  pl: suggestions
}

const NUMBER_OF_DEGREES_FROM_BOUNDS = 0.02;

const SearchForm = ({ history, intl }) => {
  const _geocoderRef = useRef();

  const getGeocoder = () => {
    // Create the Geocoder as late as possible only when it is needed.
    if (!_geocoderRef.current) {
      _geocoderRef.current = new Geocoder();
    }
    return _geocoderRef.current;
  }

  const handleSubmit = async ({ category, location }) => {
    const { search, selectedPlace, predictions } = location || {};
    let firstPrediction;

    if(!selectedPlace && predictions && predictions[0]) {
      firstPrediction = await getGeocoder().getPlaceDetails(predictions[0]);
    }
    
    const { address, bounds, origin } = selectedPlace || firstPrediction || {};

    const searchParams = {
      address,
      bounds,
    }

    const addressParam =
      search && (selectedPlace || firstPrediction)
        ? searchParams.address
        :undefined;

    const originParam =
      search && (selectedPlace || firstPrediction)
        ? `${origin.lat},${origin.lng}`
        : undefined;

    const boundsState =
      search && (selectedPlace || firstPrediction)
        ? `${bounds.ne.lat + NUMBER_OF_DEGREES_FROM_BOUNDS},${bounds.ne.lng +
          NUMBER_OF_DEGREES_FROM_BOUNDS},${bounds.sw.lat - NUMBER_OF_DEGREES_FROM_BOUNDS},${bounds
          .sw.lng - NUMBER_OF_DEGREES_FROM_BOUNDS}`
        : config.maps.search.countryBounds.bounds;
    const keywordsParam = category ? category.toLowerCase() : undefined;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { address: addressParam, origin: originParam, keywords: keywordsParam }
      ),
      { bounds: boundsState }
    );
  }

  const lang = process.env.REACT_APP_LANG;

  return (
    <Form onSubmit={handleSubmit}>
      {props => (
        <form onSubmit={props.handleSubmit} className={css.heroForm}>
          <LocationAutocompleteInputField
            className={css.customSelectWrapper}
            inputClassName={css.customSelectInput}
            iconClassName={css.customSelectInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            placeholder={intl.formatMessage({ id: 'SearchForm.searchLocation' })}
            valueFromForm={props.values.location}
            useDefaultPredictions={false}
            citiesOnly
          />
            <Field name="category">
              {props => (
                <AutocompleteTextboxField
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  suggestions={SUGGESTIONS[lang] || SUGGESTIONS["pl"]}
                  placeholder={intl.formatMessage({ id: 'SearchForm.searchProduct' })}
                  className={css.customSelectWrapper}
                  inputClassName={css.customSelectInput}
                  iconClassName={css.customSelectInputIcon}
                  predictionsClassName={css.suggestions}
                />
              )}
            </Field>

            <button className={css.submitButton} type="submit">
              <SearchIcon />
              <span style={{ marginLeft: '9px' }}><FormattedMessage id="SearchForm.search"/></span>
            </button>
        </form>
      )}
    </Form>
  );
}

export default withRouter(injectIntl(SearchForm));
