import React from 'react';
import { Controller } from 'react-hook-form';

import { Input, RadioButton } from '../../../../components';

import css from './DiscountSection.css';

const DiscountSection = ({ intl, control, setValue, errors, fields, typeField, currency }) => {
  const handleChange = event => {
    const { checked, name } = event.target;
    const secondValueName =
      name === fields.DISCOUNT_AMOUNT ? fields.DISCOUNT_PERCENTAGE : fields.DISCOUNT_AMOUNT;
    setValue(name, checked);
    setValue(secondValueName, !checked);
  };

  return (
    <div>
      <h3 className={css.title}>{intl.formatMessage({ id: 'Console.stepTwo' })} </h3>
      <div className={css.discountContainer}>
        <Controller
          control={control}
          name={fields.DISCOUNT_VALUE}
          rules={{
            required: true,
            min: 1,
            valueAsNumber: true,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder={intl.formatMessage({ id: 'Console.discountPlaceholder' })}
              id={fields.DISCOUNT_VALUE}
              value={value}
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              type="number"
              labelClassName={css.label}
              className={css.input}
              containerClassName={css.input}
              error={errors.discountValue && intl.formatMessage({ id: 'Console.discountValueError' })}
            />
          )}
        />
        <div className={css.inputBox}>
          <Controller
            name={fields.DISCOUNT_AMOUNT}
            control={control}
            render={({ field: { value } }) => (
              <RadioButton
                name={fields.DISCOUNT_AMOUNT}
                checked={value}
                onChange={handleChange}
                label={currency}
                textClassName={css.textCheckbox}
                disabled={typeField === 'gift-card'}
                labelClassName={typeField === 'gift-card' && css.disabledRadioAmount}
              />
            )}
          />
        </div>
        <div className={css.inputBox}>
          <Controller
            name={fields.DISCOUNT_PERCENTAGE}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={fields.DISCOUNT_PERCENTAGE}
                checked={value}
                onChange={handleChange}
                label={intl.formatMessage({ id:'Console.discountPercentage'})}
                disabled={typeField === 'gift-card'}
                labelClassName={typeField === 'gift-card' && css.disabledRadioPercentage}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountSection;
