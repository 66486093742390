import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { parse, stringify } from '../../util/urlHelpers';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { InvoiceSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { updateProfile } from './InvoiceSettingsPage.duck';

import css from './InvoiceSettingsPage.css';

export class ProfileSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      intl,
    } = this.props;

    const { lId } = parse(this.props.location.search);

    const handleSubmit = values => {
      const updatedValues = {
        defaultTransactionMeta: {
          ...values.checkoutFields.invoice,
          customerType: values.customerType
        }
      }

      onUpdateProfile(updatedValues).then(() => redirectAfterSaving());
    };

    const redirectAfterSaving = () => {
      const { lId, page } = parse(this.props.location.search);
      if (lId) {
        this.props.history.push(`/l/${lId}`);
      }
      if (page) {
        this.props.history.push(`/`);
      }
    }

    const user = ensureCurrentUser(currentUser);
    const { customerType, ...rest } = user.attributes.defaultTransactionMeta || {};

    const invoiceSettingsForm = user.id ? (
      <InvoiceSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ 
          customerType: customerType || 'individual',
          checkoutFields: {
            invoice: {
              ...rest
            }
          }
        }}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="InvoiceSettingsPage" />
            <UserNav selectedPageName="InvoiceSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="InvoiceSettingsPage.heading" />
                </h1>
              </div>
              {invoiceSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withRouter
)(ProfileSettingsPageComponent);

ProfileSettingsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser({ include: ['image']});
};

export default ProfileSettingsPage;
