import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { bool, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData } from './PayoutFuturePage.duck';
import {
  Page,
  PaginationLinks,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperBusinessSideNav,
  Footer,
  Table,
  NamedLink,
  IconArrowHead,
  TableSkeleton,
} from '../../components';
import { TopbarContainer } from '..';

import css from './PayoutFuturePage.css';

const MIN_NUMBER_OF_DAYS = 7;

const PayoutFuturePageComponent = props => {
  const {
    intl,
    params,
    location: { search },
    scrollingDisabled,
    futureTransactions,
    queryFutureTransactionsInProgress,
    queryFutureTransactionsError,
    queryFutureTransactionsSuccess,
    pagination,
    name,
  } = props;
  const title = intl.formatMessage({ id: 'PayoutFuturePage.header' });
  const { id } = params;
  const currentDate = moment();

  const paginationLinks =
    !queryFutureTransactionsInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={params}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = queryFutureTransactionsError ? (
    <p className={css.error}>{queryFutureTransactionsError?.message}</p>
  ) : null;

  const noResultsMessage =
    !queryFutureTransactionsInProgress &&
    !futureTransactions?.length &&
    !queryFutureTransactionsError ? (
      <p className={css.noResults}>
        <FormattedMessage id="PayoutFuturePage.noPayoutFound" />
      </p>
    ) : null;

  const tableConfig = [
    {
      label: intl.formatMessage({ id: 'PayoutFuturePage.reservationNumber' }),
      render: transaction => {
        return transaction?.attributes?.code && transaction?.id?.uuid ? (
          <NamedLink
            className={css.code}
            name={'SaleDetailsPage'}
            params={{ id: transaction?.id?.uuid }}
          >
            {transaction?.attributes?.code}
          </NamedLink>
        ) : null;
      },
    },
    {
      label: intl.formatMessage({ id: 'PayoutFuturePage.date' }),
      render: transaction =>
        intl.formatDate(moment(transaction?.attributes?.createdAt), {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
    },
    {
      label: intl.formatMessage({ id: 'PayoutFuturePage.amount' }),
      render: transaction => formatMoney(intl, transaction?.attributes?.payoutTotal),
    },
    {
      label: intl.formatMessage({ id: 'PayoutFuturePage.FirstAndLastName' }),
      render: transaction =>
        `${transaction?.attributes?.firstName} ${transaction?.attributes?.lastName}`,
    },
    {
      label: intl.formatMessage({ id: 'PayoutFuturePage.status' }),
      render: transaction => {
        const transactionDate = moment(transaction?.attributes?.createdAt);
        const days = currentDate.diff(transactionDate, 'days');
        return transaction?.attributes?.processState === 'delivered' &&
          MIN_NUMBER_OF_DAYS < days ? (
          <p className={css.readyStatus}>
            {intl.formatMessage({ id: '"PayoutFuturePage.readyStatus"' })}
          </p>
        ) : (
          <p className={css.pendingStatus}>
            {intl.formatMessage({ id: 'PayoutFuturePage.pendingStatus' })}
          </p>
        );
      },
    },
  ];

  const keyFn = payouts => payouts?.id.uuid;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={name} />
          <UserNav selectedPageName={name} />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="PayoutPage" />
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.navigation}>
            <NamedLink
              className={classNames(css.linkText, css.link)}
              name="PayoutPage"
              params={{ id }}
            >
              {intl.formatMessage({ id: 'PayoutFuturePage.payoutDetails' })}
            </NamedLink>
            <IconArrowHead direction={'right'} className={css.arrow} />
            <span className={css.linkText}>
              {intl.formatMessage({ id: 'PayoutFuturePage.transactionList' })}
            </span>
          </div>
          <div className={css.title}>
            {intl.formatMessage({ id: 'PayoutFuturePage.header' }, { date: parse(search)?.date })}
          </div>

          {queryFutureTransactionsInProgress ? (
            <div className={css.wrapper}>
              <TableSkeleton
                rowNumber={8}
                columnNumber={tableConfig.length}
                classNameCell={css.cell}
              />
            </div>
          ) : futureTransactions?.length ? (
            <div className={css.wrapper}>
              <Table
                data={futureTransactions}
                config={tableConfig}
                keyFn={keyFn}
                classNameCell={css.cell}
              />

              {paginationLinks}
            </div>
          ) : null}
          {errorMessage}
          {noResultsMessage}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutFuturePageComponent.propTypes = {
  pagination: propTypes.pagination,
  queryFutureTransactionsInProgress: bool.isRequired,
  queryFutureTransactionsError: oneOf([bool, propTypes.error]),
  queryFutureTransactionsSuccess: bool,
  futureTransactions: propTypes.transaction,
  name: string,
  scrollingDisabled: bool.isRequired,
  params: shape({ id: string, payoutId: string }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    pagination,
    futureTransactions,
    queryFutureTransactionsInProgress,
    queryFutureTransactionsError,
    queryFutureTransactionsSuccess,
  } = state.PayoutFuturePage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    futureTransactions,
    queryFutureTransactionsInProgress,
    queryFutureTransactionsError,
    queryFutureTransactionsSuccess,
    pagination,
  };
};

const PayoutFuturePage = compose(
  connect(mapStateToProps, null),
  injectIntl,
  withRouter
)(PayoutFuturePageComponent);

PayoutFuturePage.loadData = loadData;

export default withRouter(PayoutFuturePage);
