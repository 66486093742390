import { routeConfiguration } from "..";

// export class LoggingAnalyticsHandler {
//   trackPageView(url, prevUrl) {
//     const routes = routeConfiguration();
//     const page = routes.find(route => new RegExp(`^${route.path.replace(/:[^\/]+/g, '[^/]+')}$`).test(url.split('?')[0]))
//     console.log('Analytics page view:', url);
//     window.gtag({
//       event: 'pageview_data',
//       url,
//       prevUrl: prevUrl || document?.referrer,
//       type: page?.name
//     })
//   }
// }

export class GoogleAnalytics4Handler {
  trackPageView(url, prevUrl) {
    const routes = routeConfiguration();
    const page = routes.find(route => new RegExp(`^${route.path.replace(/:[^\/]+/g, '[^/]+')}$`).test(url.split('?')[0]))
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview_data',
      url,
      prevUrl: prevUrl || document?.referrer,
      type: page?.name
    })
  }
}

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

export class GoogleAnalyticsHandler {
  constructor(ga) {
    if (typeof ga !== 'function') {
      throw new Error('Variable `ga` missing for Google Analytics');
    }
    this.ga = ga;
  }
  trackPageView(url) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
    this.ga('set', 'page', url);
    this.ga('send', 'pageview');
  }
}