import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { decathlonColor, rentColor } = props;
  return (
    <svg width="206" height="24" viewBox="0 0 206 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.7902 20.05H57.6692V16.554H50.9302V13.6215H56.8987V10.367H50.9302V7.4345H57.6692V3.95H46.7902V20.05ZM71.1702 13.1155C69.5947 15.6225 68.0652 16.669 66.0987 16.669C63.5457 16.669 62.0622 14.875 62.0622 11.7585C62.0622 8.803 63.4307 7.331 65.5352 7.331C66.9267 7.331 68.0767 7.952 68.4332 10.022H72.5732C72.1247 6.0775 69.6062 3.628 65.5812 3.628C60.9122 3.628 57.8417 6.9285 57.8417 11.9885C57.8417 17.083 60.9122 20.372 65.9607 20.372C69.2612 20.372 71.5037 18.992 72.9872 17.129H78.6337V20.05H82.7507V3.95H76.9087L71.1702 13.1155ZM78.6337 13.8975H74.9882L78.6337 7.975V13.8975ZM37.9582 3.95H31.9092V20.05H37.9582C42.7537 20.05 45.8472 16.8875 45.8472 12C45.8472 7.1125 42.7537 3.95 37.9582 3.95ZM37.9007 16.554H36.0492V7.4345H37.9007C40.2927 7.4345 41.6382 9.125 41.6382 12C41.6382 14.8635 40.2927 16.554 37.9007 16.554ZM130.269 3.628C125.404 3.628 122.138 6.9285 122.138 12C122.138 17.0715 125.404 20.372 130.269 20.372C135.145 20.372 138.399 17.0715 138.399 12C138.399 6.9285 135.145 3.628 130.269 3.628ZM130.269 16.669C127.877 16.669 126.37 15.0705 126.37 12C126.37 8.9295 127.877 7.331 130.269 7.331C132.672 7.331 134.167 8.9295 134.167 12C134.167 15.0705 132.672 16.669 130.269 16.669ZM83.7857 7.4345H87.8452V20.05H91.9852V7.4345H96.0447V3.95H83.7857L83.7857 7.4345ZM148.968 3.95V12.8165L143.632 3.95H139.342V20.05H143.344V10.8155L148.899 20.05H152.97V3.95L148.968 3.95ZM116.273 3.95H112.133V20.05H122.518V16.5655H116.273V3.95ZM106.797 10.091H101.22V3.95H97.0797V20.05H101.22V13.564H106.797V20.05H110.937V3.95H106.797V10.091Z"
        fill={decathlonColor} />
      <path
        d="M20.2253 0.5C11.2354 0.5 0.0527344 9.80155 0.0527344 17.5105C0.0527344 21.492 3.11094 23.5 7.15007 23.5C10.116 23.5 13.705 22.4152 17.1671 20.3264V4.94305C16.2439 6.52408 11.9047 12.8944 8.41952 16.2873C6.6423 18.0183 5.23438 18.7684 4.02264 18.7684C2.66086 18.7684 2.01461 17.8452 2.01461 16.4719C2.01461 10.2401 12.5048 2.13874 19.4521 2.13874C22.3142 2.13874 24.1606 3.40818 24.1606 5.87782C24.1606 8.13974 22.6257 10.9787 20.0061 13.5983V18.3645C24.5761 14.7524 27.3111 10.1478 27.3111 6.43176C27.3111 2.51957 24.2645 0.5 20.2253 0.5Z"
        fill={decathlonColor} />
      <path
        d="M205.44 7.87891V10.0664H198.542V7.87891H205.44ZM200.245 5.00391H203.073V16.2695C203.073 16.6497 203.131 16.9414 203.245 17.1445C203.365 17.3424 203.521 17.4779 203.714 17.5508C203.907 17.6237 204.12 17.6602 204.354 17.6602C204.532 17.6602 204.693 17.6471 204.839 17.6211C204.99 17.5951 205.104 17.5716 205.183 17.5508L205.659 19.7617C205.508 19.8138 205.292 19.8711 205.011 19.9336C204.735 19.9961 204.396 20.0326 203.995 20.043C203.287 20.0638 202.649 19.957 202.081 19.7227C201.513 19.4831 201.063 19.1133 200.729 18.6133C200.401 18.1133 200.24 17.4883 200.245 16.7383V5.00391Z"
        fill={rentColor} />
      <path
        d="M189.53 12.8477V19.8789H186.702V7.87891H189.405V9.91797H189.546C189.822 9.24609 190.262 8.71224 190.866 8.31641C191.476 7.92057 192.228 7.72266 193.124 7.72266C193.952 7.72266 194.674 7.89974 195.288 8.25391C195.908 8.60807 196.387 9.12109 196.726 9.79297C197.069 10.4648 197.239 11.2799 197.233 12.2383V19.8789H194.405V12.6758C194.405 11.8737 194.197 11.2461 193.78 10.793C193.369 10.3398 192.799 10.1133 192.069 10.1133C191.575 10.1133 191.134 10.2227 190.749 10.4414C190.369 10.6549 190.069 10.9648 189.851 11.3711C189.637 11.7773 189.53 12.2695 189.53 12.8477Z"
        fill={rentColor} />
      <path
        d="M179.634 20.1133C178.431 20.1133 177.392 19.8633 176.517 19.3633C175.647 18.8581 174.978 18.1445 174.509 17.2227C174.04 16.2956 173.806 15.2044 173.806 13.9492C173.806 12.7148 174.04 11.6315 174.509 10.6992C174.983 9.76172 175.644 9.03255 176.493 8.51172C177.342 7.98568 178.34 7.72266 179.485 7.72266C180.225 7.72266 180.923 7.84245 181.579 8.08203C182.241 8.31641 182.824 8.68099 183.329 9.17578C183.84 9.67057 184.241 10.3008 184.532 11.0664C184.824 11.8268 184.97 12.7331 184.97 13.7852V14.6523H175.134V12.7461H182.259C182.254 12.2044 182.136 11.7227 181.907 11.3008C181.678 10.8737 181.358 10.5378 180.946 10.293C180.54 10.0482 180.066 9.92578 179.524 9.92578C178.946 9.92578 178.438 10.0664 178.001 10.3477C177.563 10.6237 177.222 10.9883 176.978 11.4414C176.738 11.8893 176.616 12.3815 176.61 12.918V14.582C176.61 15.2799 176.738 15.8789 176.993 16.3789C177.248 16.8737 177.605 17.2539 178.063 17.5195C178.522 17.7799 179.058 17.9102 179.673 17.9102C180.084 17.9102 180.457 17.8529 180.79 17.7383C181.123 17.6185 181.412 17.444 181.657 17.2148C181.902 16.9857 182.087 16.7018 182.212 16.3633L184.853 16.6602C184.686 17.3581 184.368 17.9674 183.899 18.4883C183.436 19.0039 182.842 19.4049 182.118 19.6914C181.394 19.9727 180.566 20.1133 179.634 20.1133Z"
        fill={rentColor} />
      <path
        d="M160.97 19.8789V3.87891H166.97C168.199 3.87891 169.23 4.09245 170.063 4.51953C170.902 4.94661 171.535 5.54557 171.962 6.31641C172.394 7.08203 172.61 7.97526 172.61 8.99609C172.61 10.0221 172.392 10.9128 171.954 11.668C171.522 12.418 170.884 12.9987 170.04 13.4102C169.196 13.8164 168.16 14.0195 166.931 14.0195H162.657V11.6133H166.54C167.259 11.6133 167.847 11.5143 168.306 11.3164C168.764 11.1133 169.103 10.819 169.321 10.4336C169.545 10.043 169.657 9.5638 169.657 8.99609C169.657 8.42839 169.545 7.94401 169.321 7.54297C169.097 7.13672 168.756 6.82943 168.298 6.62109C167.84 6.40755 167.248 6.30078 166.524 6.30078H163.868V19.8789H160.97ZM169.235 12.6289L173.196 19.8789H169.962L166.071 12.6289H169.235Z"
        fill={rentColor} />
    </svg>
  );
};

IconLogo.defaultProps = { decathlonColor: "#3643BA", rentColor: "#5e68c8" };

const { string } = PropTypes;

IconLogo.propTypes = { decathlonColor: string, rentColor: string };

export default IconLogo;
