import React from 'react';
import { string } from 'prop-types';

export function SearchIcon({ fill = '#2B2B2B', stroke = 'white' }) {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="8" r="7" stroke={stroke} strokeWidth="2" />
      <circle cx="10" cy="8" r="5" stroke={fill} fill={fill} strokeWidth="2" />
      <path d="M6 13.5L1 18.5" stroke={stroke} strokeWidth="2" />
    </svg>
  );
}

SearchIcon.propTypes = {
  fill: string,
};

export default SearchIcon;