import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectExperimentVariant } from '../ducks/AbTest.duck';
import { ExperimentVariant } from '../abTest';

export function useExperiment({ id, variants, defaultVariant = ExperimentVariant.ORIGINAL }) {
  const activeVariant = useSelector(selectExperimentVariant(id));
  const currentVariant = activeVariant || defaultVariant;

  useEffect(() => {
    if (activeVariant) {
      console.info(`Experiment: ${id} - variant: ${activeVariant} is selected`);
    } else {
      console.warn(
        `Experiment: ${id} - missing active variant - using ${defaultVariant} as a fallback`
      );
    }

    if (window.ga) {
      window.ga('set', 'exp', `${id}.${currentVariant}`);
      window.ga('send', 'pageview');
      console.info(`Experiment: ${id} - variant: ${currentVariant} has been logged to GA`);
    } else {
      console.error(`Experiment: ${id} - missing GA integration`);
    }
  }, [id, currentVariant]);

  const Variant = useMemo(
    () => () =>
      currentVariant && variants[currentVariant] ? <>{variants[currentVariant]}</> : null,
    [variants, currentVariant]
  );

  return {
    Variant,
    currentVariant,
  };
}

useExperiment.propTypes = {
  id: PropTypes.string.isRequired,
  variants: PropTypes.objectOf(PropTypes.node).isRequired,
};
