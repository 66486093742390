import React from 'react';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import DepositIcon from './rulesDollar.svg';
import TermsIcon from './rulesTerms.svg';
import TimeIcon from './rulesTime.svg';

import css from './ListingPage.css';
import classNames from 'classnames';

const SectionRulesMaybe = props => {
  const { className, rootClassName, authorPublicData, listingPublicData, isDecathlon, intl } = props;
  const classes = classNames(rootClassName, className);
  const isDecathlonUser = isDecathlon;
  const isExternalProvider = !isDecathlonUser;
  
  return (isExternalProvider && authorPublicData.rentTerms) || (listingPublicData.depositConditions || authorPublicData.depositConditions) || authorPublicData.openingHours ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>

      {
        authorPublicData.rentTerms &&
        <div className={css.rulesSubSection}>
          <SubSectionHeader header={intl.formatMessage({id: "SectionRulesMaybe.regulations"})}  icon={TermsIcon} />
          {!isDecathlon && <p className={css.description}> {intl.formatMessage({id: "SectionRulesMaybe.rentalRegulationsText"})} </p>}
          <a
            href={authorPublicData.rentTerms}
            className={css.termsLink}
            target="_blank"
          >
            {intl.formatMessage({id: "SectionRulesMaybe.rentalRegulations"})} 
          </a>
        </div>
      }

      {
        (listingPublicData.depositConditions || authorPublicData.depositConditions) &&
        <div className={css.rulesSubSection}>
          <SubSectionHeader header={intl.formatMessage({id: "SectionRulesMaybe.deposit"})} icon={DepositIcon} />
          <p className={css.description}>
            { listingPublicData.depositConditions || authorPublicData.depositConditions }
          </p>
        </div>
      }
      {
        authorPublicData.openingHours &&
        <div className={css.rulesSubSection}>
          <SubSectionHeader header={intl.formatMessage({id: "SectionRulesMaybe.equipment"})} icon={TimeIcon} />
          <p className={css.description}>
            { authorPublicData.openingHours }
          </p>
        </div>
      }
    </div>
  ) : null;
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    rules: string,
  }),
};

export default SectionRulesMaybe;

const SubSectionHeader = ({ header, icon }) => (
  <div className={css.rulesSubHeaderWrapper}>
    <img src={icon} className={css.rulesIcon} />
    <h3 className={css.rulesSubHeader}>{header}</h3>
  </div>
);