import React, { useEffect, useState } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  loadData,
  updateCoupons,
  createCoupons,
  updateCouponsClearState,
  createCouponsClearState,
} from './ConsoleCouponsPage.duck';
import { PaginationLinks, ConsoleWrapper, IconSpinner, IconAdd, Select } from '../../components';
import CouponCreationForm from './CouponCreationForm/CouponCreationForm';
import CouponItem from './CouponItem/CouponItem';
import CreatedCoupons from './CreatedCoupons/CreatedCoupons';
import SearchCoupon from './SearchCoupon/SearchCoupon';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';
import config from '../../config';

import css from './ConsoleCouponsPage.css';

const couponType = [
  { value: 'all', name: 'Console.choose' },
  { value: 'multi', name: 'Console.multi' },
  { value: 'once', name: 'Console.once' },
  { value: 'gift-card', name: 'Console.card' },
];

const methodsOfSettlingCoupons= [
  { id: 1, expense: true, isPaymentSource: false, message: "Console.firstSettlementMethod" },
  { id: 2, expense: false, isPaymentSource: true, message: "Console.secondSettlementMethod" },
  { id: 3, expense: true, isPaymentSource: true, message: "Console.thirdSettlementMethod" },
  { id: 4, expense: false, isPaymentSource: false, message: "Console.fourthSettlementMethod" }
];

const countryCurrency = [
  { countryId: 'pl', currency: 'PLN' },
  { countryId: 'hu', currency: 'HUF' },
  { countryId: 'cz', currency: 'CZK' },
  { countryId: 'lt', currency: 'EUR' },
  { countryId: 'lv', currency: 'EUR' },
  { countryId: 'ee', currency: 'EUR' },
];

const ConsoleCouponsPageComponent = ({
  scrollingDisabled,
  coupons,
  fetchInProgress,
  fetchCouponsError,
  pagination,
  intl,
  location: { search, pathname },
  history,
  name,
  tab,
  currentUser,
  partners,
  createCoupons,
  createInProgress,
  createCouponsError,
  createCouponsSuccess,
  createCouponsClearState,
  updateCoupon,
  updateInProgress,
  updateCouponsError,
  updateCouponsSuccess,
  updateCouponsClearState,
  createdCoupons,
}) => {
  const { codes, page, partnerId, countryId } = parse(search);
  const [isOpenCreateCouponForm, setIsOpenCreateCouponForm] = useState(false);
  const [isOpenCreatedCoupons, setIsOpenCreatedCoupons] = useState(false);
  const [couponIdEdit, setCouponIdEdit] = useState(null);
  const [couponIdExpand, setCouponIdExpand] = useState(null);
  const isAdmin = currentUser && currentUser.attributes.isAdmin;
  const isAdminForCountries = currentUser && !!currentUser.attributes.adminForCountries.length;
  const adminCountries =  currentUser && currentUser.attributes.adminForCountries;
  const allCountriesFilter = isAdmin
    ? [{ countryId: 'all', country: 'Console.all' }, ...config.countryNames]
    : isAdminForCountries
      ? config.countryNames.filter((item) => adminCountries.includes(item.countryId))
      : [];
  const defaultAdminCountryId = (isAdmin || (adminCountries && adminCountries.includes(defaultCountryId)))
    ? defaultCountryId
    : adminCountries ? adminCountries[0] : 'all';
  const isPartnerSelect = allCountriesFilter.find(item => item.countryId === 'pl');

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = !fetchInProgress && fetchCouponsError ? (
    <p className={css.error}>{fetchCouponsError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !coupons?.length && !fetchCouponsError ? (
      <p>
        <FormattedMessage id="Console.noReviewsFound" />
      </p>
    ) : null;

  const handleCreateCoupon = event => {
    event.preventDefault();
    setIsOpenCreateCouponForm(prevState => !prevState);
  };

  const handlePartnerChange = event => {
    const partnerId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), partnerId, page: 1 })}`,
    });
  };

  const handleCountryChange = event => {
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ codes, countryId: searchCountryId, page: 1 })}`,
    });
  };

  useEffect(() => {
    !!createdCoupons.length && setIsOpenCreatedCoupons(true);
  }, [createdCoupons]);

  useEffect(() => {
    if (!page && currentUser) {
      history.push({
        pathname,
        search: `${stringify({ ...parse(search), countryId: defaultAdminCountryId, page: 1 })}`,
      });
    }
  }, [page, currentUser]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <div className={css.filterContainer}>
        <SearchCoupon codes={codes} intl={intl} key='couponFilter'/>
        {isPartnerSelect && (
          <div className={css.filter} key='partnerFilter'>
            <Select
              value={partnerId || 'all'}
              onChange={handlePartnerChange}
              label={intl.formatMessage({ id: 'Console.partner' })}
              labelClassName={css.label}
              disabled={countryId !== 'pl' && countryId}
            >
              <option value={'all'}>{intl.formatMessage({ id: 'Console.choose' })}</option>
              {(countryId === 'pl' || !countryId) && partners.map(({ attributes: { name }, id: { uuid } }) => (
                <option value={uuid}>{name}</option>
              ))}
            </Select>
          </div>
        )}
        <div className={css.selectWrapper} key='countryFilter'>
          <Select
            value={countryId || 'all'}
            onChange={handleCountryChange}
            label={intl.formatMessage({id: "ConsoleTransactionPage.country" })}
            isValidation={false}
          >
            <option hidden value="">
              {intl.formatMessage({ id: 'Console.select' })}
            </option>
            {allCountriesFilter.map(({ countryId, country }) => (
              <option value={countryId}>{intl.formatMessage({ id: country })}</option>
            ))}
          </Select>
        </div>
      </div>
      <div>
        {isOpenCreateCouponForm ? (
          <CouponCreationForm
            intl={intl}
            createInProgress={createInProgress}
            createCouponsSuccess={createCouponsSuccess}
            createCoupons={createCoupons}
            createCouponsError={createCouponsError}
            setIsOpenCreateCoupon={setIsOpenCreateCouponForm}
            partners={partners}
            createCouponsClearState={createCouponsClearState}
            couponType={couponType}
            methodsOfSettlingCoupons={methodsOfSettlingCoupons}
            allCountriesFilter={allCountriesFilter}
            defaultAdminCountryId={defaultAdminCountryId}
            countryCurrency={countryCurrency}
          />
        ) : (
          <button onClick={handleCreateCoupon} className={css.addButton}>
            <IconAdd />
            {intl.formatMessage({ id: 'Console.addCoupon' })}
          </button>
        )}
      </div>
      {!!createdCoupons.length &&
        !isOpenCreateCouponForm &&
        isOpenCreatedCoupons && (
          <CreatedCoupons
            createdCoupons={createdCoupons}
            intl={intl}
            setIsOpenCreatedCoupons={setIsOpenCreatedCoupons}
            countryCurrency={countryCurrency}
            couponType={couponType}
          />
        )}
      {fetchInProgress || !currentUser ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <ul>
          {coupons.map(coupon => {
            const {
              id: { uuid },
            } = coupon;
            return (
              <CouponItem
                key={uuid}
                intl={intl}
                coupon={coupon}
                updateCoupon={updateCoupon}
                updateInProgress={updateInProgress}
                updateCouponsError={updateCouponsError}
                updateCouponsSuccess={updateCouponsSuccess}
                couponIdEdit={couponIdEdit}
                setCouponIdEdit={setCouponIdEdit}
                couponIdExpand={couponIdExpand}
                setCouponIdExpand={setCouponIdExpand}
                updateCouponsClearState={updateCouponsClearState}
                couponType={couponType}
                methodsOfSettlingCoupons={methodsOfSettlingCoupons}
                countryCurrency={countryCurrency}
              />
            );
          })}
        </ul>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchCouponsError,
    pagination,
    coupons,
    partners,
    createInProgress,
    createCouponsError,
    createCouponsSuccess,
    updateInProgress,
    updateCouponsError,
    updateCouponsSuccess,
    createdCoupons,
  } = state.ConsoleCouponsPage;
  const { currentUser } = state.user;

  return {
    fetchInProgress,
    fetchCouponsError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    coupons: getMarketplaceEntities(state, coupons),
    currentUser,
    partners: getMarketplaceEntities(state, partners),
    createInProgress,
    createCouponsError,
    createCouponsSuccess,
    updateInProgress,
    updateCouponsError,
    updateCouponsSuccess,
    createdCoupons,
  };
};

const mapDispatchToProps = dispatch => ({
  createCoupons: data => dispatch(createCoupons(data)),
  updateCoupon: data => dispatch(updateCoupons(data)),
  createCouponsClearState: () => dispatch(createCouponsClearState()),
  updateCouponsClearState: () => dispatch(updateCouponsClearState()),
});

const ConsoleCouponsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleCouponsPageComponent);

ConsoleCouponsPage.loadData = loadData;

export default ConsoleCouponsPage;
