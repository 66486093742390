import React from 'react';
import css from './BusinessInfo.css';

const BusinessInfo = ({ infoText }) => {
  return (
    <div className={css.infoContainer}>
      <div className={css.questionMark}>?</div>
      <p className={css.infoText}>{infoText}</p>
    </div>
  );
}

export default BusinessInfo;