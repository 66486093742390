import React from 'react';
import classNames from 'classnames';
import { func, string, bool, oneOf, node } from 'prop-types';

import css from './Checkbox.css';

const VARIANTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const Checkbox = ({
  checked,
  onChange,
  label,
  labelClassName,
  inputClassName,
  textClassName,
  checkmarkClassName,
  size,
  disabled,
  ...props
}) => {
  const labelClass = classNames(labelClassName, css.label, {
    [css.largeLabel]: size === VARIANTS.LARGE,
    [css.mediumLabel]: size === VARIANTS.MEDIUM,
    [css.smallLabel]: size === VARIANTS.SMALL,
    [css.labelDisabled]: disabled,
  });
  const inputClasses = classNames(inputClassName, css.checkboxInput);
  const textClasses = classNames(textClassName, css.text);
  const checkmarkClass = classNames(checkmarkClassName, css.checkmark, {
    [css.large]: size === VARIANTS.LARGE,
    [css.medium]: size === VARIANTS.MEDIUM,
    [css.small]: size === VARIANTS.SMALL,
    [css.disabled]: disabled,
  });

  return (
    <label className={labelClass}>
      <span className={textClasses}>{label}</span>
      <input
        className={inputClasses}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <span className={checkmarkClass}></span>
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  labelClassName: null,
  inputClassName: null,
  textClassName: null,
  checkmarkClassName: null,
  size: VARIANTS.MEDIUM,
};

Checkbox.propTypes = {
  checked: bool,
  onChange: func,
  label: oneOf([string, node]),
  labelClassName: string,
  inputClassName: string,
  textClassName: string,
  checkmarkClassName: string,
  size: oneOf([VARIANTS.SMALL, VARIANTS.MEDIUM, VARIANTS.LARGE]),
};

export default Checkbox;
