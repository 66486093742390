import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import Phones from './phone.png';

import css from './SectionPartners.css';
import { FormattedMessage } from 'react-intl';

const SectionPartners = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.textWrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionPartners.title"/>
        </h2>
        <p className={css.text}>
          <FormattedMessage id="SectionPartners.text"/>
        </p>
        <a className={css.btn} href="https://partner.rent.decathlon.pl" target="_blank">
          <FormattedMessage id="SectionPartners.btn"/>
        </a>
      </div>
      <div className={css.images}>
        <img src={Phones} alt="Wypożyczalnia dla partnerów"/>
      </div>
    </div>
  );
};

SectionPartners.defaultProps = { rootClassName: null, className: null };

SectionPartners.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPartners;
