import React, { useState, useEffect } from 'react';

import { Button, InlineTextButton, IconWarning, Select } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import config from '../../../config';

import css from './EditPermission.css';

const notificationSelect = [
  { text: 'TeamPage.no', value: false },
  { text: 'TeamPage.yes', value: true },
];

const EditPermission = ({
  sendNotifications,
  role,
  updateInProgress,
  updatePermissions,
  updatePermissionsError,
  updatePermissionsSuccess,
  intl,
  setPermissionIdEdit,
  permissionsId,
  clearUpdatePermissionsState,
  registered,
}) => {
  const [notifications, setNotifications] = useState(sendNotifications);
  const [teamRole, setTeamRole] = useState(role);
  const [isClicked, setIsClicked] = useState(false);

  const errorMessage = updatePermissionsError ? (
    <p className={css.error}>
      <IconWarning variant="error" /> <FormattedMessage id="TeamPage.editingError" />
    </p>
  ) : null;

  const handleNotificationChange = event => {
    setNotifications(event.target.value === 'true');
  };

  const handleRoleChange = event => {
    setTeamRole(event.target.value);
  };

  const handleCancel = event => {
    event.preventDefault();
    setNotifications(sendNotifications);
    setTeamRole(role);
    setPermissionIdEdit(null);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsClicked(true);
    updatePermissions({ id: permissionsId, role: teamRole, sendNotifications: notifications });
  };

  useEffect(() => {
    !updateInProgress && setIsClicked(false);
  }, [updateInProgress]);

  useEffect(() => {
    !updateInProgress && updatePermissionsSuccess && isClicked && setPermissionIdEdit(null);
  }, [updateInProgress, updatePermissionsSuccess]);

  useEffect(() => {
    clearUpdatePermissionsState();
  }, []);

  return (
    <div className={css.container}>
      <h3 className={css.title}>{intl.formatMessage({ id: 'TeamPage.editPermissions' })}</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={css.formContainer}>
            <Select 
              label={intl.formatMessage({ id: "TeamPage.notifications" })}
              value={notifications} 
              onChange={handleNotificationChange}
              containerClassName={css.selectContainer}
              isValidation={false}
            >
              {notificationSelect.map(({ text, value }) => (
                <option value={value}>{intl.formatMessage({ id: text })}</option>
              ))}
            </Select>
            <Select 
              label={intl.formatMessage({ id: "TeamPage.role" })}
              value={teamRole} 
              onChange={handleRoleChange}
              containerClassName={css.selectContainer}
              isValidation={false}
            >
              {config.permissions.map(({ name, value }) => (
                <option value={value}>{name}</option>
              ))}
            </Select>
          </div>
          {notifications && !registered && (
            <div className={css.notificationContainer}>
              <IconWarning />
              <p className={css.notification}>
                {intl.formatMessage({ id: 'TeamPage.receiveNotifications' })}
              </p>
            </div>
          )}
          {errorMessage}
        </div>
        <div className={css.actionContainer}>
          <InlineTextButton className={css.cancelButton} onClick={handleCancel}>
            <FormattedMessage id="TeamPage.cancel" />
          </InlineTextButton>
          <Button
            type="submit"
            className={css.submitButton}
            inProgress={updateInProgress && isClicked}
            disabled={notifications === sendNotifications && teamRole === role}
          >
            {intl.formatMessage({ id: 'TeamPage.save' })}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditPermission;
