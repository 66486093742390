import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckbox, Input } from '../../components';
import config from '../../config';

import css from './ProfileSettingsForm.css';

const CONSENT_LABELS = {
  'email-5abRusEp': 'ProfileSettingsForm.marketingConsent',
  'plloyalty-YK68FDHN': 'ProfileSettingsForm.loyaltyConsent',
  'email-DraTu9As': 'ProfileSettingsForm.marketingConsent',
  'plloyalty-WZ7FrbzX': 'ProfileSettingsForm.loyaltyConsent'
}

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  render() {

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            invalid,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadInProgress,
            values,
            form
          } = fieldRenderProps;
          console.log(form.getFieldState('email-DraTu9As'))
          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });

          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || uploadInProgress || submitInProgress;

          const { consents = {} } = this.props.currentUser.attributes || {};
          const consentsKeys = Object.keys(consents).filter(c => c !== 'email');

          if (!this.props.currentUser) return null;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                    markRequired={true}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                    markRequired={true}
                  />
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}><FormattedMessage id="ProfileSettingsForm.rules" /></h3>
                <div className={css.termsContainer}>
                  <FieldCheckbox
                    id="terms"
                    name="terms"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.acceptTerms' })}
                    textClassName={css.consentLabel}
                    checked={values.terms}
                    valueFromForm={values.terms}
                    useSuccessColor
                  />
                  <div className={css.terms}>
                    <a
                      href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/terms-${config.locale === 'hu' ? `${config.locale}-2023-09-12` : config.locale
                        }.pdf`}
                      className={css.legalLink}
                      target="_blank"
                    >
                      <FormattedMessage id="Footer.terms" />
                    </a></div>
                </div>
              </div>
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}><FormattedMessage id="ProfileSettingsForm.marketingConsents" /></h3>
                {
                  consentsKeys.map(c => {
                    return (
                      <FieldCheckbox
                        id={c}
                        name={c}
                        label={intl.formatMessage({ id: CONSENT_LABELS[c] }, { b: <br /> })}
                        textClassName={css.consentLabel}
                        checked={values[c]}
                        valueFromForm={values[c]}
                        useSuccessColor
                      />
                    )
                  })
                }
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
