import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { required } from '../../util/validators';

import css from './GetDataFromURLForm.css';

const GetDataFromURLFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      const urlLabel = intl.formatMessage({
        id: 'GetDataFromURLForm.urlLabel',
      });
      const urlPlaceholder = intl.formatMessage({
        id: 'GetDataFromURLForm.urlPlaceholder',
      });
      const urlRequiredMessage = intl.formatMessage({
        id: 'GetDataFromURLForm.urlRequired',
      });

      const classes = classNames(rootClassName, className);

      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.urlInput}
            id={formId ? `${formId}.url` : 'url'}
            type="text"
            name="url"
            label={urlLabel}
            placeholder={urlPlaceholder}
            validate={required(urlRequiredMessage)}
          />
          <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
            <FormattedMessage id="GetDataFromURLForm.submitButtonText" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

GetDataFromURLFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
};

const { string, bool } = PropTypes;

GetDataFromURLFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const GetDataFromURLForm = compose(injectIntl)(GetDataFromURLFormComponent);
GetDataFromURLForm.displayName = 'GetDataFromURLForm';

export default GetDataFromURLForm;