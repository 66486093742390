import React from 'react';
import { arrayOf, func, number, string, object } from 'prop-types';
import classNames from 'classnames';

import {
  GeocoderAttribution,
  CURRENT_LOCATION_ID,
} from '../../../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import { FormattedMessage } from '../../../../util/reactIntl';
import IconCurrentLocation from '../../../../components/LocationAutocompleteInput/IconCurrentLocation';

import css from './LocationPredictionsList.css';

const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const LocationPredictionsList = props => {
  const {
    rootClassName,
    className,
    attributionClassName,
    predictions,
    geocoder,
    highlightedIndex,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
  } = props;
  const classes = classNames(rootClassName || css.predictionsRoot, className);

  if (predictions.length === 0) return null;

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const item = (prediction, index) => {
    const isHighlighted = index === highlightedIndex;
    const predictionId = geocoder.getPredictionId(prediction);

    if (prediction.id === 'default-info') {
      return (
        <li className={css.defaultInfo} key={`default-info_${index}`}>
          <span>{prediction.label}</span>
        </li>
      );
    }

    return (
      <li
        key={predictionId}
        className={isHighlighted ? css.highlighted : null}
        onTouchStart={e => {
          e.preventDefault();
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={e => {
          e.preventDefault();
          onSelectStart();
        }}
        onTouchMove={e => {
          e.preventDefault();
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={e => {
          e.preventDefault();
          onSelectEnd(prediction);
        }}
        onMouseUp={e => {
          e.preventDefault();
          onSelectEnd(prediction);
        }}
      >
        <span>{prediction.label}</span>
        {predictionId === CURRENT_LOCATION_ID ? (
          <span className={css.currentLocation}>
            <IconCurrentLocation />
            <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
          </span>
        ) : (
          geocoder.getPredictionAddress(prediction)
        )}
      </li>
    );
  };

  return (
    <div className={classes}>
      <p className={css.subheading}>
        <FormattedMessage id={'MainFilters.suggestions'} />
      </p>
      <ul className={css.predictions}>{predictions.map(item)}</ul>
      <GeocoderAttribution className={attributionClassName} />
    </div>
  );
};

LocationPredictionsList.defaultProps = {
  rootClassName: null,
  className: null,
  attributionClassName: null,
  highlightedIndex: null,
};

LocationPredictionsList.propTypes = {
  rootClassName: string,
  className: string,
  attributionClassName: string,
  predictions: arrayOf(object).isRequired,
  geocoder: object.isRequired,
  highlightedIndex: number,
  onSelectStart: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectEnd: func.isRequired,
};

export default LocationPredictionsList;
