import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconAccount
} from '../../components';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserProviderId,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onDktLogin,
    cartLink,
    isWhiteTopbar
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className, { [css.hp]: currentPage === '/' }, { [css.whiteTopbar]: isWhiteTopbar });

  const user = ensureCurrentUser(currentUser);

  const isOfferEnabled = user.attributes.hasProvider || user.attributes.isAdmin;
  // const search = currentPage !== '/' ? (
  //   <TopbarSearchForm
  //     className={css.searchLink}
  //     desktopInputRoot={css.topbarSearchWithLeftPadding}
  //     onSubmit={onSearchSubmit}
  //     initialValues={initialSearchFormValues}
  //   />
  // ) : <div className={css.searchLink}></div>;

  const search =  <div className={css.searchLink}></div>;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserProviderId ? 'sales' : 'orders', providerId: currentUserProviderId || 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const addListingLink = authenticatedOnClientSide && currentUser && (currentUser.attributes.hasProvider || currentUser.attributes.isAdmin) && (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <IconAccount />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="Info">
          <div className={css.userWrapper}>
            <div className={css.name}>{`${currentUser?.attributes.firstName}  ${currentUser?.attributes.lastName}`}</div>
            <div className={css.email}>{currentUser?.attributes.email}</div>
          </div>
          <span className={css.menuItemBorder} />
        </MenuItem>
        {
          isOfferEnabled ?
            <MenuItem key="ManageListingsPage">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
                name="ManageListingsPage"
                params={{ providerId: currentUserProviderId || 'orders' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.yourListingsLink" />
              </NamedLink>
            </MenuItem> : null
        }
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InvoiceSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('InvoiceSettingsPage'))}
            name="InvoiceSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.invoiceSettingsLink" />
          </NamedLink>
        </MenuItem>
        { authenticatedOnClientSide && currentUser && (currentUser.attributes.hasProvider || currentUser.attributes.isAdmin) ?
          <MenuItem key="NewListingPage" className={css.createListingItem}>
            <NamedLink className={css.createListingLink} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          </MenuItem> : null
        }
        {/* {
          isOfferEnabled &&
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
        } */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const businessLink = isAuthenticatedOrJustHydrated ? null : (
    <a className={css.signupLink} href="https://partner.rent.decathlon.pl" target="_blank">
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.forBusiness" />
      </span>
    </a>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const dktLink = !authenticatedOnClientSide ? (
    <a className={css.loginLink} onClick={() => onDktLogin()}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </a>
  ) : null;

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {/*{addListingLink}*/}
      {inboxLink}
      {cartLink}
      {profileMenu}
      {/* {businessLink} */}
      {/* {signupLink} */}
      {/* {loginLink} */}
      {dktLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
