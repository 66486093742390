import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, shape, string, oneOf, arrayOf, object, func }  from 'prop-types';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperBusinessSideNav,
  Footer,
  NamedLink,
  Select,
} from '../../components';
import { TopbarContainer } from '..';
import Payouts from './Payouts/Payouts';
import DashboardSkeleton from './DashboardSkeleton/DashboardSkeleton';

import {
  queryOwnStripeAccounts,
  showOwnStripeAccount,
  queryStripeAccountPayouts,
} from './PayoutPage.duck';

import css from './PayoutPage.css';

const PayoutPageComponent = props => {
  const {
    stripeAccounts,
    queryStripeAccountsInProgress,
    intl,
    params,
    location,
    history,
    scrollingDisabled,
    stripeAccountData,
    showStripeAccountInProgress,
    payouts,
    pagination,
    queryPayoutsInProgress,
    queryPayoutsError,
    queryPayoutsSuccess,
    name,
  } = props;
  const dispatch = useDispatch();
  const futurePayoutStats = stripeAccountData?.attributes?.futurePayoutStats;
  const title = intl.formatMessage({ id: 'PayoutPage.title' });
  const { id } = params;

  const dashboardData = [
    {
      label: intl.formatMessage({ id: 'PayoutPage.readyAmount' }),
      value: futurePayoutStats && formatMoney(intl, futurePayoutStats?.ready),
    },
    {
      label: intl.formatMessage({ id: 'PayoutPage.readyTransactionsCount' }),
      value: futurePayoutStats && futurePayoutStats.readyTransactionsCount,
    },
    {
      label: intl.formatMessage({ id: 'PayoutPage.pendingAmount' }),
      value: futurePayoutStats && formatMoney(intl, futurePayoutStats?.pending),
    },
    {
      label: intl.formatMessage({ id: 'PayoutPage.pendingTransactionsCount' }),
      value: futurePayoutStats && futurePayoutStats.pendingTransactionsCount,
    },
  ];

  const isSelectedProvider = id !== intl.formatMessage({ id: 'PayoutPage.choose' });

  useEffect(() => {
    if (isSelectedProvider) {
      dispatch(showOwnStripeAccount(id));
      dispatch(queryStripeAccountPayouts(id, parse(location.search)));
    }
  }, [id, parse(location.search).page]);

  const handleAccountChange = event => {
    history.push(`/payouts/${event.target.value}`);
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={name} />
          <UserNav selectedPageName={name} />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab={name} />
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.title}>{intl.formatMessage({ id: 'PayoutPage.siteTitle' })}</div>
          <div className={css.payoutPanel}>
            <div>
              <Select
                value={id}
                onChange={handleAccountChange}
                label={intl.formatMessage({ id: "PayoutPage.provider"})}
                isValidation={false}
              >
                <option key={0}>{intl.formatMessage({ id: 'PayoutPage.choose' })}</option>
                {stripeAccounts.map(acc => {
                  return <option value={acc.id.uuid} key={acc.id.uuid}>{acc.attributes.name}</option>;
                })}
              </Select>
            </div>
            {isSelectedProvider && (
              <>
                {showStripeAccountInProgress ? (
                  <DashboardSkeleton />
                ) : (
                  <div className={css.wrapper}>
                    <div className={css.statusSection}>
                      {futurePayoutStats &&
                        dashboardData.map(({ label, value }, index) => (
                          <div className={css.statusContainer} key={index}>
                            <p className={css.text}>{label}</p>
                            <p className={css.textResult}>{value}</p>
                          </div>
                        ))}
                    </div>
                    <NamedLink className={css.btnLink} name={'PayoutFuturePage'} params={{ id }}>
                      {intl.formatMessage({ id: 'PayoutPage.pendingAndReadyTransactions' })}
                    </NamedLink>
                  </div>
                )}
                <Payouts
                  title={title}
                  intl={intl}
                  payouts={payouts}
                  pagination={pagination}
                  queryPayoutsInProgress={queryPayoutsInProgress}
                  queryPayoutsError={queryPayoutsError}
                  queryPayoutsSuccess={queryPayoutsSuccess}
                  search={parse(location.search)}
                  id={id}
                  params={params}
                />
              </>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutPageComponent.propTypes = {
  pagination: propTypes.pagination,
  queryStripeAccountsInProgress: bool.isRequired,
  showStripeAccountError: oneOf([bool, propTypes.error]),
  showStripeAccountInProgress: bool.isRequired,
  queryPayoutsInProgress: bool.isRequired,
  queryPayoutsError: oneOf([bool, propTypes.error]),
  queryPayoutsSuccess: bool.isRequired,
  transaction: propTypes.transaction,
  name: string,
  scrollingDisabled: bool.isRequired,
  params:  shape({ id: string }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  payouts: arrayOf(object).isRequired,
  stripeAccounts: arrayOf(object).isRequired,
  stripeAccountData: object,
};

const mapStateToProps = state => {
  const {
    stripeAccounts,
    queryStripeAccountsInProgress,
    queryStripeAccountsError,
    showStripeAccountInProgress,
    showStripeAccountError,
    stripeAccountData,
    payouts,
    pagination,
    queryPayoutsInProgress,
    queryPayoutsError,
    queryPayoutsSuccess,
  } = state.PayoutPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    stripeAccounts,
    queryStripeAccountsInProgress,
    showStripeAccountInProgress,
    showStripeAccountError,
    stripeAccountData,
    payouts,
    pagination,
    queryPayoutsInProgress,
    queryPayoutsError,
    queryPayoutsSuccess,
  };
};

const PayoutPage = compose(
  connect(mapStateToProps, null),
  injectIntl,
  withRouter
)(PayoutPageComponent);

PayoutPage.loadData = (params, search) => {
  return queryOwnStripeAccounts();
};

export default withRouter(PayoutPage);
