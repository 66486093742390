import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import qualityImg from './quality.svg';
import cancelImg from './cancel.svg';
import onlineImg from './online.svg';
import satisfactionImg from './satisfaction.svg';
import css from './SectionFeatures.css';
import { FormattedMessage } from 'react-intl';

const SectionFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.featuresWrapper}>
        <div className={css.feature}>
          <div>
            <img css={css.featureImage} alt="jakość" src={qualityImg} />
          </div>
          <div className={css.title}>
            <FormattedMessage id="SectionFeatures.quality"/>
          </div>
        </div>
        <div className={css.feature}>
          <div>
            <img css={css.featureImage} alt="anulacja" src={cancelImg} />
          </div>
          <div className={css.title}>
            <FormattedMessage id="SectionFeatures.cancel"/>
          </div>
        </div>
        <div className={css.feature}>
          <div>
            <img css={css.featureImage} alt="satysfakcja" src={satisfactionImg} />
          </div>
          <div className={css.title}>
            <FormattedMessage id="SectionFeatures.satisfaction"/>
          </div>
        </div>
        <div className={css.feature}>
          <div>
            <img css={css.featureImage} alt="online" src={onlineImg} />
          </div>
          <div className={css.title}>
            <FormattedMessage id="SectionFeatures.online"/>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFeatures;
