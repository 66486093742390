import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconSpinner, IconCheckmark, Modal } from '../../components';

import css from './Button.css';
import { FormattedMessage } from 'react-intl';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false, modalIsOpen: false };
  }

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  clickHandler = () => {
    const { confirmationNeeded, onClick } = this.props;

    if (confirmationNeeded) {
      return () => this.setState({ modalIsOpen: true });
    }
    return onClick;  
  }

  render() {
    const {
      children,
      className,
      confirmationNeeded,
      rootClassName,
      spinnerClassName,
      checkmarkClassName,
      inProgress,
      ready,
      disabled,
      onClick,
      ...rest
    } = this.props;

    const { modalIsOpen } = this.state;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className, {
      [css.ready]: ready,
      [css.inProgress]: inProgress,
    });

    let content;

    if (inProgress) {
      content = <IconSpinner rootClassName={spinnerClassName || css.spinner} />;
    } else if (ready) {
      content = <IconCheckmark rootClassName={checkmarkClassName || css.checkmark} />;
    } else {
      content = children;
    }

    // All buttons are disabled until the component is mounted. This
    // prevents e.g. being able to submit forms to the backend before
    // the client side is handling the submit.
    const buttonDisabled = this.state.mounted ? disabled : true;

    return (
      <>
          {
            confirmationNeeded &&
              <Modal
                id="Button.confirmationModal"
                isOpen={modalIsOpen && this.state.mounted}
                onClose={()=>this.setState({ modalIsOpen: false })}
                onManageDisableScrolling={()=>{}}
                usePortal
              >
                <h2><FormattedMessage id="Button.confirmation" /></h2>
                <div className={css.confirmationButtons}>
                  <SecondaryButton
                    onClick={()=>this.setState({ modalIsOpen: false })}
                  >
                    <FormattedMessage id="Button.cancel" />
                  </SecondaryButton>
                  <PrimaryButton
                    inProgress={inProgress}
                    onClick={onClick}
                  >
                    {content}
                  </PrimaryButton>
                </div>
              </Modal>
          }
        <button className={classes} {...rest} disabled={buttonDisabled} onClick={this.clickHandler()}>
          {content}
        </button>
      </>
    );
  }
}

const { node, string, bool } = PropTypes;

Button.defaultProps = {
  rootClassName: null,
  className: null,
  confirmationNeeded: false,
  spinnerClassName: null,
  checkmarkClassName: null,
  inProgress: false,
  ready: false,
  disabled: false,
  children: null,
};

Button.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,

  inProgress: bool,
  ready: bool,
  disabled: bool,

  children: node,
};

export default Button;

export const PrimaryButton = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonRoot, css.primaryButton);
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton = props => {
  const classes = classNames(props.rootClassName || css.secondaryButtonRoot, css.secondaryButton);
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  const classes = classNames(props.rootClassName || css.inlineTextButtonRoot, css.inlineTextButton);
  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';
