export const phraseIsWord = (itemText, value) => {
    const text = itemText.toLowerCase();
    const keywordValue = value.keywords.toLowerCase();
    const firstChar = keywordValue[0];
    const lastChar = keywordValue[keywordValue.length - 1];
    let keyword = keywordValue;
  
    if (firstChar === ' ') {
      keyword = keyword.substring(1);
    }
    if (lastChar === ' ') {
      keyword = keyword.substring(0, keyword.length - 1);
    }
  
    const isExact = keyword === text;
    const wordBegin = text.includes(`${keyword} `) && text.indexOf(keyword) === 0;
    const wordMiddle = text.includes(` ${keyword} `);
    const wordEnd =
      text.includes(` ${keyword}`) && text.indexOf(keyword) + keyword.length - 1 === text.length - 1;
    return wordBegin || wordMiddle || wordEnd || isExact;
  };
  

 export const getLabel = (item, intl, categories, businessCategoryConfig) => {
    const {  attributes: { parentId }, id: { uuid } } = item;
    const categoryLabel = !parentId && categories.find(option => option.key === uuid);
    const subcategoryLabel = parentId && businessCategoryConfig.find(({ key }) => key === uuid);
    const labelValue = parentId ? subcategoryLabel : categoryLabel;
    return intl.formatMessage({ id: labelValue.translation });
  };
  
  
 export const sortBy = (items, value, intl, categories, businessCategoryConfig) => {
    const filteredFittedCategorySuggestion = items.filter(
      item =>
        phraseIsWord(getLabel(item, intl, categories, businessCategoryConfig), value) 
        && item.attributes.keyword.exact
    );
    const filteredOtherCategorySuggestion = items.filter(
      item =>
        !phraseIsWord(getLabel(item, intl, categories, businessCategoryConfig), value) 
        && item.attributes.keyword.exact
    );
    const filteredFittedSuggestion = items.filter(item => {
      const { attributes: { keyword: { exact, text } } } = item;
      return phraseIsWord(text, value) && !exact;
    });
    const filteredOthersSuggestion = items.filter(item => {
      const { attributes: { keyword: { exact, text } } } = item;
      return !phraseIsWord(text, value) && !exact;
    });
  
    return [
      ...filteredFittedCategorySuggestion,
      ...filteredOtherCategorySuggestion,
      ...filteredFittedSuggestion,
      ...filteredOthersSuggestion,
    ];
  };
  