import { ExperimentId, ExperimentsWithVariants } from './index';

function getSortedVariantsAndWeights(variantsWithWeights) {
  const sortedVariants = variantsWithWeights
    .map(variant => Object.keys(variant)[0])
    .sort((a, b) => a + b);

  const sortedWeights = variantsWithWeights.map(variant => variant[Object.keys(variant)[0]]);

  const sortedVariantsWithWeights = variantsWithWeights.sort(
    (a, b) => a[Object.keys(a)[0]] + b[Object.keys(b)[0]]
  );

  return {
    sortedVariants,
    sortedWeights,
    sortedVariantsWithWeights,
  };
}

export function selectVariant({ variants, weights }) {
  const variantsWithWeights = variants.map((variant, i) => ({
    [variant]: weights[i],
  }));

  const { sortedVariants, sortedWeights } = getSortedVariantsAndWeights(variantsWithWeights);

  const weightSum = sortedWeights.reduce((a, b) => a + b, 0);
  let weightedIndex = Math.floor(Math.random() * weightSum);
  let selectedVariant = sortedVariants[sortedVariants.length - 1];

  for (const [i, sortedWeight] of sortedWeights.entries()) {
    weightedIndex -= sortedWeight;
    if (weightedIndex < 0) {
      selectedVariant = sortedVariants[i];
      break;
    }
  }

  return selectedVariant;
}

export const initMissingAbExperiments = (currentlyActiveExperiments = {}) => {
  const availableExperiments = Object.values(ExperimentId);

  return availableExperiments.reduce((acc, experimentId, index) => {
    const experiment = ExperimentsWithVariants[experimentId];

    if (!experiment) {
      return acc;
    }

    return {
      ...acc,
      [experimentId]: currentlyActiveExperiments[experimentId] || selectVariant(experiment),
    };
  }, {});
};
