import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { BUSINESS_PAGES, ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ensureCurrentUser } from '../../util/data';

import css from './UserNav.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);

  // const isOfferEnabled = !!user.attributes.profile.metadata.decathlon_user || !!user.attributes.profile.metadata.is_provider;
  const isOfferEnabled = false;
  
  const manageListingsTab = isOfferEnabled ? [{
    text: <FormattedMessage id="ManageListingsPage.yourRental" />,
    selected: BUSINESS_PAGES.includes(selectedPageName),
    linkProps: {
      name: 'BusinessPage',
    },
  }] : []

  const tabs = [
    ...manageListingsTab,
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.invoiceSettings" />,
      selected: selectedPageName === 'InvoiceSettingsPage',
      disabled: false,
      linkProps: {
        name: 'InvoiceSettingsPage',
      },
    },
    // {
    //   text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
    //   selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
    //   disabled: false,
    //   linkProps: {
    //     name: 'PaymentMethodsPage',
    //   },
    // },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default connect((state) => {
  return {
    currentUser: state.user.currentUser
  }
})(UserNav);
