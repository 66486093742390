import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { RadioButton } from '../../components';

import css from './FieldRadioButton.css';

const IconRadioButton = props => {
  return (
    <div>
      <svg className={props.className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={props.showAsRequired ? css.required : css.notChecked}
          cx="5"
          cy="19"
          r="6"
          transform="translate(2 -12)"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />

        <g className={css.checked} transform="translate(2 -12)" fill="none" fillRule="evenodd">
          <circle strokeWidth="2" cx="5" cy="19" r="6" />
          <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
        </g>
      </svg>
    </div>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
  const { rootClassName, className, svgClassName, textClassName,  id, label, showAsRequired, size, radioClassName, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);
  const classesRadio = classNames(radioClassName, css.radio)
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps}>
        {({ input }) => (
          <RadioButton  
            label={label}
            size={size}
            {...input}
            textClassName={classNames(css.text, textClassName)}
            radioClassName={classesRadio}
          />
        )}
      </Field>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  label: null,
  size: 'small',
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
  size: string
};

export default FieldRadioButtonComponent;
