
export const viewItemEvent = params => {
    const { currency, value, item } = params;
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: currency,
        value: value,
        items: [
          {
            item_id: item.id,
            item_name: item.name,
            item_brand: '',
            item_category: item.category,
            item_variant: '',
            store_name: item.provider,
            location_id: '',
            price: item.price,
            quantity: item.quantity,
          },
        ],
      },
    });
  };
  