import React from 'react';
import { func, string } from 'prop-types';
import * as validators from '../../util/validators';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, FieldSelect, FieldTextInput, IconClose } from '../../components';
import { Field } from 'react-final-form';


import css from './EditListingCheckoutFieldsForm.css';

const customFormFieldTypes = [
  { name: 'text', label: 'Pole tekstowe'},
  // { name: 'select', label: 'Lista rozwijalna'},
  // { name: 'checkbox', label: 'Pole wyboru'},
  // { name: 'radio', label: 'Pole opcji'},
]

export const CustomFormFieldset = props => {
  const { fieldId, intl, push } = props;

  const requiredMessage = intl.formatMessage({
    id: 'EditListingCheckoutFieldsForm.validateRequired',
  });
  const requiredValidation = validators.required(requiredMessage);
  const typeLabel = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.typeLabel' });
  const typePlaceholder = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.typePlaceholder' });
  const nameLabel = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.nameLabel' });
  const namePlaceholder = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.namePlaceholder' });

  const optionsLabel = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.optionsLabel' });

  const placeholderLabel = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.placeholderLabel' });
  const placeholderPlaceholder = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.placeholderPlaceholder' });

  const requiredLabel = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.requiredLabel' });
  const requiredPlaceholder = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.requiredPlaceholder' });

  const valueChecked = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.valueChecked' });
  const deleteOption = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.deleteOption' });
  const addOption = intl.formatMessage({ id: 'EditListingCheckoutFieldsForm.addOption' });


  const valueChange = (fields, index) => {
    const currValue = fields.value[index];

    if (!currValue) return null;

    const { placeholder, options, ...updatedValue } = currValue;

    return fields.update(index, updatedValue);
  }

  return (
    <>
      <FieldArray id={`${fieldId}`} name={`${fieldId}`}>
        {({ fields }) => {
          return fields.map((name, index) => (
            <div key={name} className={css.fieldWrapper}>
              <div
                className={css.removeBtn}
                onClick={() => fields.remove(index)}
              >
                {/* <span>{deleteField}</span> */}
                <IconClose />
              </div>
              <div className={css.firstRow}>
                <FieldTextInput
                  className={css.field}
                  id={`${fieldId}.${index}.label`}
                  label={nameLabel}
                  name={`${fieldId}.${index}.label`}
                  placeholder={namePlaceholder}
                  type="text"
                  validate={requiredValidation}
                />
                <Field name={`${fieldId}.${index}.type`}>
                  {({input, meta}) => {
                    const { onChange } = input
                    const selectOnChange = e => {
                      valueChange(fields, index);
                      onChange(e);
                    }
                    const customInput = { ...input, onChange: selectOnChange }
                    return (
                      <FieldSelect
                        className={css.field}
                        id={`${fieldId}.${index}.type`}
                        label={typeLabel}
                        name={`${fieldId}.${index}.type`}
                        validate={requiredValidation}
                        {...customInput}
                      >
                        <option disabled value="">
                          {typePlaceholder}
                        </option>
                        {customFormFieldTypes.map(type => (
                          <option key={type.name} value={type.name}>
                            {type.label}
                          </option>
                        ))}
                      </FieldSelect>
                    )
                  }}
                </Field>
              </div>
              {/* <div className={css.field}>
                <p className={css.label}>{requiredLabel}</p>
                <FieldCheckbox
                  className={css.checkbox}
                  id={`${fieldId}.${index}.required`}
                  name={`${fieldId}.${index}.required`}
                  label={requiredPlaceholder}
                  value={valueChecked}
                />
              </div> */}

              {fields.value[index] && fields.value[index].type ?
               fields.value[index].type === 'text' || fields.value[index].type === 'checkbox' ? <FieldTextInput
                className={css.field}
                id={`${fieldId}.${index}.placeholder`}
                label={placeholderLabel}
                name={`${fieldId}.${index}.placeholder`}
                placeholder={placeholderPlaceholder}
                type="text"
                validate={requiredValidation}
              /> :
                <div className={css.optionsContainer}>
                  <div className={css.label}>{optionsLabel}</div>
                  <FieldTextInput
                    className={css.field}
                    id={`${fieldId}.${index}.options.main.0`}
                    name={`${fieldId}.${index}.options.main.0`}
                    placeholder={ intl.formatMessage(
                      { id: 'EditListingCheckoutFieldsForm.option' },
                      { option: 1 })}
                    type="text"
                    validate={requiredValidation}
                  />
                  <FieldTextInput
                    className={css.field}
                    id={`${fieldId}.${index}.options.main.1`}
                    name={`${fieldId}.${index}.options.main.1`}
                    placeholder={ intl.formatMessage(
                      { id: 'EditListingCheckoutFieldsForm.option' },
                      { option: 2 })}
                    type="text"
                    validate={requiredValidation}
                  />

                  <FieldArray id={`${fieldId}.${index}.options.add`} name={`${fieldId}.${index}.options.add`}>
                    {({ fields }) =>
                      fields.map((name, i) => (
                        <div key={name}>
                          <div className={css.removeOptionBtn} onClick={() => fields.remove(i)}>
                            <span>{deleteOption}</span>
                            <IconClose />
                          </div>
                          <FieldTextInput
                            className={css.field}
                            id={`${fieldId}.${index}.options.add.${i}`}
                            key={name}
                            name={`${fieldId}.${index}.options.add.${i}`}
                            placeholder={ intl.formatMessage(
                              { id: 'EditListingCheckoutFieldsForm.option' },
                              { option: i+ 3 })}
                            type="text"
                            validate={requiredValidation}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                  <div className={css.addOptionBtn} onClick={() => push(`${fieldId}.${index}.options.add`, undefined)}>
                    {addOption}
                    +
                  </div>
                </div> : null}

            </div>
          ))}
        }
      </FieldArray>
    </>
  )
}

CustomFormFieldset.propTypes = {
  fieldId: string.isRequired,
  push: func.isRequired,
};

export default CustomFormFieldset;