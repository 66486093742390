import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData, requestUpdateProvider } from './ConsoleProviderPage.duck';
import { ConsoleWrapper, IconSpinner, Input } from '../../components';
import ProviderItem from './ProviderItem/ProviderItem';

import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './ConsoleProviderPage.css';

const ConsoleProviderPageComponent = props => {
  const {
    providers,
    fetchInProgress,
    scrollingDisabled,
    fetchProviderListError,
    intl,
    location: { search, pathname },
    history,
    tab,
    name,
    updateProvider,
    updateProviderError,
    updateProviderInProgress,
  } = props;

  const {phrase, page} = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);

  const errorMessage = fetchProviderListError ? (
    <p className={css.error}>{fetchProviderListError.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !providers.length && !fetchProviderListError ? (
      <p>
        <FormattedMessage id="Console.noProvidersFound" />
      </p>
    ) : null;

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        phrase: searchPhrase ? searchPhrase : undefined,
        page: 1,
      })}`,
    });
  };

  const providerSearch = (
    <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
      <Input
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Console.searchProvider' })}
        label={intl.formatMessage({ id: 'Console.search' })}
      />
    </form>
  );

  useEffect(() => {
    if (!page) {
      setSearchPhrase('');
      history.push({ pathname, search: `${stringify({ ...parse(search), page: 1 })}` });
    }
  }, [page]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      {providerSearch}
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <ul>
          {providers.map(provider => {
            const id = provider.id.uuid;
            return (
              <ProviderItem
                key={id}
                providerId={id}
                name={provider.attributes.name}
                banned={provider.attributes.banned}
                language={provider.attributes.countryId}
                updateProvider={updateProvider}
                intl={intl}
                updateProviderInProgress={updateProviderInProgress}
                updateProviderError={updateProviderError}
              />
            );
          })}
        </ul>
      )}
      {noResultsMessage}
    </ConsoleWrapper>
  );
};

ConsoleProviderPageComponent.defaultProps = {
  providers: [],
  fetchInProgress: false,
  fetchProviderListError: null,
  scrollingDisabled: false,
  updateProviderInProgress: false,
  updateProviderError: false,
};

ConsoleProviderPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchProviderListError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  providers: arrayOf(propTypes.providerDetails),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  updateProvider: func.isRequired,
  updateProviderInProgress: bool.isRequired,
  updateProviderError: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchProviderListError,
    providerList,
    updateProviderInProgress,
    updateProviderError,
  } = state.ConsoleProviderPage;
  return {
    fetchInProgress,
    fetchProviderListError,
    updateProviderInProgress,
    updateProviderError,
    scrollingDisabled: isScrollingDisabled(state),
    providers: getMarketplaceEntities(state, providerList),
  };
};

const mapDispatchToProps = dispatch => ({
  updateProvider: data => dispatch(requestUpdateProvider(data)),
});

const ConsoleProviderPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleProviderPageComponent);

ConsoleProviderPage.loadData = loadData;

export default ConsoleProviderPage;
