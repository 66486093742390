import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { IconChevronRight, NamedLink } from '../../components';

import css from './SectionReviews.css';

const reviews = [
  {
    grade: 5,
    text: 'Fajna sprawa z tym wypożyczeniem - myslę, że jeszcze nie raz tego użyje. Poprawiłbym jedynie opcje przedłużenia. Brakowało mi informacji jak jak zrobić. Reszta ok.',
    formattedDate: '2021-03-25',
    author: 'Kamil N',
    productId: '5fa6b2e1-f2f6-49ab-8291-080112c960e7',
    title: 'Bieżnia RUN100'
  },
  {
    grade: 5,
    text: 'Bardzo fajny sposób aby przetestować rower. To był mój pierwszy kontakt z rowerem szosowym i moje odczucia były bardzo pozytywne. Sprzęt dobrze przygotowany.', 
    formattedDate: '2021-03-02',
    author: 'Łukasz G',
    productId: '5f9ec307-e81f-48c1-b275-1d4c0fd8574f',
    title: 'Rower szosowy RC120 TRIBAN'
  },
  { grade: 4,
    text: 'Ogólnie wszystko ok, zarówno obsluga jak i sam rower. Niestety musze zabrac jedna gwiazdke za niedokrecone siodelko...', 
    formattedDate: '2021-03-25',
    productId: '5f9ebc28-8cee-4833-9b09-6f6d4b4bf573',
    title: 'Rower szosowy RC520 Triban',
    author: 'Jakub D'
  },
  { grade: 5,
    text: 'Wypożyczenie, sposób rezerwacji, zwrot - wszystko bez zastrzeżeń. A sam sprzęt super sprawa - fajnie, że można przetestować przed ewentualnym zakupem. ', 
    formattedDate: '2021-03-01',
    productId: '5f883a08-9d7e-4454-9454-33e8197d4e8d',
    title: 'Trenażer Flow Smart TACX',
    author: 'Jakub B'
  },
  { grade: 4,
    text: 'Wszystko fajnie. Rower nówka, tylko trochę słabe hamulce. Nie wiem czy to kwestia regulacji czy może tego że były jeszcze "nie dotarte". ', 
    formattedDate: '2021-04-10',
    productId: '5f9ea665-82ab-4569-894d-dc590f39c2a7',
    author: 'Piotr J',
    title: 'Rower szosowy TRIBAN WOMEN'
  },
  { grade: 5,
    text: 'Rower super. Ciesze się, że był naładowany. Największym minusem był czas odbioru - czekałem 40 minut w sklepie.', 
    formattedDate: '2020-04-10',
    productId: '6034ca8b-855f-45e6-8d35-36fc5c3f6bc2',
    title: 'WÓZEK DLA DZIECKA IXPLORER',
    author: 'Mateusz B'
  }
];

const MAX_MOBILE_SCREEN_WIDTH = 768;

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.icon)} onClick={onClick}>
      <IconChevronRight className={css.left}/>
    </div>
  );
};

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.icon)} onClick={onClick}>
      <IconChevronRight />
    </div>
  );
}

function SectionReviews(props) {
  const { rootClassName, className, screenWidth } = props;

  const classes = classNames(rootClassName || css.root, className);

  const settings = {
    slidesToShow: screenWidth <= MAX_MOBILE_SCREEN_WIDTH ? 1 : 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionReviews.reviews" />
      </div>

      <div className={css.reviews}>
        <Slider {...settings} className={css.slider}>
          {
            reviews.map(({ grade, text, 
            
          formattedDate, title, author, productId }) => (
              <div className={css.reviewWrapper}>
                <div className={css.ratingsWrapper}>
                  <div className={classNames(css.stars, { [css.five]: grade === 5, [css.four]: grade === 4,  [css.three]: grade === 3 })} />
                  <span className={css.rating}>({grade})</span>
                </div>
                <span className={css.product}>{title}</span>
                <p className={css.review}>{text}</p>
                <span className={css.date}>{formattedDate}</span>
                <span className={css.author}>{author}</span>
                <NamedLink name="ListingPageCanonical" params={{ id: productId }} className={css.link}>
                  {title}
                </NamedLink>
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  );
}

export default SectionReviews;