import React from 'react';
import PropTypes from 'prop-types';

const IconAccount = props => {
  const { className, color } = props;
  return (
   <svg
     viewBox="0 0 24 24"
     xmlns="http://www.w3.org/2000/svg"
     fill={color}
     role="presentation"
     className={className}
     width="24"
     height="24"
   >
     <path d="M11.7749 10.8814C13.9476 10.8814 15.7153 9.11365 15.7153 6.94096C15.7153 4.76826 13.9476 3 11.7749 3C9.60225 3 7.83398 4.7677 7.83398 6.94096C7.83398 9.11421 9.60169 10.8814 11.7749 10.8814ZM11.7749 4.68513C13.0186 4.68513 14.0302 5.69677 14.0302 6.94096C14.0302 8.18514 13.0186 9.19622 11.7749 9.19622C10.5313 9.19622 9.51911 8.18458 9.51911 6.94096C9.51911 5.69733 10.5308 4.68513 11.7749 4.68513Z"
           fill={color}
     >
     </path>
     <path d="M16.8268 15.6209C15.5169 13.2972 13.7217 12.0176 11.7714 12.0176C9.82116 12.0176 8.02649 13.2972 6.71602 15.6209C5.86279 17.1348 5.27692 18.9878 5 20.9999H6.70647C7.34458 16.8427 9.40605 13.7021 11.7714 13.7021C14.1368 13.7021 16.1977 16.8421 16.8364 20.9999H18.5428C18.2659 18.9873 17.68 17.1342 16.8268 15.6209Z"
           fill={color}
     >
     </path>
   </svg>
  );
};

IconAccount.defaultProps = { className: null, color: "#4a4a4a" };

const { string } = PropTypes;

IconAccount.propTypes = { className: string, color: string };

export default IconAccount;
