import React, { forwardRef } from 'react';
import { number, string, func, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';

import css from './AutocompleteSelect.css';

const Options = ({
  value,
  handleClick,
  highlightedSuggestionIndex,
  listClasses,
  options,
  setScroll
}, ref) => {

  return (
    <div className={css.optionsContainer}>
      <ul className={listClasses} ref={ref}>
        {options?.length ?
          options?.map((option, index) => {
          const labelLowerCase = option.label.toLowerCase();
          const keywordLowerCase = value.toLowerCase();
          const indexOfFirst = labelLowerCase.indexOf(keywordLowerCase);
          const indexOfSecond = labelLowerCase.indexOf(keywordLowerCase) >= 0 ? (labelLowerCase.indexOf(keywordLowerCase) + value.length) : 0;
          const firstPart = option.label.substring(0, indexOfFirst);
          const secondPart = option.label.substring(indexOfFirst,indexOfSecond);
          const thirdPart = option.label.substring(indexOfSecond);
            let isSelector;
            if (index === highlightedSuggestionIndex) {
              isSelector = "highlighted";
            }

            setTimeout(() => {
              setScroll();
            }, [50]);

          return (
            <li
              key={index}
              onClick={() => handleClick(option.value, option.label)}
              className={classNames(css.listItem, {
                [css.highlightedSuggestion]: index === highlightedSuggestionIndex,
              })}
              id={isSelector}
            >
                <>
                  <span className={css.text}>{firstPart}</span>
                  <span className={css.searchText}>{secondPart}</span>
                  <span className={css.text}>{thirdPart}</span>
                </>
            </li>
          );
        })
          : <li className={css.notFound}>
            <span className={css.text}>Nie znaleziono</span>
          </li>
        }
      </ul>
    </div>
  );
};

Options.defaultProps = {
  listClasses: null,
  suggestions: [],
};

Options.propTypes = {
  listClasses: string,
  handleClick: func.isRequired,
  options: arrayOf(shape({ value: string, label: string })),
  highlightedSuggestionIndex: number.isRequired,
  value: string,
};

export default forwardRef(Options);
