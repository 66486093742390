import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';

import Actiff from './actiff.png';
import Dobrenarty from './dobrenarty.jpg';
import Kajakwstolicy from './kajakwstolicy.png';
import Suptours from './suptours.png';
import Boxy from './wypozyczalniaboxow.png';
import Tsl from './tsl.png';
import Autocarrier from './autocarrier.png';
import Onlyebikes from './onlyebikes.png';
import Tentrent from './tentrent.png';

import { IconChevronRight } from '../../components';
import { NamedLink } from '../../components';

import css from './SectionOurPartners.css';
import { FormattedMessage } from 'react-intl';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const partners = [
  { name: 'Dobrenarty.pl', imgUrl: Dobrenarty, to: 'address=Polska&bounds=50.01987671%2C19.95049776%2C49.95365341%2C19.87634004' },
  { name: 'Actiff', imgUrl: Actiff, to: 'address=Polska&bounds=50.1250903%2C19.94989096%2C50.05901207%2C19.87573324' },
  { name: 'Wypożyczalnia boxów', imgUrl: Boxy, to: 'address=Polska&bounds=52.23746963%2C20.94561256%2C52.17435056%2C20.87145484' },
  // { name: 'kajakwstolicy', imgUrl: Kajakwstolicy },
  { name: 'Suptours', imgUrl: Suptours, to: 'address=86-010%20Samociążek%2C%20Polska&bounds=53.28325363017296%2C17.98760642377142%2C53.26990972838132%2C17.95559157622858' },
  { name: 'Technology Sport Life', imgUrl: Tsl, to: 'address=Polska&bounds=50.08477186%2C19.99487703%2C50.0486851%2C19.95779817' },
  { name: 'Onlyebikes', imgUrl: Onlyebikes, to: 'address=Polska&bounds=52.3721813%2C20.99464606%2C52.30348107%2C20.92048834' },
  { name: 'AUTO-CARRIER', imgUrl: Autocarrier, to: 'address=Polska&bounds=50.38137225%2C19.08719276%2C50.30961907%2C19.01303504' },
  { name: 'TENTRENT', imgUrl: Tentrent, to: 'address=Tarnowskie%20Góry%2C%20Polska&bounds=50.60874782%2C18.99013321%2C50.32246275%2C18.69392078' }
]

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.icon)} onClick={onClick}>
      <IconChevronRight className={css.left}/>
    </div>
  );
};

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.icon)} onClick={onClick}>
      <IconChevronRight />
    </div>
  );
}

const SectionOurPartners = props => {
  const { rootClassName, className, screenWidth } = props;

  const settings = {
    slidesToShow: screenWidth <= MAX_MOBILE_SCREEN_WIDTH ? 2 : 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionOurPartners.ourPartners"/>
      </div>
      <div>
      <Slider {...settings} className={css.slider}>  
        {
          partners.map(partner =>
            <NamedLink
              name="SearchPage"
              to={{
                search: partner.to,
              }}
              className={css.partnerLink}
              key={partner.url}
            >
                <img
                  alt={partner.name}
                  src={partner.imgUrl}
                />
            </NamedLink>
          )
        }
      </Slider>
    </div>
    </div>
  );
};

SectionOurPartners.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionOurPartners.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionOurPartners;