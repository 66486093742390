import React, { useState } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { LINE_ITEM_PROMO_DISCOUNT } from '../../../util/types';
import { Button, IconClose, Input } from '../../../components';
import css from './PromoCode.css';

const PromoCode = ({
  initialPromoCodeValue,
  applyPromoCode,
  cancelPromoCode,
  validatePromoInProgress,
  info,
  intl,
  transaction
}) => {
  const [value, setValue] = useState(initialPromoCodeValue);
  const [isPromoAdd, setIsPromoAdd] = useState(false);

  const promoCode = transaction ? transaction.attributes.lineItems.filter(
    item => item?.code === LINE_ITEM_PROMO_DISCOUNT && !item?.reversal
  ) : [];

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    applyPromoCode(value);
    setIsPromoAdd(true);
  };

  const handleRemove = () => {
    isPromoAdd && cancelPromoCode();
    setIsPromoAdd(false);
    setValue('');
  };

  const removeButton = (classNamesRemove) => (
    value ? <button onClick={handleRemove} className={classNamesRemove}>
      <IconClose />
    </button> : null
  );

  const successMessage = (!!promoCode.length || !transaction.attributes?.payinTotal.amount) && isPromoAdd && <p className={css.successMessage}> <FormattedMessage id="PromoCode.successMessage"/></p>

  const errorMessage = info && isPromoAdd && <p className={css.errorMessage}><FormattedMessage id={info} /></p>

  return (
    <div className={css.container}>
      <p className={css.title}>
      <FormattedMessage id="PromoCode.discountCode"/>
       <span className={css.labelText}>
        <FormattedMessage id="PromoCode.optional" />
       </span>
      </p>
      <div className={css.wrapper}>
        <div className={css.codeWrapper}>
          {isPromoAdd ? (
            <div className={css.promoCodeContainer}>
              <span className={css.promoText}>{value}</span>
              {removeButton(css.removeBtn)}
            </div>
          ) : (
            <div className={css.inputContainer}>
              <Input
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "PromoCode.enterDiscountCode" })}
                isValidation={false}
              />
              {removeButton(classNames(css.removeBtn, css.positionBtn))}
            </div>
          )}
        </div>
        { (!isPromoAdd || validatePromoInProgress) && (
          <Button className={css.btn} onClick={handleSubmit} inProgress={validatePromoInProgress} disabled={!value}>
          <FormattedMessage id="PromoCode.addButton"/>
          </Button>
        )}
      </div>
      {successMessage }
      {errorMessage}
    </div>
  );
};

export default PromoCode;
