import { storableError } from '../util/errors';
import { fetchCurrentUser } from './user.duck';

// ================ Helpers ================ //
const STORAGE_KEY = 'Promo';

export const getData = () => {
  try {
    const serializedData = localStorage.getItem(STORAGE_KEY);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
}

export const storeData = (data) => {
  try {
    if (window && window.localStorage) {
      const serializedData = JSON.stringify(data)
      localStorage.setItem(STORAGE_KEY, serializedData)
    }
  } catch (err) {
    
  }
}

// ================ Action types ================ //

export const PROMO_VALIDATION_REQUEST = 'app/Promo/PROMO_VALIDATION_REQUEST';
export const PROMO_VALIDATION_SUCCESS = 'app/Promo/PROMO_VALIDATION_SUCCESS';
export const PROMO_VALIDATION_ERROR = 'app/Promo/PROMO_VALIDATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  isPromoOn: false, 
  code: null,
  title: '',
  discount: 0,
  discountedCategories: [],
  codeValid: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PROMO_VALIDATION_REQUEST:
      return { ...state, verificationInProgress: true, isVerified: false };
    case PROMO_VALIDATION_SUCCESS:
      return { ...state, ...payload.promo, isPromoOn: payload.codeValid, codeValid: payload.codeValid };
    case PROMO_VALIDATION_ERROR:
      return { ...state, isPromoOn: payload.codeValid };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const verificationInProgress = state => {
  return state.Promo.verificationInProgress;
};

// ================ Action creators ================ //

export const promoValidationRequest = () => ({ type: PROMO_VALIDATION_REQUEST });
export const promoValidationSuccess = payload => {
  storeData({ ...payload.promo, isPromoOn: payload.codeValid })
  return ({ type: PROMO_VALIDATION_SUCCESS, payload })
};
export const promoValidationError = error => ({
  type: PROMO_VALIDATION_ERROR,
  payload: error,
  error: true,
});