export default function(view) {
  const { name, productId, price, category, brand } = view;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_details',
    ecommerce: {
      currencyCode: 'PLN',
      detail: {
        products: [{
          name,
          id: productId,
          price,
          brand,
          category
        }]
      }
    },
    value: price,
    items: [
      {
        id: productId, 
        google_business_vertical: 'retail'
      }
    ]
  });
}